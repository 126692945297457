import React, { useState, useEffect } from "react";
import { DateRangePicker as RBDaterangepicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CalanderIcon from "../../../../assets/img/calander-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setStartDate, setEndDate, setDatesRangeLabel } from "../../../../_redux/analytics/analyticsSlice"; // Import actions
import toast from "react-hot-toast";
import { i18n } from "../../../../app/pages/private/languageSelector";

const DateRangePicker = ({
  setStartDate: setPropStartDate,
  setEndDate: setPropEndDate,
  startDate: propStartDate,
  endDate: propEndDate,
  handleTimeRangeChange,
  setSelectedTimeRange,
  selectedTimeRange,
  shouldSetDailyTimeRange,
}) => {
  const dispatch = useDispatch(); // Initialize dispatch
  const today = moment();

  const getInitialDates = () => {
    const savedStartDate = localStorage.getItem("startDate");
    const savedEndDate = localStorage.getItem("endDate");
    const savedRangeLabel = localStorage.getItem("rangeLabel");
    const savedTimeRange = localStorage.getItem("selectedTimeRange");

    if (savedStartDate && savedEndDate && savedRangeLabel && savedTimeRange) {
      return {
        startDate: moment(savedStartDate),
        endDate: moment(savedEndDate),
        rangeLabel: savedRangeLabel,
        timeRange: JSON.parse(savedTimeRange),
      };
    }

    if (propStartDate && propEndDate) {
      return {
        startDate: propStartDate,
        endDate: propEndDate,
        rangeLabel: "",
        timeRange: selectedTimeRange || [],
      };
    }

    return {
      startDate: propStartDate || moment().subtract(29, "days"),
      endDate: propEndDate || today,
      rangeLabel: "Last 30 Days",
      timeRange: [],
    };
  };

  const [startDate, setStartDateState] = useState(getInitialDates().startDate);
  const [endDate, setEndDateState] = useState(getInitialDates().endDate);
  const [rangeLabel, setRangeLabel] = useState(getInitialDates().rangeLabel);
  const [timeRange, setTimeRange] = useState(getInitialDates().timeRange);

  useEffect(() => {
    if (propStartDate && propEndDate) {
      setStartDateState(propStartDate);
      setEndDateState(propEndDate);
    }
  }, [propStartDate, propEndDate]);

  useEffect(() => {
    setPropStartDate(startDate);
    setPropEndDate(endDate);
    handleTimeRangeChange(timeRange);
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));
  }, [startDate, endDate, timeRange]);

  const handleApply = (event, picker) => {
    let { startDate, endDate } = picker;

    const daysDiff = endDate.diff(startDate, "days");

    // Check if the difference exceeds 366 days
    if (daysDiff > 365) {
      toast.error("You can't select a date range longer than 1 year.");
      return; // Exit early if the range exceeds 365 days
    }

    setPropStartDate(startDate);
    setPropEndDate(endDate);
    setStartDateState(startDate);
    setEndDateState(endDate);
    setRangeLabel(picker.chosenLabel);
    dispatch(setDatesRangeLabel(picker.chosenLabel));
    localStorage.setItem("startDate", startDate.toISOString());
    localStorage.setItem("endDate", endDate.toISOString());
    localStorage.setItem("rangeLabel", picker.chosenLabel);
    // dispatch(setDatesRangeLabel(picker.chosenLabel));

    let timeRanges = [];

    if (startDate.isSame(endDate, "day")) {
      timeRanges = ["hourly"];
      if (shouldSetDailyTimeRange) {
        setSelectedTimeRange("hourly");
      }
    } else if (daysDiff >= 365) {
      timeRanges = ["daily", "weekly", "monthly", "quarterly", "yearly"];
      if (shouldSetDailyTimeRange) {
        setSelectedTimeRange("daily");
      }
    } else if (daysDiff > 90) {
      timeRanges = ["daily", "weekly", "monthly", "quarterly"];
      if (shouldSetDailyTimeRange) {
        setSelectedTimeRange("daily");
      }
    } else {
      switch (picker.chosenLabel) {
        case "Today":
        case "Yesterday":
          timeRanges = ["hourly"];
          if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("hourly");
          }
          break;
        case "This Week":
        case "Last Week":
        case "Last 7 Days":
          timeRanges = ["daily", "weekly"];
          if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("daily");
          }
          break;
        case "This Month":
        case "Last Month":
        case "Last 30 Days":
          timeRanges = ["daily", "weekly", "monthly"];
          if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("daily");
          }
          break;
        default:
          timeRanges = ["daily", "weekly", "monthly"];
          if (shouldSetDailyTimeRange) {
            setSelectedTimeRange("daily");
          }
          break;
      }
    }

    setTimeRange(timeRanges);
    localStorage.setItem("selectedTimeRange", JSON.stringify(timeRanges));
    handleTimeRangeChange(timeRanges);
  };

  const ranges = {
    [i18n("DASHBOARD.Today")]: [moment(), moment()],
    [i18n("DASHBOARD.Yesterday")]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    [i18n("DASHBOARD.ThisWeek")]: [moment().startOf("week"), moment().endOf("week")],
    [i18n("DASHBOARD.LastWeek")]: [
      moment()
        .subtract(1, "week")
        .startOf("week"),
      moment()
        .subtract(1, "week")
        .endOf("week"),
    ],
    [i18n("DASHBOARD.Last7Days")]: [moment().subtract(6, "days"), moment()],
    [i18n("DASHBOARD.ThisMonth")]: [moment().startOf("month"), moment().endOf("month")],
    [i18n("DASHBOARD.LastMonth")]: [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month"),
    ],
    [i18n("DASHBOARD.Last30Days")]: [moment().subtract(29, "days"), today],
  };
  const { language } = useSelector((state) => state.auth);

  useEffect(() => {
    const datePickerElement = document.querySelector(".daterangepicker");
    const rangesElement = document.querySelector(".ranges");
    const buttonsElement = document.querySelector(".drp-buttons");
    const cancelButton = document.querySelector(".cancelBtn");
    const applyButton = document.querySelector(".applyBtn");
    const customRange = document.querySelector('li[data-range-key="Custom Range"]');
    customRange.innerHTML = i18n("DASHBOARD.CustomRange");
    cancelButton.innerHTML = i18n("Customer.Cancel");
    applyButton.innerHTML = i18n("DASHBOARD.Apply");
    if (datePickerElement) {
      if (datePickerElement.classList.contains("opensleft")) {
        rangesElement.style.textAlign = "right";
        buttonsElement.style.textAlign = "left";
      } else if (datePickerElement.classList.contains("opensright")) {
        rangesElement.style.textAlign = "left";
        buttonsElement.style.textAlign = "right";
      }
    }
  }, [language]);

  // Define locale settings
  const locales = {
    english: {
      direction: "ltr",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    arabic: {
      direction: "rtl",
      daysOfWeek: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
      monthNames: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
    },
  };

  return (
    <div className={`test ${language === "arabic" ? "rtl" : "ltr"}`} style={{ display: "inline", direction: language === "arabic" ? "rtl" : "ltr" }}>
      <RBDaterangepicker
        key={language}
        startDate={startDate}
        endDate={endDate}
        ranges={ranges}
        onApply={handleApply}
        maxDate={today}
        opens={language === "arabic" ? "left" : "right"}
        locale={language === "arabic" ? locales.arabic : locales.english}
      >
        <span>
          <img src={CalanderIcon} alt="" style={{ right: language === "arabic" ? "25px" : "0px", left: language !== "arabic" ? "25px" : "0px" }} />
          <input
            type="text"
            className="form-control text-center"
            value={`${startDate.format("MM/DD/YYYY")} - ${endDate.format("MM/DD/YYYY")}`}
            readOnly
          />
        </span>
      </RBDaterangepicker>
    </div>
  );
};

export default DateRangePicker;
