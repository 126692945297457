import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Storage } from "react-jhipster";
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FATORATAY_AUTH } from "../../../helpers/constants";
import { i18n } from "../../pages/private/languageSelector";
import { Input, Card } from "../../../_metronic/_partials/controls";
import * as Yup from "yup";
import toast from "react-hot-toast";
import PinInput from "react-pin-input";
import ImageUploading from "react-images-uploading";
import IconClose from "../../../assets/img/icon-close.svg";
import IconEditAvatar from "../../../assets/img/icon-edit-avatar.svg";
import UserAvatar from "../../../assets/img/user-avatar-default.jpg";
import * as usersActions from "../../../_redux/users/usersActions";
import * as actions from "../../../app/modules/Auth/_redux/authCrud";
import { getUserByToken } from "../../modules/Auth/_redux/authCrud";

const passwordValidation = "Password must contain letters and numbers";
const UserSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation),
  newPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(20, "Maximum 20 symbols")
    .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation),
});
export const ManageProfile = ({ userPosPin, openManageUser, setOpenManageUser, setOpenProfileDrawer }) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState([]);
  const [posPin, setPosPin] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  const passwordShowHandlerConfirm = () => {
    setPasswordShowConfirm(passwordShowConfirm ? false : true);
  };
  const handlePin = (value) => {
    setPosPin(value);
  };
  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
  };

  useEffect(() => {
    if (userPosPin) {
      setPosPin(userPosPin);
    }
  }, [openManageUser, userPosPin]);

  useEffect(() => {
    if (image?.length) {
      dispatch(
        usersActions.updateUserPosPin(user._id, {
          profileImage: image[0].data_url,
          posPin: posPin,
        })
      );
    }
  }, [dispatch, posPin, user, image]);

  const changePasswordHandler = (values) => {
    if (values.currentPassword && values.newPassword) {
      actions
        .changePassword(values)
        .then(() => {
          toast.success("Password Changed");
          setTimeout(() => {
            Storage.session.remove(FATORATAY_AUTH);
            Storage.local.remove(FATORATAY_AUTH);
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          if (err.message === "Request failed with status code 400") {
            toast.error("Please Enter Correct Password");
          }
        });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: user?.name?.first ? user?.name?.first + " " + user?.name?.last : "",
          email: user?.email ? user?.email : "",
          mobile: user?.mobile ? user?.mobile : "",
          currentPassword: "",
          newPassword: "",
        }}
        validationSchema={UserSchema}
        onSubmit={(values) => {
          changePasswordHandler(values);
          getUserByToken().then((res) => {
            if (res?.data?.posPin !== "" && posPin.toString().length !== 4) {
              toast.error("Enter a 4 Digits Pos Pin");
            } else {
              dispatch(
                usersActions.updateUserPosPin(user._id, {
                  posPin: posPin ? posPin : user?.posPin,
                })
              );
              toast.success("User Updated Successfully");
              setOpenManageUser(false);
              setOpenProfileDrawer(false);
            }
          });
        }}>
        {({ handleSubmit }) => {
          return (
            <>
              <Modal
                className="modal-drawer my-profile invoice-drawer"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={openManageUser}
                onHide={() => {
                  setOpenManageUser(false);
                }}>
                <Modal.Header>
                  <button type="button" className="btn btn-close" onClick={() => setOpenManageUser(false)}>
                    <img src={IconClose} alt="loading" />
                  </button>
                  <Modal.Title>
                    <span>{user?.name?.first}'s</span> {i18n("UserProfile.Profile")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form>
                    <Card>
                      <div className="invoice-title" style={{ borderBottom: "none" }}>
                        <h5 className="m-0">
                          <b>{i18n("UserProfile.ProfilePicture")}</b>
                        </h5>
                      </div>
                      <div className="user-avatar">
                        <div className="img-wrap">
                          {image?.length ? (
                            image.map((image, index) => (
                              <img key={index} src={image["data_url"]} alt="loading" width="100" />
                            ))
                          ) : (
                            <img src={user?.profileImage ? user?.profileImage : UserAvatar} alt="loading" />
                          )}
                        </div>
                        <ImageUploading value={image} onChange={onChange} dataURLKey="data_url">
                          {({ imageList, onImageUpload }) => (
                            <button
                              className="btn-edit"
                              onClick={(e) => {
                                e.preventDefault();
                                onImageUpload();
                              }}>
                              <img src={IconEditAvatar} alt="loading" />
                            </button>
                          )}
                        </ImageUploading>
                      </div>
                    </Card>
                    <Card>
                      <div className="invoice-title" style={{ borderBottom: "none" }}>
                        <h5 className="m-0">
                          <b> {i18n("UserProfile.MyInformation")}</b>
                        </h5>
                      </div>
                      <div className="form-group">
                        <Field
                          disabled
                          name="name"
                          component={Input}
                          placeholder={i18n("UserProfile.Name")}
                          label={i18n("UserProfile.Name")}
                          withFeedbackLabel={false}
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          disabled
                          name="email"
                          component={Input}
                          placeholder={i18n("UserProfile.Email")}
                          label={i18n("UserProfile.Email")}
                          withFeedbackLabel={false}
                        />
                      </div>
                      <div className="form-group">
                        <Field
                          disabled
                          name="mobile"
                          component={Input}
                          placeholder={i18n("UserProfile.Mobile")}
                          label={i18n("UserProfile.Mobile")}
                          withFeedbackLabel={false}
                        />
                      </div>
                    </Card>
                    <Card>
                      <div className="invoice-title" style={{ borderBottom: "none" }}>
                        <h5 className="m-0">
                          <b> {i18n("UserProfile.MyRole")}</b>
                        </h5>
                      </div>
                      <div className="row">
                        <h6 className="col-lg-12">
                          {user?.rolePayload?.name
                            ? user?.rolePayload?.name.charAt(0).toUpperCase() + user?.rolePayload?.name.slice(1)
                            : "Owner"}
                        </h6>
                        <h6 className="col-lg-6 mt-5">{i18n("UserProfile.POSPIN")}</h6>
                        <h6 className="col-lg-6 mt-3">
                          <div className="form-group">
                            <PinInput
                              length={4}
                              initialValue={posPin}
                              secret={false}
                              focus={false}
                              type="numeric"
                              style={{ width: "100%" }}
                              inputStyle={{
                                width: "30px",
                                height: "30px",
                                border: "0",
                                borderBottom: "1px solid",
                              }}
                              onChange={handlePin}
                            />
                          </div>
                        </h6>
                      </div>
                    </Card>

                    <Card>
                      <div className="invoice-title" style={{ borderBottom: "none" }}>
                        <h5 className="m-0">
                          <b> {i18n("UserProfile.MyPassword")}</b>
                        </h5>
                      </div>
                      <div className="form-group">
                        <Field
                          name="currentPassword"
                          component={Input}
                          label={i18n("global.CurrentPassword")}
                          type={passwordShow ? "text" : "password"}
                          placeholder={i18n("global.CurrentPassword")}
                        />
                        <span
                          onClick={passwordShowHandler}
                          style={{
                            float: " right",
                            marginTop: "-30px",
                            position: "relative",
                            zIndex: "1",
                            cursor: "pointer",
                            width: "auto",
                          }}>
                          <i className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"} />
                        </span>
                      </div>
                      <div className="form-group">
                        <Field
                          name="newPassword"
                          component={Input}
                          label={i18n("global.NewPassword")}
                          placeholder={i18n("global.NewPassword")}
                          type={passwordShowConfirm ? "text" : "password"}
                        />
                        <span
                          onClick={passwordShowHandlerConfirm}
                          style={{
                            float: " right",
                            marginTop: "-30px",
                            position: "relative",
                            zIndex: "1",
                            cursor: "pointer",
                            width: "auto",
                          }}>
                          <i className={passwordShowConfirm ? "fa fa-eye" : "fa fa-eye-slash"} />
                        </span>
                      </div>
                    </Card>
                  </Form>
                  <div className="btn-bar">
                    <button type="button" className="btn btn-sec" onClick={() => setOpenManageUser(false)}>
                      {i18n("UserProfile.Back")}
                    </button>
                    <button type="submit" onClick={() => handleSubmit()} className="btn btn-pri ml-5">
                      {i18n("UserProfile.Save")}
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
};
