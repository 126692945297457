import React, { useState, useEffect } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { LineChart } from "../charts/LineChartDashboard";
import IconSelect from "../../../assets/img/icon-select.svg";
import { DASHBOARD_ATTRIBUTES } from "../../../helpers/constants";
import { i18n } from "../../../app/pages/private/languageSelector";
import * as actions from "../../../_redux/invoices/invoicesActions";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { SelectDur } from "../../../app/components/selectDur/selectDur";

const Demo1Dashboard = () => {
  const dispatch = useDispatch();

  const [dashboardButton, setDashboardButton] = useState([
    ...DASHBOARD_ATTRIBUTES,
  ]);

  // eslint-disable-next-line
  const [years, setYears] = useState(moment().year());

  const [totalPaid, setTotalPaid] = useState(0);
  const [balanceDue, setBalanceDue] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [dueInvoice, setDueInvoice] = useState([]);
  const [totalVolume, setTotalVolume] = useState(0);
  const [voidInvoice, setVoidInvoice] = useState([]);
  const [paidInvoices, setPaidInvoice] = useState([]);
  const [dashboardDate, setDashboardDate] = useState();
  const [dueInvoiceValue, setDueInvoiceValue] = useState(0);
  const [paidInvoiceValue, setPaidInvoiceValue] = useState(0);
  const [voidInvoiceValue, setVoidInvoiceValue] = useState(0);
  const [months, setMonths] = useState(moment().format("MMM"));
  const [invoicesTotalLength, setInvoicesTotalLength] = useState(0);
  const [selectDurLabel, setSelectDurLabel] = useState("This Year");
  const [customersTotalLength, setCustomersTotalLength] = useState(0);
  const [totalNumberOfInvoices, setTotalNumberOfInvoices] = useState(0);
  const [invoicesValueToggle, setInvoicesValueToggle] = useState(false);
  const [invoicesCountToggle, setInvoicesCountToggle] = useState(false);
  const [openSelectDurDrawer, setOpenSelectDurDrawer] = useState(false);
  const [totalNumberOfCustomers, setTotalNumberOfCustomers] = useState([]);

  const { user } = useSelector((state) => ({
    user: state.auth,
  }));

  const [data, setData] = useState({
    labels: [
      i18n("Form.Jan"),
      i18n("Form.Feb"),
      i18n("Form.Mar"),
      i18n("Form.Apr"),
      i18n("Form.May"),
      i18n("Form.Jun"),
      i18n("Form.Jul"),
      i18n("Form.Aug"),
      i18n("Form.Sep"),
      i18n("Form.Oct"),
      i18n("Form.Nov"),
      i18n("Form.Dec"),
    ],
    years: [],
    datasets: [],
  });

  const [newChart, setNewChart] = useState({
    series: [
      {
        name: i18n("DASHBOARD.Invoices"),
        data: [],
      },
      {
        name: i18n("DASHBOARD.Customers"),
        data: [],
      },
    ],
    options: {
      plotOptions: {},

      colors: ["#F2994A", "#6FCF97"],
      labels: [i18n("DASHBOARD.Invoices"), i18n("DASHBOARD.Customers")],
      legend: {
        show: false,
        floating: true,
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
      },
    },
  });
  const [newDonutOneChart, setNewDonutOneChart] = useState({
    series: [],
    options: {
      colors: ["#6FCF97", "#BB6BD9", "#EB5757"],
      labels: [
        i18n("DASHBOARD.Paid"),
        i18n("DASHBOARD.Due"),
        i18n("DASHBOARD.Void"),
      ],

      legend: {
        show: false,
        floating: true,
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
      },
    },
  });
  const [newDonutTwoChart, setNewDonutTwoChart] = useState({
    series: [],
    options: {
      colors: ["#6FCF97", "#BB6BD9", "#EB5757"],

      labels: [
        i18n("DASHBOARD.Paid"),
        i18n("DASHBOARD.Due"),
        i18n("DASHBOARD.Void"),
      ],
      legend: {
        show: false,
        floating: true,
        formatter: function(seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
      },
    },
  });

  //Setting and Updating Bar CHart Data
  useEffect(() => {
    let newChart = {
      series: [
        {
          name: i18n("DASHBOARD.Invoices"),
          data: [invoicesTotalLength],
        },
        {
          name: i18n("DASHBOARD.Customers"),
          data: [customersTotalLength],
        },
      ],
      options: {
        plotOptions: {},

        colors: ["#F2994A", "#6FCF97"],
        labels: [i18n("DASHBOARD.Invoices"), i18n("DASHBOARD.Customers")],
        legend: {
          show: false,
          floating: true,
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
        },
      },
    };
    setNewChart(newChart);
    // eslint-disable-next-line
  }, [invoicesTotalLength, customersTotalLength]);

  useEffect(() => {
    let dueArray;
    dueArray = dueInvoice?.length
      ? dueInvoice?.reduce((a, b) => {
          return a + Number(b?.invoiceData?.balance);
        }, 0)
      : 0;
    setDueInvoiceValue(dueArray);
  }, [dueInvoice]);
  useEffect(() => {
    let voidArray;
    voidArray =
      voidInvoice?.length && voidInvoice?.length > 0
        ? voidInvoice?.reduce((a, b) => {
            return b?.invoiceData?.balance && b.invoiceData?.paidTotal
              ? a +
                  Number(b?.invoiceData?.balance) +
                  Number(b.invoiceData?.paidTotal)
              : b.invoiceData?.balance
              ? a + Number(b?.invoiceData?.balance)
              : a + Number(b?.invoiceData?.paidTotal);
          }, 0)
        : 0;

    setVoidInvoiceValue(voidArray);
  }, [voidInvoice]);
  useEffect(() => {
    let paidArray = 0;
    paidArray =
      paidInvoices?.length && paidInvoices?.length > 0
        ? paidInvoices?.reduce(function(a, b) {
            return b?.invoiceData?.channel === "web"
              ? a + Number(b?.invoiceData?.paidTotal)
              : a;
          }, 0)
        : 0;
    let paidPosArray = 0;
    paidPosArray =
      paidInvoices?.length && paidInvoices?.length > 0
        ? paidInvoices?.reduce(function(a, b) {
            return b?.invoiceData?.channel === "pos"
              ? a +
                  (Number(b?.invoiceData?.paidTotal) -
                    Number(b?.invoiceData?.change))
              : a;
          }, 0)
        : 0;
    setPaidInvoiceValue(paidArray + paidPosArray);
  }, [paidInvoices]);

  // Updating Donut Charts Data
  useEffect(() => {
    let newDonutOneChart = {
      series: [
        paidInvoiceValue && totalVolume
          ? ((paidInvoiceValue / totalVolume) * 100)?.toFixed(2)
          : "0",
        dueInvoiceValue && totalVolume
          ? ((dueInvoiceValue / totalVolume) * 100)?.toFixed(2)
          : "0",
        voidInvoiceValue && totalVolume
          ? ((voidInvoiceValue / totalVolume) * 100)?.toFixed(2)
          : "0",
      ],

      options: {
        colors: ["#6FCF97", "#BB6BD9", "#EB5757"],
        labels: ["Paid", "Due", "Void"],

        legend: {
          show: false,
          floating: true,
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
        },
      },
    };
    setNewDonutOneChart(newDonutOneChart);
    let newDonutTwoChart = {
      series: [
        paidInvoices?.length
          ? (
              (paidInvoices?.length /
                (paidInvoices?.length +
                  dueInvoice?.length +
                  voidInvoice?.length)) *
              100
            )?.toFixed(2)
          : "0",
        dueInvoice?.length
          ? (
              (dueInvoice?.length /
                (paidInvoices?.length +
                  dueInvoice?.length +
                  voidInvoice?.length)) *
              100
            )?.toFixed(2)
          : "0",
        voidInvoice?.length
          ? (
              (voidInvoice?.length /
                (paidInvoices?.length +
                  dueInvoice?.length +
                  voidInvoice?.length)) *
              100
            )?.toFixed(2)
          : "0",
      ],
      options: {
        colors: ["#6FCF97", "#BB6BD9", "#EB5757"],
        labels: ["Paid", "Due", "Void"],
        legend: {
          show: false,
          floating: true,
          formatter: function(seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
          },
        },
      },
    };
    setNewDonutTwoChart(newDonutTwoChart);
  }, [
    dueInvoice,
    voidInvoice,
    totalVolume,
    paidInvoices,
    dueInvoiceValue,
    paidInvoiceValue,
    voidInvoiceValue,
  ]);
  // Fetching Invoices and doing calculations
  useEffect(() => {
    let startDate;
    let endDate;
    if (dashboardDate) {
      startDate = dashboardDate.startDate;
      endDate = dashboardDate.endDate;
    } else {
      startDate = moment()
        .startOf("year")
        .format("MM/DD/YYYY");
      endDate = moment()
        .endOf("year")
        .format("MM/DD/YYYY");
    }
    dispatch(
      actions.fetchInvoicesByDate({
        startDate: startDate,
        endDate: endDate,
      })
    ).then((response) => {
      let totalCustomersLength = [];
      response.data.invoices.forEach((invoice) => {
        let customer = totalCustomersLength.find(
          (item) => item.customerId === invoice.customerId
        );
        if (!customer) {
          totalCustomersLength.push(invoice);
        }
      });
      setInvoicesTotalLength(response.data.invoices.length);
      setCustomersTotalLength(totalCustomersLength?.length);
      setPaidInvoice([
        ...response.data.invoices.filter((item) => item.invoiceData.invoiceType === 'sale' && item.status === "paid"),
      ]);
      setDueInvoice([
        ...response.data.invoices.filter((item) => item.status === "due"),
      ]);
      setVoidInvoice([
        ...response.data.invoices.filter((item) => item.status === "void"),
      ]);
      let salesInvoices = response?.data?.invoices.filter(
        (invoice) => invoice.invoiceData.invoiceType === "sale"
      );
      let totalVolume = 0;
      let totalPosVolume = 0;
      totalVolume = salesInvoices
        ? salesInvoices.reduce(function(a, b) {
            return b?.invoiceData?.channel === "web"
              ? a + Number(b?.invoiceData?.totalSales)
              : a;
          }, 0)
        : 0;
      totalPosVolume = salesInvoices
        ? salesInvoices.reduce(function(a, b) {
            return b?.invoiceData?.channel !== "web"
              ? a + Number(b?.invoiceData?.totalaftertax)
              : a;
          }, 0)
        : 0;
      setTotalVolume(totalVolume + totalPosVolume);
      data.years = [];
    });

    if (Number(moment(user?.companyId?.created_at).format("YYYY"))) {
      for (
        let i = Number(moment(user?.companyId?.created_at).format("YYYY"));
        i <= Number(moment().format("YYYY"));
        i++
      ) {
        data.years.push(
          Number(moment(user?.companyId?.created_at).format("YYYY"))
        );
      }
    }
    // eslint-disable-next-line
  }, [dashboardDate, dispatch]);
  useEffect(() => {
    let startDate = moment()
      .startOf("year")
      .format("MM/DD/YYYY");
    let endDate = moment()
      .endOf("year")
      .format("MM/DD/YYYY");

    let perMonthData = {};
    if (startDate && endDate) {
      dispatch(actions.getInvoiceForLineChart(startDate, endDate)).then(
        (response) => {
          if (response) {
            const { invoices } = response?.data;
            invoices &&
              invoices.length &&
              invoices.forEach((item) => {
                let sales = 0;
                let tPaid = 0;
                let dueBalance = 0;
                let totalInvoices = 0;
                let totalCustomers = [];
                totalInvoices = response?.data?.invoices;

                data.labels.forEach((subitem) => {
                  if (!perMonthData[subitem]) {
                    perMonthData[subitem] = {};
                    perMonthData[subitem].totalCustomers = [];
                    perMonthData[subitem].dueBalance = 0;
                    perMonthData[subitem].totalInvoices = 0;
                    perMonthData[subitem].tPaid = 0;
                    perMonthData[subitem].sales = 0;
                  }

                  if (
                    moment(item.invoiceData.invoiceDate).format("MMMM-YYYY") ===
                    moment()
                      .month(subitem)
                      .year(years)
                      .format("MMMM-YYYY")
                  ) {
                    // eslint-disable-next-line
                    item.invoiceData?.payments?.map((payment) => {
                      if (payment.type === "CREDIT") {
                        tPaid = Number(tPaid + +payment.amount);
                      }
                    });
                    sales = sales + item.invoiceData.totalSales;
                    dueBalance = Number(dueBalance + +item.invoiceData.balance);

                    if (!totalCustomers.includes(item.customerId)) {
                      totalCustomers.push(item.customerId);
                    }
                    if (
                      !perMonthData[subitem].totalCustomers.includes(
                        item.customerId
                      )
                    ) {
                      perMonthData[subitem].totalCustomers.push(
                        item.customerId
                      );
                    }
                    perMonthData[subitem].dueBalance =
                      perMonthData[subitem].dueBalance + dueBalance;
                    perMonthData[subitem].totalInvoices = totalInvoices.length;
                    perMonthData[subitem].tPaid =
                      perMonthData[subitem].tPaid + tPaid;
                    perMonthData[subitem].sales =
                      perMonthData[subitem].sales + sales;
                  }
                });
              });
            let customerData = {
              hidden: false,
              label: "Customers",
              fill: false,
              borderColor: "#25AFF3",
              yAxisID: "y",
              data: [],
            };

            let invoiceData = {
              hidden: false,
              label: "Invoices",
              fill: false,
              borderColor: "#F2994A",
              yAxisID: "y",
              data: [],
            };

            let paidData = {
              hidden: false,
              label: "Paid",
              fill: false,
              borderColor: "#3CAEA3",
              yAxisID: "y1",
              data: [],
            };
            let balanceData = {
              hidden: false,
              label: "Balance",
              fill: false,
              borderColor: "#ED553B",
              yAxisID: "y1",
              data: [],
            };
            let totalSalesData = {
              hidden: false,
              label: "Total Sales",
              fill: false,
              borderColor: "#7925C7",
              yAxisID: "y1",
              data: [],
            };
            data.labels.forEach((subitem) => {
              if (perMonthData[subitem]) {
                paidData.data.push(perMonthData[subitem]?.tPaid);
                totalSalesData.data.push(perMonthData[subitem]?.sales);
                balanceData.data.push(perMonthData[subitem]?.dueBalance);
                invoiceData.data.push(perMonthData[subitem]?.totalInvoices);
                customerData.data.push(
                  perMonthData[subitem]?.totalCustomers?.length
                );
              }
            });
            setData({
              ...data,
              datasets: [
                { ...paidData },
                { ...invoiceData },
                { ...balanceData },
                { ...customerData },
                { ...totalSalesData },
              ],
            });

            if (perMonthData[months]) {
              setTotalPaid(perMonthData[months].tPaid);
              setTotalSales(perMonthData[months].sales);
              setBalanceDue(perMonthData[months].dueBalance);
              setTotalNumberOfInvoices(perMonthData[months].totalInvoices);
              setTotalNumberOfCustomers([
                ...perMonthData[months].totalCustomers,
              ]);
            }
          }
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="action-bar">
        <div className="d-flex align-items-center">
          <button
            className="btn btn-select"
            onClick={() => {
              setOpenSelectDurDrawer(true);
            }}
          >
            {selectDurLabel === "today"
              ? i18n("DASHBOARD.Today")
              : selectDurLabel === "yesterday"
              ? i18n("DASHBOARD.Yesterday")
              : selectDurLabel === "thisWeek"
              ? i18n("DASHBOARD.ThisWeek")
              : selectDurLabel === "lastWeek"
              ? i18n("DASHBOARD.LastWeek")
              : selectDurLabel === "thisMonth"
              ? i18n("DASHBOARD.ThisMonth")
              : selectDurLabel === "lastMonth"
              ? i18n("DASHBOARD.LastMonth")
              : selectDurLabel === "thisYear"
              ? i18n("DASHBOARD.ThisYear")
              : selectDurLabel === "lastYear"
              ? i18n("DASHBOARD.LastYear")
              : selectDurLabel === "custom"
              ? i18n("DASHBOARD.Custom")
              : i18n("DASHBOARD.ThisYear")}{" "}
            <img className="ml-3" src={IconSelect} alt="loading" />
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-end"></div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-12 col-md-4">
          <Card className="card-visual">
            <div className="card-title">
              <h5 className="title-text dashboard-header">
                {i18n("DASHBOARD.CustomerCount")}
              </h5>
              <small>
                {i18n("DASHBOARD.TotalCustomers")} {customersTotalLength}
              </small>
            </div>
            <div className="graph-block">
              <div className="graph-detail">
                <ul>
                  <li className="color-green">
                    <small>{i18n("DASHBOARD.Customers")}</small>
                    <b className="color-primary-light">
                      {customersTotalLength}
                    </b>
                  </li>
                  <li className="color-orange">
                    <small>{i18n("DASHBOARD.Invoices")}</small>
                    <b>{invoicesTotalLength}</b>
                  </li>
                </ul>
              </div>
              <div className="graph-img">
                <div className="img-wrap">
                  {newChart.series[0].data.length ? (
                    <Chart
                      series={newChart.series}
                      type="bar"
                      options={newChart.options}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-4">
          <Card className="card-visual">
            <div className="card-title">
              <h5 className="title-text dashboard-header">
                {i18n("DASHBOARD.InvoicesValue")}
                <Form.Check
                  type="switch"
                  id="invoiceValueSwitch"
                  label=""
                  className="float-right switch-by-value switch-sar-perc"
                  onChange={(e) => {
                    setInvoicesValueToggle(e.target.checked);
                  }}
                ></Form.Check>
              </h5>
              <small>
                {i18n("DASHBOARD.TotalVolume")}{" "}
                {user?.user?.companyId?.currency}&nbsp;
                {totalVolume?.toLocaleString()
                  ? totalVolume?.toLocaleString()
                  : "0"}
              </small>
            </div>
            <div className="graph-block">
              <div className="graph-detail">
                <ul>
                  <li className="color-green">
                    <small> {i18n("DASHBOARD.Paid")}</small>
                    <b>
                      {invoicesValueToggle && paidInvoiceValue
                        ? `${Number(
                            (paidInvoiceValue / totalVolume) * 100
                          )?.toFixed(2)}%`
                        : paidInvoiceValue
                        ? Number(paidInvoiceValue)?.toFixed(2)
                        : "0.00"}
                    </b>
                  </li>
                  <li className="color-purple">
                    <small> {i18n("DASHBOARD.Due")}</small>
                    <b>
                      {invoicesValueToggle && dueInvoiceValue
                        ? `${((dueInvoiceValue / totalVolume) * 100)?.toFixed(
                            2
                          )}%`
                        : dueInvoiceValue
                        ? `${dueInvoiceValue?.toFixed(2)}`
                        : "0.00"}
                    </b>
                  </li>
                  <li className="color-red">
                    <small> {i18n("DASHBOARD.Void")}</small>
                    <b>
                      {invoicesValueToggle && voidInvoiceValue
                        ? `${((voidInvoiceValue / totalVolume) * 100)?.toFixed(
                            2
                          )}%`
                        : voidInvoiceValue
                        ? `${voidInvoiceValue?.toFixed(2)}`
                        : "0.00"}
                    </b>
                  </li>
                </ul>
              </div>
              <div className="graph-img">
                <div className="img-wrap">
                  {newDonutOneChart.series.length ? (
                    <Chart
                      series={newDonutOneChart?.series}
                      type="radialBar"
                      options={newDonutOneChart?.options}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-xl-4 col-lg-6 col-md-4">
          <Card className="card-visual">
            <div className="card-title">
              <h5 className="title-text dashboard-header">
                {i18n("DASHBOARD.InvoicesCount")}
                <Form.Check
                  type="switch"
                  id="invoiceCountSwitch"
                  label=""
                  className="float-right switch-by-value  switch-sigma-perc"
                  onChange={(e) => {
                    setInvoicesCountToggle(e.target.checked);
                  }}
                />
              </h5>
              <small>
                {i18n("DASHBOARD.TotalCount")} &nbsp;
                {invoicesTotalLength}
              </small>
            </div>
            <div className="graph-block">
              <div className="graph-detail">
                <ul>
                  <li className="color-green">
                    <small> {i18n("DASHBOARD.Paid")}</small>
                    <b>
                      {invoicesCountToggle
                        ? paidInvoices?.length
                          ? (
                              (paidInvoices?.length /
                                (paidInvoices?.length +
                                  dueInvoice?.length +
                                  voidInvoice?.length)) *
                              100
                            )?.toFixed(2)
                          : "0"
                        : paidInvoices?.length}

                      {invoicesCountToggle ? "%" : ""}
                    </b>
                  </li>
                  <li className="color-purple">
                    <small> {i18n("DASHBOARD.Due")}</small>
                    <b>
                      {invoicesCountToggle
                        ? dueInvoice?.length
                          ? (
                              (dueInvoice?.length /
                                (paidInvoices?.length +
                                  dueInvoice?.length +
                                  voidInvoice?.length)) *
                              100
                            )?.toFixed(2)
                          : "0"
                        : dueInvoice?.length}

                      {invoicesCountToggle ? "%" : ""}
                    </b>
                  </li>
                  <li className="color-red">
                    <small> {i18n("DASHBOARD.Void")}</small>
                    <b>
                      {invoicesCountToggle
                        ? voidInvoice?.length
                          ? (
                              (voidInvoice?.length /
                                (paidInvoices?.length +
                                  dueInvoice?.length +
                                  voidInvoice?.length)) *
                              100
                            )?.toFixed(2)
                          : "0"
                        : voidInvoice?.length}

                      {invoicesCountToggle ? "%" : ""}
                    </b>
                  </li>
                </ul>
              </div>
              <div className="graph-img">
                <div className="img-wrap">
                  {newDonutTwoChart.series.length ? (
                    <Chart
                      series={newDonutTwoChart.series}
                      type="radialBar"
                      options={newDonutTwoChart.options}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-12">
          <Card className="card-trend-line mb-0">
            <div className="card-title">
              <h5 className="title-text dashboard-header">
                {i18n("DASHBOARD.TrendLine")}
              </h5>
              <small>
                {moment()
                  .startOf("year")
                  .format("DD MMM YYYY")}{" "}
                {i18n("global.To")}
                {moment()
                  .endOf("year")
                  .format("DD MMM YYYY")}
              </small>
            </div>
            <CardBody className="f-cardBody">
              <div className="trendline-chart">
                <LineChart data={data} />
              </div>
              <div className="trendline-action mt-9">
                {dashboardButton.map((item, index) => (
                  <button
                    key={index}
                    className={`btn btn-rounded btn-sm ${
                      item.active ? "btn-primary" : "btn-secondary"
                    } btn-elevate mx-2 my-3`}
                    style={
                      item.active
                        ? {
                            backgroundColor: item.color,
                            border: item.color,
                          }
                        : {}
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      dashboardButton[index] = {
                        ...dashboardButton[index],
                        active: !dashboardButton[index].active,
                      };
                      data.datasets.forEach((element) => {
                        if (dashboardButton[index].name === element.label) {
                          element.hidden = !element.hidden;
                        }
                      });
                      setDashboardButton([...dashboardButton]);
                      setData({ ...data });
                    }}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <div className="row d-none">
        <div
          className="col-4"
          style={{ textAlign: "center", fontSize: "20px" }}
        >
          <Card className="shadow p-3 mb-5 bg-body rounded">
            <CardBody className="f-cardBody">
              <div className="row" style={{ justifyContent: "center" }}>
                <i
                  className="fas fa-users fa-2x"
                  style={{ color: "#8092A1" }}
                ></i>
              </div>

              <div
                className="col-12"
                style={{ color: "#20639B", fontSize: "30px" }}
              >
                <b>{totalNumberOfCustomers?.length?.toLocaleString()}</b>
              </div>
              <div className="col-12" style={{ fontSize: "17px" }}>
                {i18n("DASHBOARD.TotalCustomers")}
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Card className="shadow p-3 mb-5 bg-body rounded">
            <CardBody>
              <div className="row">
                <select
                  style={{
                    height: "54px",
                    width: "22%",
                    border: "none",
                    textAlignLast: "center",
                    fontSize: "20px",
                    backgroundColor: "#E4ECF3",
                    borderRadius: "3px",
                    color: "#20639B",
                  }}
                  defaultValue={moment().format("MMMM")}
                  onChange={(e) => {
                    setMonths(e.target.value);
                  }}
                >
                  <option disabled hidden>
                    {moment().format("MMMM")}
                  </option>
                  {data.labels.map((months, index) => {
                    return (
                      <option value={months} key={index}>
                        {months}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="row">
                <div
                  className="col-12 mt-5 mb-5"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    color: "#20639B",
                  }}
                >
                  {moment()
                    .month(months)
                    .year(years)
                    .startOf("month")
                    .format("DD MMM YYYY")}{" "}
                  -{" "}
                  {moment()
                    .month(months)
                    .year(years)
                    .endOf("month")
                    .format("DD MMM YYYY")}
                </div>
              </div>
              <div className="row">
                <div
                  className="col-12"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  <Card className="shadow p-3 mb-5 bg-body rounded .f-cardBody">
                    <CardBody className="f-cardBody">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fas fa-dollar-sign fa-2x"
                          style={{ color: "#8092A1" }}
                        ></i>
                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          className="col-6 my-3"
                          style={{ fontSize: "20px" }}
                        >
                          {i18n("DASHBOARD.TotalSales")}
                        </div>
                        <div
                          className="col-6"
                          style={{
                            color: "#20639B",
                            fontSize: "30px",
                          }}
                        >
                          <b>${totalSales?.toLocaleString()}</b>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-6"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  <Card className="shadow p-3 mb-5 bg-body rounded">
                    <CardBody className="f-cardBody">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fas fa-file-invoice fa-2x"
                          style={{ color: "#8092A1" }}
                        ></i>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          color: "#20639B",
                          fontSize: "30px",
                        }}
                      >
                        <b>{totalNumberOfInvoices?.toLocaleString()}</b>
                      </div>
                      <div className="col-12" style={{ fontSize: "17px" }}>
                        {i18n("DASHBOARD.TotalInvoices")}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-6"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  <Card className="shadow p-3 mb-5 bg-body rounded">
                    <CardBody className="f-cardBody">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fas fa-hand-holding-usd fa-2x"
                          style={{ color: "#8092A1" }}
                        ></i>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          color: "#20639B",
                          fontSize: "25px",
                        }}
                      >
                        <b>${totalPaid?.toLocaleString()}</b>
                      </div>
                      <div className="col-12" style={{ fontSize: "17px" }}>
                        {i18n("DASHBOARD.Amount")}
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div
                  className="col-6"
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  <Card className="shadow p-3 mb-5 bg-body rounded">
                    <CardBody className="f-cardBody">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="fas fa-hand-holding-usd fa-2x fa-rotate-180"
                          style={{ color: "#8092A1" }}
                        ></i>
                      </div>

                      <div
                        className="col-12"
                        style={{
                          color: "#20639B",
                          fontSize: "25px",
                        }}
                      >
                        <b>${balanceDue?.toLocaleString()}</b>
                      </div>
                      <div className="col-12" style={{ fontSize: "17px" }}>
                        {i18n("DASHBOARD.BalanceDue")}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <SelectDur
        openSelectDurDrawer={openSelectDurDrawer}
        setOpenSelectDurDrawer={setOpenSelectDurDrawer}
        data={data?.labels}
        setDashboardDate={setDashboardDate}
        setSelectDurLabel={setSelectDurLabel}
      />
    </>
  );
};

export default Demo1Dashboard;
