import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

export function InvoicesDeleteDialog({ show, onHide }) {
  // Invoices UI Context
  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
    };
  }, [invoicesUIContext]);

  // Invoices Redux state
  const dispatch = useDispatch();
  const { isLoading, invoiceFilterState } = useSelector(
    (state) => ({
      isLoading: state.invoices.actionsLoading,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );

  // if invoices weren't selected we should close modal
  useEffect(() => {
    if (!invoicesUIProps.ids || invoicesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesUIProps.ids]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteInvoices = () => {
    // server request for deleting invoice by selected ids
    // if (
    //   invoicesUIProps &&
    //   invoicesUIProps.ids &&
    //   invoicesUIProps.ids.length
    // ) {
    //   invoicesUIProps.ids.forEach((item) => {
    //     dispatch(actions.deleteInvoice(item));
    //   });
    // }
    // dispatch(actions.fetchInvoices(invoicesUIProps.queryParams)).then(() => {
    //   // clear selections list
    //   invoicesUIProps.setIds([]);
    //   // closing delete modal
    //   onHide();
    // });
    dispatch(actions.deleteInvoices(invoiceFilterState, invoicesUIProps.ids)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchInvoices(invoiceFilterState, invoicesUIProps.queryParams)).then(() => {
        // clear selections list
        invoicesUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal show={show} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Invoices Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && <span>Are you sure to toggle status of selected invoices?</span>}
        {isLoading && <span>Invoice are updating...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={deleteInvoices} className="btn btn-primary btn-elevate">
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
