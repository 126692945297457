import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import { ManageDiscounts } from "./ManageDiscounts";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/discount/discountActions";
import IconClose from "../../../../assets/img/icon-close.svg";
import IconDiscount from "../../../../assets/img/discount.svg";
import { DiscountEditForm } from "./DiscountEditForm";
import { i18n } from "../../private/languageSelector";

const initAddState = {
  name: "",
  percentage: "",
};

export const Discounts = ({ userAccess, openDiscountDialog, setOpenDiscountDialog }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [initDiscountState] = useState(initAddState);
  const [searchDiscount, setSearchDiscount] = useState("");
  const [openAddDiscountDialog, setOpenAddDiscountDialog] = useState(false);
  const [openManageDiscountDialog, setOpenManageDiscountDialog] = useState(false);

  const { discounts } = useSelector(
    (state) => ({
      discounts: state.discounts?.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchDiscounts());
  }, [dispatch]);

  const createDiscountHandler = () => {
    setEdit(false);
    setOpenAddDiscountDialog(true);
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openDiscountDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenDiscountDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("INVOICES.Discounts")}</Modal.Title>
        </Modal.Header>
        <Formik enableReinitialize={true} initialValues={initDiscountState} onSubmit={(values, { resetForm }) => {}}>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                {discounts?.length ? (
                  <>
                    <Form className="form form-label-right">
                      <div className="row mt-5">
                        <div className="col-lg-12 ">
                          <Field
                            name="discount"
                            component={Input}
                            placeholder={i18n("Discount.SearchDiscounts")}
                            withFeedbackLabel={false}
                            onChange={(e) => {
                              setSearchDiscount(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <ul className="card-listitem list-unstyled">
                        {discounts
                          ?.filter((discount) => discount?.name?.toLowerCase().includes(searchDiscount?.toLowerCase()))
                          ?.map((discount, i) => {
                            return (
                              <li key={i}>
                                <span className="card-icon discount">
                                  <img src={IconDiscount} alt="loading" />
                                </span>
                                <div className="card-info">
                                  <h5 className="truncate">{discount.name}</h5>
                                  <span>
                                    {discount.value > 0 ? discount.value : ""} {discount?.type}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="text-center">
                        <h4>{i18n("Discount.YouHaveNoDiscountsAdded")}</h4>
                        {userAccess?.createData ? (
                          <>
                            <p className="mt-5">{i18n("Discount.ToAddANewDiscountClickHereTo")}</p>
                            <p style={{ color: "#2D9CDB", cursor: "pointer" }} onClick={() => createDiscountHandler()}>
                              {i18n("Discount.AddNewDiscounts")}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
            );
          }}
        </Formik>
        {discounts?.length ? (
          <>
            <Modal.Footer>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenManageDiscountDialog(true);
                    setEdit(true);
                  }}>
                  {i18n("Discount.Manage")}
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
      <ManageDiscounts
        discounts={discounts}
        userAccess={userAccess}
        openManageDiscountDialog={openManageDiscountDialog}
        setOpenManageDiscountDialog={setOpenManageDiscountDialog}
      />
      <DiscountEditForm
        edit={edit}
        openDiscountDialog={openAddDiscountDialog}
        setOpenDiscountDialog={setOpenAddDiscountDialog}
      />
    </>
  );
};
