import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  return classes.join(" ");
};

export function Input({
  field,
  label,
  classes,
  type = "text",
  customFeedbackLabel,
  form: { touched, errors, setFieldValue },
  withFeedbackLabel = true,
  setFormChanged,
  ...props
}) {
  return (
    <>
      <input
        type={type}
        className={`${classes} ${getFieldCSSClasses(
          touched[field.name],
          errors[field.name]
        )}`}
        {...field}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value);
          if (setFormChanged) {
            setFormChanged(true);
          }
        }}
        {...props}
      />
      {label && <label>{label}</label>}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          type={type}
          label={label}
          error={errors[field.name]}
          touched={touched[field.name]}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
