import React from "react";
import { Route } from "react-router-dom";
import { ProductsLoadingDialog } from "./products-loading-dialog/ProductsLoadingDialog";
import { ProductEditDialog } from "./product-edit-dialog/ProductEditDialog";
import { ProductDeleteDialog } from "./product-delete-dialog/ProductDeleteDialog";
import { ProductsDeleteDialog } from "./products-delete-dialog/ProductsDeleteDialog";
import { ProductsFetchDialog } from "./products-fetch-dialog/ProductsFetchDialog";
import { ProductsUpdateStateDialog } from "./products-update-status-dialog/ProductsUpdateStateDialog";
import { ProductsUIProvider } from "./ProductsUIContext";
import { ProductsCard } from "./ProductsCard";
import { ProductActionModal } from "../../modules/ECommerce/pages/products/product-edit/ProductActionModal";

function ProductsPage({ history }) {
  const productsUIEvents = {
    newProductButtonClick: () => {
      history.push("/products/new");
    },
    openEditProductDialog: (id) => {
      history.push(`/products/${id}/edit`);
    },
    openDeleteProductDialog: (id) => {
      history.push(`/products/${id}/delete`);
    },
    openDeleteProductsDialog: () => {
      history.push(`/products/deleteProducts`);
    },
    openFetchProductsDialog: () => {
      history.push(`/products/fetch`);
    },
    openUpdateProductsStatusDialog: () => {
      history.push("/products/updateStatus");
    },
    openViewProductDialog: (id) => {
      history.push(`/products/${id}/view`);
    },
    openViewProductActionDialog: (id) => {
      history.push(`/products/actions-modal/${id}`);
    },
  };

  return (
    <ProductsUIProvider productsUIEvents={productsUIEvents}>
      <ProductsLoadingDialog />
      <Route path="/products/new">
        {({ history, match }) => (
          <ProductEditDialog
            show={match != null}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <Route path="/products/:id/edit">
        {({ history, match }) => (
          <ProductEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <Route path="/products/:id/view">
        {({ history, match }) => (
          <ProductEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/products");
            }}
            view={true}
          />
        )}
      </Route>
      <Route path="/products/deleteProducts">
        {({ history, match }) => (
          <ProductsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <Route path="/products/:id/delete">
        {({ history, match }) => (
          <ProductDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <Route path="/products/fetch">
        {({ history, match }) => (
          <ProductsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <Route path="/products/updateStatus">
        {({ history, match }) => (
          <ProductsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <Route exact path="/products/actions-modal/:id">
        {({ history, match }) => (
          <ProductActionModal
            show={match != null}
            onHide={() => {
              history.push("/products");
            }}
          />
        )}
      </Route>
      <ProductsCard />
    </ProductsUIProvider>
  );
}
export default ProductsPage;
