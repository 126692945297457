import React from 'react'
import {Switch} from "react-router-dom"
import DiscountsReport from './analyticsView/DiscountsReport'

const DiscountReportPage = () => {
  return (
   <Switch>
    <DiscountsReport/>
   </Switch>
  )
}

export default DiscountReportPage