export const BACK_OFFICE_OPTIONS = [
  { name: "Access Dashboard", selected: false },
  { name: "Access Customers", selected: false },
  {
    name: "Access Invoices",
    selected: false,
    subItem: [
      { name: "Create New Products in a Sale", selected: false },
      { name: "Apply Discounts with Restricted Access", selected: false },
    ],
  },
  {
    name: "Access Menu",
    selected: false,
    subItem: [
      { name: "Products", selected: true },
      { name: "Categories", selected: false },
      { name: "Discounts", selected: false },
    ],
  },
  {
    name: "Access Company Profile",
    selected: false,
    subItem: [
      { name: "Company Information", selected: false },
      { name: "Roles", selected: false },
      { name: "Users", selected: false },
      { name: "POSs", selected: false },
      { name: "Stores", selected: false },
      { name: "Taxes", selected: false },
      { name: "Payment Channels", selected: false },
      { name: "Subscription", selected: false },
      { name: "Log Book", selected: false },
    ],
  },
  { name: "Create Data", selected: false },
  { name: "Edit Data", selected: false },
  { name: "Delete Data", selected: false },
  { name: "Access to live chat support", selected: false },
];

export const POS_OPTIONS = [
  {
    name: "View Invoices",
    selected: true,
    subItem: [
      { name: "Only Last 5", selected: true },
      // { name: "Only Last 20", selected: false },
      { name: "All Invoices", selected: false },
    ],
  },
  { name: "Apply discounts with restricted access", selected: false },
  { name: "Change taxes in a sale", selected: false },
  { name: "Show quick product in POS", selected: false },
  { name: "Perform refunds", selected: false },
  { name: "Reprint and resend invoices", selected: false },
  { name: "Manage menu", selected: false },
  { name: "Change Settings", selected: false },
  { name: "Access to live chat support", selected: false },
];

export const BACK_OFFICE_OPTIONS_MANAGER = [
  { name: "Access Dashboard", selected: true },
  { name: "Access Customers", selected: true },
  {
    name: "Access Invoices",
    selected: true,
    subItem: [
      { name: "Create New Products in a Sale", selected: true },
      { name: "Apply Discounts with Restricted Access", selected: true },
    ],
  },
  {
    name: "Access Menu",
    selected: true,
    subItem: [
      { name: "Products", selected: true },
      { name: "Categories", selected: true },
      { name: "Discounts", selected: true },
    ],
  },
  {
    name: "Access Company Profile",
    selected: false,
    subItem: [
      { name: "Company Information", selected: false },
      { name: "Roles", selected: false },
      { name: "Users", selected: false },
      { name: "POSs", selected: false },
      { name: "Stores", selected: false },
      { name: "Taxes", selected: false },
      { name: "Payment Channels", selected: false },
      { name: "Subscription", selected: false },
      { name: "Log Book", selected: false },
    ],
  },
  { name: "Create Data", selected: true },
  { name: "Edit Data", selected: true },
  { name: "Delete Data", selected: true },
  { name: "Access to live chat support", selected: false },
];

export const POS_OPTIONS_MANAGER = [
  {
    name: "View Invoices",
    selected: true,
    subItem: [
      { name: "Only Last 5", selected: false },
      // { name: "Only Last 20", selected: false },
      { name: "All Invoices", selected: true },
    ],
  },
  { name: "Apply discounts with restricted access", selected: true },
  { name: "Change taxes in a sale", selected: true },
  { name: "Show quick product in POS", selected: false },
  { name: "Perform refunds", selected: true },
  { name: "Reprint and resend invoices", selected: true },
  { name: "Manage menu", selected: true },
  { name: "Change Settings", selected: true },
  { name: "Access to live chat support", selected: false },
];
export const BACK_OFFICE_OPTIONS_CASHIER = [
  { name: "Access Dashboard", selected: false },
  { name: "Access Customers", selected: true },
  {
    name: "Access Invoices",
    selected: true,
    subItem: [
      { name: "Create New Products in a Sale", selected: false },
      { name: "Apply Discounts with Restricted Access", selected: false },
    ],
  },
  {
    name: "Access Menu",
    selected: false,
    subItem: [
      { name: "Products", selected: false },
      { name: "Categories", selected: false },
      { name: "Discounts", selected: false },
    ],
  },
  {
    name: "Access Company Profile",
    selected: false,
    subItem: [
      { name: "Company Information", selected: false },
      { name: "Roles", selected: false },
      { name: "Users", selected: false },
      { name: "POSs", selected: false },
      { name: "Stores", selected: false },
      { name: "Taxes", selected: false },
      { name: "Payment Channels", selected: false },
      { name: "Subscription", selected: false },
      { name: "Log Book", selected: false },
    ],
  },
  { name: "Create Data", selected: true },
  { name: "Edit Data", selected: true },
  { name: "Delete Data", selected: false },
  { name: "Access to live chat support", selected: false },
];

export const POS_OPTIONS_CASHIER = [
  {
    name: "View Invoices",
    selected: true,
    subItem: [
      { name: "Only Last 5", selected: true },
      // { name: "Only Last 20", selected: false },
      { name: "All Invoices", selected: false },
    ],
  },
  { name: "Apply discounts with restricted access", selected: false },
  { name: "Change taxes in a sale", selected: false },
  { name: "Show quick product in POS", selected: false },
  { name: "Perform refunds", selected: false },
  { name: "Reprint and resend invoices", selected: false },
  { name: "Manage menu", selected: false },
  { name: "Change Settings", selected: false },
  { name: "Access to live chat support", selected: false },
];

export const BACK_OFFICE_OPTIONS_DEFAULT = [
  { name: "Access Dashboard", selected: true },
  { name: "Access Customers", selected: true },
  {
    name: "Access Invoices",
    selected: true,
    subItem: [
      { name: "Create New Products in a Sale", selected: true },
      { name: "Apply Discounts with Restricted Access", selected: true },
    ],
  },
  {
    name: "Access Menu",
    selected: true,
    subItem: [
      { name: "Products", selected: true },
      { name: "Categories", selected: true },
      { name: "Discounts", selected: true },
    ],
  },
  {
    name: "Access Company Profile",
    selected: true,
    subItem: [
      { name: "Company Information", selected: true },
      { name: "Roles", selected: true },
      { name: "Users", selected: true },
      { name: "POSs", selected: true },
      { name: "Stores", selected: true },
      { name: "Taxes", selected: true },
      { name: "Payment Channels", selected: true },
      { name: "Subscription", selected: true },
      { name: "Log Book", selected: true },
    ],
  },
  { name: "Create Data", selected: true },
  { name: "Edit Data", selected: true },
  { name: "Delete Data", selected: true },
  { name: "Access to live chat support", selected: true },
];

export const POS_OPTIONS_DEFAULT = [
  {
    name: "View Invoices",
    selected: true,
    subItem: [
      { name: "Only Last 5", selected: false },
      // { name: "Only Last 20", selected: false },
      { name: "All Invoices", selected: true },
    ],
  },
  { name: "Apply discounts with restricted access", selected: true },
  { name: "Change taxes in a sale", selected: true },
  { name: "Show quick product in POS", selected: true },
  { name: "Perform refunds", selected: true },
  { name: "Reprint and resend invoices", selected: true },
  { name: "Manage menu", selected: true },
  { name: "Change Settings", selected: true },
  { name: "Access to live chat support", selected: true },
];
