import React, { createContext, useContext, useState, useCallback } from "react";
import isEqual from "lodash/isEqual";
import isFunction from "lodash/isFunction";
import { initialFilter } from "./ProductsUIHelpers";

const ProductsUIContext = createContext();

export function useProductsUIContext() {
  return useContext(ProductsUIContext);
}

export const ProductsUIConsumer = ProductsUIContext.Consumer;

export function ProductsUIProvider({ productsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initProduct = {
    id: undefined,
    englishName: "",
    arabicName: "",
    price: "0",
    category: "",
    serviceIsActive: false,
    serviceIsTaxable: false,
    englishDetail: "",
    arabicDetail: "",
    detail: "",
    barcode: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initProduct,
    newProductButtonClick: productsUIEvents.newProductButtonClick,
    openEditProductDialog: productsUIEvents.openEditProductDialog,
    openViewProductDialog: productsUIEvents.openViewProductDialog,
    openDeleteProductDialog: productsUIEvents.openDeleteProductDialog,
    openDeleteProductsDialog: productsUIEvents.openDeleteProductsDialog,
    openFetchProductsDialog: productsUIEvents.openFetchProductsDialog,
    openUpdateProductsStatusDialog: productsUIEvents.openUpdateProductsStatusDialog,
    openViewProductActionDialog: productsUIEvents.openViewProductActionDialog,
  };

  return <ProductsUIContext.Provider value={value}>{children}</ProductsUIContext.Provider>;
}
