/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import SubscriptionEnglishInvoice from "./SubscriptionEnglishInvoice";
import SubscriptionArabicInvoice from "./SubscriptionArabicInvoice";

const SubscriptionInvoices = ({
  rxPrintRef,
  language,
  invoice,
  paymentCardLastFourDigit,
  billedTo
}) => {

  return (
    <>
      {language === "arabic" ? (
        <SubscriptionArabicInvoice
          rxPrintRef={rxPrintRef}
          invoice={invoice}
          paymentCardLastFourDigit={paymentCardLastFourDigit}
          billedTo={billedTo}
        />
      ) : (
        <SubscriptionEnglishInvoice
          rxPrintRef={rxPrintRef}
          invoice={invoice}
          paymentCardLastFourDigit={paymentCardLastFourDigit}
          billedTo={billedTo}
        />
      )}
    </>
  );
};

export default SubscriptionInvoices;
