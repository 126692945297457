import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Input, Card, Mobile } from "../../../../../_metronic/_partials/controls";
import IconClose from "../../../../../assets/img/icon-close.svg";
import * as storesActions from "../../../../../_redux/stores/storeActions";
import * as posActions from "../../../../../_redux/pos/posActions";
import { AsYouType } from "libphonenumber-js/max";
import { incrementCharacter } from "./../../../../../helpers/helpers";

let asYouType = new AsYouType();
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});
// Validation schema
export const AddStoreSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(20, "Maximum 20 Alphabets")
    .required("English name is required"),
  address: Yup.string().required("Address is required"),
  mobile: Yup.string()
    .validatePhoneNumber("Invalid Phone Number")
    .required("Phone number is required"),
  description: Yup.string(),
  identityPrefix: Yup.string().required("Store code is required"),
});

const initAddState = {
  name: "",
  mobile: "",
  address: "",
  description: "",
  identityPrefix: "",
};

export function StoresEditForm({ edit, store, openStoresAddDialog, setOpenStoresAddDialog }) {
  const dispatch = useDispatch();

  const { storeCode } = useSelector((state) => ({
    storeCode: state.pos.generatedCode,
  }));
  let generatedStoreCode = storeCode?.storeIdentityPrefix;
  useEffect(() => {
    dispatch(posActions.fetchLatestStorePosCode());
  }, [dispatch, openStoresAddDialog]);

  return (
    <>
      <Modal
        className="modal-drawer invoice-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openStoresAddDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              setOpenStoresAddDialog(false);
            }}>
            <img src={IconClose} alt="loading" />
          </button>
          {edit === false ? (
            <>{<Modal.Title>Add New Store</Modal.Title>} </>
          ) : (
            <> {<Modal.Title>Edit Store</Modal.Title>}</>
          )}
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={
            edit
              ? {
                  ...store,
                  name: store?.name ? store?.name : store?.storeId,
                  address: store?.address ? store?.address : "",
                  description: store?.description ? store?.description : "",
                  mobile: store?.mobile ? store?.mobile : "",
                }
              : { ...initAddState, identityPrefix: incrementCharacter(generatedStoreCode) }
          }
          validationSchema={AddStoreSchema}
          onSubmit={(values) => {
            if (edit) {
              dispatch(storesActions.updateStore(store._id, values));
            } else {
              dispatch(storesActions.createStore(values));
            }
            setOpenStoresAddDialog(false);
          }}>
          {({ handleSubmit, values, setValues }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <Card>
                    <div className="invoice-items row">
                      <div className="col-12">
                        <div className="form-group">
                          <Field name="name" component={Input} placeholder="Store Name" label="Store Name" />
                        </div>
                        <div className="form-group">
                          <Field name="address" component={Input} placeholder="Store Address" label="Store Address" />
                        </div>
                        <div className="form-group">
                          <Field
                            component={Mobile}
                            name="mobile"
                            international
                            defaultCountry="SA"
                            style={{ display: "flex" }}
                            value={values?.mobile}
                            onChange={(value) => {
                              setValues({
                                ...values,
                                mobile: value,
                              });
                            }}
                            switchIt={true}
                          />
                        </div>
                        <div className="form-group">
                          <Field name="description" component={Input} placeholder="Description" label="Description" />
                        </div>
                        <div className="form-group mb-4">
                          <Field
                            name="identityPrefix"
                            label="Store Code"
                            component={Input}
                            placeholder="Enter Store Code"
                            withFeedbackLabel={true}
                            readOnly={edit}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <div className="row justify-content-center">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate f-button"
                    style={{ display: "block" }}>
                    Save
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
}
