import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const getFieldCSSClasses = (touched, errors, externalClasses) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  // if (touched && !errors) {
  //   classes.push("is-valid");
  // }

  return classes.join(" ");
};
export function Mobile({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  value,
  switchIt,
  onChange,
  type = "text",
  uniqueMobileError,
  internalClasses,
  externalClasses,
  customFeedbackLabel,
  withFeedbackLabel = true,
  form: { touched, errors },
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <div className={getFieldCSSClasses(touched[field.name], errors[field.name])}>
        <PhoneInput {...field} {...props} value={value} onChange={onChange} />
      </div>
      {withFeedbackLabel && switchIt && (
        <FieldFeedbackLabel
          type={type}
          label={label}
          uniqueMobileError={uniqueMobileError}
          error={errors[field.name]}
          touched={touched[field.name]}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
