import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { i18n } from "../../private/languageSelector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import IconClose from "../../../../assets/img/icon-close.svg";
import IconEdit from "../../../../assets/img/invoice-actions/edit.svg";
import IconCopy from "../../../../assets/img/invoice-actions/copy.svg";
import IconShare from "../../../../assets/img/invoice-actions/share.svg";
import IconPrint from "../../../../assets/img/invoice-actions/print.svg";
import IconRefund from "../../../../assets/img/invoice-actions/refund.svg";
import IconDelete from "../../../../assets/img/invoice-actions/delete.svg";
import IconFilter from "../../../../assets/img/invoice-actions/filter.svg";
import IconMobile from "../../../../assets/img/invoice-actions/mobile.svg";
import IconPayment from "../../../../assets/img/invoice-actions/payment.svg";
import IconDesktop from "../../../../assets/img/invoice-actions/desktop.svg";

import { InvoiceStore } from "../invoice-create-dialog/InvoiceStore";
import { InvoiceMobileDialog } from "../invoice-pdf/InvoiceMobileDialog";
import SendPdfInvoiceDialog from "../send-invoice-pdf/sendPDFInvoiceDialog";
import InvoiceRefundDialog from "../invoice-refund-dialog copy/InvoiceRefundDialog";
import { AddPaymentDialog } from "../invoice-refund-dialog/AddPaymentDialog";

export function InvoiceActionsModal({ show, onHide }) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [message, setMessage] = useState();
  const [emailMessage, setEmailMessage] = useState();
  const [parentInvoiceId, setParentInvoiceId] = useState("");
  const [openStoreDialog, setOpenStoreDialog] = useState(false);
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const [sendPdfInvoiceDialog, setSendPdfInvoiceDialog] = useState(false);
  const [showPaymentAndRefund, setShowPaymentAndRefund] = useState(false);
  const [showInvoiceMobileDialog, setShowInvoiceMobileDialog] = useState(false);

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext?.queryParams,
      openPrintInvoicePage: invoicesUIContext.openPrintInvoicePage,
      openEditInvoiceDialog: invoicesUIContext.openEditInvoiceDialog,
      openViewInvoicesDialog: invoicesUIContext.openViewInvoicesDialog,
      openDeleteInvoiceDialog: invoicesUIContext.openDeleteInvoiceDialog,
    };
  }, [invoicesUIContext]);

  useEffect(() => {
    dispatch(actions.fetchInvoice(id));
  }, [dispatch, id]);

  const { userAccess, invoice, invoiceFilterState } = useSelector(
    (state) => ({
      userAccess: state?.users?.userAccess,
      invoice: state?.invoices.invoiceForEdit,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );

  useEffect(() => {
    axios.get("api/whoAmI").then((res) => {
      setMessage(res?.data?.companyId?.defaultSms?.messageEnglish);
      setEmailMessage(res?.data?.companyId?.defaultEmail?.messageEnglish);
    });
  }, []);

  let copyToClipboard = (textToCopy) => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  const invoiceEditHandler = () => {
    setEdit(true);
    setOpenStoreDialog(true);
  };

  useEffect(() => {
    if (invoice?.invoiceData?.invoiceType === "refund") {
      setParentInvoiceId(invoice?.parentInvoice);
    } else {
      setParentInvoiceId(invoice?._id);
    }
  }, [invoice, show]);

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={show}
        onHide={() => {}}
      >
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={onHide}>
            <img src={IconClose} alt="loading" />
          </button>
          {<Modal.Title>{i18n("INVOICES.Actions")}</Modal.Title>}
        </Modal.Header>
        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            <div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  {
                    invoice?.invoiceData?.channel === "web"
                      ? window.open(`/view/invoiceHtml/${id}`, "_blank")
                      : window.open(`/posInvoice/${id}`, "_blank");
                  }
                }}
              >
                <img src={IconDesktop} alt="loading" />
                <span className="ml-4">
                  {i18n("INVOICES.DesktopWebPreview")}
                </span>
              </div>
              <div
                className="mt-5 ml-1 cursor-pointer"
                onClick={() => {
                  invoice?.invoiceData?.channel === "web"
                    ? setShowInvoiceMobileDialog(true)
                    : window.open(`/posInvoice/${id}`, "_blank");
                }}
              >
                <img src={IconMobile} alt="loading" />
                <span className="ml-5">
                  {i18n("INVOICES.MobileWebPreview")}
                </span>
              </div>
              {userAccess?.editData ? (
                <div
                  className={`mt-5 cursor-pointer ${
                    invoice?.status === "void" ||
                    invoice?.invoiceData?.invoiceType === "refund" ||
                    invoice?.invoiceData?.channel === "pos"
                      ? "text-muted"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      invoice?.status === "void" ||
                      invoice?.invoiceData?.invoiceType === "refund" ||
                      invoice?.invoiceData?.channel === "pos"
                    ) {
                    } else {
                      invoiceEditHandler();
                    }
                  }}
                >
                  <img src={IconEdit} alt="loading" />
                  <span className="ml-5">{i18n("INVOICES.Edit")}</span>
                </div>
              ) : (
                ""
              )}
              <div
                className="mt-5 cursor-pointer"
                onClick={() => {
                  dispatch(
                    actions.filterInvoice(
                      { ...invoiceFilterState, parentInvoice: parentInvoiceId },
                      invoicesUIProps.queryParams
                    )
                  );
                  dispatch(
                    actions.setInvoiceFilterState({
                      ...invoiceFilterState,
                      parentInvoice: parentInvoiceId,
                    })
                  );
                }}
              >
                <img src={IconFilter} alt="loading" />
                <span className="ml-4">
                  {i18n("INVOICES.FilterRelatedInvoices")}
                </span>
              </div>
              <hr />
            </div>
            {invoice?.status === "void" ||
            invoice?.invoiceData?.invoiceType === "refund" ||
            invoice?.invoiceData?.channel === "pos" ? (
              ""
            ) : (
              <>
                {userAccess?.editData ? (
                  <div className="mt-7 cursor-pointer">
                    <div onClick={() => setShowPaymentAndRefund(true)}>
                      <img src={IconPayment} alt="loading" />
                      <span className={`ml-5`}>
                        {i18n("INVOICES.AddPayment")}
                      </span>
                    </div>

                    <div
                      className="mt-5 cursor-pointer"
                      onClick={() => {
                        invoice?.status === "due"
                          ? toast.error("Only paid invoices are refundable")
                          : setShowRefundDialog(true);
                      }}
                    >
                      <img src={IconRefund} alt="loading" />
                      <span className={`ml-5`}>{i18n("INVOICES.Refund")}</span>
                    </div>
                    <hr />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            <div
              className="mt-7 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                setSendPdfInvoiceDialog(true);
              }}
            >
              <img src={IconShare} alt="loading" />
              <span className="ml-5">{i18n("INVOICES.ShareInvoice")}</span>
            </div>
            <div
              className="mt-5 cursor-pointer"
              onClick={() => {
                {
                  invoice?.invoiceData?.channel === "web"
                    ? copyToClipboard(
                        `${process.env.REACT_APP_URL}view/invoiceHtml/${id}`
                      )
                    : copyToClipboard(
                        `${process.env.REACT_APP_URL}posInvoice/${id}`
                      );
                }
                toast.success("Copied to clipboard");
              }}
            >
              <img src={IconCopy} alt="loading" />
              <span className="ml-5">{i18n("INVOICES.CopyInvoiceLink")}</span>
            </div>
            <hr />
            <div
              className="mt-7 cursor-pointer"
              onClick={() => {
                invoicesUIProps.openPrintInvoicePage(id);
              }}
            >
              <img src={IconPrint} alt="loading" />
              <span className="ml-5">{i18n("INVOICES.Print")}</span>
            </div>
            {invoice?.status === "void" ||
            invoice?.invoiceData?.invoiceType === "refund" ? (
              ""
            ) : userAccess?.deleteData ? (
              <div
                className={`mt-5 cursor-pointer`}
                onClick={() => invoicesUIProps.openDeleteInvoiceDialog(id)}
              >
                <img src={IconDelete} alt="loading" />
                <span className="ml-5">{i18n("INVOICES.Delete")}</span>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
        </>
      </Modal>
      <AddPaymentDialog
        id={id}
        invoice={invoice}
        showPaymentAndRefund={showPaymentAndRefund}
        secondaryAction={() => {
          setShowPaymentAndRefund(false);
        }}
      />
      <SendPdfInvoiceDialog
        id={id}
        secondaryAction={() => {
          setSendPdfInvoiceDialog(false);
        }}
        invoice={invoice}
        smsMessage={message}
        emailMessageFromCompany={emailMessage}
        sendPdfInvoiceDialog={sendPdfInvoiceDialog}
        setSendPdfInvoiceDialog={setSendPdfInvoiceDialog}
      />
      <InvoiceStore
        id={id}
        edit={edit}
        onHide={onHide}
        invoice={invoice}
        openStoreDialog={openStoreDialog}
        setOpenStoreDialog={setOpenStoreDialog}
      />
      <InvoiceRefundDialog
        onHide={onHide}
        invoice={invoice}
        showRefundDialog={showRefundDialog}
        setShowRefundDialog={setShowRefundDialog}
      />
      <InvoiceMobileDialog
        id={id}
        show={showInvoiceMobileDialog}
        onHide={() => setShowInvoiceMobileDialog(false)}
      />
    </>
  );
}
