import React from 'react'
import TaxReport from './analyticsView/TaxReport'
import {Switch} from "react-router-dom"

const TaxReportPage = () => {
  return (
   <Switch>
    <TaxReport/>
   </Switch>
  )
}

export default TaxReportPage