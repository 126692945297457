import React, { useEffect, useState } from "react";
import blueBanner from "../../../assets/img/EasyToolsImg.svg";
import logo from "../../../assets/img/posLogo.svg";
import headphoneicon from "../../../assets/img/headPhoneIcon.svg";
import globalIcon from "../../../assets/img/globalIcon.svg";
import { useHistory } from "react-router-dom";
const HomeHeader = () => {
  const history = useHistory();
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="home-pageheader" style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 18px" }}>
      <div className="d-flex mb-4">
        <div>
          <img className="home-logo" width={"160px"} src={logo} alt="" />
        </div>
        <div className="header-features" style={{ gap: "31px", marginLeft: "51px", marginTop: "13px" }}>
          <p style={{ fontWeight: "500", fontSize: "16px", color: "#4F4F4F" }}>Features</p>
          <p style={{ fontWeight: "500", fontSize: "16px", color: "#4F4F4F" }}> Industries</p>
          <p style={{ fontWeight: "500", fontSize: "16px", color: "#4F4F4F" }}> Prices</p>
          <p style={{ fontWeight: "500", fontSize: "16px", color: "#4F4F4F" }}> About Us</p>
          <p style={{ fontWeight: "500", fontSize: "16px", color: "#4F4F4F" }}> Contact</p>
        </div>
        <div className="d-flex ml-auto">
          <div className="phoneIcon" style={{ marginRight: "38px", marginTop: "8px" }}>
            <img width={"26px"} height={"26px"} src={headphoneicon} alt="" />
          </div>
          {screenSize > 467 ? (
            <div className="start-btn" style={{ marginRight: "45px" }}>
              <button
                style={{
                  borderRadius: "5px",
                  border: "0",
                  backgroundColor: "#9B51E0",
                  color: "#FFFFFF",
                  padding: "8px 28px",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                START NOW
              </button>
            </div>
          ) : (
            <div className="d-flex" style={{ alignItems: "center", position: "relative", bottom: "2px", marginRight: "41px" }}>
              <span className="mr-1" style={{ fontWeight: "400", fontSize: "16px", color: "#4F4F4F" }}>
                En
              </span>
              <img src={globalIcon} alt="" />
            </div>
          )}

          <div
            // onClick={() => {
            //   history.push("/auth/login");
            // }}
            className="login-btn"
            style={{ cursor:"pointer",marginTop: "8px", color: "#4F4F4F", lineHeight: "21.80px", fontWeight: "700", fontSize: "16px", marginRight: "40px" }}
          >
            Log In
          </div>
        </div>

        {screenSize < 468 ? (
          <div className="start-btn">
            <button
              style={{
                borderRadius: "5px",
                border: "0",
                backgroundColor: "#9B51E0",
                color: "#FFFFFF",
                padding: "5px 16px",
                fontWeight: "700",
                fontSize: "10px",
              }}
            >
              START NOW
            </button>
          </div>
        ) : (
          <div className="d-flex" style={{ alignItems: "center", position: "relative", bottom: "6px" }}>
            <span className="mr-1" style={{ fontWeight: "400", fontSize: "16px", color: "#4F4F4F" }}>
              En
            </span>
            <img src={globalIcon} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeHeader;
