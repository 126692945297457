import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SUBSCRIPTION_URL = "api/payment";

export const savePaymentCard = createAsyncThunk("payment/savePaymentCard", async ({ companyId, values }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${SUBSCRIPTION_URL}/savePaymentCard/${companyId}`, values);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    return rejectWithValue(errorMessage);
  }
});

export const verifyCompanyPaymentCard = createAsyncThunk("payment/verifyCompanyPaymentCard", async ({ companyId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${SUBSCRIPTION_URL}/verifyCompanyPaymentCard/${companyId}`);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    return rejectWithValue(errorMessage);
  }
});

export const getPaymentCard = createAsyncThunk("payment/getPaymentCard", async ({ companyId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${SUBSCRIPTION_URL}/getPaymentCard/${companyId}`);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    return rejectWithValue(errorMessage);
  }
});
