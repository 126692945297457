import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { ProductStatusCssClasses } from "../ProductsUIHelpers";
import { useProductsUIContext } from "../ProductsUIContext";

const selectedProducts = (entities, ids) => {
  const _products = [];
  ids.forEach((id) => {
    const product = entities.find((el) => el.id === id);
    if (product) {
      _products.push(product);
    }
  });
  return _products;
};

export function ProductsFetchDialog({ show, onHide }) {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
    };
  }, [productsUIContext]);

  // Products Redux state
  const { products } = useSelector(
    (state) => ({
      products: selectedProducts(state.products.entities, productsUIProps.ids),
    }),
    shallowEqual
  );

  // if products weren't selected we should close modal
  useEffect(() => {
    if (!productsUIProps.ids || productsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.ids]);

  return (
    <Modal show={show} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Fetch selected elements</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {products.map((product) => (
            <div className="timeline-item align-items-start" key={`id${product.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i className={`fa fa-genderless text-${ProductStatusCssClasses[product.status]} icon-xxl`} />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span className={`label label-lg label-light-${ProductStatusCssClasses[product.status]} label-inline`}>
                  ID: {product.id}
                </span>
                <span className="ml-3">
                  {product.lastName}, {product.firstName}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={onHide} className="btn btn-primary btn-elevate">
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
