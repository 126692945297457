import React, { useState, useEffect } from "react";

export const RefundQuantity = ({
  price,
  taxRate,
  products,
  setProducts,
  soldQuantity,
  productIndex,
  alreadyRefunded,
  originalProducts,
  setOriginalProducts,
}) => {
  const [productTax, setProductTax] = useState(0);
  const [productDiscount, setProductDiscount] = useState(0);
  const [productQuantity, setProductQuantity] = useState(0);

  useEffect(() => {
    if (products[productIndex]?.refundQuantity) {
      setProductQuantity(products[productIndex]?.refundQuantity);
    }
  }, [productIndex, products]);

  useEffect(() => {
    let discount = 0;
    let productPrice = +products[productIndex].price * +productQuantity;
    if (products[productIndex].discounts?.length) {
      discount = products[productIndex].discounts?.reduce((a, b) => {
        if (b?.selected) {
          if (b?.type === "%") {
            return (discount = a + (+b?.value * +productPrice) / 100);
          } else {
            return (discount = a + +b?.value * +productQuantity);
          }
        } else {
          return discount;
        }
      }, 0);
    }
    setProductDiscount(discount);
    // eslint-disable-next-line
  }, [productQuantity]);

  useEffect(() => {
    let tax = 0;
    if (products[productIndex]?.tax) {
      tax = productQuantity ? taxRate : 0;
    }
    setProductTax(tax);
    // eslint-disable-next-line
  }, [productQuantity, productDiscount]);

  useEffect(() => {
    let newProduct = {
      ...products[productIndex],
      refundQuantity: productQuantity,
    };
    products[productIndex] = newProduct;
    setProducts([...products]);
    addRefundQuantity(productIndex);

    // eslint-disable-next-line
  }, [productQuantity, productTax, productDiscount]);

  const addRefundQuantity = (productIndex) => {
    let newProduct;
    let originalProduct;
    newProduct = {
      ...products[productIndex],
      refundAmount: productQuantity * +price - +productDiscount + productTax,
      totalPrice: productQuantity * price,
    };
    originalProduct = {
      ...originalProducts[productIndex],
      refundedQuantity: productQuantity + +alreadyRefunded,
    };

    products[productIndex] = newProduct;
    originalProducts[productIndex] = originalProduct;
    setProducts([...products]);
    setOriginalProducts([...originalProducts]);
  };

  return (
    <div className="refund-dialog">
      <div>
        <button
          className="btn border btn-elevate float-right"
          onClick={() => {
            if (productQuantity > 0) {
              setProductQuantity(productQuantity - 1);
            }
          }}
        >
          -
        </button>
      </div>
      <div className="refunded-quantity">
        <span>{productQuantity}</span>
      </div>
      <div>
        <button
          className="btn border btn-elevate"
          onClick={() => {
            if (productQuantity < soldQuantity) {
              setProductQuantity(productQuantity + 1);
            }
          }}
        >
          +
        </button>
      </div>
    </div>
  );
};
