import React, { useState, useEffect } from "react";
import "./PdfInvoicePageHtml.css";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import IconFatoraty from "../../../../assets/img/icon-fatoraty.svg";
import IconPdf from "../../../../assets/img/icon-pdf.svg";
import IconPrint from "../../../../assets/img/icon-print.svg";
import IconPremier from "../../../../assets/img/Premier-Logo.svg";
import moment from "moment";
import IconQr from "../../../../assets/img/icon-qr.svg";
import { PosInvoicePreview } from "./PosInvoicePreview";

const InvoiceMobilePreview = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [invoiceData, setInvoiceData] = useState();
  const [companyData, setCompanyData] = useState();

  const { invoice } = useSelector(
    (state) => ({
      invoice: state?.invoices?.invoiceForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchInvoice(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (invoice) {
      let customerName =
        invoice?.invoiceData?.channel === "web"
          ? invoice?.invoiceData?.customerName
          : invoice?.invoiceData?.customerName?.first
          ? invoice?.invoiceData?.customerName?.first + " " + invoice?.invoiceData?.customerName?.last
          : "";

      let receieverName =
        invoice?.invoiceData?.channel === "web"
          ? invoice?.invoiceData?.receiverInformation?.name
          : invoice?.invoiceData?.receiverInformation?.name?.first
          ? invoice?.invoiceData?.receiverInformation?.name?.first +
            " " +
            invoice?.invoiceData?.receiverInformation?.name?.last
          : "";
      setInvoiceData({
        ...invoice,
        invoiceData: {
          ...invoice?.invoiceData,
          customerName: customerName,
          receiverInformation: { ...invoice?.invoiceData.receiverInformation, name: receieverName },
        },
      });
      setCompanyData({ ...invoice?.company });
    }
  }, [dispatch, invoice]);
  return invoice?.invoiceData?.channel === "web" ? (
    <>
      <div>
        <div className="html-invoice-mobile">
          {/* //////header section////// */}
          <div className="d-flex justify-content-between header">
            <div className="header-img">
              <img src={IconFatoraty} alt="loading" />
            </div>
            <div className="d-flex">
              <div className="mt-5 mr-5">
                <img
                  src={IconPdf}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${process.env.REACT_APP_URL}view/invoice/${invoiceData?._id}`, "_blank");
                  }}
                  alt="loading"
                />
              </div>
              <div className="mr-3 mt-5">
                <img
                  src={IconPrint}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${process.env.REACT_APP_URL}view/invoice/${invoiceData?._id}`, "_blank");
                  }}
                  alt="loading"
                />
              </div>
            </div>
          </div>
          <div>
            <div className=" header-img">
              <img style={{ maxWidth: "350px" }} src={IconPremier} alt="loading" />
            </div>

            {/* company detail */}

            <div className="company-detail">
              <b>{companyData?.companyName ? companyData?.companyName : ""}</b>
              <p className="mb-0">
                {companyData?.taxNumber ? companyData?.taxName + " No. " + companyData?.taxNumber : ""}
              </p>
              <p className="mb-0">{companyData?.crNumber ? "C.R. " + companyData?.crNumber : ""}</p>
              <p className="mb-0">{companyData?.mobile ? companyData?.mobile : ""}</p>
              <p className="mb-0">{companyData?.email ? companyData?.email : ""}</p>
              <p className="mb-0">{companyData?.city ? companyData?.city : ""}</p>
            </div>

            {/* Invoice Type */}

            <div className="tax-invoice">
              <b>{invoiceData?.invoiceData?.type === "refund" ? "Refund Invoice" : "Tax Invoice"}</b>
            </div>

            {/* Customer Information */}

            <div className="customer-information">
              <div className="customer-name">
                <b>Customer Information</b>
              </div>
              <div className="customer-detail">
                <div>
                  <div className="title">Name:</div>
                  <div className="detail">
                    {invoiceData?.invoiceData?.customerName
                      ? invoiceData?.invoiceData?.customerName
                      : invoiceData?.invoiceData?.receiverInformation?.name
                      ? invoiceData?.invoiceData?.receiverInformation?.name
                      : ""}
                  </div>
                </div>
                <br />
                <div>
                  <div className="title">Email:</div>
                  <div className="detail">
                    {invoiceData?.invoiceData?.customerEmail
                      ? invoiceData?.invoiceData?.customerEmail
                      : invoiceData?.invoiceData?.receiverInformation?.email
                      ? invoiceData?.invoiceData?.receiverInformation?.email
                      : ""}
                  </div>
                </div>
                <br />
                <div>
                  <div className="title">Mobile:</div>
                  <div className="detail">{invoiceData?.invoiceData?.receiverInformation?.mobile}</div>
                </div>
                <br />
                <div>
                  <div className="title">Tax No.:</div>
                  <div className="detail">{invoiceData?.invoiceData?.receiverInformation?.taxNumber}</div>
                </div>
                <br />
              </div>
            </div>

            {/* Invoice Information */}

            <div className="invoice-information">
              <div className="invoice-name">
                <b>Invoice Information</b>
              </div>
              <div className="invoice-detail">
                <div>
                  <div className="title">Number:</div>
                  <div className="detail">{invoiceData?.invoiceNumber}</div>
                </div>
                <br />
                <div>
                  <div className="title">Date:</div>
                  <div className="detail">{moment(invoiceData?.invoiceData?.invoiceDate).format("MM/DD/YYYY")}</div>
                </div>
                <br />
                <div>
                  <div className="title">Status:</div>
                  <div
                    className="detail"
                    style={{
                      color: invoiceData?.status === "paid" ? "#3CAEA3" : "",
                    }}>
                    {invoiceData?.status
                      ? invoiceData?.status.charAt(0).toUpperCase() + invoiceData?.status.slice(1)
                      : ""}
                  </div>
                </div>
                <br />
                <div>
                  <div className="title">Cashier:</div>
                  <div className="detail">
                    {invoiceData?.invoiceData?.senderInformation?.name?.first
                      ? invoiceData?.invoiceData?.senderInformation?.name?.first +
                        " " +
                        invoiceData?.invoiceData?.senderInformation?.name?.last
                      : ""}
                  </div>
                </div>
                <br />
              </div>
            </div>
            {/* //////invoice products Table////// */}
            <div className="table-div">
              <table className="table">
                <thead>
                  <tr>
                    <th>Item No.</th>
                    <th>Item Name</th>
                    <th>Item Description</th>
                    <th>Unit Price</th>
                    <th>Qty</th>
                    <th>Taxable Amount</th>
                    <th>Discount Amount</th>
                    <th>Tax Rates</th>
                    <th>Tax Amount</th>
                    <th>Item Subtotal Including VAT</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceData?.invoiceData?.products?.map((item, index) => (
                    <>
                      <tr key={item?.tournament?._id}>
                        <td>{index + 1}.</td>
                        <td>{item.name ? item.name : ""}</td>
                        <td style={{ minWidth: "100px" }}>{item.description ? item.description : ""}</td>
                        <td>{Number(item?.price).toFixed(2)}</td>
                        <td>{item?.quantity}</td>
                        <td>
                          {item?.price && item?.quantity
                            ? (item?.price * item?.quantity).toFixed(2)
                            : Number(item?.price).toFixed(2)}
                        </td>
                        <td>{item?.discount ? item?.discount?.value.toFixed(2) + " " + item?.discount?.type : 0}</td>
                        <td>{invoiceData?.company?.taxRate} %</td>
                        <td>
                          {item?.taxable
                            ? (
                                ((item.price?.toLocaleString() * invoiceData?.company?.taxRate) / 100) *
                                item?.quantity
                              ).toFixed(2)
                            : 0}
                        </td>
                        <td style={{ minWidth: "150px" }}>
                          {item?.taxable
                            ? (
                                ((item.price?.toLocaleString() * invoiceData?.company?.taxRate) / 100) *
                                  item?.quantity +
                                +item?.price?.toLocaleString() * item?.quantity
                              ).toFixed(2)
                            : (item?.price?.toLocaleString() * item?.quantity).toFixed(2)}
                        </td>
                      </tr>
                      {item && item.refundedQuantity ? (
                        <>
                          <tr>
                            <td colSpan="1"></td>
                            <td colSpan="9" style={{ color: "red" }}>
                              {item?.refundedQuantity} X Refunded On{" "}
                              {moment(invoiceData?.invoiceData.invoiceDate).format("dddd MMM DD, YYYY")}
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>

            {/* ////// Total ////// */}

            <div className="total-information">
              <div className="total-name">
                <b>Total</b>
              </div>
              <div className="total-detail">
                <div>
                  <div className="title">Subtotal (Excluding Tax)</div>
                  <div className="detail">
                    {companyData?.currency} {Number(invoiceData?.invoiceData?.productsTotal).toFixed(2)}
                  </div>
                </div>
                <br />
                <div>
                  <div className="title">Discount</div>
                  <div className="detail">
                    {companyData?.currency} {Number(invoiceData?.invoiceData?.discountTotal).toFixed(2)}
                  </div>
                </div>
                <br />
                <div>
                  <div className="title">Taxable Amount</div>
                  <div className="detail">
                    {companyData?.currency} {Number(invoiceData?.invoiceData?.productsTotal).toFixed(2)}
                  </div>
                </div>
                <br />
                <div>
                  <div className="title">Tax Amount</div>
                  <div className="detail">
                    {companyData?.currency} {Number(invoiceData?.invoiceData?.taxTotal).toFixed(2)}
                  </div>
                </div>
                <div>
                  <div className="title">
                    <b>Total (Including Tax)</b>
                  </div>
                  <div className="detail">
                    <b>
                      {companyData?.currency} {Number(invoiceData?.invoiceData?.totalSales).toFixed(2)}
                    </b>
                  </div>
                </div>
                <br />
              </div>
            </div>

            {/* ////// Payments ////// */}

            <div className="payment-information">
              <div className="payment-name">
                <b>Payment Details</b>
              </div>
              <div className="payment-detail">
                <div className="payment-border">
                  <div>
                    <div className="title due">Paid</div>
                    <div className="detail due">
                      {companyData?.currency}{" "}
                      {invoiceData?.invoiceData?.paidTotal ? Number(invoiceData?.invoiceData?.paidTotal).toFixed(2) : 0}
                    </div>
                    <div className="payments">
                      {invoiceData?.invoiceData?.payments?.map((payment) => (
                        <>
                          <span className="date">{moment(payment?.date).format("MM/DD/YYYY")}</span>
                          <span className="method">
                            {payment?.method === "visa/master_card"
                              ? "Master/Visa Card"
                              : payment.method === "mada_card"
                              ? "Mada Card"
                              : payment.method === "bank_transfer"
                              ? "Bank "
                              : payment.method === "cash"
                              ? "Cash"
                              : ""}
                          </span>
                          <span className="amount">
                            {" "}
                            {companyData?.currency} {Number(payment?.amount).toFixed(2)}
                          </span>
                          <br />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                <br />
                <div className="payment-border">
                  <div>
                    <div>
                      <div className="title due">Balance Due</div>
                      <div className="detail due">
                        {companyData?.currency}{" "}
                        {invoiceData?.invoiceData?.balance ? Number(invoiceData?.invoiceData?.balance).toFixed(2) : 0}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Due Date</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.dueDate && invoiceData?.invoiceData?.dueDate !== "Invalid date"
                          ? moment(invoiceData?.invoiceData?.dueDate).format("MM/DD/YYYY")
                          : ""}
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>

            {/* ////// Replacement Policy ////// */}

            <div className="terms-information">
              <div className="header-name">
                <b>Replacement Policy:</b>
              </div>
              <div className="terms-border">
                <div className="terms-detail">
                  <div>
                    <p>
                      To view the replacement policy, please visit the following link <br />
                      <a href="https://www.premier.com/ReplacementPolicy" target="_blank" rel="noopener noreferrer">
                        www.premier.com/ReplacementPolicy
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* ////// Terms And Conditions ////// */}

            <div className="terms-information">
              <div className="header-name">
                <b>Terms And Conditions:</b>
              </div>
              <div className="terms-border2">
                <div className="terms-detail">
                  <p>
                    To view the Terms and Conditions, please visit the following link
                    <br />
                    <a href="https://www.premier.com/TermsAndConditions" target="_blank" rel="noopener noreferrer">
                      www.premier.com/TermsAndConditions
                    </a>
                  </p>
                </div>
              </div>
            </div>

            {/* ////// Notes ////// */}

            <div className="terms-information">
              <div className="header-name">
                <b>Note</b>
              </div>
              <div className="terms-border">
                <div className="terms-detail">
                  <div>
                    <p>{invoiceData?.invoiceData?.generalNotes ? invoiceData?.invoiceData?.generalNotes : ""}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* ////// QR Image ////// */}

            <div className="qr-img">
              <img src={IconQr} alt="loading" style={{ width: "10rem" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <PosInvoicePreview invoice={invoice} invoiceData={invoiceData} />
  );
};
export default InvoiceMobilePreview;
