import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/category/categoryActions";
import { ProductCategoryDialog } from "./productCategoryDialog";
import { AddCategoryForm } from "./addCategory";
import { i18n } from "../../private/languageSelector";

const initAddState = {
  name: "",
  color: "",
  isActive: true,
  description: "",
};
export const Categories = ({ userAccess, openManageCategoryDialog, setOpenManageCategoryDialog }) => {
  // Categories Redux state
  const dispatch = useDispatch();

  const [initAddCategoryState] = useState(initAddState);
  const [searchCategory, setSearchCategory] = useState("");
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openCategoryAddDialog, setOpenCategoryAddDialog] = useState(false);

  const { categories, products } = useSelector(
    (state) => ({
      products: state?.products?.entities,
      categories: state?.category?.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCategories());
  }, [dispatch]);

  let itemsAssignedToCategory = categories?.map((item) => {
    return products?.filter((product) => product?.categoryData?._id === item?._id).length;
  });

  return (
    <>
      <Modal
        size="lg"
        onHide={() => {}}
        className="modal-drawer"
        show={openManageCategoryDialog}
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenManageCategoryDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("Category.ManageCategories")}</Modal.Title>
        </Modal.Header>
        <Formik enableReinitialize={true} initialValues={initAddCategoryState} onSubmit={(values) => {}}>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                {categories?.length ? (
                  <>
                    <Form className="form form-label-right">
                      <div className="row mt-5">
                        <div className="col-lg-12 ">
                          <Field
                            name="category"
                            component={Input}
                            placeholder={i18n("Category.SearchCategory")}
                            withFeedbackLabel={false}
                            onChange={(e) => {
                              setSearchCategory(e.target.value);
                            }}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <ul className="card-listitem list-unstyled">
                        {categories
                          ?.filter((category) => category?.name?.toLowerCase().includes(searchCategory?.toLowerCase()))
                          ?.map((category, index) => {
                            return (
                              <li key={index}>
                                <span
                                  className="card-icon category"
                                  style={{
                                    backgroundColor: category.color,
                                  }}
                                />
                                <div className="card-info w-100">
                                  <h5 className="truncate">{category.name}</h5>
                                  <span className="d-block w-100">
                                    {itemsAssignedToCategory?.length ? itemsAssignedToCategory[index] : 0}{" "}
                                    {i18n("MENU.Items")}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="text-center">
                        <h4>{i18n("Category.YouHaveNoCategoriesAdded")}</h4>
                        {userAccess?.createData ? (
                          <>
                            <p className="mt-5">{i18n("Category.ToAddANewCategoryClickHereTo")}</p>
                            <p
                              style={{ color: "#2D9CDB", cursor: "pointer" }}
                              onClick={() => setOpenCategoryAddDialog(true)}>
                              {i18n("Category.AddNewCategories")}
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
            );
          }}
        </Formik>
        {categories?.length ? (
          <>
            <Modal.Footer>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenCategoryDialog(true);
                  }}>
                  {i18n("Category.Manage")}
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
      <ProductCategoryDialog
        userAccess={userAccess}
        openCategoryDialog={openCategoryDialog}
        setOpenCategoryDialog={setOpenCategoryDialog}
      />
      <AddCategoryForm
        edit={false}
        initAddCategoryState={initAddCategoryState}
        openCategoryAddDialog={openCategoryAddDialog}
        setOpenCategoryAddDialog={setOpenCategoryAddDialog}
      />
    </>
  );
};
