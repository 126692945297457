import React from "react";

export function Checkbox({ isSelected, onChange, children, label, disabled }) {
  return (
    <>
      {label && (
        <label>
          <b>{label}</b>
        </label>
      )}
      <label className="checkbox checkbox-lg checkbox-single">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={onChange}
          disabled={disabled}
          style={{ background: "white" }}
        />
        {children}
        <span
          style={{
            height: "17px",
            width: "16px",
            border: "1px solid",
          }}
        />
      </label>
    </>
  );
}
