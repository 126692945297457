import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { Formik, Form, Field } from "formik";
import {
  Card,
  Input,
  Checkbox,
  SwitchToggler,
  ReactSelector,
} from "../../../../_metronic/_partials/controls";
import ImageUploading from "react-images-uploading";
import { i18n } from "../../private/languageSelector";
import ConfirmationDialog from "../../confirmationDialoge";
import { AddCategoryForm } from "../categories/addCategory";
import IconBarcode from "../../../../assets/img/barcode.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as taxesActions from "../../../../_redux/taxes/taxesActions";
import { replacingObjectInArray } from "../../../../helpers/constants";
import * as actions from "../../../../_redux/category/categoryActions";
import * as storesActions from "../../../../_redux/stores/storeActions";
import * as productActions from "../../../../_redux/products/productsActions";
import { PRODUCT_COLORS_AND_SHAPES } from "../../../../helpers/constants";
import { first } from "lodash";

// Validation schema
const ProductEditSchema = Yup.object().shape(
  {
    englishName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.RequiredField")),

    arabicName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .nullable(),

    sku: Yup.number(),
    price: Yup.number(),
    barcode: Yup.string(),
    // webSku: Yup.string(),
    arabicDetail: Yup.string(),
    englishDetail: Yup.string(),
    serviceIsActive: Yup.boolean(),
    serviceIsTaxable: Yup.boolean(),
  },
  ["englishName", "arabicName"]
);

export function ProductEditForm({ view, onHide, product, saveProduct }) {
  const dispatch = useDispatch();

  const [colorsAndShapes] = useState(PRODUCT_COLORS_AND_SHAPES);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);

  const [image, setImage] = useState();
  const [allTaxes, setAllTaxes] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [showColor, setShowColor] = useState(true);
  const [firstOption, setFirstOption] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [storesAccess, setStoresAccess] = useState([]);
  const [taxType, setTaxType] = useState("basic rate");
  const [posData, setPosData] = useState("colorAndShape");
  const [selectColors, setSelectColors] = useState({
    borderColor: "black",
    backgroundColor: "",
    clipPath: "black",
  });
  const [productName, setProductName] = useState({
    english: "",
    arabic: "",
  });
  const { userAccess, category, stores, taxes } = useSelector(
    (state) => ({
      taxes: state.taxes.entities,
      stores: state.stores.entities,
      userAccess: state?.users?.userAccess,
      category: state.category.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCategories());
    dispatch(taxesActions.fetchTaxes());
    dispatch(storesActions.fetchStores());
    if (
      window.location.pathname === "/products/new" ||
      window.location.pathname === "/invoices"
    ) {
      dispatch(productActions.fetchProductsSku());
    }
  }, [dispatch]);
  useEffect(() => {
    if (
      window.location.pathname === "/products/new" ||
      window.location.pathname === "/invoices"
    ) {
      let newTaxesArray;
      if (taxes && taxes.length) {

          newTaxesArray = taxes.map((item) => {
            return  item?.taxScheme === "Tax Exempt"
              ? { ...item, active: false }
              : { ...item, active: true } ;
          })
        
      }
      if (newTaxesArray && newTaxesArray.length) {
        setAllTaxes([...newTaxesArray]);
      }
    }
  }, [taxes, product]);

  const storesSelected = (event) => {
    if (stores && stores.length) {
      let allSelectedStores = stores.map((item) => {
        return event === true
          ? { ...item, active: true }
          : { ...item, active: false };
      });
      setAllStores(allSelectedStores);
    }
  };
  //let storeItem = [{ name: "All Stores", selected: true }];

  useEffect(() => {
    setProductName({
      ...productName,
      english: product?.englishName ? product?.englishName : "",
      arabic: product?.arabicName ? product?.arabicName : "",
    });
    setSelectColors({
      borderColor: product?.outlineColor,
      backgroundColor: product?.fillColor,
      clipPath: product?.shape,
    });
    setImage([{ image_url: product?.image }]);
    if (product?.image && product?.image !== "") {
      setShowColor(false);
      setShowImage(true);
      setPosData("image");
    }

    if (product?.taxType) {
      setTaxType(product.taxType);
    }
    setStoresAccess(product?.store);
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (product && product?.taxes?.length && taxes?.length) {
      let refactoredProductTax = product.taxes.map((item) => {
        return { ...item, active: true };
      });
      let refactoredAllTaxes = taxes?.map((item) => {
        return { ...item, active: false };
      });
      let newModifiedAllTaxes = replacingObjectInArray(
        refactoredAllTaxes,
        refactoredProductTax
      );
      if (newModifiedAllTaxes && newModifiedAllTaxes.length) {
        setAllTaxes([...newModifiedAllTaxes]);
      }
    } else {
      if (taxes?.length && product && product?.taxes?.length == 0) {
        let refactoredAllTaxes = taxes?.map((item) => {
          return { ...item, active: false };
        });
        setAllTaxes([...refactoredAllTaxes]);
      }
    }
    // eslint-disable-next-line
  }, [product, taxes]);

  useEffect(() => {
    if (product && product?.store?.length && stores?.length) {
      let refactoredProductStores = product.store.map((item) => {
        return { ...item, active: true };
      });
      let refactoredAllStores = stores?.map((item) => {
        return { ...item, active: false };
      });
      let newModifiedAllStores = replacingObjectInArray(
        refactoredAllStores,
        refactoredProductStores
      );
      if (newModifiedAllStores && newModifiedAllStores.length) {
        setAllStores([...newModifiedAllStores]);
      }
      if (product.allStoreOption === false) {
        setFirstOption(false);
      } else {
        setFirstOption(true);
      }
    } else {
      if (stores?.length) {
        let refactoredAllStores = stores?.map((item) => {
          return window.location.pathname === "/products/new" ||
            window.location.pathname === "/invoices"
            ? { ...item, active: true }
            : { ...item, active: false };
        });
        setAllStores([...refactoredAllStores]);

        if (product.allStoreOption === false) {
          setFirstOption(false);
        } else {
          setFirstOption(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [product, stores]);

  useEffect(() => {
    if (stores?.length === 1) {
      storeAccessHandler(stores[0], true);
    }
    // eslint-disable-next-line
  }, [stores]);

  const storeAccessHandler = (store, checked) => {
    if (storesAccess?.length) {
      if (checked) {
        let data = storesAccess?.filter((item) => item === store._id);
        if (!data.length) {
          setStoresAccess([...storesAccess, store._id]);
        }
      } else if (!checked) {
        let ids = [];
        // eslint-disable-next-line
        let data = storesAccess?.filter((item) =>
          item !== store._id ? ids.push(item) : {}
        );
        setStoresAccess(ids);
      }
    } else {
      setStoresAccess([store._id]);
    }
  };
  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
  };

  const onSubmitHandler = (values) => {
    let activeProductTaxes;
    if (allTaxes && allTaxes.length) {
      activeProductTaxes = allTaxes.filter((item) => item.active);
    }
    let findActiveStore =
      allStores && allStores.length
        ? allStores.filter((item) => item.active)
        : [];
    saveProduct({
      ...values,
      taxType: taxType,
      image: showImage ? image[0]?.image_url : "",
      allStoreOption: firstOption,
      storesAccess:
        findActiveStore && findActiveStore.length ? [...findActiveStore] : [],
      shape: selectColors?.clipPath ? selectColors?.clipPath : "#FFFFFF",
      arabicName: productName?.arabic,
      englishName: productName?.english,
      outlineColor: selectColors?.borderColor
        ? selectColors?.borderColor
        : "#E0E0E0",
      fillColor: selectColors?.backgroundColor
        ? selectColors?.backgroundColor
        : "#E0E0E0",
      taxes:
        activeProductTaxes && activeProductTaxes.length
          ? [...activeProductTaxes]
          : [],
    });
    setImage("");
  };
  const filteredTaxes = allTaxes.filter((i) => i.isDeleted === false)
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...product,
          stores: {},
          pos: posData,
          sku: product?.sku?.toString().padStart(4, "0"),
        }}
        validationSchema={ProductEditSchema}
        onSubmit={onSubmitHandler}
      >
        {({ handleSubmit, values, setValues, setFieldValue, touched }) => {
          return (
            <>
              <Modal.Body
                className="overlay overlay-block cursor-default"
                style={{ backgroundColor: "whitesmoke", width: "100%" }}
              >
                <Form className="form form-label-right">
                  <Card>
                    <div className="form-select">
                      <div className="invoice-title border-bottom-0">
                        <p className="m-0">{i18n("MENU.ProductStatus")}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-9 my-6">
                        <div>
                          <strong>{i18n("MENU.Active")}</strong>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="mx-5">
                          <SwitchToggler
                            className="default-switch"
                            id="serviceIsActive"
                            name="serviceIsActive"
                            checked={values.serviceIsActive || false}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                serviceIsActive: e.target.checked,
                              });
                            }}
                            {...(view ? { disabled: true } : {})}
                            readOnly={view}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="invoice-title border-bottom-0">
                      <p className="m-0">{i18n("MENU.ProductInformation")}</p>
                    </div>
                    <div className="invoice-items row">
                      <div className="col-lg-12">
                        <div className="form-group">
                          <Field
                            name="englishName"
                            component={Input}
                            placeholder={i18n("global.ProductName")}
                            label={i18n("global.ProductName")}
                            value={productName.english}
                            onChange={(e) => {
                              setFieldValue("englishName", e.target.value);
                              setProductName({
                                ...productName,
                                english: e.target.value,
                              });
                            }}
                            {...(view ? { disabled: true } : {})}
                            withFeedbackLabel={view ? false : true}
                            readOnly={view}
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            name="arabicName"
                            component={Input}
                            placeholder={i18n(
                              "global.ProductNameInAlternativeLanguage"
                            )}
                            arabicText="text-right"
                            label={i18n(
                              "global.ProductNameInAlternativeLanguage"
                            )}
                            value={productName.arabic}
                            onChange={(e) => {
                              setFieldValue("arabicName", e.target.value);
                              setProductName({
                                ...productName,
                                arabic: e.target.value,
                              });
                            }}
                            {...(view ? { disabled: true } : {})}
                            withFeedbackLabel={view ? false : true}
                            readOnly={view}
                          />
                        </div>
                        <div className="row mb-4" role="group">
                          <div className="col-4">
                            <strong>{i18n("MENU.SoldBy")}</strong>
                          </div>
                          <div className="col-lg-4">
                            <label>
                              <Field
                                name="soldBy"
                                type="radio"
                                value="each"
                                {...(view ? { disabled: true } : {})}
                                readOnly={view}
                              />
                              <b className="ml-1">{i18n("MENU.Each")}</b>
                            </label>
                          </div>
                          <div className="col-lg-4">
                            <label>
                              <Field
                                name="soldBy"
                                type="radio"
                                value="amount"
                                {...(view ? { disabled: true } : {})}
                                readOnly={view}
                              />
                              <b className="ml-1">{i18n("MENU.Amount")}</b>
                            </label>
                          </div>
                        </div>
                        <div className="form-group remove-arrows mt-5">
                          <Field
                            name="price"
                            component={Input}
                            type="number"
                            label={i18n("global.UnitPrice")}
                            {...(view ? { disabled: true } : {})}
                            withFeedbackLabel={view ? false : true}
                            readOnly={view}
                            placeholder={i18n("global.UnitPrice")}
                          />
                          <span>
                            {i18n(
                              "MENU.LeaveBlankToSpecifyPriceDuringTheSales"
                            )}
                          </span>
                        </div>
                        <div className="d-flex">
                          <div className="form-group input-select w-100">
                            <Field
                              name="categoryInProduct"
                              placeholder={i18n("global.Category")}
                              component={ReactSelector}
                              options={
                                category
                                  ? [].concat(
                                      ...category
                                        .filter(
                                          (item) => item.isActive !== false
                                        )
                                        .map((item) => {
                                          return {
                                            value: item?._id ? item?._id : "",
                                            label: `${
                                              item?.name ? item?.name : ""
                                            }`,
                                          };
                                        })
                                    )
                                  : undefined
                              }
                              onChange={(option) =>
                                setFieldValue("categoryInProduct", option)
                              }
                              touched2={touched}
                              {...(view ? { isDisabled: true } : {})}
                              withFeedbackLabel={view ? false : true}
                              readOnly={view}
                            />
                          </div>
                          <span
                            disabled={view}
                            className="svg-icon menu-icon svg-icon-md fas fa-plus mt-5 ml-3"
                            style={{
                              margin: "0px",
                              color: view ? "grey" : "#20639B",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              !view &&
                              userAccess?.products?.subItem[1]?.selected
                                ? setOpenCategoryDialog(true)
                                : toast.error("Access Denied");
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                  {!view && stores && stores.length > 1 ? (
                    <Card>
                      <h5 className="mb-5">
                        <b>Stores Availability</b>
                      </h5>
                      <div className="d-flex mb-5">
                        <Field
                          {...(view ? { disabled: true } : {})}
                          readOnly={view}
                          name="AllStores"
                          component={Checkbox}
                          isSelected={firstOption}
                          onChange={(e) => {
                            storesSelected(e.target.checked);
                            e.target.checked === true
                              ? setFirstOption(true)
                              : setFirstOption(false);
                          }}
                        />
                        <span className="ml-3">All Stores</span>
                      </div>

                      <div>
                        {allStores.map((store, i) => {
                          return (
                            <div key={i} className="d-flex mb-5 ml-5">
                              <Field
                                {...(view ? { disabled: true } : {})}
                                readOnly={view}
                                name={store?.storeId}
                                component={Checkbox}
                                isSelected={store.active}
                                onChange={(e) => {
                                  let newStore;
                                  newStore = {
                                    ...allStores[i],
                                    active: e.target.checked,
                                  };
                                  allStores[i] = newStore;
                                  setAllStores([...allStores]);
                                  let inActiveStore = allStores.filter(
                                    (item) => item.active === false
                                  );

                                  inActiveStore && inActiveStore.length >= 1
                                    ? setFirstOption(false)
                                    : setFirstOption(true);
                                }}
                              />
                              <span className="ml-5">
                                {store?.name ? store?.name : store?.storeId}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  ) : (
                    ""
                  )}

                  {filteredTaxes && filteredTaxes.length ? (
                    <Card>
                      <h5 className="mb-5">
                        <b>{i18n("MENU.Taxes")}</b>
                      </h5>
                      <div>
                        {filteredTaxes.map((tax, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex justify-content-between"
                            >
                              <div className="d-flex">
                                <SwitchToggler
                                  className="default-switch pb-13"
                                  id={tax._id}
                                  name={tax._id}
                                  readOnly={view}
                                  {...(view ? { disabled: true } : {})}
                                  checked={tax.active}
                                  onChange={(e) => {
                                    let DuplicateTaxObject = {
                                      ...allTaxes[index],
                                      active: e.target.checked,
                                    };
                                    allTaxes[index] = DuplicateTaxObject;
                                    setAllTaxes([...allTaxes]);
                                  }}
                                />
                                <div className="ml-5 pt-3">
                                  <b>{tax?.name ? tax?.name : ""}</b>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Card>
                  ) : (
                    ""
                  )}

                  <Card>
                    <div className="form-select">
                      <div className="invoice-title border-bottom-0">
                        <p className="m-0">
                          {i18n("MENU.RepresentationOnPOS")}
                        </p>
                      </div>
                    </div>
                    <div className="row" role="group">
                      <div className="col-1 mt-1">
                        <Field
                          name="pos"
                          type="radio"
                          id="colorAndShape"
                          value="colorAndShape"
                          onChange={(e) => {
                            if (e.target.id === "colorAndShape") {
                              setShowImage(false);
                              setShowColor(true);
                              setFieldValue("pos", e.target.id);
                            }
                          }}
                          {...(view ? { disabled: true } : {})}
                          readOnly={view}
                        />
                      </div>
                      <div className="col-lg-7">
                        <label>
                          <b>{i18n("MENU.ColorAndShape")}</b>
                        </label>
                      </div>
                      <div className="col-lg-1 mt-1">
                        <Field
                          name="pos"
                          type="radio"
                          value="image"
                          id="image"
                          onChange={(e) => {
                            if (e.target.id === "image") {
                              setShowColor(false);
                              setShowImage(true);
                              setFieldValue("pos", e.target.id);
                            }
                          }}
                          {...(view ? { disabled: true } : {})}
                          readOnly={view}
                        />
                      </div>
                      <div className="col-lg-3">
                        <label>
                          <b>{i18n("MENU.Image")}</b>
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      {colorsAndShapes?.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div className="product-color-empty-spaces" />
                            <div
                              className="product-color-shapes"
                              onClick={() =>
                                setSelectColors({
                                  ...selectColors,
                                  backgroundColor: item.color,
                                })
                              }
                              style={{
                                backgroundColor: item.color,
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </div>

                    <div className="row mt-3">
                      {colorsAndShapes?.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div className="product-color-empty-spaces" />
                            <div
                              className="product-color-shapes"
                              onClick={() => {
                                setSelectColors({
                                  ...selectColors,
                                  borderColor: item.color,
                                });
                              }}
                              style={{
                                border: `3px solid ${item.color}`,
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      {colorsAndShapes?.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div
                              className="product-text-colors d-flex justify-content-center"
                              onClick={() =>
                                setSelectColors({
                                  ...selectColors,
                                  clipPath: item.color,
                                })
                              }
                              style={{
                                color: item.color,
                              }}
                            >
                              Aa
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                    {showColor && (
                      <div
                        className="clip-preview"
                        style={{
                          clipPath: selectColors.clipPath,
                          border: `3px solid ${selectColors.borderColor}`,
                          backgroundColor: selectColors.backgroundColor
                            ? selectColors.backgroundColor
                            : "lightgrey",
                        }}
                      >
                        <p style={{ color: selectColors.clipPath }}>
                          {productName.english && productName.arabic
                            ? productName.english
                            : productName.english
                            ? productName.english
                            : productName.arabic
                            ? productName.arabic
                            : ""}
                        </p>
                      </div>
                    )}
                    {showImage && (
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <ImageUploading
                            value={image}
                            onChange={onChange}
                            dataURLKey="image_url"
                          >
                            {({ imageList, onImageUpload }) => (
                              <>
                                <div className="text-center mt-5">
                                  <button
                                    {...(view ? { disabled: true } : {})}
                                    readOnly={view}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={onImageUpload}
                                  >
                                    {i18n("MENU.ClickToUploadImage")}
                                  </button>
                                </div>
                                {imageList.map((image, index) => (
                                  <div
                                    key={index}
                                    className="mt-5 clip-preview with-img"
                                    style={{
                                      clipPath: selectColors.clipPath,
                                    }}
                                  >
                                    <img src={image["image_url"]} alt="" />
                                    <p>
                                      {productName.english && productName.arabic
                                        ? productName.english
                                        : productName.english
                                        ? productName.english
                                        : productName.arabic
                                        ? productName.arabic
                                        : ""}
                                    </p>
                                  </div>
                                ))}
                              </>
                            )}
                          </ImageUploading>
                        </div>
                      </div>
                    )}
                  </Card>
                  <Card>
                    <div className="invoice-title border-bottom-0">
                      <p className="m-0">
                        {i18n("MENU.AdditionalInformation")}
                      </p>
                    </div>
                    <div className="form-group">
                      <Field
                        name="barcode"
                        component={Input}
                        label={i18n("global.Barcode")}
                        placeholder={i18n("global.Barcode")}
                        {...(view ? { disabled: true } : {})}
                        withFeedbackLabel={view ? false : true}
                        readOnly={view}
                      />
                      <span
                        style={{
                          float: " right",
                          marginTop: "-30px",
                          position: "relative",
                          zIndex: "1",
                          width: "auto",
                        }}
                      >
                        <img src={IconBarcode} alt="loading" />
                      </span>
                    </div>
                    <div className="form-group">
                      <Field
                        name="sku"
                        component={Input}
                        label="SKU"
                        placeholder="SKU"
                        {...(view ? { disabled: true } : {})}
                        withFeedbackLabel={view ? false : true}
                        readOnly={view}
                      />
                    </div>
                    <div className="form-group">
                      <b className="mb-4">{i18n("MENU.ProductDescription")}</b>
                      <textarea
                        rows="7"
                        style={{ width: "100%" }}
                        name="englishDetail"
                        placeholder={i18n("global.EnterProductDescription")}
                        label={i18n("global.EnterProductDescription")}
                        readOnly={view}
                        value={values?.englishDetail}
                        onChange={(e) =>
                          setFieldValue("englishDetail", e.target.value)
                        }
                      />
                    </div>
                  </Card>
                  <div className="btn-bar">
                    <button
                      type="button"
                      onClick={() => {
                        view ? onHide() : setConfirmDialogForClose(true);
                      }}
                      className={
                        view
                          ? "btn btn-primary btn-elevate"
                          : "btn btn-light btn-elevate"
                      }
                    >
                      {view ? i18n("MENU.Close") : i18n("MENU.Cancel")}
                    </button>
                    <> </>
                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate f-button"
                      style={view ? { display: "none" } : { display: "block" }}
                    >
                      {i18n("MENU.Save")}
                    </button>
                  </div>
                </Form>
              </Modal.Body>
            </>
          );
        }}
      </Formik>
      <ConfirmationDialog
        show={confirmDialogForClose}
        title="Confirmation ?"
        subTitle="Are you sure you want to cancel without saving!"
        primaryAction={() => {
          setConfirmDialogForClose(false);
          onHide();
        }}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle="Yes"
        secondaryActionTitle="No"
      />
      <AddCategoryForm
        edit={false}
        openCategoryAddDialog={openCategoryDialog}
        setOpenCategoryAddDialog={setOpenCategoryDialog}
      />
    </>
  );
}
