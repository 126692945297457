import { createSlice } from "@reduxjs/toolkit";

const initialCompanyState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  companyForEdit: {
    companyName: "",
    email: "",
    mobile: "",
    currency: "",
    industry: "",
    taxNumber: "",
    taxRate: "",
    taxName: "",
    crNumber: "",
    country: "",
    city: "",
    state: "",
    zipcode: "",
    street: "",
    defaultEmail: {
      messageEnglish: "",
      messageArabic: "",
    },
    defaultSms: {
      messageEnglish: "",
      messageArabic: "",
    },
    termsAndConditions: "",
    returnPolicy: "",
    paymentCards: {
      moyasar: { token: String, tempToken: String },
    },
  },
  lastError: null,
  navigate: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const companySlice = createSlice({
  name: "company",
  initialState: initialCompanyState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      state.companyForEdit = {
        companyForEdit: {
          companyName: "",
          email: "",
          mobile: "",
          currency: "",
          industry: "",
          taxNumber: "",
          taxRate: "",
          taxName: "",
          crNumber: "",
          country: "",
          city: "",
          state: "",
          zipcode: "",
          street: "",
          defaultEmail: {
            messageEnglish: "",
            messageArabic: "",
          },
          defaultSms: {
            messageEnglish: "",
            messageArabic: "",
          },
          termsAndConditions: "",
          returnPolicy: "",
        },
      };
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCompanyById
    companyFetchedById: (state, action) => {
      state.actionsLoading = false;

      state.CompanyForEdit = {
        ...action.payload.companyForEdit,
      };
      state.error = null;
    },
    // findcompany
    companyFetched: (state, action) => {
      const { totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.companyForEdit = action.payload.companyForEdit;
      state.totalCount = totalCount;
    },
    // updateCustomer
    companyUpdated: (state) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
    },
  },
});
