import axios from "axios";

export const COMPANY_URL = "api/company";
// READ
export function getCompany() {
  return axios.get(`${COMPANY_URL}`);
}

export function getCompanyById(companyId) {
  return axios.get(`${COMPANY_URL}/${companyId}`);
}

export function updateCompany(company) {
  return axios.patch(`${COMPANY_URL}/${company._id}`, company);
}
