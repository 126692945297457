import React from "react";

export function Radio({ isSelected, onChange, children, label, id, name, disabled }) {
  return (
    <>
      <div className="custom-control custom-radio ">
        <input
          className="custom-control-input "
          id={id}
          type="radio"
          checked={isSelected}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
        {label && (
          <label htmlFor={id} className="custom-control-label">
            <b>{label}</b>
          </label>
        )}
        {children}
      </div>
    </>
  );
}
