import React, { useState, useMemo, useEffect } from "react";
import * as Yup from "yup";
import moment from "moment";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../private/languageSelector";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { PAYMENT_TYPES } from "../../../../helpers/constants";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import {
  Card,
  Input,
  Select,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";

const paymentSchema = Yup.object().shape({
  method: Yup.string().required("Payment method is required."),
  date: Yup.date()
    .typeError("Date is required.")
    .required("Date is required."),
});

const initPayments = {
  method: "",
  amount: "",
  type: "CREDIT",
  createdAt: new Date(),
  date: new Date(),
};

export function AddPaymentDialog({
  id,
  invoice,
  secondaryAction,
  showPaymentAndRefund,
}) {
  const dispatch = useDispatch();

  const [payment, setPayment] = useState();
  const [payments, setPayments] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [defaultPayment, setDefaultPayment] = useState("Cash");
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext?.queryParams,
    };
  }, [invoicesUIContext]);

  const { user, companyCurrency, invoiceFilterState } = useSelector(
    (state) => ({
      user: state?.auth?.user,
      companyCurrency: state?.auth?.user?.companyId?.currency,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (invoice) {
      if (invoice?.invoiceData?.balance < 1) {
        setTransactionType([{ label: "Refund", value: "DEBIT" }]);
      } else if (
        invoice?.invoiceData?.balance > 0 &&
        invoice?.invoiceData?.paidTotal < 1
      ) {
        setTransactionType([{ label: "Payment", value: "CREDIT" }]);
      } else {
        setTransactionType([
          { label: "Payment", value: "CREDIT" },
          { label: "Refund", value: "DEBIT" },
        ]);
      }
      setPayments(invoice?.invoiceData?.payments);
    }
  }, [invoice, showPaymentAndRefund]);

  useEffect(() => {
    if (user?.companyId?.paymentTypes?.length) {
      setPaymentTypesToShow([...user?.companyId?.paymentTypes]);
    } else {
      setPaymentTypesToShow([...PAYMENT_TYPES]);
    }
  }, [user]);

  // Setting Default Payment Channel
  useEffect(() => {
    if (paymentTypesToShow?.length) {
      let defaultChannel = paymentTypesToShow.filter((item) => {
        return item.default === true;
      });
      setDefaultPayment(defaultChannel[0]?.name);
    }
    // eslint-disable-next-line
  }, [paymentTypesToShow]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...initPayments,
          method: defaultPayment,
        }}
        validationSchema={paymentSchema}
        onSubmit={(values) => {
          if (invoice?.invoiceData?.balance > 0) {
            if (
              payment &&
              id &&
              +payment <= invoice.invoiceData.balance?.toFixed(2)
            ) {
              let payments = [...invoice.invoiceData.payments];
              payments.push({
                ...values,
                amount: Number(payment),
              });
              let invoiceData = {
                ...invoice.invoiceData,
                payments: [...payments],
                balance:
                  values.type === "CREDIT"
                    ? Number(
                        (invoice.invoiceData.balance - Number(payment)).toFixed(
                          2
                        )
                      )
                    : values.type === "DEBIT"
                    ? Number(
                        (invoice.invoiceData.balance + Number(payment)).toFixed(
                          2
                        )
                      )
                    : Number(invoice.invoiceData.balance.toFixed(2)),
                paidTotal:
                  values.type === "CREDIT"
                    ? Number(
                        (
                          invoice.invoiceData.paidTotal + Number(payment)
                        ).toFixed(2)
                      )
                    : values.type === "DEBIT"
                    ? Number(
                        (
                          invoice.invoiceData.paidTotal - Number(payment)
                        ).toFixed(2)
                      )
                    : Number(invoice.invoiceData.paidTotal.toFixed(2)),
              };
              dispatch(
                actions.updateInvoiceFromActionDialog(
                  invoiceFilterState,
                  { ...invoice, invoiceData: invoiceData, id: invoice?._id },
                  invoicesUIProps.queryParams
                )
              );

              setPayment(0);
              secondaryAction();
            } else {
              toast.error("Payment can not exceed due amount");
            }
          } else {
            toast.error("No Balance Is Due");
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Modal
              size="lg"
              onHide={() => {}}
              className="modal-drawer invoice-drawer"
              show={showPaymentAndRefund}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <button
                  type="button"
                  onClick={() => {
                    secondaryAction();
                    setPayment("");
                  }}
                  className="btn btn-close"
                >
                  <img src={IconClose} alt="loading" />
                </button>
                <Modal.Title>
                  <div className="ml-5">{` Add a payment for Invoice #`}</div>
                  <div>{invoice?.invoiceData?.newInvoiceNumber}</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Card className="invoice-total">
                  <div className="invoice-title h5">
                    <b>{i18n("INVOICES.Total")}</b>
                    <b>
                      {companyCurrency}{" "}
                      {+invoice?.invoiceData?.totalSales
                        ? Number(invoice?.invoiceData?.totalSales).toFixed(2)
                        : 0}
                    </b>
                  </div>
                  <ul>
                    <li>
                      <span>{i18n("INVOICES.Subtotal")}</span>
                      <span>
                        {companyCurrency}{" "}
                        {+invoice?.invoiceData?.productsTotal
                          ? Number(invoice?.invoiceData?.productsTotal).toFixed(
                              2
                            )
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span>{i18n("INVOICES.Discounts")}</span>
                      <span>
                        {companyCurrency}{" "}
                        {+invoice?.invoiceData?.discountTotal
                          ? Number(invoice?.invoiceData?.discountTotal).toFixed(
                              2
                            )
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span>Taxable Amount</span>
                      <strong>
                        {companyCurrency}{" "}
                        {invoice?.invoiceData?.productsTotal &&
                        invoice?.invoiceData?.discountTotal
                          ? Number(
                              +invoice?.invoiceData?.productsTotal -
                                +invoice?.invoiceData?.discountTotal
                            ).toFixed(2)
                          : invoice?.invoiceData?.productsTotal
                          ? +invoice?.invoiceData?.productsTotal
                          : 0}
                      </strong>
                    </li>
                    <li>
                      <span>{i18n("INVOICES.Tax")}</span>
                      <span>
                        {companyCurrency}{" "}
                        {invoice?.invoiceData?.taxTotal
                          ? Number(invoice?.invoiceData?.taxTotal).toFixed(2)
                          : 0}
                      </span>
                    </li>
                  </ul>
                </Card>
                {payments?.length
                  ? payments.map((item, index) => {
                      return (
                        <Card key={index}>
                          <div
                            className="invoice-title h5 mb-0"
                            style={{ borderBottom: "none" }}
                          >
                            <b>Payment {index + 1}</b>
                            <b>
                              {companyCurrency}{" "}
                              {item?.amount ? item?.amount : 0.0}
                            </b>
                          </div>
                          <div
                            className="invoice-title text-muted mb-0 h5"
                            style={{ borderBottom: "none" }}
                          >
                            <span>{item?.method}</span>
                            <span>
                              {moment(item?.date).format("d MMM YYYY")}
                            </span>
                          </div>
                          {item?.note ? (
                            <div>
                              <span>{item?.note}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </Card>
                      );
                    })
                  : ""}

                <Card className="invoice-total">
                  <div className="invoice-title h5">
                    <b>{i18n("INVOICES.Paid")}</b>
                    <b>
                      {companyCurrency}{" "}
                      {invoice?.invoiceData?.paidTotal
                        ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2)
                        : 0.0}
                    </b>
                  </div>
                  <ul>
                    <li>
                      <span>{i18n("INVOICES.BalanceDue")}</span>
                      <strong>
                        {companyCurrency}{" "}
                        {invoice?.invoiceData?.balance
                          ? Number(invoice?.invoiceData?.balance)?.toFixed(2)
                          : 0.0}
                      </strong>
                    </li>
                  </ul>
                </Card>
                <Card>
                  <Form className="form form-label-right">
                    <div className="mb-7">
                      <b>{i18n("INVOICES.PaymentDetail")}</b>
                    </div>
                    <div className="form-group form-select row">
                      <div className="col-lg-12">
                        <Field
                          component={Select}
                          label={i18n("Company.PaymentType")}
                          name="type"
                        >
                          {transactionType?.map((item, index) => {
                            return (
                              <option key={index} value={item.value}>
                                {item.value ? item.label : ""}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                    </div>
                    <div className="form-group form-select row">
                      <div className="col-lg-12">
                        <Field
                          component={Select}
                          name="method"
                          label={i18n("INVOICES.PaymentChannel")}
                        >
                          <option aria-label="None" value="" />
                          {paymentTypesToShow?.length
                            ? paymentTypesToShow
                                ?.filter((it) => it.active)
                                ?.map((item, index) => {
                                  return (
                                    <option key={index} value={item?.name}>
                                      {item?.name}
                                    </option>
                                  );
                                })
                            : ""}
                        </Field>
                      </div>
                    </div>
                    <div className=" form-select row">
                      <div
                        className="col-lg-12"
                        style={{ display: "inline-grid" }}
                      >
                        <DatePickerField
                          name="date"
                          label={"Payment Date"}
                          selected={values.date}
                          onChange={(val) => {
                            setFieldValue("date", val);
                          }}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <Field
                          min="1"
                          type="number"
                          name="amount"
                          value={payment}
                          component={Input}
                          label={"Transaction Value"}
                          placeholder={i18n("INVOICES.EnterPaymentAmount")}
                          onChange={(e) =>
                            setPayment(
                              e.target.value
                                .toString()
                                .split(".")
                                .map((el, i) =>
                                  i
                                    ? el
                                        .split("")
                                        .slice(0, 2)
                                        .join("")
                                    : el
                                )
                                .join(".")
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form>
                </Card>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate f-button"
                >
                  {i18n("INVOICES.Save")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
