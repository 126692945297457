import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { i18n } from "../../../private/languageSelector";
import { ManageUserRole } from "./ManageUserRoles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as rolesActions from "../../../../../_redux/roles/rolesActions";

const UserRole = ({ userAccess, openUserRoleDialog, setOpenUserRoleDialog }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [openManageRoleDialog, setOpenManageRoleDialog] = useState(false);

  const { roles } = useSelector(
    (state) => ({
      roles: state.roles.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(rolesActions.fetchRoles());
  }, [dispatch]);

  return (
    <>
      <Modal
        className="modal-drawer roles-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openUserRoleDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenUserRoleDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("Company.RolesManagement")}</Modal.Title>
        </Modal.Header>
        <Formik>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <span>Search</span>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control mr-2"
                      placeholder="Enter Role Name"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <ul className="card-listitem list-unstyled">
                    <div className="h4 d-flex ml-2 w-100">
                      <b style={{ width: "165px" }}>Role</b>
                      <b>Access</b>
                    </div>
                    {roles && roles.length
                      ? roles
                          ?.filter((it) => {
                            return it.name?.toLowerCase()?.includes(searchText?.toLowerCase());
                          })
                          .map((item, i) => {
                            return (
                              <li key={i}>
                                <div className="card-info d-flex w-100">
                                  <div style={{ width: "165px" }}>
                                    <b className="heading-color truncate-160 d-inline-block h6">
                                      {item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
                                    </b>
                                    <p className="mb-0 sub-heading-color">{item?.users.length} Employees</p>
                                  </div>
                                  <b className="heading-color h6">
                                    {item.pos === true && item.backOffice === true
                                      ? "Back office & POS"
                                      : item.pos === true && item.backOffice === false
                                      ? "POS"
                                      : item.pos === false && item.backOffice === true
                                      ? "Back office"
                                      : ""}
                                  </b>
                                </div>
                              </li>
                            );
                          })
                      : ""}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <Modal.Footer>
          <div className="row justify-content-center">
            <button className="btn btn-primary btn-elevate f-button" onClick={() => setOpenManageRoleDialog(true)}>
              Manage
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ManageUserRole
        roles={roles}
        userAccess={userAccess}
        openManageRoleDialog={openManageRoleDialog}
        setOpenManageRoleDialog={setOpenManageRoleDialog}
      />
    </>
  );
};
export default UserRole;
