import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { i18n } from "../../private/languageSelector";
import IconClose from "../../../../assets/img/icon-close.svg";
import { Card } from "../../../../_metronic/_partials/controls";
import moment from "moment";
import { Formik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import SendPdfInvoiceDialog from "../send-invoice-pdf/sendPDFInvoiceDialog";

export function InvoiceSummaryDialog({
  show,
  onHide,
  payments,
  invNumber,
  invoiceValues,
  companyCurrency,
  formResetHandler,
  closePaymentModal,
  setOpenInvoiceDialog,
}) {
  const [shareInvoiceDialog, setShareInvoiceDialog] = useState(false);

  const { invoiceId } = useSelector(
    (state) => ({
      invoiceId: state?.invoices?.invoiceForShow?.id,
    }),
    shallowEqual
  );

  return (
    <>
      <Modal
        show={show}
        className="modal-drawer invoice-drawer"
        onHide={() => {}}
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              onHide();
              closePaymentModal();
              formResetHandler();
              setOpenInvoiceDialog();
            }}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">Invoice #{invNumber}</Modal.Title>
        </Modal.Header>

        <Formik enableReinitialize={true} initialValues={{ paymentDate: new Date() }}>
          {() => (
            <>
              <Modal.Body>
                <Card className="invoice-total">
                  <div className="invoice-title h5">
                    <b>{i18n("INVOICES.Total")}</b>
                    <b>
                      {companyCurrency} {invoiceValues?.totalSales ? invoiceValues?.totalSales.toFixed(2) : 0.0}
                    </b>
                  </div>
                  <ul>
                    <li>
                      <span>{i18n("INVOICES.Subtotal")}</span>
                      <span>
                        {companyCurrency} {invoiceValues?.subTotalPrice ? invoiceValues?.subTotalPrice.toFixed(2) : 0}
                      </span>
                    </li>
                    <li>
                      <span>{i18n("INVOICES.Discounts")}</span>
                      <span>
                        {companyCurrency} {invoiceValues?.totalDiscount ? invoiceValues?.totalDiscount.toFixed(2) : 0}
                      </span>
                    </li>
                    <li>
                      <span>Taxable Amount</span>
                      <strong>
                        {companyCurrency}{" "}
                        {invoiceValues.subTotalPrice && invoiceValues.totalDiscount
                          ? +invoiceValues.subTotalPrice - +invoiceValues.totalDiscount > 0
                            ? (invoiceValues.subTotalPrice - invoiceValues.totalDiscount).toFixed(2)
                            : 0.0
                          : invoiceValues?.subTotalPrice
                          ? invoiceValues?.subTotalPrice.toFixed(2)
                          : 0}
                      </strong>
                    </li>
                    <li>
                      <span>{i18n("INVOICES.Tax")}</span>
                      <span>
                        {companyCurrency} {invoiceValues?.tax ? invoiceValues?.tax.toFixed(2) : 0.0}
                      </span>
                    </li>
                  </ul>
                </Card>
                {payments?.length
                  ? payments.map((item, index) => {
                      return (
                        <Card key={index}>
                          <div className="invoice-title h5 mb-0" style={{ borderBottom: "none" }}>
                            <b>Payment {index + 1}</b>
                            <b>
                              {companyCurrency} {item?.amount ? item?.amount : 0.0}
                            </b>
                          </div>
                          <div className="invoice-title text-muted mb-0 h5" style={{ borderBottom: "none" }}>
                            <span>{item?.method}</span>
                            <span>{moment(item?.date).format("d MMM YYYY")}</span>
                          </div>
                          {item?.note ? (
                            <div>
                              <span>{item?.note}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </Card>
                      );
                    })
                  : ""}

                <Card className="invoice-total">
                  <div className="invoice-title h5">
                    <b>{i18n("INVOICES.Paid")}</b>
                    <b>
                      {companyCurrency} {invoiceValues.totalPaid ? invoiceValues?.totalPaid.toFixed(2) : 0.0}
                    </b>
                  </div>
                  <ul>
                    <li>
                      <span>{i18n("INVOICES.BalanceDue")}</span>
                      <strong>
                        {companyCurrency} {invoiceValues.balance ? invoiceValues?.balance.toFixed(2) : 0.0}
                      </strong>
                    </li>
                  </ul>
                </Card>
              </Modal.Body>

              <Modal.Footer>
                <div>
                  <button type="button" className="btn btn-primary" onClick={() => setShareInvoiceDialog(true)}>
                    Share
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-elevate ml-5"
                    onClick={() => window.open(`/view/invoiceHtml/${invoiceId}`, "_blank")}>
                    Print
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-outline-primary btn-elevate ml-5"
                    onClick={() => {
                      onHide();
                      closePaymentModal();
                      formResetHandler();
                      setOpenInvoiceDialog();
                    }}
                  >
                    Close
                  </button> */}
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      <SendPdfInvoiceDialog
        id={invoiceId}
        sendPdfInvoiceDialog={shareInvoiceDialog}
        secondaryAction={() => setShareInvoiceDialog(false)}
        setSendPdfInvoiceDialog={() => setShareInvoiceDialog(false)}
      />
    </>
  );
}
