import React from "react";
import logo from "./../../../../assets/img/forgot-password-next.svg";
import { NavLink } from "react-router-dom";
import { i18n } from "../../../pages/private/languageSelector";

const ForgotPasswordNext = () => {
  return (
    <div className="forget-password-next">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 image">
            <p>{i18n("ForgotPasswordNext.PasswordReset")}</p>
            <img src={logo} alt="no data to show" />
          </div>
          <div className="col-lg-6 text">
            <h6 className="text-center">
              {i18n(
                "ForgotPasswordNext.YourRequestHaveBeenSuccessfullyRecievedPleaseCheckYourEmailToResetYourPassword"
              )}
            </h6>

            <NavLink className="menu-link" to="/logout">
              <p
                style={{
                  marginTop: "16%",
                  marginLeft: "50%",
                  color: "blue",
                }}>
                <button className={`btn btn-primary font-weight-bold px-9 py-4 my-3 f-button`}>
                  <span>{i18n("ForgotPasswordNext.Back")}</span>
                </button>
              </p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordNext;
