import React from "react";
import { Switch } from "react-router-dom";
import CompanyView from "./company-edit-Dialog/companyView";

function CompanyPage() {
  return (
    <>
      <Switch>
        <CompanyView />
      </Switch>
    </>
  );
}
export default CompanyPage;
