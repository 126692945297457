import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { Field, Form, Formik } from "formik";
import { i18n } from "../../private/languageSelector";
import { DatePickerField, ReactSelector } from "../../../../_metronic/_partials/controls";
import IconClose from "../../../../assets/img/icon-close.svg";
import FilterIcon from "../../../../assets/img/Icon-filter.png";
import * as usersActions from "../../../../_redux/users/usersActions";
import * as productActions from "../../../../_redux/products/productsActions";
import * as invoiceActions from "../../../../_redux/invoices/invoicesActions";
import * as storesActions from "../../../../_redux/stores/storeActions";

const FilterDialogeNew = ({
  title,
  classes,
  size = "sm",
  show = false,
  setFilterActive,
  secondaryAction = () => {},
  primaryActionTitle = "yes",
}) => {
  const dispatch = useDispatch();

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext.queryParams,
    };
  }, [invoicesUIContext]);

  const { users, filter, stores, products, invoiceFilterState, productFilterState } = useSelector(
    (state) => ({
      users: state?.users?.entities,
      filter: state?.invoices?.filterOn,
      products: state?.products?.entities,
      stores: state?.stores?.entities,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
      productFilterState: state?.products?.productFilterState,
    }),
    shallowEqual
  );

  const [state, setState] = useState({
    type: "",
    status: "",
    channel: "",
    store: "",
    product: "",
    user: "",
    startDate: "",
    endDate: "",
    typeName: "",
    statusName: "",
    channelName: "",
    storeName: "",
    productName: "",
    userName: "",
    startDateName: "",
    endDateName: "",
  });

  const taxType = [
    { label: "Sale ", value: "sale" },
    { label: "Refund", value: "refund" },
  ];
  const statusType = [
    { name: "Due", value: "due" },
    { name: "Paid", value: "paid" },
    { name: "Void", value: "void" },
  ];

  const invoiceChannel = [
    { name: "Web", value: "web" },
    { name: "Pos", value: "pos" },
  ];

  useEffect(() => {
    dispatch(storesActions.fetchStores());
    dispatch(usersActions.fetchUsers());
    dispatch(productActions.fetchProducts(productFilterState, invoicesUIContext.queryParams));
    // eslint-disable-next-line
  }, [dispatch]);

  const handleFilter = () => {
    dispatch(invoiceActions.filterInvoice(state, invoicesUIProps.queryParams));
    dispatch(invoiceActions.setInvoiceFilterState(state));
    dispatch(invoiceActions.setInvoiceFilterOnState(true));
  };
  const clearFilterhandle = () => {
    dispatch(invoiceActions.clearInvoiceFilterState());
    dispatch(
      invoiceActions.fetchInvoices(
        {
          type: "",
          status: "",
          channel: "",
          store: "",
          product: "",
          user: "",
          startDate: "",
          endDate: "",
        },
        invoicesUIProps.queryParams
      )
    );
    dispatch(invoiceActions.setInvoiceFilterOnState(false));
    setState({
      type: "",
      status: "",
      channel: "",
      store: "",
      product: "",
      user: "",
      startDate: "",
      endDate: "",
      typeName: "",
      statusName: "",
      channelName: "",
      storeName: "",
      productName: "",
      userName: "",
      startDateName: "",
      endDateName: "",
    });
    secondaryAction(false);
  };
  useEffect(() => {
    setFilterActive(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <>
      <Modal
        size={size}
        show={show}
        backdrop={true}
        onHide={() => {}}
        className={`f-confirmation-modal ${classes}`}
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <button type="button" className="btn btn-close" onClick={() => secondaryAction(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <br />
          <br />
          <Formik enableReinitialize={true} initialValues={state}>
            {({ setFieldValue }) => (
              <>
                <Form>
                  <div className="mb-5" onClick={clearFilterhandle}>
                    <img src={FilterIcon} alt="loading" style={{ cursor: "pointer", height: "25px" }} />
                    <span
                      style={{
                        color: "#2D9CDB",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}>
                      {i18n("MENU.ClearAllFilters")}
                    </span>
                  </div>
                  <div>
                    <DatePickerField
                      name="startDate"
                      label="Start Date"
                      placeholderText={"Start Date"}
                      selected={state.startDate}
                      onChange={(val) => {
                        setFieldValue("startDate", val);
                        setState({
                          ...state,
                          startDate: val,
                        });
                      }}
                    />
                    <DatePickerField
                      name="endDate"
                      label="End Date"
                      placeholderText={"End Date"}
                      selected={state.endDate}
                      onChange={(val) => {
                        setFieldValue("endDate", val);
                        setState({
                          ...state,
                          endDate: val,
                        });
                      }}
                    />
                  </div>

                  <div className="form-group input-select w-100">
                    <Field
                      name="type"
                      placeholder={i18n("global.Type")}
                      component={ReactSelector}
                      value={{
                        name: state?.typeName ? state?.typeName : i18n("global.Type"),
                        label: state?.typeName ? state?.typeName : i18n("global.Type"),
                      }}
                      options={
                        taxType
                          ? [].concat(
                              ...taxType.map((item) => {
                                return {
                                  value: item?.value ? item?.value : "",
                                  label: `${item?.label ? item?.label : ""}`,
                                };
                              })
                            )
                          : undefined
                      }
                      onChange={(option) => {
                        setState({
                          ...state,
                          type: option.value,
                          typeName: option.label,
                        });
                      }}
                    />
                  </div>

                  <div className="form-group input-select w-100">
                    <Field
                      name="channel"
                      placeholder={i18n("global.Channel")}
                      component={ReactSelector}
                      value={{
                        name: state?.channelName ? state?.channelName : i18n("global.Channel"),
                        label: state?.channelName ? state?.channelName : i18n("global.Channel"),
                      }}
                      options={
                        invoiceChannel
                          ? [].concat(
                              ...invoiceChannel.map((item) => {
                                return {
                                  value: item?.value ? item?.value : "",
                                  label: `${item?.name ? item?.name : ""}`,
                                };
                              })
                            )
                          : undefined
                      }
                      onChange={(option) =>
                        setState({
                          ...state,
                          channel: option.value,
                          channelName: option.label,
                        })
                      }
                    />
                  </div>

                  <div className="form-group input-select w-100">
                    <Field
                      name="status"
                      placeholder={i18n("global.Status")}
                      component={ReactSelector}
                      value={{
                        name: state?.statusName ? state?.statusName : i18n("global.Status"),
                        label: state?.statusName ? state?.statusName : i18n("global.Status"),
                      }}
                      options={
                        statusType
                          ? [].concat(
                              ...statusType.map((item) => {
                                return {
                                  value: item?.value ? item?.value : "",
                                  label: `${item?.name ? item?.name : ""}`,
                                };
                              })
                            )
                          : undefined
                      }
                      onChange={(option) =>
                        setState({
                          ...state,
                          status: option.value,
                          statusName: option.label,
                        })
                      }
                    />
                  </div>
                  <div className="form-group input-select w-100">
                    <Field
                      name="store"
                      placeholder={i18n("global.Store")}
                      component={ReactSelector}
                      value={{
                        name: state?.storeName ? state?.storeName : i18n("global.Store"),
                        label: state?.storeName ? state?.storeName : i18n("global.Store"),
                      }}
                      options={
                        stores
                          ? [].concat(
                              ...stores.map((item) => {
                                return {
                                  value: item?._id ? item?._id : "",
                                  label: `${item?.name ? item?.name : ""}`,
                                };
                              })
                            )
                          : undefined
                      }
                      onChange={(option) =>
                        setState({
                          ...state,
                          store: option.value,
                          storeName: option.label,
                        })
                      }
                    />
                  </div>
                  <div className="form-group input-select w-100">
                    <Field
                      name="user"
                      placeholder={i18n("global.User")}
                      component={ReactSelector}
                      value={{
                        name: state?.userName ? state?.userName : i18n("global.User"),
                        label: state?.userName ? state?.userName : i18n("global.User"),
                      }}
                      options={
                        users?.length
                          ? [].concat(
                              ...users?.map((item) => {
                                return {
                                  value: item?._id ? item?._id : "",
                                  label: `${item?.name?.first ? item?.name?.first + " " + item?.name?.last : ""}`,
                                };
                              })
                            )
                          : undefined
                      }
                      onChange={(option) =>
                        setState({
                          ...state,
                          user: option.value,
                          userName: option.label,
                        })
                      }
                    />
                  </div>
                  <div className="form-group input-select w-100">
                    <Field
                      name="product"
                      placeholder={i18n("global.Product")}
                      component={ReactSelector}
                      value={{
                        name: state?.productName ? state?.productName : i18n("global.Product"),
                        label: state?.productName ? state?.productName : i18n("global.Product"),
                      }}
                      options={
                        products
                          ? [].concat(
                              ...products.map((item) => {
                                return {
                                  value: item?._id ? item?._id : "",
                                  label: `${item?.name ? item?.name : ""}`,
                                };
                              })
                            )
                          : undefined
                      }
                      onChange={(option) =>
                        setState({
                          ...state,
                          product: option.value,
                          productName: option.label,
                        })
                      }
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-primary btn-elevate mt-5"
              onClick={() => {
                handleFilter();
                secondaryAction(false);
              }}>
              {primaryActionTitle}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FilterDialogeNew;
