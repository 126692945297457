import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import IconClose from "../../../../assets/img/icon-close.svg";
import IconDiscount from "../../../../assets/img/discount.svg";
import IconTrash from "../../../../assets/img/icon-trash.svg";
import IconPen from "../../../../assets/img/icon-pen.svg";
import * as actions from "../../../../_redux/discount/discountActions";
import { DiscountEditForm } from "./DiscountEditForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ConfirmationDialoge from "../../confirmationDialoge";
import { i18n } from "../../private/languageSelector";

const initAddState = {
  name: "",
  percentage: "",
};

export const ManageDiscounts = ({ discounts, userAccess, openManageDiscountDialog, setOpenManageDiscountDialog }) => {
  const dispatch = useDispatch();

  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);
  const [edit, setEdit] = useState(false);
  const [searchDiscount, setSearchDiscount] = useState("");
  const [discountDeleteId, setDiscountDeleteId] = useState("");
  const [initDiscountState, setInitDiscountState] = useState(initAddState);

  const { discountForEdit } = useSelector(
    (state) => ({
      discountForEdit: state.discounts?.discountForEdit,
    }),
    shallowEqual
  );

  const deleteDiscountHandler = () => {
    dispatch(actions.deleteDiscount(discountDeleteId));
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openManageDiscountDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenManageDiscountDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("Discount.ManageDiscounts")}</Modal.Title>
        </Modal.Header>
        <Formik enableReinitialize={true} initialValues={initDiscountState} onSubmit={(values) => {}}>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="row mt-5">
                    <div className="col-lg-12 ">
                      <Field
                        name="discount"
                        component={Input}
                        placeholder={i18n("Discount.SearchDiscounts")}
                        withFeedbackLabel={false}
                        onChange={(e) => {
                          setSearchDiscount(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <ul className="card-listitem list-unstyled">
                    {discounts
                      ?.filter((discount) => discount?.name?.toLowerCase().includes(searchDiscount?.toLowerCase()))
                      ?.map((discount, i) => {
                        return (
                          <li key={i}>
                            <span className="card-icon discount">
                              <img src={IconDiscount} alt="loading" />
                            </span>
                            <div className="card-info">
                              <h5 className="truncate-160">{discount.name}</h5>
                              <span>
                                {discount.value > 0 ? discount.value : ""} {discount?.type}
                              </span>
                            </div>
                            <div className="card-actions ml-auto">
                              {userAccess?.editData ? (
                                <span className="action action-edit">
                                  <img
                                    title="Edit"
                                    src={IconPen}
                                    alt="loading"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      dispatch(actions.fetchDiscount(discount._id));
                                      setOpenDiscountDialog(true);
                                      setEdit(true);
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                              {userAccess?.deleteData ? (
                                <span className="action action-delete">
                                  <img
                                    title="Delete"
                                    style={{ cursor: "pointer" }}
                                    src={IconTrash}
                                    alt="loading"
                                    onClick={() => {
                                      setDiscountDeleteId(discount._id);
                                      setConfirmDialogForClose(true);
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <>
          <Modal.Footer>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary btn-elevate mr-5"
                onClick={() => {
                  setInitDiscountState(initAddState);
                  setOpenManageDiscountDialog(false);
                }}>
                {i18n("Discount.Back")}
              </button>
              {userAccess?.createData ? (
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenDiscountDialog(true);
                    setInitDiscountState(initAddState);
                    setEdit(false);
                  }}>
                  {i18n("Discount.AddNew")}
                </button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal>
      <DiscountEditForm
        setOpenDiscountDialog={setOpenDiscountDialog}
        openDiscountDialog={openDiscountDialog}
        edit={edit}
        discountForEdit={discountForEdit}
      />
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title={i18n("Discount.DeleteDiscount")}
        subTitle={i18n("Discount.AreYouSureYouWantToDeleteThisDiscount")}
        primaryAction={() => deleteDiscountHandler()}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle={i18n("Discount.Delete")}
        secondaryActionTitle={i18n("Discount.Back")}
        classes="modal-drawer"
        heading={i18n("Discount.DeleteDiscount")}
        headingColor="#ED553B"
        viewButon={true}
      />
    </>
  );
};
