import React from "react";
import { i18n } from "../../pages/private/languageSelector";
import { useSelector } from "react-redux";
const PageNavigator = (props) => {
  const { page, limit, handlePageChange, handleLimitChange, analyticsTableData, data } = props;
  const { language } = useSelector((state) => state.auth);

  return (
    <div className={`page-navigation ${language === "arabic" ? "rtl" : "ltr"}`}>
      <div className="main-container">
        <div className="btn-section">
          {language === "arabic" ? (
            <>
              <button className="btn-right" onClick={() => handlePageChange(page + 1)} disabled={data?.length < limit}>
                {">"}
              </button>
              <button className="btn-left" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                {"<"}
              </button>
            </>
          ) : (
            <>
              <button className="btn-left" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                {"<"}
              </button>
              <button className="btn-right" onClick={() => handlePageChange(page + 1)} disabled={data?.length < limit}>
                {">"}
              </button>
            </>
          )}
        </div>
        <span>{i18n("DASHBOARD.Page")}</span>
        <span className="page-number">{page}</span>
        <span>{i18n("DASHBOARD.RowsPerPage")}</span>
        <select className="limit-select" value={limit} onChange={(e) => handleLimitChange(Number(e.target.value))}>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
    </div>
  );
};

export default PageNavigator;
