import * as requestFromServer from "./posCrud";
import { posSlice, callTypes } from "./posSlice";
import toast from "react-hot-toast";

const { actions } = posSlice;

export const fetchAllPos = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllPos()
    .then((response) => {
      const { pointOfSales } = response.data;
      dispatch(
        actions.posListFetched({
          entities: pointOfSales,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find pos";
      toast.error(error?.response?.data?.message);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLatestStorePosCode = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getLatestPosSerial()
    .then((response) => {
      dispatch(
        actions.posLatestCodeFetched({
          generatedCode: response.data,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find pos";
      toast.error(error?.response?.data?.message);
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createPos = (posForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPos(posForCreation)
    .then((response) => {
      dispatch(fetchAllPos());
      toast.success("Pos Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create Pos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchPos = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.posFetched({ posForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPosById(id)
    .then((response) => {
      const { pointOfSale } = response.data;
      dispatch(actions.posFetched({ posForEdit: pointOfSale }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find Pos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePos = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deletePos(id).then((response) => {
    toast.success("Pos deleted");
    dispatch(fetchAllPos());
  });
};

export const updatePos = (id, pos) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePos(id, pos)
    .then(() => {
      dispatch(fetchAllPos());
      toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update Pos";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
