import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../assets/img/icon-close.svg";

const ConfirmationDialoge = ({
  title,
  classes,
  heading,
  subTitle,
  viewButon,
  buttonText,
  size = "sm",
  headingColor,
  show = false,
  secondaryActionTitle,
  primaryAction = () => {},
  secondaryAction = () => {},
  primaryActionTitle = "yes",
}) => {
  return (
    <>
      <Modal
        size={size}
        show={show}
        backdrop={true}
        onHide={secondaryAction}
        className={`f-confirmation-modal ${classes}`}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <button
            type="button"
            className="btn btn-close"
            onClick={secondaryAction}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default text-center">
          {heading && (
            <h3 style={{ color: `${headingColor}`, marginTop: "85%" }}>
              {heading}
            </h3>
          )}

          {subTitle ? subTitle : ""}
        </Modal.Body>

        <>
          <Modal.Footer>
            <button
              style={
                buttonText
                  ? {
                      backgroundColor: `${headingColor}`,
                      borderColor: `${headingColor}`,
                    }
                  : {}
              }
              type="button"
              onClick={secondaryAction}
              className={
                buttonText
                  ? "btn btn-primary btn-elevate"
                  : "btn btn-outline-primary btn-elevate mr-5"
              }
            >
              {secondaryActionTitle ? secondaryActionTitle : buttonText}
            </button>
            <> </>
            {viewButon && (
              <button
                style={
                  headingColor
                    ? {
                        backgroundColor: `${headingColor}`,
                        borderColor: `${headingColor}`,
                      }
                    : {}
                }
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  primaryAction();
                  secondaryAction();
                }}
                className="btn btn-primary btn-elevate"
              >
                {primaryActionTitle}
              </button>
            )}
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default ConfirmationDialoge;
