import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AddCategoryForm } from "./addCategory";
import * as actions from "../../../../_redux/category/categoryActions";
import ConfirmationDialoge from "../../confirmationDialoge";
import IconTrash from "../../../../assets/img/icon-trash.svg";
import IconPen from "../../../../assets/img/icon-pen.svg";
import { i18n } from "../../private/languageSelector";

const initAddState = {
  name: "",
  color: "",
  isActive: true,
  description: "",
};
export const ProductCategoryDialog = ({ userAccess, openCategoryDialog, setOpenCategoryDialog }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [searchCategory, setSearchCategory] = useState("");
  const [categoryDeleteId, setCategoryDeleteId] = useState("");
  const [openCategoryAddDialog, setOpenCategoryAddDialog] = useState(false);
  const [openCategoryDltDialog, setOpenCategoryDltDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);
  const [initAddCategoryState, setInitAddCategoryState] = useState(initAddState);

  const { categories, singleCategory, products } = useSelector(
    (state) => ({
      singleCategory: state?.category,
      products: state?.products?.entities,
      categories: state?.category?.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCategories());
  }, [dispatch]);

  const deleteCategoryHandler = (test) => {
    if (test && categoryDeleteId) {
      dispatch(actions.deleteCategory(categoryDeleteId))
        .then((res) => {
          setConfirmDialogForClose(false);
          dispatch(actions.fetchCategories());
        })
        .catch((error) => {
          error.clientMessage = "Can't delete category";
          setOpenCategoryDltDialog(true);
        });
    }
  };

  let itemsAssignedToCategory = categories?.map((item) => {
    return products?.filter((product) => product?.categoryData?._id === item?._id).length;
  });

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openCategoryDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenCategoryDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("Category.ManageCategories")}</Modal.Title>
        </Modal.Header>
        <Formik enableReinitialize={true} initialValues={initAddCategoryState} onSubmit={(values) => {}}>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="row mt-5">
                    <div className="col-lg-12 ">
                      <Field
                        name="category"
                        component={Input}
                        placeholder={i18n("Category.SearchCategory")}
                        withFeedbackLabel={false}
                        onChange={(e) => {
                          setSearchCategory(e.target.value);
                        }}
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <ul className="card-listitem list-unstyled">
                    {categories
                      ?.filter((category) => category?.name?.toLowerCase().includes(searchCategory?.toLowerCase()))
                      ?.map((category, index) => {
                        return (
                          <li key={index}>
                            <span
                              className="card-icon category"
                              style={{
                                backgroundColor: category.color,
                              }}
                            />
                            <div className="card-info w-50">
                              <h5 className="truncate-180">{category.name}</h5>
                              <small className="d-block w-100">
                                {itemsAssignedToCategory?.length ? itemsAssignedToCategory[index] : 0}{" "}
                                {i18n("MENU.Items")}
                              </small>
                            </div>
                            <div className="card-actions ml-auto">
                              {userAccess?.editData ? (
                                <span className="action action-edit">
                                  <img
                                    title="Edit"
                                    src={IconPen}
                                    alt="loading"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      dispatch(actions.fetchCategory(category._id));
                                      setEdit(true);
                                      setOpenCategoryAddDialog(true);
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                              {userAccess?.deleteData ? (
                                <span className="action action-delete">
                                  <img
                                    title="Delete"
                                    style={{ cursor: "pointer" }}
                                    src={IconTrash}
                                    alt="loading"
                                    onClick={() => {
                                      setCategoryDeleteId(category._id);
                                      setConfirmDialogForClose(true);
                                    }}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <Modal.Footer>
          <div className="row justify-content-center">
            <button
              className="btn btn-outline-primary btn-elevate mr-5"
              onClick={() => {
                setInitAddCategoryState(initAddState);
                setOpenCategoryDialog(false);
              }}>
              {i18n("Category.Back")}
            </button>
            {userAccess?.createData ? (
              <button
                className="btn btn-primary btn-elevate f-button"
                onClick={() => {
                  setEdit(false);
                  setInitAddCategoryState(initAddState);
                  setOpenCategoryAddDialog(true);
                }}>
                {i18n("Category.AddNew")}
              </button>
            ) : (
              ""
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <AddCategoryForm
        edit={edit}
        category={singleCategory}
        initAddCategoryState={initAddCategoryState}
        openCategoryAddDialog={openCategoryAddDialog}
        setOpenCategoryAddDialog={setOpenCategoryAddDialog}
      />
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title={i18n("Category.DeleteCategory")}
        subTitle={i18n("Category.AreYouSureYouWantToDeleteThisCategory")}
        warning={i18n("Category.ThereAreProductsLinkedToThisCategory")}
        primaryAction={() => deleteCategoryHandler(true)}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle={i18n("Category.Yes")}
        secondaryActionTitle={i18n("Category.No")}
        classes="modal-drawer"
        heading={i18n("Category.DeleteCategory")}
        headingColor="#ED553B"
        viewButon={true}
      />
      <ConfirmationDialoge
        show={openCategoryDltDialog}
        secondaryAction={() => {
          setOpenCategoryDltDialog(false);
          setConfirmDialogForClose(false);
        }}
        title="Delete Category!"
        subTitle="There are products linked to this catagory! Kindly serach for theose products and change its catagory before you will be able to delete this Catagory."
        classes="modal-drawer"
        heading="Can't Delete Category!"
        headingColor="#ED553B"
        viewButon={false}
        buttonText={"ok"}
      />
    </>
  );
};
