import { createSlice } from "@reduxjs/toolkit";

const initialRolesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  roleForEdit: undefined,
  error: null,
  navigate: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState: initialRolesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    roleFetched: (state, action) => {
      state.actionsLoading = false;
      state.roleForEdit = {
        ...action.payload.roleForEdit,
      };
      state.error = null;
    },
    rolesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    roleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.navigate = true;
    },
    roleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
    },

    roleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
