import React from "react";
import { Form } from "react-bootstrap";
export function SwitchToggler({ name, className, label = "", ...props }) {
  // console.log(field.name, 'name')
  // console.log(errors, 'error')
  // console.log(touched, 'touched')
  return (
    <>
      <Form.Check type="switch" id={name} name={name} label={label} className={className} {...props} />
    </>
  );
}
