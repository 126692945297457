// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextArea } from "../../../../_metronic/_partials/controls";
import "react-phone-input-2/lib/style.css";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../private/languageSelector";

// Validation schema
const CompanyEditSchema = Yup.object().shape({});

function CompanyMessageDialog({ saveMessage, companyForEdit, secondaryAction, openCompanyMessageDialog }) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={companyForEdit}
        validationSchema={CompanyEditSchema}
        onSubmit={(values) => {
          saveMessage({
            _id: values._id,
            defaultSms: {
              messageEnglish: values.defaultSms.messageEnglish,
              messageArabic: values.defaultSms.messageArabic,
            },
            defaultEmail: {
              messageEnglish: values.defaultEmail.messageEnglish,
              messageArabic: values.defaultEmail.messageArabic,
            },
          });
        }}>
        {({ handleSubmit, values, setValues }) => (
          <>
            <Modal
              size="lg"
              className="modal-drawer"
              show={openCompanyMessageDialog}
              onHide={() => {}}
              aria-labelledby="example-modal-sizes-title-lg">
              <Modal.Header>
                <button type="button" className="btn btn-close" onClick={() => secondaryAction()}>
                  <img src={IconClose} alt="loading" />
                </button>
                <Modal.Title>{i18n("Company.DefaultEmail/SMSMessage")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right mt-10">
                  <div>
                    <Field
                      name="defaultSms.messageEnglish"
                      component={TextArea}
                      label={i18n("Company.EnterDefaultSMSMessageEnglish")}
                      withFeedbackLabel={false}
                      companyLabel={true}
                    />
                  </div>
                  <div>
                    <Field
                      name="defaultSms.messageArabic"
                      component={TextArea}
                      label={i18n("Company.EnterDefaultSMSMessageArabic")}
                      withFeedbackLabel={false}
                      companyLabel={true}
                    />
                  </div>
                  <hr />
                  <div className="mt-10">
                    <Field
                      name="defaultEmail.messageEnglish"
                      component={TextArea}
                      label={i18n("Company.EnterDefaultEmailMessageEnglish")}
                      withFeedbackLabel={false}
                      companyLabel={true}
                    />
                  </div>
                  <div>
                    <Field
                      name="defaultEmail.messageArabic"
                      component={TextArea}
                      label={i18n("Company.EnterDefaultEmailMessageArabic")}
                      withFeedbackLabel={false}
                      companyLabel={true}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" className="btn btn-light btn-elevate" onClick={secondaryAction}>
                  {i18n("Company.Cancel")}
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-primary btn-elevate">
                  {i18n("Company.Save")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
export default CompanyMessageDialog;
