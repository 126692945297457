import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/products/productsActions";
import { useProductsUIContext } from "../ProductsUIContext";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../../pages/private/languageSelector";

export function ProductDeleteDialog({ id, show, onHide }) {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  // Products Redux state
  const dispatch = useDispatch();
  const { isLoading, productFilterState } = useSelector(
    (state) => ({
      isLoading: state.products.actionsLoading,
      productFilterState: state?.products?.productFilterState,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteProduct = () => {
    // server request for deleting product by id
    dispatch(actions.deleteProduct(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchProducts(productFilterState, productsUIProps.queryParams));
      // clear selections list
      productsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal className="modal-drawer" show={show} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <button type="button" className="btn btn-close" onClick={() => onHide()}>
          <img src={IconClose} alt="loading" />
        </button>

        <Modal.Title id="example-modal-sizes-title-lg">{i18n("MENU.ProductDelete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-center">
                <h6 style={{ color: "red" }}>{i18n("MENU.DeleteProduct")}</h6>
                <span>{i18n("MENU.AreYouSureToPermanentlyDeleteThisProduct")}</span>
              </div>
            </div>
          </>
        )}
        {isLoading && <span>{i18n("MENU.ProductIsDeleting")}...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-outline-primary btn-elevate">
            {i18n("LandingPage.Back")}
          </button>
          <> </>
          <button type="button" onClick={deleteProduct} className="btn btn-danger btn-elevate ml-5">
            {i18n("MENU.Delete")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
