import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { UserEditForm } from "./UsersEditForm";
import { useDispatch } from "react-redux";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconTrash from "../../../../../assets/img/icon-trash.svg";
import IconBin from "../../../../../assets/img/bin.svg";
import IconPen from "../../../../../assets/img/icon-pen.svg";
import UserAvatar from "../../../../../assets/img/user-avatar-default.jpg";
import ConfirmationDialoge from "../../../confirmationDialoge";
import * as usersActions from "../../../../../_redux/users/usersActions";
import toast from "react-hot-toast";
import { getUserByToken } from "../../../../modules/Auth/_redux/authCrud";
import { SetupOwnerPosPin } from "./SetupOwnerPosPin";
import { i18n } from "../../../private/languageSelector";

export const ManageUsers = ({
  user,
  users,
  userAccess,
  userForEdit,
  defaultPosPin,
  setUserForEdit,
  openUsersManageDialog,
  setOpenUsersManageDialog,
}) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [posPin, setPosPin] = useState("");
  const [searchText, setSearchText] = useState("");
  const [userPosPin, setUserPosPin] = useState("");
  const [deleteUserId, setDeleteUserId] = useState("");
  const [userPosPinModal, setUserPosPinModal] = useState(false);
  const [openUsersAddDialog, setOpenUsersAddDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);

  useEffect(() => {
    getUserByToken().then((res) => {
      setUserPosPin(res?.data?.posPin);
    });
  }, [openUsersManageDialog, openUsersAddDialog]);

  const deleteUserHandler = () => {
    dispatch(usersActions.deleteUser(deleteUserId))
      .then((res) => {
        if (res) {
          toast.success("User Deleted");
          dispatch(usersActions.fetchUsers());
        }
      })
      .catch((err) => {
        toast.error("Cannot delete User");
      });
  };

  const userEditHandler = (userData) => {
    if (
      userData?.rolePayload?.originalName === "owner" &&
      user?.rolePayload?.originalName !== "owner"
    ) {
      toast.error("Cannot modify Owner");
    } else {
      setEdit(true);
      setUserForEdit(userData);
      setPosPin(userData?.posPin);
      setOpenUsersAddDialog(true);
    }
  };

  const userCreateHandler = () => {
    if (userPosPin) {
      setEdit(false);
      setPosPin(defaultPosPin);
      setUserForEdit(null);
      setOpenUsersAddDialog(true);
    } else {
      toast.error(i18n("SubscriptionModals.PleaseSetupYourOwnPosPinFirst"));
      setUserPosPinModal(true);
      setPosPin(defaultPosPin);
    }
  };

  const userDeleteHandler = (index, id) => {
    if (index === 0) {
      toast.error("Cannot delete Owner");
    } else {
      setDeleteUserId(id);
      setConfirmDialogForClose(true);
    }
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openUsersManageDialog}
        onHide={() => {}}
      >
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => setOpenUsersManageDialog(false)}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Manage Users</Modal.Title>
        </Modal.Header>
        <Formik>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="input-group">
                    <input
                      className="form-control mr-2"
                      placeholder="Search by users, email, mobile"
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <table className="table table-user">
                    <tbody>
                      {users?.length
                        ? users
                            .filter((item) => {
                              return (
                                item.name?.first
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase()) ||
                                item.name?.last
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase()) ||
                                item.email
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase()) ||
                                item.mobile
                                  ?.toLowerCase()
                                  ?.includes(searchText?.toLowerCase())
                              );
                            })
                            .map((item, index) => {
                              return (
                                <tr key={index} className="company-users-list">
                                  <td>
                                    <div className="col-avatar mb-5">
                                      <div className="avatar-wrap">
                                        <img
                                          src={
                                            item?.profileImage
                                              ? item.profileImage
                                              : UserAvatar
                                          }
                                          alt="loading"
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td className="col-user-detail">
                                    <b className="h6 heading-color truncate-160 mb-0">
                                      {item?.name?.first + " " + item?.name?.last}
                                    </b>
                                    <small className="default-payment truncate-160">
                                      {item?.email}
                                    </small>
                                    <small className="default-payment">
                                      {item?.rolePayload?.name}
                                    </small>
                                    <small className="default-payment">
                                      {item?.mobile}
                                    </small>
                                  </td>
                                  <td className="col-status">
                                    <div className="card-actions ml-auto d-flex justify-content-end">
                                      {userAccess?.editData ? (
                                        <span className="action action-edit ml-3">
                                          <img
                                            title="Edit"
                                            src={IconPen}
                                            alt="loading"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => userEditHandler(item)}
                                          />
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {userAccess?.deleteData ? (
                                        item?.email === user?.email ||
                                        item?.rolePayload?.originalName ===
                                          "owner" ? (
                                          <span className="action action-delete ml-5">
                                            <img
                                              title="Delete"
                                              src={IconBin}
                                              alt="loading"
                                            />
                                          </span>
                                        ) : (
                                          <span className="action action-delete ml-5">
                                            <img
                                              title="Delete"
                                              style={{ cursor: "pointer" }}
                                              src={IconTrash}
                                              alt="loading"
                                              onClick={() =>
                                                userDeleteHandler(index, item._id)
                                              }
                                            />
                                          </span>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                        : ""}
                    </tbody>
                  </table>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <>
          <Modal.Footer>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary btn-elevate mr-5"
                onClick={() => {
                  setOpenUsersManageDialog(false);
                }}
              >
                Back
              </button>
              {userAccess?.createData ? (
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => userCreateHandler()}
                >
                  Add New
                </button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal>
      <UserEditForm
        edit={edit}
        userForEdit={userForEdit}
        defaultPosPin={posPin}
        openUsersAddDialog={openUsersAddDialog}
        setOpenUsersAddDialog={setOpenUsersAddDialog}
      />
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title="Delete User"
        subTitle="Are you sure you want to delete this user"
        primaryAction={() => deleteUserHandler()}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle="Delete"
        secondaryActionTitle="Cancel"
        classes="modal-drawer"
        heading="Delete User!"
        headingColor="#ED553B"
        viewButon={true}
      />
      <SetupOwnerPosPin
        userPosPin={userPosPin}
        userPosPinModal={userPosPinModal}
        setUserPosPinModal={setUserPosPinModal}
        setOpenUsersAddDialog={setOpenUsersAddDialog}
        setOpenUsersManageDialog={setOpenUsersManageDialog}
      />
    </>
  );
};
