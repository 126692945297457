import axios from "axios";

export const CUSTOMERS_URL = "api/customer";

// CREATE =>  POST: add a new customer to the server
export function createCustomer(customer) {
  const customers = {
    name: {
      first: customer.firstName,
      last: customer.lastName,
    },
    mobile: customer?.mobile?.length > 7 ? customer?.mobile : "",
    email: customer.email ? customer.email : undefined,
    country: customer.country,
    zip: customer.zip,
    address: customer.address,
    city: customer.city,
    state: customer.state,
    province: customer.state,
    uploadImage: customer.photo,
    taxNumber: customer.taxNumber,
    businessName: customer.businessName,
  };
  return axios.post(`${CUSTOMERS_URL}`, customers);
}

// READ
export function getAllCustomers() {
  return axios.get(`${CUSTOMERS_URL}`);
}

export function getCustomerById(customerId) {
  return axios.get(`${CUSTOMERS_URL}/${customerId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCustomers(queryParams) {
  return axios.get(`${CUSTOMERS_URL}`);
}

// UPDATE => PUT: update the customer on the server
export function updateCustomer(customer) {
  const customers = {
    name: {
      first: customer.firstName,
      last: customer.lastName,
    },
    mobile: customer?.mobile?.length > 7 ? customer?.mobile : "",
    email: customer.email ? customer.email : "",
    country: customer.country,
    zip: customer.zip,
    address: customer.address,
    city: customer.city,
    state: customer.state,
    province: customer.state,
    uploadImage: customer.photo,
    taxNumber: customer.taxNumber,
    businessName: customer.businessName,
  };

  return axios.patch(`${CUSTOMERS_URL}/${customer._id}`, customers);
}

// UPDATE Status
export function updateStatusForCustomers(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForCustomers`, {
    ids,
    status,
  });
}

// DELETE => delete the customer from the server
export function deleteCustomer(customerId) {
  return axios.delete(`${CUSTOMERS_URL}/${customerId}`);
}

// DELETE Customers by ids
export function deleteCustomers(ids) {
  let toDelete = "?ids=";
  ids.forEach((item) => {
    toDelete = toDelete + item + ",";
  });
  var tmp = toDelete.split(""); // convert to an array
  tmp.splice(toDelete.length - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
  tmp = tmp.join("");
  return axios.delete(`${CUSTOMERS_URL}${tmp}`);
}
