import axios from "axios";

export const Roles_URL = "api/roles";

export function getRoleById(roleId) {
  return axios.get(`${Roles_URL}/${roleId}`);
}

export function getAllRoles() {
  return axios.get(`${Roles_URL}`);
}

export function createRole(role) {
  return axios.post(`${Roles_URL}`, role);
}

export function updateRole(id, role) {
  return axios.patch(`${Roles_URL}/${id}`, role);
}

export function deleteRole(roleId) {
  return axios.delete(`${Roles_URL}/${roleId}`);
}
