import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Card,
  ReactSelector,
} from "../../../../../_metronic/_partials/controls";
import IconClose from "../../../../../assets/img/icon-close.svg";
import * as posActions from "../../../../../_redux/pos/posActions";
import * as storesActions from "../../../../../_redux/stores/storeActions";
import SubscriptionDialog from "../SubscriptionDialog";
import { i18n } from "../../../private/languageSelector";

// Validation schema
export const posSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(20, "Maximum 20 Alphabets")
    .required("Name is required"),
  store: Yup.string().required("Store is required"),
  identityPrefix: Yup.string().required("POS Code is required"),
});

const initAddState = {
  name: "",
  store: "",
  identityPrefix: "",
};

export function PosEditForm({
  pos,
  edit,
  openPosAddDialog,
  setOpenPosAddDialog,
}) {
  const dispatch = useDispatch();

  const { stores, posCode } = useSelector((state) => ({
    stores: state.stores.entities,
    posCode: state.pos.generatedCode,
  }));

  const [isFormFilled, setIsFormFilled] = useState(false);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);

  useEffect(() => {
    dispatch(storesActions.fetchStores());
    dispatch(posActions.fetchLatestStorePosCode());
  }, [dispatch, openPosAddDialog]);

  let generatedPosCode = posCode?.posIdentityPrefix
    ? parseInt(posCode?.posIdentityPrefix) + 1
    : 1;

  const handleClosePosDialog = (dirty, resetForm) => {
    if (dirty) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenPosAddDialog(false);
      resetForm();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={
        edit
          ? {
              name: pos?.name || pos?.posId,
              store: {
                value: pos?.store?._id || "No Store Selected",
                label:
                  pos?.store?.name ||
                  pos?.store?.storeId ||
                  "No Store Selected",
              },
              identityPrefix: pos?.identityPrefix,
            }
          : { ...initAddState, identityPrefix: generatedPosCode }
      }
      validationSchema={posSchema}
      onSubmit={(values, { resetForm }) => {
        if (edit) {
          dispatch(
            posActions.updatePos(pos._id, {
              name: values.name,
              isActive: pos?.isActive,
              storeId: values.store.value,
              identityPrefix: values.identityPrefix,
            })
          );
        } else {
          dispatch(
            posActions.createPos({
              isActive: false,
              name: values.name,
              storeId: values.store.value,
              identityPrefix: values.identityPrefix?.toString(),
            })
          );
        }
        resetForm();
        setOpenPosAddDialog(false);
      }}
    >
      {({ handleSubmit, setFieldValue, dirty, resetForm, values }) => {
        if (values.name && values.store.value) {
          setIsFormFilled(true);
        }

        return (
          <>
            <Modal
              className="modal-drawer invoice-drawer"
              size="lg"
              aria-labelledby="example-modal-sizes-title-lg"
              show={openPosAddDialog}
              onHide={() => handleClosePosDialog(dirty, resetForm)}
            >
              <Modal.Header>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <span
                    onClick={() => handleClosePosDialog(dirty, resetForm)}
                    className="cursor-pointer"
                  >
                    <img src={IconClose} alt="loading" />
                  </span>
                  <span className="add-edit-tax">
                    {edit === false ? "Add POS" : "Edit POS"}
                  </span>
                  <span
                    onClick={handleSubmit}
                    className="add-edit-tax cursor-pointer"
                    style={{
                      color: isFormFilled && "#FFFFFF",
                      opacity: isFormFilled ? 1 : 0.5,
                    }}
                  >
                    Save
                  </span>
                </div>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <Card>
                    <div className="invoice-items row">
                      <div className="col-12">
                        <div className="form-group">
                          <Field
                            name="name"
                            label="POS Name"
                            component={Input}
                            placeholder="POS Name"
                            withFeedbackLabel={true}
                          />
                        </div>
                        <div className="form-group input-select w-100">
                          <Field
                            name="store"
                            placeholder="Stores"
                            component={ReactSelector}
                            withFeedbackLabel={true}
                            isDisabled={pos?.isActive}
                            options={
                              stores?.length
                                ? stores.map((item) => ({
                                    value: item._id,
                                    label: item?.name || item?.storeId,
                                  }))
                                : undefined
                            }
                            onChange={(option) =>
                              setFieldValue("store", option)
                            }
                          />
                        </div>
                        <div className="form-group mb-4">
                          <Field
                            name="identityPrefix"
                            label="POS Serial No:"
                            component={Input}
                            placeholder="Enter POS Serial"
                            withFeedbackLabel={true}
                            readOnly={edit}
                            disabled={true}
                          />
                        </div>
                        <span className="text-muted pos-prefix">
                          POS serial can only be entered once and cannot be
                          changed
                        </span>
                      </div>
                    </div>
                  </Card>
                </Form>
              </Modal.Body>
            </Modal>
            {/* un-saved changes modal */}
            <SubscriptionDialog
              modalWidth={"350px"}
              modalHeight={"185px"}
              modalBackgroundColor={"#FFFFFF"}
              show={showUnsavedChangesModal}
              onHide={() => setShowUnsavedChangesModal(false)}
              title={i18n("SubscriptionModals.UnsavedChanges")}
              titleWidth={"140px"}
              titleHeight={"20px"}
              titleFontWeight={"700"}
              titleFontSize={"16px"}
              titleLineHeight={"20px"}
              titleColor={"#333333"}
              message={i18n(
                "SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges"
              )}
              messageWidth={"320px"}
              messageHeight={"44px"}
              messageFontSize={"16px"}
              messageFontWeight={"400"}
              messageLineHeight={"21.79px"}
              messageColor={"#333333"}
              primaryLabel={i18n("SubscriptionModals.CANCEL")}
              primaryAction={() => setShowUnsavedChangesModal(false)}
              primaryLabelWidth={"55px"}
              primaryLabelHeight={"20px"}
              primaryLabelFontWeight={"700"}
              primaryLabelFontSize={"14px"}
              primaryLabelLineHeight={"20px"}
              primaryLabelColor={"#828282"}
              secondaryAction={() => {
                setShowUnsavedChangesModal(false);
                setOpenPosAddDialog(false);
                setIsFormFilled(false);
                resetForm();
              }}
              secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
              secondaryLabelWidth={"75px"}
              secondaryLabelHeight={"20px"}
              secondaryLabelFontWeight={"700"}
              secondaryLabelFontSize={"14px"}
              secondaryLabelLineHeight={"20px"}
              secondaryLabelColor={"#2D9CDB"}
            />
          </>
        );
      }}
    </Formik>
  );
}
