import React, { useEffect, useState, useMemo } from "react";
import { styles } from "../../../../helpers/pdfStyles";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import IconQr from "../../../../assets/img/icon-qr.png";
import IconFatoratyFooter from "../../../../assets/img/fat-footer.png";
import * as invoiceActions from "../../../../_redux/invoices/invoicesActions";
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";

const PdfInvoicePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [productsDiscounts, setProductsDiscounts] = useState([]);
  const [productsTax, setProductsTax] = useState([]);
  const [invoiceData, setInvoiceData] = useState();
  const [companyData, setCompanyData] = useState();
  const [products, setProducts] = useState([]);
  const [invoiceSummary, setInvoiceSummary] = useState({
    subtotal: 0,
    discount: 0,
    taxableAmount: 0,
    taxAmount: 0,
    allTaxAmount: 0,
    total: 0,
  });

  const { invoice } = useSelector(
    (state) => ({
      invoice: state?.invoices?.invoiceForEdit,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (id) {
      dispatch(invoiceActions.fetchInvoice(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (invoice) {
      setInvoiceData({ ...invoice });
      setCompanyData({ ...invoice?.company });
      setProducts([...invoice.invoiceData.products]);
    }
  }, [dispatch, invoice]);

  ///// products discount /////
  useEffect(() => {
    let totalDiscount = [];
    products.forEach((product) => {
      return product?.discount?.type
        ? product?.discount.type === "%"
          ? totalDiscount.push(
              (+product.discount.value * +product.totalPrice) / 100
            )
          : totalDiscount.push(product.discount.value)
        : totalDiscount.push(0);
    });
    setProductsDiscounts([...totalDiscount]);
  }, [products]);

  ///// products tax /////
  useEffect(() => {
    let totalTax = [];

    if (productsDiscounts && productsDiscounts.length) {
      products.forEach((product, index) => {
        return product?.vat && product?.rate === "basic"
          ? totalTax.push(
              ((+product?.price * +product?.quantity -
                productsDiscounts[index]) *
                +companyData?.taxRate) /
                100
            )
          : totalTax.push(0);
      });
    }
    setProductsTax([...totalTax]);
    // eslint-disable-next-line
  }, [products, productsDiscounts]);

  useEffect(() => {
    let subtotalP = 0;
    let totalD = 0;
    let taxAmount = 0;
    let allTaxAmount = 0;

    if (products && products.length) {
      ///// Subtotal ////
      subtotalP = products?.reduce((a, b) => {
        return (subtotalP = a + +b.price * +b?.quantity);
      }, 0);

      ///// Discount ////
      totalD = products.reduce((a, b) => {
        if (b.discount) {
          return (totalD = a + +b.discount);
        } else {
          return totalD;
        }
      }, 0);

      //  Added Tax
      taxAmount = products?.reduce((a, b) => {
        if (b?.addedTax) {
          return (taxAmount = a + b?.addedTax);
        } else {
          return taxAmount;
        }
      }, 0);
      // Total Tax
      allTaxAmount = products?.reduce((a, b) => {
        if (b?.tax) {
          return (allTaxAmount = a + +b?.tax);
        } else {
          return allTaxAmount;
        }
      }, 0);
    }
    setInvoiceSummary({
      subtotal: subtotalP,
      discount: totalD,
      taxableAmount: subtotalP - totalD,
      taxAmount: taxAmount,
      allTaxAmount: allTaxAmount,
      total: subtotalP - totalD + taxAmount,
    });
  }, [invoiceData, products, companyData]);

  return useMemo(
    () => (
      <>
        {invoiceData?.invoiceData &&
          companyData &&
          productsDiscounts?.length &&
          productsTax?.length && (
            <PDFViewer style={{ height: "100%" }}>
              <Document>
                <Page style={styles.page} size="A4" wrap={true}>
                  <View style={styles.row}>
                    <View style={[styles.col_4]}>
                      {invoice?.invoiceData?.senderInformation?.companyId
                        ?.image ? (
                        <>
                          <Image
                            style={[styles.companyLogo]}
                            src={
                              invoice?.invoiceData?.senderInformation?.companyId
                                ?.image
                            }
                          />
                        </>
                      ) : (
                        <Text></Text>
                      )}
                    </View>
                    <View style={[styles.col_2]}>
                      <Image style={[styles.qrCode]} src={IconQr} />
                    </View>
                    <View style={[styles.col_4, { marginLeft: "6" }]}>
                      <Text style={[styles.textBold]}>
                        {companyData?.companyName
                          ? companyData?.companyName
                          : ""}
                      </Text>
                      <Text style={[styles.infoText]}>
                        {companyData?.taxName
                          ? companyData?.taxName +
                            " No. " +
                            companyData?.taxNumber
                          : ""}
                      </Text>
                      <Text style={[styles.infoText]}>
                        {companyData?.crNumber
                          ? "C.R. " + companyData?.crNumber
                          : ""}
                      </Text>
                      <Text style={[styles.infoText]}>
                        {companyData?.mobile ? companyData?.mobile : ""}
                      </Text>
                      <Text style={[styles.infoText]}>
                        {companyData?.email ? companyData?.email : ""}
                      </Text>
                      <Text style={[styles.infoText]}>
                        {companyData?.city ? companyData?.city : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.row}>
                    <View style={[styles.col_4]}>
                      <Text style={[styles.blockTitle]}>
                        Customer Information
                      </Text>
                      <View style={styles.blockInfoRow}>
                        <Text style={styles.blockInfoLabel}> Name:</Text>
                        <Text style={styles.blockInfoText}>
                          {invoiceData?.invoiceData?.customerName
                            ? invoiceData?.invoiceData?.customerName
                            : invoiceData?.invoiceData?.receiverInformation
                                ?.name
                            ? invoiceData?.invoiceData?.receiverInformation
                                ?.name
                            : ""}
                        </Text>
                      </View>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Email:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {invoiceData?.invoiceData?.customerEmail
                            ? invoiceData?.invoiceData?.customerEmail
                            : invoiceData?.invoiceData?.receiverInformation
                                ?.email
                            ? invoiceData?.invoiceData?.receiverInformation
                                ?.email
                            : ""}
                        </Text>
                      </View>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Mobile:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {
                            invoiceData?.invoiceData?.receiverInformation
                              ?.mobile
                          }
                        </Text>
                      </View>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Tax No.:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {
                            invoiceData?.invoiceData?.receiverInformation
                              ?.taxNumber
                          }
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.col_2]}>
                      <Text style={{ textAlign: "center", fontSize: "14" }}>
                        {invoiceData?.invoiceData?.invoiceType === "refund"
                          ? "Refund Invoice"
                          : "Tax Invoice"}
                      </Text>
                    </View>
                    <View style={[styles.col_4]}>
                      <Text style={[styles.blockTitle]}>
                        Invoice Information
                      </Text>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Number:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {invoiceData?.invoiceNumber}
                        </Text>
                      </View>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Date:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {moment(invoiceData?.invoiceData?.invoiceDate).format(
                            "MM/DD/YYYY"
                          )}
                        </Text>
                      </View>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Status:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {invoiceData?.status?.charAt(0)?.toUpperCase() +
                            invoiceData?.status?.slice(1)}
                        </Text>
                      </View>
                      <View style={styles.blockInfoRow}>
                        <Text style={[styles.blockInfoLabel]}>Type:</Text>
                        <Text style={[styles.blockInfoText]}>
                          {invoiceData?.invoiceData?.invoiceType}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={styles.table}>
                    <View style={[styles.tableRow, styles.tableBorderTop]}>
                      <View style={styles.tableColHeaderNumber}>
                        <Text style={styles.tableCellHeader}>No.</Text>
                      </View>
                      <View style={styles.tableColHeaderLarge}>
                        <Text style={styles.tableCellHeader}>Item Name</Text>
                      </View>
                      <View style={styles.tableColHeaderLarge}>
                        <Text style={styles.tableCellHeader}>Item Desc.</Text>
                      </View>
                      <View style={styles.tableColHeaderSmall}>
                        <Text style={styles.tableCellHeader}>Unit Price</Text>
                      </View>
                      <View style={styles.tableColHeaderSmall}>
                        <Text style={styles.tableCellHeader}>Qty.</Text>
                      </View>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>
                          Discount Amount
                        </Text>
                      </View>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>
                          Taxable Amount
                        </Text>
                      </View>
                      <View style={styles.tableColHeader}>
                        <Text style={styles.tableCellHeader}>Tax Rates</Text>
                      </View>
                      <View style={styles.tableColHeaderMedium}>
                        <Text style={styles.tableCellHeader}>Tax Amount</Text>
                      </View>
                      <View style={styles.tableColHeaderMedium}>
                        <Text style={[styles.tableCellHeader, { fontSize: 8 }]}>
                          Item Subtotal Including VAT
                        </Text>
                      </View>
                    </View>
                    <View style={[styles.tableBorderTop]}>
                      {invoiceData?.invoiceData?.products?.map(
                        (item, index) => {
                          return (
                            <View style={[styles.tableRow]}>
                              <View style={styles.tableColNumber}>
                                <Text style={styles.tableCell}>
                                  {index + 1}
                                </Text>
                              </View>
                              <View style={styles.tableColLarge}>
                                <Text style={styles.tableCell}>
                                  {item.name ? item.name : ""}
                                </Text>
                              </View>
                              <View style={styles.tableColLarge}>
                                <Text style={styles.tableCell}>
                                  {item.description ? item.description : ""}
                                </Text>
                              </View>
                              <View style={styles.tableColSmall}>
                                <Text style={styles.tableCell}>
                                  {Number(item?.price)?.toFixed(2)}
                                </Text>
                              </View>
                              <View style={styles.tableColSmall}>
                                <Text style={styles.tableCell}>
                                  {item?.quantity}
                                </Text>
                              </View>
                              <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                  {item?.discount
                                    ? Number(item?.discount)?.toFixed(2)
                                    : 0.0}
                                </Text>
                              </View>
                              <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                  {item?.price && item?.quantity
                                    ? (
                                        item?.price * item?.quantity -
                                        Number(item?.discount)
                                      ).toFixed(2)
                                    : Number(item?.price)?.toFixed(2)}
                                </Text>
                              </View>
                              <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>
                                  {item?.taxRate ? Number(item?.taxRate) : 0.0}{" "}
                                  %
                                </Text>
                              </View>
                              <View style={styles.tableColMedium}>
                                <Text style={styles.tableCell}>
                                  {item?.tax ? Number(item?.tax) : 0.0}
                                </Text>
                              </View>
                              <View style={styles.tableColMedium}>
                                <Text style={styles.tableCell}>
                                  {item?.addedTax && item?.discount
                                    ? (
                                        item?.addedTax +
                                        +item?.price?.toLocaleString() *
                                          item?.quantity -
                                        Number(item?.discount)
                                      )?.toFixed(2)
                                    : item?.discount
                                    ? (
                                        item?.price?.toLocaleString() *
                                          item?.quantity -
                                        Number(item?.discount)
                                      )?.toFixed(2)
                                    : item?.addedTax
                                    ? (
                                        item?.addedTax +
                                        +item?.price?.toLocaleString() *
                                          item?.quantity
                                      )?.toFixed(2)
                                    : 0.0}
                                </Text>
                              </View>
                            </View>
                          );
                        }
                      )}
                    </View>
                    <View style={[styles.tableBorderBot]}></View>
                  </View>

                  <View style={[styles.row, { marginTop: "20" }]} wrap={false}>
                    <View style={[styles.col_4]}>
                      <Text style={[styles.blockTitle]}>Payment Details</Text>
                      <View style={[styles.border]}>
                        <View
                          style={[
                            styles.blockBorderRow,
                            styles.blockPaymentRow,
                          ]}
                        >
                          <Text style={styles.blockPaymentTitleText}>Paid</Text>
                          <Text style={styles.blockPaymentTitleText}>
                            {companyData?.currency}{" "}
                            {invoiceData?.invoiceData?.paidTotal
                              ? Number(
                                  invoiceData?.invoiceData?.paidTotal
                                )?.toFixed(2)
                              : 0}
                          </Text>
                        </View>
                        {invoiceData?.invoiceData?.payments?.map((payments) => {
                          return (
                            <View
                              style={
                                (styles.blockBorderRow, styles.blockPaymentRow)
                              }
                            >
                              <Text style={styles.blockPaymentText}>
                                {moment(payments.createdAt).format(
                                  "MM/DD/YYYY"
                                )}
                              </Text>
                              <Text style={styles.blockPaymentText}>
                                {payments?.method === "visa/master_card"
                                  ? "Master/Visa Card"
                                  : payments.method === "mada_card"
                                  ? "Mada Card"
                                  : payments.method === "bank_transfer"
                                  ? "Bank "
                                  : payments.method === "cash"
                                  ? "Cash"
                                  : ""}
                              </Text>
                              <Text style={styles.blockPaymentText}>
                                {companyData?.currency}{" "}
                                {Number(payments?.amount)?.toFixed(2)}
                              </Text>
                            </View>
                          );
                        })}
                      </View>
                      <View style={[styles.border]}>
                        <View
                          style={
                            (styles.blockBorderRow, styles.blockPaymentRow)
                          }
                        >
                          <Text style={styles.blockPaymentTitleText}>
                            Balance Due
                          </Text>
                          <Text style={styles.blockPaymentTitleText}>
                            {companyData?.currency}{" "}
                            {invoiceData?.invoiceData?.balance
                              ? Number(
                                  invoiceData?.invoiceData?.balance
                                )?.toFixed(2)
                              : 0}
                          </Text>
                        </View>
                        <View
                          style={
                            (styles.blockBorderRow, styles.blockPaymentRow)
                          }
                        >
                          <Text style={styles.blockPaymentText}>Due Date</Text>
                          <Text style={styles.blockPaymentText}>
                            {invoiceData?.invoiceData?.dueDate &&
                            invoiceData?.invoiceData?.dueDate !== "Invalid date"
                              ? moment(
                                  invoiceData?.invoiceData?.dueDate
                                ).format("MM/DD/YYYY")
                              : ""}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.col_2}></View>
                    <View style={[styles.col_4]}>
                      <Text style={[styles.blockTitle]}>Total</Text>

                      <View
                        style={(styles.blockBorderRow, styles.blockPaymentRow)}
                      >
                        <Text style={styles.blockPaymentTitleText}>
                          Sub Total (Excluding Tax)
                        </Text>
                        <Text style={styles.blockPaymentTitleText}>
                          {companyData?.currency}{" "}
                          {Number(invoiceSummary?.subtotal)?.toFixed(2)}
                        </Text>
                      </View>
                      <View
                        style={(styles.blockBorderRow, styles.blockPaymentRow)}
                      >
                        <Text style={styles.blockPaymentTitleText}>
                          Discount
                        </Text>
                        <Text style={styles.blockPaymentTitleText}>
                          {companyData?.currency}{" "}
                          {Number(invoiceSummary?.discount)?.toFixed(2)}
                        </Text>
                      </View>
                      <View
                        style={(styles.blockBorderRow, styles.blockPaymentRow)}
                      >
                        <Text style={styles.blockPaymentTitleText}>
                          Taxable Amount
                        </Text>
                        <Text style={styles.blockPaymentTitleText}>
                          {companyData?.currency}{" "}
                          {Number(invoiceSummary?.taxableAmount)?.toFixed(2)}
                        </Text>
                      </View>
                      <View
                        style={(styles.blockBorderRow, styles.blockPaymentRow)}
                      >
                        <Text style={styles.blockPaymentTitleText}>
                          Total Tax
                        </Text>
                        <Text style={styles.blockPaymentTitleText}>
                          {companyData?.currency}{" "}
                          {Number(invoiceSummary?.allTaxAmount)?.toFixed(2)}
                        </Text>
                      </View>
                      <View
                        style={(styles.blockBorderRow, styles.blockPaymentRow)}
                      >
                        <Text
                          style={
                            (styles.blockPaymentTitleText,
                            { fontWeight: "bold" })
                          }
                        >
                          Total (Including Tax)
                        </Text>
                        <Text
                          style={
                            (styles.blockPaymentTitleText,
                            { fontWeight: "bold" })
                          }
                        >
                          {companyData?.currency}{" "}
                          {Number(invoiceSummary?.total).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.termsAndCond]}>
                    <View style={[styles.termsRow]} wrap={false}>
                      <View style={[styles.col_3]}>
                        <View style={[styles.blockTitle]}>
                          <Text>Replacement Policy:</Text>
                        </View>
                      </View>
                      <View style={[styles.col_3_5, { marginLeft: "15" }]}>
                        <View style={[styles.blockTitle]}>
                          <Text>Note:</Text>
                        </View>
                      </View>
                      <View style={[styles.col_3, { marginLeft: "15" }]}>
                        <View style={[styles.blockTitle]}>
                          <Text>Terms & Conditions:</Text>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.row]} wrap={false}>
                      <View style={[styles.col_3, styles.border]}>
                        <View style={[styles.blockInfoRow]}>
                          <Text style={[styles.footerInfo]}>
                            To view the replacement policy, please visit the
                            following link{" "}
                            <Text style={[{ color: "#2D9CDB" }]}>
                              {" "}
                              www.premier.com/ReplacementPolicy{" "}
                            </Text>
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.col_3_5,
                          styles.border,
                          { marginLeft: "15" },
                        ]}
                      >
                        <View style={[styles.blockInfoRow]}>
                          <Text style={[styles.footerInfo]}>
                            {invoiceData?.invoiceData?.generalNotes
                              ? invoiceData?.invoiceData?.generalNotes
                              : ""}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.col_3,
                          styles.border,
                          { marginLeft: "15" },
                        ]}
                      >
                        <View style={[styles.blockInfoRow]}>
                          <Text style={[styles.footerInfo]}>
                            To view the Terms and Conditions, please visit the
                            following link{" "}
                            <Text style={[{ color: "#2D9CDB" }]}>
                              {" "}
                              www.premier.com/TermsAndConditions
                            </Text>
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={[styles.bottomRow, styles.footer]} fixed>
                    <Text
                      render={({ pageNumber, totalPages }) =>
                        `${
                          invoiceData?.invoiceData?.invoiceType === "refund"
                            ? "Refund Invoice No."
                            : "Invoice No."
                        }   ${
                          invoiceData?.invoiceNumber
                        }, Page  ${pageNumber} / ${totalPages} \n ${
                          invoiceData?.invoiceData?.invoiceType === "refund"
                            ? "Reference Invoice No."
                            : ""
                        } ${
                          invoiceData?.invoiceData?.invoiceType === "refund"
                            ? invoiceData?.parentInvoiceNumber
                            : ""
                        } `
                      }
                      fixed
                    />
                    <Image
                      style={[{ width: "23%" }]}
                      src={IconFatoratyFooter}
                    />
                    <Text
                      render={({ pageNumber, totalPages }) =>
                        `${moment().format(
                          "ddd, DD MMM YYYY"
                        )} ${moment().format("HH:mm:ss")}`
                      }
                    />
                  </View>
                </Page>
              </Document>
            </PDFViewer>
          )}
      </>
    ),
    // eslint-disable-next-line
    [companyData, invoiceData?.invoiceData, productsDiscounts, productsTax]
  );
};

export default PdfInvoicePage;
