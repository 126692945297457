import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }
  return classes.join(" ");
};

export function paymentCardExpiry({
  field,
  label,
  classes,
  type = "text",
  customFeedbackLabel,
  form: { touched, errors, setFieldValue },
  withFeedbackLabel = true,
  ...props
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value.length < field.value.length) {
      if (value.endsWith("/") && value.length === 3) {
        setFieldValue(name, value.slice(0, -1));
        return;
      }
    }

    if (name === "expiry" && /^\d{2}$/.test(value) && value.length === 2) {
      setFieldValue(name, `${value}/`);
    } else {
      setFieldValue(name, value);
    }
  };

  return (
    <>
      <input
        type={type}
        className={`${classes} ${getFieldCSSClasses(touched[field.name], errors[field.name])}`}
        {...field}
        onChange={handleChange}
        {...props}
      />
      {label && <label>{label}</label>}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          type={type}
          label={label}
          error={errors[field.name]}
          touched={touched[field.name]}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
