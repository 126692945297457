import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../assets/img/icon-close.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { i18n } from "../../../pages/private/languageSelector";
import { useSelector } from "react-redux";
import * as actions from "../../../../app/modules/Auth/_redux/authCrud";
import toast from "react-hot-toast";

export const InvoiceColumnsModal = ({
  tableFields,
  setTableFields,
  openInvoiceColumnDialog,
  setOpenInvoiceColumnDialog,
}) => {
  const [tableColumns, setTableColumns] = useState();

  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));

  const handleColumns = (tableColumns) => {
    actions
      .updateUserProfile(user?._id, {
        ...user,
        invoiceTableContent: tableColumns,
      })
      .then((res) => {
        toast.success("Invoice Columns Updated");
      });
  };

  useEffect(() => {
    setTableColumns([...tableFields]);
  }, [tableFields]);

  return (
    <>
      <Modal
        size="lg"
        onHide={() => {}}
        className="modal-drawer"
        show={openInvoiceColumnDialog}
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenInvoiceColumnDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>

          {<Modal.Title>{i18n("INVOICES.Columns")}</Modal.Title>}
        </Modal.Header>

        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            <h6>{i18n("INVOICES.ChooseWhichColumnsToShow")}</h6>
            <div>
              {tableColumns?.map((item, index) => (
                <div key={index}>
                  <FormControlLabel
                    className="form-group w-100 mb-0"
                    control={
                      <Checkbox
                        key={index}
                        checked={item.active}
                        onClick={(e) => {
                          e.preventDefault();
                          tableColumns[index] = {
                            ...tableColumns[index],
                            active: !tableColumns[index].active,
                          };
                          setTableColumns([...tableColumns]);
                        }}
                      />
                    }
                    label={item.name}
                  />
                </div>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-outline-primary btn-elevate"
                onClick={() => {
                  handleColumns(tableColumns);
                  setTableFields(tableColumns);
                  setOpenInvoiceColumnDialog(false);
                }}>
                {i18n("INVOICES.SaveAsDefault")}
              </button>
              <button
                type="button"
                className="btn btn-primary btn-elevate ml-5"
                onClick={() => {
                  setTableFields(tableColumns);
                }}>
                {i18n("DASHBOARD.Apply")}
              </button>
            </div>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};
