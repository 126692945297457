// eslint-disable-next-line
import React, { useEffect } from "react";
import english from "./../private/english.json";
import arabic from "./../private/arabic.json";
import { shallowEqual, useSelector } from "react-redux";

let language = localStorage.getItem("language");

const I18n = ({ key }) => {
  const { user } = useSelector(
    (state) => ({
      user: state?.auth?.user,
    }),
    shallowEqual
  );
  let getKey = key.split(".");

  useEffect(() => {
    language = user?.language
      ? user?.language
      : localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "english";
  }, [key, user]);

  return language === "arabic"
    ? `${arabic[getKey.length ? getKey[0] : ""][getKey.length ? getKey[1] : ""]}`
    : `${english[getKey.length ? getKey[0] : ""][getKey.length ? getKey[1] : ""]}`;
};
export default I18n;

export const i18n = (key) => {
  let getKey = key.split(".");

  return language === "arabic"
    ? `${arabic[getKey.length ? getKey[0] : ""][getKey.length ? getKey[1] : ""]}`
    : `${english[getKey.length ? getKey[0] : ""][getKey.length ? getKey[1] : ""]}`;
};
