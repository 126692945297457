import toast from "react-hot-toast";
import * as requestFromServer from "./companyCrud";
import { companySlice, callTypes } from "./companySlice";

const { actions } = companySlice;

export const CompanyFetch = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCompany()
    .then((response) => {
      const { company } = response.data;
      dispatch(actions.companyFetched({ companyForEdit: company }));
    })
    .catch((error) => {
      // toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find company";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCompany = (company) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCompany(company)
    .then((res) => {
      dispatch(actions.companyUpdated());
      dispatch(CompanyFetch());
    })
    .catch((error) => {
      dispatch(CompanyFetch());
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update company";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
