import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as posActions from "../../../../../_redux/pos/posActions";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconTrash from "../../../../../assets/img/icon-trash.svg";
import IconPen from "../../../../../assets/img/icon-pen.svg";
import ConfirmationDialoge from "../../../confirmationDialoge";
import { PosEditForm } from "./PosEditForm";

export const ManagePos = ({ pos, stores, userAccess, openPosManageDialog, setOpenPosManageDialog }) => {
  const dispatch = useDispatch();

  const [edit, setEdit] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [deletePosId, setDeletePosId] = useState("");
  const [openPosAddDialog, setOpenPosAddDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);

  const { posForEdit } = useSelector(
    (state) => ({
      posForEdit: state.pos?.posForEdit,
    }),
    shallowEqual
  );

  const deletePosHandler = () => {
    dispatch(posActions.deletePos(deletePosId));
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openPosManageDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenPosManageDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Manage Pos Devices</Modal.Title>
        </Modal.Header>
        <Formik>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="d-flex justify-content-end">
                    <div className="form-group input-select w-50 mb-0">
                      <p className="text-muted mb-0">Store</p>
                      <select className="form-control" onChange={(e) => setSearchText(e.target.value)}>
                        <option value="" selected>
                          All Stores
                        </option>
                        {stores?.length
                          ? stores.map((item) => {
                              return (
                                <option value={item?.name ? item?.name : item?.storeId}>
                                  {item?.name ? item?.name : item?.storeId}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>
                  <ul className="card-listitem list-unstyled">
                    <div className="h5 d-flex justify-content-between">
                      <b>POS Name</b>
                      <b>Status</b>
                    </div>
                    {pos?.length
                      ? pos
                          .filter((it) => {
                            return (
                              it?.store?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                              it?.store?.storeId?.toLowerCase()?.includes(searchText?.toLowerCase())
                            );
                          })
                          .map((item, i) => {
                            return (
                              <li key={i}>
                                <div className="card-info">
                                  <b className="heading-color truncate-160 h5">{item.name ? item.name : item.posId}</b>
                                  <p className="sub-heading-color mb-0">
                                    {item?.store?.name ? item?.store?.name : item?.store?.storeId}
                                  </p>
                                </div>
                                <div className="card-actions ml-auto">
                                  {userAccess?.editData ? (
                                    <span className="action action-edit">
                                      <img
                                        title="Edit"
                                        src={IconPen}
                                        alt="loading"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          dispatch(posActions.fetchPos(item._id));
                                          setOpenPosAddDialog(true);
                                          setEdit(true);
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {userAccess?.deleteData ? (
                                    <span className="action action-delete">
                                      <img
                                        title="Delete"
                                        style={{ cursor: "pointer" }}
                                        src={IconTrash}
                                        alt="loading"
                                        onClick={() => {
                                          setDeletePosId(item._id);
                                          setConfirmDialogForClose(true);
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                            );
                          })
                      : ""}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <>
          <Modal.Footer>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary btn-elevate mr-5"
                onClick={() => {
                  setOpenPosManageDialog(false);
                }}>
                Back
              </button>
              {userAccess?.createData ? (
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenPosAddDialog(true);
                    setEdit(false);
                  }}>
                  Add New
                </button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal>
      <PosEditForm
        edit={edit}
        pos={edit ? posForEdit : undefined}
        openPosAddDialog={openPosAddDialog}
        setOpenPosAddDialog={setOpenPosAddDialog}
      />
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title="Delete POS"
        subTitle="Are you sure you want to delete this Pos? Fatoraty POS app will be deactivated on the device assigned to this POS."
        primaryAction={() => deletePosHandler()}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle="Delete"
        secondaryActionTitle="Cancel"
        classes="modal-drawer"
        heading="Delete POS!"
        headingColor="#ED553B"
        viewButon={true}
      />
    </>
  );
};
