import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { requestPassword } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../pages/private/languageSelector";

const initialValues = {
  email: "",
};

function ForgotPassword() {
  const history = useHistory();
  const [isRequested, setIsRequested] = useState(false);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(i18n("Validation.WrongEmailFormat"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .required(i18n("Validation.RequiredField")),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(values.email)
        .then((res) => {
          setIsRequested(true);
          history.push("/forgot-password-next");
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(i18n("Validation.WrongEmailFormat"), {
            // name: values.email,
          });
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <>
          <div className="form-title" style={{ padding: "0 5rem 4rem" }}>
            <p className="m-0">{i18n("ForgotPassword.PleaseEnterYourEmailToReceiveAPasswordResetMessage")}</p>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp">
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{formik.status}</div>
              </div>
            )}
            <div className="form-group with-icon" style={{ marginBottom: "3rem" }}>
              <span>
                <i className="flaticon2-black-back-closed-envelope-shape"></i>
              </span>
              <field>
                <input
                  type="email"
                  placeholder={i18n("global.Email")}
                  className={`form-control ${getInputClasses("email")}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                <label> {i18n("global.Email")}</label>
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                ) : null}
              </field>
            </div>

            <div className="d-flex flex-wrap flex-center" style={{ marginBottom: "3rem" }}>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}>
                {i18n("ForgotPassword.Send")}
              </button>
            </div>
            <div className="d-block w-100 mt-3 text-center">
              <span className="font-weight-bold text-dark-50">
                {i18n("ForgotPassword.DontHaveAnAccountYet")}{" "}
                <Link to="/auth/registration" style={{ color: "#20639b" }}>
                  {i18n("ForgotPassword.RegisterNow")}
                </Link>
              </span>
            </div>
          </form>
        </>
      )}
    </>
  );
}

export default connect(null, auth.actions)(ForgotPassword);
