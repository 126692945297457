import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../../private/languageSelector";
import IconCross from "../../../../../assets/img/cross.svg";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { Input } from "../../../../../_metronic/_partials/controls";
import { PAYMENT_TYPES } from "../../../../../helpers/constants";

export const EditPaymentTypes = ({
  userAccess,
  savePayments,
  companyForEdit,
  showEditPayment,
  setShowEditPayments,
}) => {
  const [newPaymentMethod, setNewPaymentMethod] = useState("");
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);
  const [allPaymentTypes, setAllPaymentTypes] = useState([]); // All payment types, including deleted
  const [editedPayment, setEditedPayment] = useState("");
  const [editModeIndex, setEditModeIndex] = useState(-1);

  useEffect(() => {
    const initialPaymentTypes = companyForEdit?.paymentTypes?.length
      ? [...companyForEdit.paymentTypes]
      : [...PAYMENT_TYPES];
    setPaymentTypesToShow(initialPaymentTypes.filter(item => !item.isDeleted));
    setAllPaymentTypes(initialPaymentTypes);
  }, [companyForEdit]);

  const addEditedPayment = () => {
    if (editedPayment) {
      // Check if we are in edit mode
      if (editModeIndex !== -1) {
        setAllPaymentTypes((prev) => {
          const updatedPayments = [...prev];

          // Mark the old payment type as deleted
          updatedPayments[editModeIndex] = {
            ...updatedPayments[editModeIndex],
            isDeleted: true,
          };

          // Add the new payment type in place of the old one
          const newPayment = {
            name: editedPayment,
            default: false,
            active: false,
            isDeleted: false,
          };
          updatedPayments.push(newPayment); // Add the new payment type to the list

          return updatedPayments;
        });

        setPaymentTypesToShow((prev) => {
          const updatedPayments = [...prev];

          // Update only the displayed payment types
          updatedPayments[editModeIndex] = {
            name: editedPayment,
            default: false,
            active: false,
            isDeleted: false,
          };

          return updatedPayments;
        });

        setEditedPayment("");
        setEditModeIndex(-1);
      }
    } else {
      toast.error("Enter a valid payment method");
    }
  };

  const addPaymentHandler = () => {
    if (newPaymentMethod) {
      const newPayment = {
        name: newPaymentMethod,
        default: false,
        active: false,
        isDeleted: false,
      };
      setPaymentTypesToShow((prev) => [...prev, newPayment]);
      setAllPaymentTypes((prev) => [...prev, newPayment]); // Add to all payment types
      setNewPaymentMethod("");
    } else {
      toast.error("Enter Payment Channel Name");
    }
  };

  const handleEditClick = (item, index) => {
    setEditedPayment(item.name);
    setEditModeIndex(index);
  };

  const removePaymentHandler = (businessId) => {
    const updatedPayments = allPaymentTypes.map((item) => {
      if (businessId === item.businessId) {
        return {
          ...item,
          isDeleted: true,
        };
      }
      return item;
    });

    setPaymentTypesToShow(updatedPayments.filter(item => !item.isDeleted));
    setAllPaymentTypes(updatedPayments);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={companyForEdit}
        onSubmit={(values) => {
          const paymentData = {
            _id: values._id,
            paymentTypes: allPaymentTypes, // Send all payment types, including soft deleted ones
          };
          savePayments(paymentData);
        }}
      >
        {({ handleSubmit }) => (
          <Modal
            size="md"
            className="modal-drawer"
            show={showEditPayment}
            onHide={() => {}}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <button
                type="button"
                className="btn btn-close"
                onClick={() => setShowEditPayments(false)}
              >
                <img src={IconClose} alt="loading" />
              </button>
              <Modal.Title>{i18n("Company.ManagePaymentChannel")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div>
                  <b className="ml-5">{i18n("global.PaymentChannels")}</b>
                  {paymentTypesToShow.map((item, i) => (
                    <div key={i}>
                      <div className="form-group d-flex mt-5">
                        <img
                          src={IconCross}
                          alt="loading"
                          className="mr-3 cursor-pointer"
                          onClick={() => {
                            item.name === "Card"
                              ? toast.error("You can't delete Card payment method")
                              : userAccess?.deleteData
                              ? removePaymentHandler(item.businessId)
                              : toast.error("Access Denied");
                          }}
                        />
                        <div>
                          {editModeIndex === i ? (
                            <Field
                              name="paymentType"
                              component={Input}
                              value={editedPayment}
                              placeholder="Payment Method"
                              withFeedbackLabel={false}
                              onChange={(e) => setEditedPayment(e.target.value)}
                            />
                          ) : (
                            <span>{item.name}</span>
                          )}
                        </div>
                        {editModeIndex === i ? (
                          <span
                            className="fas fa-plus cursor-pointer mt-2 mr-3"
                            style={{ color: "#2D9CDB" }}
                            onClick={addEditedPayment} 
                          />
                        ) : (
                          <span
                            className="fas fa-edit cursor-pointer mt-2 ml-3"
                            style={{ color: "#2D9CDB" }}
                            onClick={() => handleEditClick(item, i)}
                          />
                        )}
                      </div>
                    </div>
                  ))}

                  <div className="form-group d-flex mt-15">
                    <span
                      className="fas fa-plus cursor-pointer mt-2 mr-3"
                      style={{ color: "#2D9CDB" }}
                      onClick={() =>
                        userAccess?.createData
                          ? addPaymentHandler()
                          : toast.error("Access Denied")
                      }
                    />
                    <Field
                      name="newPaymentType"
                      component={Input}
                      placeholder="Enter new payment channel name"
                      withFeedbackLabel={false}
                      value={newPaymentMethod}
                      onChange={(e) => setNewPaymentMethod(e.target.value)}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline-primary btn-elevate"
                onClick={() => setShowEditPayments(false)}
              >
                {i18n("Company.Cancel")}
              </button>
              <button
                type="submit"
                onClick={() => {
                  handleSubmit();
                  setShowEditPayments(false);
                }}
                className="btn btn-primary btn-elevate ml-5"
              >
                {i18n("Company.Save")}
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
};
