import React, { useState, useMemo } from "react";
import toast from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { i18n } from "../../private/languageSelector";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import IconClose from "../../../../assets/img/icon-close.svg";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import IconPrint from "../../../../assets/img/invoice-actions/print.svg";
import IconDelete from "../../../../assets/img/invoice-actions/delete.svg";
import IconPdf from "../../../../assets/img/invoice-group-actions/pdf.svg";
import IconListPdf from "../../../../assets/img/invoice-group-actions/pdf-list.svg";
import IconListExcel from "../../../../assets/img/invoice-group-actions/excel-list.svg";
import { InvoiceDeleteConfirmation } from "../invoice-delete-dialog/InvoiceDeleteConfirmation";

export const InvoiceGroupActionsModal = ({
  userAccess,
  selectedInvoices,
  setSelectedInvoices,
  openInvoiceGroupDialog,
  setOpenInvoiceGroupDialog,
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const { invoiceFilterState } = useSelector(
    (state) => ({
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );
  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext.queryParams,
    };
  }, [invoicesUIContext]);

  const deleteInvoices = () => {
    if (selectedInvoices?.length) {
      dispatch(actions.deleteInvoices(invoiceFilterState, selectedInvoices, invoicesUIProps.queryParams)).then(() => {
        toast.success("Invoices Deleted Successfully");
        setOpenInvoiceGroupDialog(false);
        setSelectedInvoices([]);
      });
    } else {
      toast.error("No Invoice Selected");
    }
  };
  return (
    <>
      <Modal
        size="lg"
        className="modal-drawer"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openInvoiceGroupDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenInvoiceGroupDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>

          {<Modal.Title>{i18n("INVOICES.Actions")}</Modal.Title>}
        </Modal.Header>

        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            <div className="mt-7 cursor-pointer">
              <img src={IconPrint} alt="loading" />
              <span className="ml-5">{i18n("INVOICES.PrintInvoices")}</span>
            </div>
            {userAccess?.deleteData ? (
              <div className="mt-5 cursor-pointer" onClick={() => setOpenDeleteDialog(true)}>
                <img src={IconDelete} alt="loading" />
                <span className={"ml-5"}>{i18n("INVOICES.Delete")}</span>
              </div>
            ) : (
              ""
            )}
            <div className="mt-5 cursor-pointer">
              <img src={IconPdf} alt="loading" />
              <span className="ml-5">{i18n("MENU.ExportInvoicesToPdf")}</span>
            </div>
            <div className="mt-5 cursor-pointer">
              <img src={IconListExcel} alt="loading" />
              <span className="ml-5">{i18n("MENU.ExportListToExcel")}</span>
            </div>
            <div className="mt-5 cursor-pointer">
              <img src={IconListPdf} alt="loading" />
              <span className="ml-5">{i18n("INVOICES.ExportListToPDF")}</span>
            </div>
          </Modal.Body>
        </>
      </Modal>
      <InvoiceDeleteConfirmation
        deleteInvoices={deleteInvoices}
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
      />
    </>
  );
};
