import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { i18n } from "../../../pages/private/languageSelector";

const PasswordResetNext = () => {
  const history = useHistory();

  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      <div class="container">
        <h2 class="text-center" style={{ marginLeft: "4%", marginTop: "23%", color: "#20639b" }}>
          {i18n("ResetPasswordNext.YourPasswordWasSetSuccessfully")}
        </h2>

        <NavLink className="menu-link" to="/logout">
          <p
            style={{
              marginTop: "6%",
              marginLeft: "39%",
              color: "blue",
            }}>
            <button
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3 f-button`}
              style={{ width: "16%", marginLeft: "13%" }}
              onClick={() => history.push("/auth/login")}>
              <span> {i18n("ResetPasswordNext.SignIn")}</span>
            </button>
          </p>
        </NavLink>
      </div>
    </div>
  );
};

export default PasswordResetNext;
