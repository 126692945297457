import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as storesActions from "../../../../../_redux/stores/storeActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { StoresEditForm } from "./StoresEditForm";
import { ManageStores } from "./ManageStores";

const Stores = ({ userAccess, openStoresDialog, setOpenStoresDialog }) => {
  const dispatch = useDispatch();
  const [openStoresAddDialog, setOpenStoresAddDialog] = useState(false);
  const [openStoresManageDialog, setOpenStoresManageDialog] = useState(false);

  const { stores } = useSelector(
    (state) => ({
      stores: state.stores?.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(storesActions.fetchStores());
  }, [dispatch, openStoresDialog]);

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openStoresDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenStoresDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Stores</Modal.Title>
        </Modal.Header>
        <Formik>
          {() => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                {stores?.length ? (
                  <>
                    <Form className="form form-label-right">
                      <ul className="card-listitem list-unstyled">
                        <div className="h4 d-flex justify-content-between">
                          <b>Stores</b>
                          <b>Number of POS</b>
                        </div>
                        {stores?.length
                          ? stores.map((store, i) => {
                              return (
                                <li key={i}>
                                  <div className="card-info d-flex justify-content-between w-100">
                                    <div>
                                      <b className="heading-color truncate h5">
                                        {store.name ? store.name : store.storeId}
                                      </b>
                                      <p className="sub-heading-color mb-0">{store.address}</p>
                                      <p className="sub-heading-color mb-0">{store.mobile}</p>
                                    </div>
                                    <b className="heading-color h5">{store.numberOfPos}</b>
                                  </div>
                                  <hr />
                                </li>
                              );
                            })
                          : ""}
                      </ul>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="text-center">
                        <h4>You have no Stores added!</h4>
                        {userAccess?.createData ? (
                          <>
                            <p className="mt-5">To add a new Store, click here to</p>
                            <p
                              style={{ color: "#2D9CDB", cursor: "pointer" }}
                              onClick={() => setOpenStoresAddDialog(true)}>
                              Add New Store
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
            );
          }}
        </Formik>
        {stores?.length ? (
          <>
            <Modal.Footer>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenStoresManageDialog(true);
                  }}>
                  Manage
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
      <StoresEditForm
        edit={false}
        openStoresAddDialog={openStoresAddDialog}
        setOpenStoresAddDialog={setOpenStoresAddDialog}
      />
      <ManageStores
        stores={stores}
        userAccess={userAccess}
        openStoresManageDialog={openStoresManageDialog}
        setOpenStoresManageDialog={setOpenStoresManageDialog}
      />
    </>
  );
};
export default Stores;
