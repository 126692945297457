import React, { useMemo } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { ProductsTable } from "./products-table/ProductsTable";
import { useProductsUIContext } from "./ProductsUIContext";
import ProductsFilter from "./products-filter/ProductsFilter";

export function ProductsCard() {
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      newProductButtonClick: productsUIContext.newProductButtonClick,
    };
  }, [productsUIContext]);
  return (
    <>
      <ProductsFilter selectedProducts={productsUIProps.ids} setIds={productsUIProps.setIds} />
      <Card>
        <CardBody>
          <ProductsTable />
        </CardBody>
      </Card>
    </>
  );
}
