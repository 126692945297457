import React, { useEffect, useRef } from "react";
import "./style.scss";
import { transformColumnName } from "../../../../helpers/helpers";
import { useSelector } from "react-redux";

const SidebarDialog = ({ columns, visibleColumns, onClose, handleColumnVisibilityChange, isSidebarOpen }) => {
  const sidebarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);
  const { language } = useSelector((state) => state.auth);

  return (
    <div ref={sidebarRef} className={`sidebar-dialog ${isSidebarOpen ? "open" : ""} ${language === "arabic" ? "rtl" : "ltr"}`}>
      <div className="sidebar-content">
        <div className="columns-list mx-2">
          {columns.map((column, index) => (
            <div key={column} className="column-item d-flex align-items-center">
              <input
                className="d-flex align-items-center"
                type="checkbox"
                checked={visibleColumns.includes(column)}
                onChange={() => index !== 0 && handleColumnVisibilityChange(column)} // Disable change for first column
                disabled={index === 0} // Disable checkbox for the first column
              />
              <label className="mt-2 mx-2">{transformColumnName(column)}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarDialog;
