import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { LoadingDialog } from "../../../../_metronic/_partials/controls/LoadingDialog";
import axios from "axios";
import toast from "react-hot-toast";

const VerifyAccount = ({ onHide }) => {
  const history = useHistory();
  let { token } = useParams();

  useEffect(() => {
    if (token) {
      axios
        .get(`api/confirm/${token}`)
        .then((res) => {
          toast.success("Account verified");
          history.push("/logout");
        })
        .catch((error) => {
          toast.error("Error verifying account");
          onHide();
        });
    }
    // eslint-disable-next-line
  }, [token]);
  return (
    <>
      <div className="spinner spinner-lg spinner-success" />
      <LoadingDialog isLoading={true} text="Verifing your account ..." />
    </>
  );
};
export default VerifyAccount;
