import * as requestFromServer from "./customersCrud";
import TableUtils from "./../utils/table.utils";
import { customersSlice, callTypes } from "./customersSlice";
import toast from "react-hot-toast";
import moment from "moment";
import { getNumber } from "../../helpers/helpers";

const { actions } = customersSlice;

export const fetchCustomers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCustomers(queryParams)
    .then((response) => {
      const { toReturn } = response.data;
      let refactoredCustomers = [].concat(
        ...toReturn.map((it, index) => ({
          ...it?.customer,
          sr_no: getNumber(it?.customer?.customerNumber + 1),

          id: it?.customer._id,
          name: it?.customer.name.first + " " + it?.customer.name.last,
          email: it?.customer.email ? it?.customer.email : "",
          mobile: it?.customer.mobile,
          first_invoice: it?.firstInvoice?.created_at
            ? moment(it?.firstInvoice?.created_at).format("D MMM Y")
            : "",
          last_invoice: it?.lastInvoice?.created_at
            ? moment(it?.lastInvoice?.created_at).format("D MMM Y")
            : "",
          total_invoices: it?.totalInvoices,
          total_sales: it?.totalSales ? it?.totalSales : 0.0,
        }))
      );
      const tableUtils = new TableUtils();
      const filterdCustomers = queryParams
        ? tableUtils.baseFilter(refactoredCustomers, queryParams)
        : {
            totalCount: refactoredCustomers?.length
              ? refactoredCustomers?.length
              : 0,
            entities: refactoredCustomers,
          };

      dispatch(
        actions.customersFetched({
          totalCount: filterdCustomers.totalCount,
          entities: filterdCustomers.entities,
        })
      );
    })
    .catch((error) => {
      console.log(error);
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find customers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCustomer = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.customerFetched({ customerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCustomerById(id)
    .then((response) => {
      const { customer } = response.data;
      dispatch(actions.customerFetched({ customerForEdit: customer }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomer = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomer(id)
    .then((response) => {
      dispatch(actions.customerDeleted({ id }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCustomer = (
  customerForCreation,
  closeModal,
  showEmailError,
  showMobileError,
  queryParams
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCustomer(customerForCreation)
    .then((res) => {
      const { customer } = res.data;
      dispatch(actions.customerCreated({ newCustomer: customer }));
      dispatch(fetchCustomers(queryParams));
      toast.success("Customer Created");
      closeModal(false);
      showMobileError(false);
      showEmailError(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      if (
        error?.response?.data?.message === "Mobile Number is already in use"
      ) {
        showMobileError(true);
      } else if (
        error?.response?.data?.message === "Customer exists for this user"
      ) {
        showEmailError(true);
      }
      error.clientMessage = "Can't create customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomer = (
  customer,
  closeModal,
  showEmailError,
  showMobileError,
  queryParams
) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCustomer(customer)
    .then(() => {
      dispatch(actions.customerUpdated({ customer }));
      dispatch(fetchCustomers(queryParams));
      toast.success("Updated");
      closeModal(false);
      showMobileError(false);
      showEmailError(false);
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      if (
        error?.response?.data?.message === "Mobile Number is already in use"
      ) {
        showMobileError(true);
      } else if (
        error?.response?.data?.message === "Customer exists for this user"
      ) {
        showEmailError(true);
      }
      error.clientMessage = "Can't update customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForCustomers(ids, status)
    .then(() => {
      dispatch(actions.customersStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update customers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCustomers = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCustomers(ids)
    .then(() => {
      dispatch(actions.customersDeleted({ ids }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete customers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
