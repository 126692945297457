import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { DatePickerField } from "../../../_metronic/_partials/controls";
import IconClose from "../../../assets/img/icon-close.svg";
import { Radio } from "../../../_metronic/_partials/controls/forms/Radio";
import { Formik } from "formik";
import moment from "moment";
import { i18n } from "../../pages/private/languageSelector";

const initSelectDurDrawer = {
  startDate: "",
  endDate: "",
};

export const SelectDur = ({ openSelectDurDrawer, setOpenSelectDurDrawer, setDashboardDate, setSelectDurLabel }) => {
  const [disabledDate, setDisabledDate] = useState(false);
  const [customDate, setCustomDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [selectDate, setSelectDate] = useState({
    startDate: moment()
      .startOf("year")
      .format("MM/DD/YYYY"),
    endDate: moment()
      .endOf("year")
      .format("MM/DD/YYYY"),
  });

  const dur = [
    { id: "today", label: i18n("DASHBOARD.Today"), name: "select-dur" },
    { id: "yesterday", label: i18n("DASHBOARD.Yesterday"), name: "select-dur" },
    { id: "thisWeek", label: i18n("DASHBOARD.ThisWeek"), name: "select-dur" },
    { id: "lastWeek", label: i18n("DASHBOARD.LastWeek"), name: "select-dur" },
    { id: "thisMonth", label: i18n("DASHBOARD.ThisMonth"), name: "select-dur" },
    { id: "lastMonth", label: i18n("DASHBOARD.LastMonth"), name: "select-dur" },
    { id: "thisYear", label: i18n("DASHBOARD.ThisYear"), name: "select-dur" },
    { id: "lastYear", label: i18n("DASHBOARD.LastYear"), name: "select-dur" },
    { id: "custom", label: i18n("DASHBOARD.Custom"), name: "select-dur" },
  ];
  const defineDate = (value) => {
    setSelectDurLabel(value);
    if (value === "today") {
      setSelectDate({
        startDate: moment().format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY"),
      });
    } else if (value === "yesterday") {
      setSelectDate({
        startDate: moment()
          .subtract(1, "days")
          .format("MM/DD/YYYY"),
        endDate: moment()
          .subtract(1, "days")
          .format("MM/DD/YYYY"),
      });
    } else if (value === "thisWeek") {
      setSelectDate({
        startDate: moment()
          .startOf("week")
          .format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY"),
      });
    } else if (value === "lastWeek") {
      setSelectDate({
        startDate: moment()
          .subtract(1, "weeks")
          .startOf("week")
          .format("MM/DD/YYYY"),
        endDate: moment()
          .subtract(1, "weeks")
          .endOf("week")
          .format("MM/DD/YYYY"),
      });
    } else if (value === "thisMonth") {
      setSelectDate({
        startDate: moment()
          .date(1)
          .format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY"),
      });
    } else if (value === "lastMonth") {
      setSelectDate({
        startDate: moment()
          .subtract(1, "months")
          .date(1)
          .format("MM/DD/YYYY"),
        endDate: moment()
          .subtract(1, "months")
          .date(30)
          .format("MM/DD/YYYY"),
      });
    } else if (value === "thisYear") {
      setSelectDate({
        startDate: moment()
          .startOf("year")
          .format("MM/DD/YYYY"),
        endDate: moment().format("MM/DD/YYYY"),
      });
    } else if (value === "lastYear") {
      setSelectDate({
        startDate: moment()
          .subtract(1, "year")
          .startOf("year")
          .format("MM/DD/YYYY"),
        endDate: moment()
          .subtract(1, "year")
          .endOf("year")
          .format("MM/DD/YYYY"),
      });
    }
  };
  useEffect(() => {
    setDashboardDate(selectDate);
    setOpenSelectDurDrawer(false);
    // eslint-disable-next-line
  }, [selectDate]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initSelectDurDrawer}
        onSubmit={(values) => {
          let newDate = {
            startDate: moment(customDate.startDate).format("MM/DD/YYYY"),
            endDate: moment(customDate.endDate).format("MM/DD/YYYY"),
          };
          setDashboardDate(newDate);
          setOpenSelectDurDrawer(false);
        }}>
        {({ handleSubmit, values, setValues, setFieldValue }) => (
          <Modal
            className="modal-drawer select-dur"
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            show={openSelectDurDrawer}
            onHide={() => {}}>
            <Modal.Header>
              <button type="button" className="btn btn-close" onClick={() => setOpenSelectDurDrawer(false)}>
                <img src={IconClose} alt="loading" />
              </button>
              <Modal.Title>{i18n("DASHBOARD.SelectDuration")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              {dur?.map((item, index) => {
                return (
                  <Radio
                    id={item.id}
                    name={item.name}
                    label={item.label}
                    key={index}
                    value={item.name}
                    onChange={(e) => {
                      e.target.id === "custom" ? setDisabledDate(true) : setDisabledDate(false);
                      defineDate(e.target.id);
                    }}
                  />
                );
              })}
              {disabledDate && (
                <>
                  <div className="date-range">
                    <DatePickerField
                      name="startDate"
                      label="Start Date"
                      placeholderText={"Start Date"}
                      selected={values.startDate}
                      onChange={(val) => {
                        setFieldValue("startDate", val);
                        setCustomDate({
                          ...customDate,
                          startDate: moment(val).format("MM/DD/YYYY"),
                        });
                      }}
                      withFeedbackLabel={false}
                    />
                    <DatePickerField
                      name="endDate"
                      label="End Date"
                      placeholderText={"End Date"}
                      selected={values.endDate}
                      onChange={(val) => {
                        setFieldValue("endDate", val);
                        setCustomDate({
                          ...customDate,
                          endDate: moment(val).format("MM/DD/YYYY"),
                        });
                      }}
                      withFeedbackLabel={false}
                    />
                  </div>
                  <div className="btn-bar py-5">
                    <button type="submit" onClick={() => handleSubmit()} className="btn btn-pri">
                      {i18n("DASHBOARD.Apply")}
                    </button>
                  </div>
                </>
              )}
            </Modal.Body>
          </Modal>
        )}
      </Formik>
    </>
  );
};
