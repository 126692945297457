import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SUBSCRIPTION_URL = "api";

export const getSubscriptionInvoices = createAsyncThunk(
  "subscriptionInvoices/getAllSubscriptionsInvoices",
  async ({ page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${SUBSCRIPTION_URL}/subscriptionInvoice/All?page=${page}&limit=${limit}`
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue(errorMessage);
    }
  }
);

export const getSubscriptionInvoice = createAsyncThunk(
  "subscriptionInvoices/subscriptionInvoice",
  async ({ invoiceId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${SUBSCRIPTION_URL}/subscriptionInvoice/${invoiceId}`
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      return rejectWithValue(errorMessage);
    }
  }
);
