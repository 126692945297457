import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "../../../../../_metronic/_partials/controls/forms/Radio";
import * as Yup from "yup";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconReset from "../../../../../assets/img/reset.png";
import ConfirmationDialoge from "../../../confirmationDialoge";
import * as rolesActions from "../../../../../_redux/roles/rolesActions";
import * as usersActions from "../../../../../_redux/users/usersActions";
import {
  Input,
  SwitchToggler,
} from "../../../../../_metronic/_partials/controls";
import {
  POS_OPTIONS,
  BACK_OFFICE_OPTIONS,
  POS_OPTIONS_DEFAULT,
  BACK_OFFICE_OPTIONS_DEFAULT,
  POS_OPTIONS_MANAGER,
  BACK_OFFICE_OPTIONS_MANAGER,
  POS_OPTIONS_CASHIER,
  BACK_OFFICE_OPTIONS_CASHIER,
} from "./rolesConstants";
import cloneDeep from "lodash/cloneDeep";
import SubscriptionDialog from "../SubscriptionDialog";
import { i18n } from "../../../private/languageSelector";

// Validation schema
export const AddRoleSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(20, "Maximum 20 Alphabets")
    .required("Name is required"),
});

const initAddState = {
  name: "",
  pos: false,
  backOffice: false,
};

export function RolesEditForm({
  edit,
  disabled,
  roleForEdit,
  nameDisabled,
  openRolesAddDialog,
  setOpenRolesAddDialog,
}) {
  const dispatch = useDispatch();

  const [usersWithRole, setUsersWithRole] = useState([]);
  const [roleForEditing, setRoleForEditing] = useState({});
  const [showPosOptions, setShowPosOptions] = useState(false);
  const [usersIdsWithRole, setUsersIdsWithRole] = useState([]);
  const [posOptions, setPosOptions] = useState([...POS_OPTIONS]);
  const [showConfirmChanges, setShowConfirmChanges] = useState(false);
  const [showDefaultChanges, setShowDefaultChanges] = useState(false);
  const [showBackOfficeOptions, setShowBackOfficeOptions] = useState(false);
  const [backOfficeOptions, setBackOfficeOptions] = useState([
    ...BACK_OFFICE_OPTIONS,
  ]);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const { users } = useSelector((state) => ({
    users: state.users.entities,
  }));

  useEffect(() => {
    if (roleForEdit && edit) {
      dispatch(usersActions.fetchUsers());

      let backOfficeData = cloneDeep(
        Object.values(roleForEdit.backOfficePayload)
      );
      let posData = cloneDeep(Object.values(roleForEdit.posPayload));

      setBackOfficeOptions([...backOfficeData]);
      setPosOptions([...posData]);

      if (roleForEdit.backOffice) {
        setShowBackOfficeOptions(true);
      }
      if (roleForEdit.pos) {
        setShowPosOptions(true);
      }
      setRoleForEditing(cloneDeep(roleForEdit));
    } else {
      setBackOfficeOptions([...BACK_OFFICE_OPTIONS]);
      setPosOptions([...POS_OPTIONS]);
      setShowBackOfficeOptions(false);
      setShowPosOptions(false);
    }
  }, [dispatch, roleForEdit, openRolesAddDialog, edit]);

  // Filtering users with the role
  useEffect(() => {
    if (roleForEdit && users?.length) {
      let data = users.filter((item) => {
        return item.role === roleForEdit._id;
      });
      setUsersWithRole(data);
    }
  }, [roleForEdit, openRolesAddDialog, users]);

  // Selecting filtered users ids
  useEffect(() => {
    let usersIds = [];
    if (usersWithRole?.length) {
      usersWithRole.forEach((user) => {
        return usersIds.push(user._id);
      });
    }
    setUsersIdsWithRole(usersIds);
  }, [usersWithRole]);

  const resetDefaultHandler = () => {
    if (roleForEdit.originalName === "owner") {
      // restore default owner role
      setRoleForEditing({ ...roleForEditing, name: "owner" });
      setShowPosOptions(true);
      setShowBackOfficeOptions(true);
      setPosOptions([...POS_OPTIONS_DEFAULT]);
      setBackOfficeOptions([...BACK_OFFICE_OPTIONS_DEFAULT]);
    } else if (roleForEdit.originalName === "manager") {
      // restore default manager role
      setRoleForEditing({ ...roleForEditing, name: "manager" });
      setShowPosOptions(true);
      setShowBackOfficeOptions(true);
      setPosOptions([...POS_OPTIONS_MANAGER]);
      setBackOfficeOptions([...BACK_OFFICE_OPTIONS_MANAGER]);
    } else if (roleForEdit.originalName === "cashier") {
      // restore default cashier role
      setRoleForEditing({ ...roleForEditing, name: "cashier" });
      setShowPosOptions(true);
      setShowBackOfficeOptions(true);
      setPosOptions([...POS_OPTIONS_CASHIER]);
      setBackOfficeOptions([...BACK_OFFICE_OPTIONS_CASHIER]);
    }
  };

  const handleClosePaymentDialog = (dirty, resetForm) => {
    if (dirty) {
      setShowUnsavedChangesModal(true);
    } else {
      setOpenRolesAddDialog(false);
      setPosOptions([...POS_OPTIONS]);
      setBackOfficeOptions([...BACK_OFFICE_OPTIONS]);
      setShowBackOfficeOptions(false);
      setShowPosOptions(false);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={edit ? roleForEditing : initAddState}
        validationSchema={AddRoleSchema}
        onSubmit={(values, { resetForm }) => {
          if (roleForEdit && edit) {
            dispatch(
              rolesActions.updateRole(roleForEdit._id, {
                name: values.name,
                pos: values.pos,
                backOffice: values.backOffice,
                backOfficePayload: [...backOfficeOptions],
                posPayload: [...posOptions],
              })
            );
            if (usersIdsWithRole?.length) {
              dispatch(
                usersActions.updateUsersWithRole(usersIdsWithRole, {
                  name: values.name,
                  originalName: roleForEdit?.originalName,
                  pos: values.pos,
                  backOffice: values.backOffice,
                  backOfficePayload: [...backOfficeOptions],
                  posPayload: [...posOptions],
                })
              );
            }
          } else {
            dispatch(
              rolesActions.createRole({
                ...values,
                originalName: values.name,
                backOfficePayload: [...backOfficeOptions],
                posPayload: [...posOptions],
              })
            );
          }
          // Resetting Form Values
          resetForm();
          setPosOptions([...POS_OPTIONS]);
          setBackOfficeOptions([...BACK_OFFICE_OPTIONS]);
          setShowBackOfficeOptions(false);
          setShowPosOptions(false);
          setOpenRolesAddDialog(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue, resetForm, dirty }) => {
          if (values.name) {
            setIsFormFilled(true);
          }
          return (
            <>
              <Modal
                className="modal-drawer invoice-drawer"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={openRolesAddDialog}
                onHide={() => handleClosePaymentDialog(dirty, resetForm)}
              >
                <Modal.Header>
                  <div className="d-flex justify-content-around align-items-center">
                    <span
                      onClick={() => handleClosePaymentDialog(dirty, resetForm)}
                      className="cursor-pointer"
                    >
                      <img src={IconClose} alt="loading" />
                    </span>
                    <span>
                      {edit === false ? (
                        <span className="add-edit-tax">Add Role</span>
                      ) : (
                        <span className="add-edit-tax">Edit Role</span>
                      )}
                    </span>
                    <span
                      onClick={handleSubmit}
                      className="add-edit-tax cursor-pointer"
                      style={{
                        color: isFormFilled && "#FFFFFF",
                        opacity: isFormFilled ? 1 : 0.5,
                      }}
                    >
                      Save
                    </span>
                  </div>
                </Modal.Header>

                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form className="form form-label-right">
                    <div className="form-group">
                      <Field
                        name="name"
                        component={Input}
                        disabled={nameDisabled}
                        label={"Role Name"}
                        placeholder={"Role Name"}
                        withFeedbackLabel={true}
                      />
                    </div>

                    <div className="d-flex justify-content-between">
                      <h6 className="mt-4">Back Office</h6>
                      <div style={{ marginTop: "-7px" }}>
                        <SwitchToggler
                          className="default-switch"
                          id="backOffice"
                          name="backOffice"
                          value="backOffice"
                          disabled={disabled}
                          checked={showBackOfficeOptions || false}
                          onChange={(e) => {
                            setShowBackOfficeOptions(e.target.checked);
                            setFieldValue("backOffice", e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    <p className="text-muted">
                      Employees can log in to the back office using their email
                      and password
                    </p>

                    {showBackOfficeOptions &&
                      backOfficeOptions.map((item, index) => (
                        <div key={index}>
                          <div>
                            <Field
                              name={item.name}
                              component={Checkbox}
                              checked={item.selected}
                              disabled={disabled}
                              onChange={(e) => {
                                let newOption = {
                                  ...item,
                                  selected: e.target.checked,
                                };
                                backOfficeOptions[index] = newOption;
                                setBackOfficeOptions([...backOfficeOptions]);
                              }}
                            />
                            {item.name}
                          </div>
                          {item.selected &&
                            item?.subItem?.length &&
                            item.subItem.map((it, ind) => (
                              <div className="d-flex ml-4" key={ind}>
                                <Field
                                  name={it.name}
                                  component={Checkbox}
                                  checked={it.selected}
                                  disabled={
                                    it.name === "Products" ? true : disabled
                                  }
                                  onChange={(e) => {
                                    item.subItem[ind] = {
                                      ...item.subItem[ind],
                                      selected: e.target.checked,
                                    };
                                    setBackOfficeOptions([
                                      ...backOfficeOptions,
                                    ]);
                                  }}
                                />
                                <div className="mt-3">{it.name}</div>
                              </div>
                            ))}
                        </div>
                      ))}

                    <div className="d-flex justify-content-between">
                      <h6 className="mt-4">POS</h6>
                      <div style={{ marginTop: "-7px" }}>
                        <SwitchToggler
                          className="default-switch"
                          id="Pos"
                          name="pos"
                          value="Pos"
                          disabled={disabled}
                          checked={showPosOptions || false}
                          onChange={(e) => {
                            setShowPosOptions(e.target.checked);
                            setFieldValue("pos", e.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    <p className="text-muted">
                      (Users can log in to the app using a personal PIN code)
                    </p>

                    {showPosOptions &&
                      posOptions.map((item, index) => (
                        <div key={index}>
                          <div>
                            <Field
                              name={item.name}
                              disabled={
                                disabled
                                  ? disabled
                                  : item.name === "View Invoices"
                              }
                              component={Checkbox}
                              checked={item.selected}
                              onChange={(e) => {
                                posOptions[index] = {
                                  ...item,
                                  selected: e.target.checked,
                                };
                                setPosOptions([...posOptions]);
                              }}
                            />
                            {item.name}
                          </div>
                          {item.selected &&
                            item?.subItem?.length &&
                            item.subItem.map((it, ind) => (
                              <div className="ml-7" key={ind}>
                                <Radio
                                  id={it.name}
                                  name="invoice"
                                  label={it.name}
                                  value={it.name}
                                  disabled={disabled}
                                  isSelected={it.selected}
                                  onChange={(e) => {
                                    let originalData = [
                                      {
                                        name: "Only Last 5",
                                        selected: false,
                                      },
                                      {
                                        name: "All Invoices",
                                        selected: false,
                                      },
                                    ];
                                    item.subItem = originalData;
                                    item.subItem[ind] = {
                                      ...it,
                                      selected: true,
                                    };
                                    setPosOptions([...posOptions]);
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      ))}

                    {edit && (
                      <div
                        className="row ml-3"
                        style={{ color: "#2B8EC6" }}
                        onClick={() => {
                          setShowDefaultChanges(true);
                        }}
                      >
                        <img src={IconReset} alt="loading" />
                        <div className="cursor-pointer mt-1 ml-3">
                          Reset All to default
                        </div>
                      </div>
                    )}

                    {/* Unsaved changes modal */}
                    <SubscriptionDialog
                      modalWidth={"350px"}
                      modalHeight={"185px"}
                      modalBackgroundColor={"#FFFFFF"}
                      show={showUnsavedChangesModal}
                      onHide={() => setShowUnsavedChangesModal(false)}
                      title={i18n("SubscriptionModals.UnsavedChanges")}
                      titleWidth={"140px"}
                      titleHeight={"20px"}
                      titleFontWeight={"700"}
                      titleFontSize={"16px"}
                      titleLineHeight={"20px"}
                      titleColor={"#333333"}
                      message={i18n(
                        "SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges"
                      )}
                      messageWidth={"320px"}
                      messageHeight={"44px"}
                      messageFontSize={"16px"}
                      messageFontWeight={"400"}
                      messageLineHeight={"21.79px"}
                      messageColor={"#333333"}
                      primaryLabel={i18n("SubscriptionModals.CANCEL")}
                      primaryAction={() => setShowUnsavedChangesModal(false)}
                      primaryLabelWidth={"55px"}
                      primaryLabelHeight={"20px"}
                      primaryLabelFontWeight={"700"}
                      primaryLabelFontSize={"14px"}
                      primaryLabelLineHeight={"20px"}
                      primaryLabelColor={"#828282"}
                      secondaryAction={() => {
                        setShowUnsavedChangesModal(false);
                        setOpenRolesAddDialog(false);
                        setIsFormFilled(false);
                        resetForm();
                      }}
                      secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
                      secondaryLabelWidth={"75px"}
                      secondaryLabelHeight={"20px"}
                      secondaryLabelFontWeight={"700"}
                      secondaryLabelFontSize={"14px"}
                      secondaryLabelLineHeight={"20px"}
                      secondaryLabelColor={"#2D9CDB"}
                    />
                  </Form>
                </Modal.Body>

                <Modal.Footer>
                  <div className="row justify-content-center">
                    {edit ? (
                      <button
                        type="submit"
                        onClick={() => {
                          setShowConfirmChanges(true);
                        }}
                        className="btn btn-primary btn-elevate f-button"
                        style={{ display: "block" }}
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        className="btn btn-primary btn-elevate f-button ml-5"
                        style={{ display: "block" }}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </Modal.Footer>

                {/* Confirmation modals */}
                <ConfirmationDialoge
                  show={showConfirmChanges}
                  title="Confirm Changes"
                  subTitle={`You have made some changes to the permissions related to "${roleForEdit?.name}" role, are you sure you want to permanently save these changes?`}
                  primaryAction={handleSubmit}
                  secondaryAction={() => setShowConfirmChanges(false)}
                  primaryActionTitle="Confirm"
                  secondaryActionTitle="Cancel"
                  classes="modal-drawer"
                  heading="Confirm Changes!"
                  viewButton={true}
                />
                <ConfirmationDialoge
                  show={showDefaultChanges}
                  title="Confirm Reset to Default!"
                  subTitle="Are you sure you want to reset to default?"
                  primaryAction={() => resetDefaultHandler()}
                  secondaryAction={() => setShowDefaultChanges(false)}
                  primaryActionTitle="Confirm"
                  secondaryActionTitle="Cancel"
                  classes="modal-drawer"
                  heading="Confirm Reset to default!"
                  viewButton={true}
                />
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
}
