import * as requestFromServer from "./invoicesCrud";
import TableUtils from "./../utils/table.utils";
import { invoicesSlice, callTypes } from "./invoicesSlice";
import moment from "moment";
import toast from "react-hot-toast";
import { getNumber } from "../../helpers/helpers";
const { actions } = invoicesSlice;

export const fetchInvoices = (state, queryParams) => (dispatch) => {
  dispatch(filterInvoice(state, queryParams));
  // dispatch(actions.startCall({ callType: callTypes.list }));
  // return requestFromServer
  //   .getFilteredInvoices(state)
  //   .then((response) => {
  //     const { invoices } = response.data;
  //     const tableUtils = new TableUtils();

  //     let refractoredInvoices = [].concat(
  //       ...invoices.map((it) => ({
  //         id: it?._id,
  //         _id: it?._id,
  //         invoice: getNumber(it?.invoiceNumber),

  //         date: moment(it?.invoiceData?.invoiceDate).format("MM/DD/YYYY"),
  //         paid:
  //           it?.invoiceData?.channel === "web"
  //             ? it?.invoiceData?.paidTotal
  //               ? Number(it?.invoiceData?.paidTotal)
  //               : 0
  //             : it?.invoiceData?.channel === "pos"
  //             ? it?.invoiceData?.paidTotal &&
  //               Number(it?.invoiceData?.change) > 0
  //               ? Number(it?.invoiceData?.paidTotal) -
  //                 Number(it?.invoiceData?.change)
  //               : Number(it?.invoiceData?.paidTotal)
  //             : 0,
  //         balance:
  //           it?.invoiceData?.balance || it?.invoiceData?.balance === 0
  //             ? Number(it?.invoiceData?.balance)
  //             : it?.invoiceData?.total
  //             ? Number(it?.invoiceData?.total)
  //             : 0,
  //         totalSales:
  //           it?.invoiceData?.channel === "web"
  //             ? it?.invoiceData?.totalSales
  //               ? Number(it?.invoiceData?.totalSales)
  //               : 0
  //             : it?.invoiceData?.channel === "pos"
  //             ? it?.invoiceData?.totalaftertax
  //               ? Number(it?.invoiceData?.totalaftertax)
  //               : 0
  //             : 0,
  //         status: it?.status,
  //         invoiceType: it?.invoiceData?.invoiceType,
  //         customerName: it?.invoiceData?.receiverInformation?.name?.first
  //           ? it?.invoiceData?.receiverInformation?.name?.first +
  //             " " +
  //             it?.invoiceData?.receiverInformation?.name?.last
  //           : it?.invoiceData?.receiverInformation?.name
  //           ? it?.invoiceData?.receiverInformation?.name
  //           : "",
  //         issuedBy: it?.invoiceData?.senderInformation
  //           ? it?.invoiceData?.senderInformation?.name?.first +
  //             " " +
  //             it?.invoiceData?.senderInformation?.name?.last
  //           : "",
  //         pos: "-",
  //         type: it?.invoiceData?.invoiceType,
  //         mobile: it?.invoiceData?.receiverInformation?.mobile,
  //         customerEmail: it?.invoiceData?.receiverInformation?.email,
  //         channel: it?.invoiceData?.channel,
  //         store: it?.invoiceData?.store?.name
  //           ? it?.invoiceData?.store?.name
  //           : "-",
  //       })),
  //     );

  //     const filterdInvoices = tableUtils.baseFilter(
  //       refractoredInvoices,
  //       queryParams,
  //     );
  //     dispatch(
  //       actions.invoicesFetched({
  //         totalCount: filterdInvoices.totalCount,
  //         entities: filterdInvoices.entities,
  //       }),
  //     );
  //   })
  //   .catch((error) => {
  //     toast.error(error?.response?.data?.message);
  //     error.clientMessage = "Can't find invoices";
  //     dispatch(actions.catchError({ error, callType: callTypes.list }));
  //   });
};

export const fetchInvoice = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.invoiceFetched({ invoiceForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getInvoiceById(id)
    .then((response) => {
      const { invoice } = response.data;

      const handlingProducts = (invoice) => {
        if (invoice.invoiceData?.channel === "web") {
          return invoice.invoiceData?.products;
        } else if (invoice.invoiceData?.channel === "pos") {
          let refactoredProducts = invoice.invoiceData?.products.map((item) => {
            return {
              _id: item._id,
              discount: 0,
              isPrice: true,
              description: "",
              rate: item?.rate,
              taxable: item?.taxable,
              barcode: item?.barcode,
              price: item?.basePrice,
              name: item?.englishName,
              totalPrice: item?.price,
              quantity: item?.quantity,
              vat: item?.taxes?.length > 0,
              taxes: item?.taxes,
              discounts: item?.discounts,
            };
          });
          return refactoredProducts;
        }
      };

      let refactoredProducts = handlingProducts(invoice);
      let newInvoice = {
        ...invoice,
        invoiceNumber: getNumber(invoice?.invoiceNumber),
        parentInvoiceNumber:
          invoice?.invoiceData?.invoiceType === "refund" ? getNumber(invoice?.parentInvoiceNumber) : 0,
      };

      let testInvoice = {
        ...newInvoice,
        invoiceData: {
          ...newInvoice.invoiceData,
          products: refactoredProducts,
          customerName: invoice?.invoiceData?.receiverInformation?.name?.first
            ? invoice?.invoiceData?.receiverInformation?.name?.first +
              " " +
              invoice?.invoiceData?.receiverInformation?.name?.last
            : invoice?.invoiceData?.receiverInformation?.name
            ? invoice?.invoiceData?.receiverInformation?.name
            : "",
          generalNotes: invoice?.invoiceData?.generalNotes ? invoice?.invoiceData?.generalNotes : "",
        },
      };
      dispatch(actions.invoiceFetched({ invoiceForEdit: testInvoice }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find invoice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const filterInvoice = (data, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getFilteredInvoices(data)
    .then((response) => {
      const { invoices } = response.data;

      const tableUtils = new TableUtils();

      let refactoredInvoices = [].concat(
        ...invoices.map((it) => ({
          id: it?._id,
          _id: it?._id,

          invoice:
            it?.invoiceData?.channel === "web"
              ? `${it?.invoiceData?.store?.identityPrefix}0-${getNumber(it?.invoiceNumber)}`
              : it?.invoiceData?.channel === "pos"
              ? it?.invoiceData?.invoiceNumber
              : it?.invoiceNumber,
          // invoice: getNumber(it?.invoiceNumber),
          date: moment(it?.invoiceData?.invoiceDate).format("MM/DD/YYYY"),
          paid:
            it?.invoiceData?.channel === "web"
              ? it?.invoiceData?.paidTotal
                ? Number(it?.invoiceData?.paidTotal)
                : 0
              : it?.invoiceData?.channel === "pos"
              ? it?.invoiceData?.paidTotal && Number(it?.invoiceData?.change) > 0
                ? Number(it?.invoiceData?.paidTotal) - Number(it?.invoiceData?.change)
                : Number(it?.invoiceData?.paidTotal)
              : 0,
          balance:
            it?.invoiceData?.balance || it?.invoiceData?.balance === 0
              ? Number(it?.invoiceData?.balance)
              : it?.invoiceData?.total
              ? Number(it?.invoiceData?.total)
              : 0.0,
          totalSales:
            it?.invoiceData?.channel === "web"
              ? it?.invoiceData?.totalSales
                ? Number(it?.invoiceData?.totalSales)
                : 0.0
              : it?.invoiceData?.channel === "pos"
              ? it?.invoiceData?.totalaftertax
                ? Number(it?.invoiceData?.totalaftertax)
                : 0.0
              : 0.0,
          status: it?.status ? it?.status : "",
          invoiceType: it?.invoiceData?.invoiceType ? it?.invoiceData?.invoiceType : "",
          customerName:
            it?.invoiceData?.channel === "pos"
              ? it?.invoiceData?.receiverInformation?.name?.first
                ? it?.invoiceData?.receiverInformation?.name?.first +
                  " " +
                  it?.invoiceData?.receiverInformation?.name?.last
                : ""
              : it?.invoiceData?.channel === "web"
              ? it?.invoiceData?.receiverInformation?.name
                ? it?.invoiceData?.receiverInformation?.name
                : ""
              : "",
          issuedBy:
            it?.invoiceData?.channel === "web"
              ? it?.invoiceData?.senderInformation?.name?.first
                ? it?.invoiceData?.senderInformation?.name?.first + " " + it?.invoiceData?.senderInformation?.name?.last
                : ""
              : it?.invoiceData?.channel === "pos"
              ? it?.invoiceData?.activeuser
                ? it?.invoiceData?.activeuser
                : ""
              : "",
          pos: "-",
          type: it?.invoiceData?.invoiceType ? it?.invoiceData?.invoiceType : "",
          mobile: it?.invoiceData?.receiverInformation?.mobile ? it?.invoiceData?.receiverInformation?.mobile : "",
          customerEmail: it?.invoiceData?.receiverInformation?.email ? it?.invoiceData?.receiverInformation?.email : "",
          channel: it?.invoiceData?.channel ? it?.invoiceData?.channel : "",
          store: it?.invoiceData?.store?.name ? it?.invoiceData?.store?.name : "-",
        }))
      );
      const filterdInvoices = tableUtils.baseFilter(refactoredInvoices, queryParams);
      dispatch(
        actions.invoicesFetched({
          totalCount: filterdInvoices.totalCount,
          entities: filterdInvoices.entities,
          filterOn: false,
        })
      );
    })
    .catch((error) => {
      console.log(error);
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't filter invoices";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteInvoice = (id, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteInvoice(id, status)
    .then((response) => {
      dispatch(actions.invoiceDeleted({ id }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete invoice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createInvoice = (invoiceFilterState, invoiceForCreation, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createInvoice(invoiceForCreation)
    .then((response) => {
      const { invoice } = response.data;

      dispatch(actions.invoiceCreated({ invoice: { ...invoice, id: invoice?._id } }));
      dispatch(fetchInvoices(invoiceFilterState, queryParams));

      toast.success("Invoice Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateInvoice = (invoiceFilterState, invoice, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateInvoice(invoice)
    .then(() => {
      dispatch(actions.invoiceUpdated({ invoice }));
      dispatch(fetchInvoices(invoiceFilterState, queryParams));
      toast.success("Invoice Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update invoice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateInvoiceFromActionDialog = (invoiceFilterState, invoice, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateInvoiceFromDialog(invoice)
    .then(() => {
      dispatch(fetchInvoices(invoiceFilterState, queryParams));
      dispatch(fetchInvoice(invoice.id));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update invoice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRefundInvoice = (invoiceFilterState, invoice, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRefundInvoice(invoice)
    .then(() => {
      dispatch(fetchInvoices(invoiceFilterState, queryParams));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update invoice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateInvoiceRefund = (invoice) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .invoiceRefundUpdate(invoice)
    .then(() => {
      dispatch(actions.invoiceUpdated({ invoice }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update invoice";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateInvoicesStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForInvoices(ids, status)
    .then(() => {
      dispatch(actions.invoicesStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update invoices status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteInvoices = (invoiceFilterState, ids, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteInvoices(ids)
    .then(() => {
      dispatch(actions.invoicesDeleted({ ids }));
      dispatch(fetchInvoices(invoiceFilterState, queryParams));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't delete invoices";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchInvoicesByDate = (date) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.getInvoiceByDate(date);
  // .then((response) => {
  //   dispatch(actions.invoicesFetchedByDate({ entities: response }));
  // })
  // .catch((error) => {
  //   error.clientMessage = "Can't find invoice";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};

export const getNotifications = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNotifications()
    .then((response) => {
      const { notifications } = response.data;

      dispatch(
        actions.notificationsFetched({
          totalCount: notifications.length,
          entities: notifications,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find Notifications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const readSingleNotification = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .readSingleNotification(id)
    .then((response) => {
      dispatch(getNotifications());
      toast.success("Notification Read");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't read notification";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const readAllNotification = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .readAllNotifications(ids)
    .then((response) => {
      dispatch(getNotifications());
      toast.success("Notifications Read");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't read notification";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getInvoiceForLineChart = (startDate, endDate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.getInvoicesforLineChart(startDate, endDate).catch((error) => {
    toast.error(error?.response?.data?.message);
    error.clientMessage = "Can't update invoice";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  });
};

export const setInvoiceFilterState = (state) => (dispatch) => {
  dispatch(actions.invoiceFilterSet({ invoiceFilters: state }));
};

export const clearInvoiceFilterState = () => (dispatch) => {
  dispatch(actions.invoiceFilterClear({ invoiceFilters: undefined }));
};

export const setInvoiceFilterOnState = (state) => (dispatch) => {
  dispatch(actions.invoiceFilterOn({ filterOn: state }));
};
