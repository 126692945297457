import React from "react";

export const TermsAndConditions = () => {
  return (
    <div className="text-center" style={{ backgroundColor: "white", height: "100%" }}>
      <h1>Welcome To Fatoraty Terms And Conditions</h1>
      <div>Terms And Conditions</div>
    </div>
  );
};
