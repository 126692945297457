import { createSlice } from "@reduxjs/toolkit";

const initialCategoriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  categoryForEdit: undefined,
  singleCategory: undefined,
  lastError: null,
  navigate: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialCategoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCategoryById
    categoryFetched: (state, action) => {
      state.actionsLoading = false;
      state.singleCategory = {
        ...action.payload.categoryForEdit,
        //name: action.payload.categoryForEdit?.englishName,
      };
      state.error = null;
    },
    // findCategories
    categoriesFetched: (state, action) => {
      const { categoryForEdit } = action.payload;
      state.listLoading = false;
      state.error = null;
      //   state.entities = entities;
      //   state.totalCount = totalCount;
      state.categoryForEdit = categoryForEdit;
    },
    // createCategory
    categoryCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.navigate = true;
      // state.entities.push(action.payload.category);
    },
    // updateCategory
    categoryUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
      // state.entities = state.entities.map((entity) => {
      //   if (entity._id === action.payload.category._id) {
      //     return action.payload.category;
      //   }
      //   return entity;
      // });
    },
    // deleteCategory
    categoryDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.filter(
      //   (el) => el.id !== action.payload.id
      // );
    },
  },
});
