import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconTrash from "../../../../../assets/img/icon-trash.svg";
import IconPen from "../../../../../assets/img/icon-pen.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as taxesActions from "../../../../../_redux/taxes/taxesActions";
import ConfirmationDialoge from "../../../confirmationDialoge";
import { TaxesEditForm } from "./TaxesEditForm";

export const ManageTaxes = ({ taxes, userAccess, openTaxesManageDialog, setOpenTaxesManageDialog }) => {
  const dispatch = useDispatch();

  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteTaxId, setDeleteTaxId] = useState("");
  const [openTaxesAddDialog, setOpenTaxesAddDialog] = useState(false);

  const { taxForEdit } = useSelector(
    (state) => ({
      taxForEdit: state.taxes?.taxForEdit,
    }),
    shallowEqual
  );
  const deleteTaxHandler = () => {
    dispatch(taxesActions.deleteTax(deleteTaxId));
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openTaxesManageDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenTaxesManageDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Manage TAX</Modal.Title>
        </Modal.Header>
        <Formik>
          {({ handleSubmit }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <ul className="card-listitem list-unstyled">
                    <div className="h4 d-flex justify-content-between">
                      <b>Defined Taxes</b>
                      <b>Actions</b>
                    </div>
                    {taxes?.map((tax, i) => {
                      return (
                        <li key={i}>
                          <div className="card-info">
                            <b className="heading-color truncate-160 h6">{tax.name}</b>
                            <p className="sub-heading-color mb-0">Default Scheme: {tax.taxScheme}</p>
                            <p className="sub-heading-color mb-0">{tax.type}</p>
                          </div>
                          <div className="card-actions ml-auto">
                            {userAccess?.editData ? (
                              <span className="action action-edit">
                                <img
                                  title="Edit"
                                  src={IconPen}
                                  alt="loading"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    dispatch(taxesActions.fetchTax(tax._id));
                                    setOpenTaxesAddDialog(true);
                                    setEdit(true);
                                  }}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                            {userAccess?.deleteData ? (
                              <span className="action action-delete">
                                <img
                                  title="Delete"
                                  style={{ cursor: "pointer" }}
                                  src={IconTrash}
                                  alt="loading"
                                  onClick={() => {
                                    setDeleteTaxId(tax._id);
                                    setConfirmDialogForClose(true);
                                  }}
                                />
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <>
          <Modal.Footer>
            <div className="row justify-content-center">
              <button
                className="btn btn-outline-primary btn-elevate mr-5"
                onClick={() => {
                  setOpenTaxesManageDialog(false);
                }}>
                Back
              </button>
              {userAccess?.createData ? (
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenTaxesAddDialog(true);
                    setEdit(false);
                  }}>
                  Add New
                </button>
              ) : (
                ""
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal>
      <TaxesEditForm
        edit={edit}
        tax={edit ? taxForEdit : undefined}
        openTaxesAddDialog={openTaxesAddDialog}
        setOpenTaxesAddDialog={setOpenTaxesAddDialog}
      />
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title="Delete Tax"
        subTitle="Are you sure you want to delete this tax"
        primaryAction={() => deleteTaxHandler()}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle="Delete"
        secondaryActionTitle="Back"
        classes="modal-drawer"
        heading="Delete Tax!"
        headingColor="#ED553B"
        viewButon={true}
      />
    </>
  );
};
