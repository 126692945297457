import React from "react";
import { Route, Switch } from "react-router-dom";
import { InvoicesCard } from "./InvoicesCard";
import { InvoicesUIProvider } from "./InvoicesUIContext";
import { InvoiceDeleteDialog } from "./invoice-delete-dialog/InvoiceDeleteDialog";
import { InvoicesFetchDialog } from "./invoices-fetch-dialog/InvoicesFetchDialog";
import { InvoiceActionsModal } from "./invoice-actions-modals/InvoiceActionsModal";
import { InvoicesDeleteDialog } from "./invoices-delete-dialog/InvoicesDeleteDialog";
import { InvoicesLoadingDialog } from "./invoices-loading-dialog/InvoicesLoadingDialog";
import { InvoicesUpdateStateDialog } from "./invoices-update-status-dialog/InvoicesUpdateStateDialog";

function InvoicesPage({ history }) {
  const invoicesUIEvents = {
    newInvoiceButtonClick: () => {
      history.push("/invoices/new");
    },
    openEditInvoiceDialog: (id) => {
      history.push(`/invoices/${id}/edit`);
    },
    openDeleteInvoiceDialog: (id, status) => {
      history.push(`/invoices/${id}/delete/${status}`);
    },
    openDeleteInvoicesDialog: () => {
      history.push(`/invoices/deleteInvoices`);
    },
    openFetchInvoicesDialog: () => {
      history.push(`/invoices/fetch`);
    },
    openUpdateInvoicesStatusDialog: () => {
      history.push("/invoices/updateStatus");
    },
    openViewInvoicesDialog: (id) => {
      history.push(`/invoices/${id}/view`);
    },
    openViewInvoiceActionDialog: (id) => {
      history.push(`/invoices/actions-modal/${id}`);
    },
    openPrintInvoicePage: (id) => {
      window.open(`${process.env.REACT_APP_URL}view/invoice/${id}`, "_blank");
    },
  };

  return (
    <InvoicesUIProvider invoicesUIEvents={invoicesUIEvents}>
      <InvoicesLoadingDialog />
      <Switch>
        <Route path="/invoices">
          <Route path="/invoices/deleteInvoices">
            {({ history, match }) => (
              <InvoicesDeleteDialog
                show={match != null}
                onHide={() => {
                  history.push("/invoices");
                }}
              />
            )}
          </Route>
          <Route path="/invoices/:id/delete/:status">
            {({ history, match }) => (
              <InvoiceDeleteDialog
                show={match != null}
                id={match && match.params.id}
                status={match && match.params.status}
                onHide={() => {
                  history.push("/invoices");
                }}
              />
            )}
          </Route>
          <Route path="/invoices/fetch">
            {({ history, match }) => (
              <InvoicesFetchDialog
                show={match != null}
                onHide={() => {
                  history.push("/invoices");
                }}
              />
            )}
          </Route>
          <Route path="/invoices/updateStatus">
            {({ history, match }) => (
              <InvoicesUpdateStateDialog
                show={match != null}
                onHide={() => {
                  history.push("/invoices");
                }}
              />
            )}
          </Route>
          <Route exact path="/invoices/actions-modal/:id">
            {({ history, match }) => (
              <InvoiceActionsModal
                show={match != null}
                onHide={() => {
                  history.push("/invoices");
                }}
              />
            )}
          </Route>
          <InvoicesCard />
        </Route>
      </Switch>
    </InvoicesUIProvider>
  );
}

export default InvoicesPage;
