import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "./customers/customersSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { productsSlice } from "./products/productsSlice";
import { invoicesSlice } from "./invoices/invoicesSlice";
import { companySlice } from "./company/companySlice";
import { categoriesSlice } from "./category/categorySlice";
import { discountsSlice } from "./discount/discountSlice";
import { taxesSlice } from "./taxes/taxesSlice";
import { storesSlice } from "./stores/storeSlice";
import { posSlice } from "./pos/posSlice";
import { rolesSlice } from "./roles/rolesSlice";
import { usersSlice } from "./users/usersSlice";
import { subscriptionSlice } from "./subscription/subscriptionSlice";
import { paymentCardSlice } from "./payment/paymentSlice";
import { analyticsSlice } from "./analytics/analyticsSlice";
import { subscriptionInvoicesSlice } from "./subscriptionInvoices/SubscriptionInvoicesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  invoices: invoicesSlice.reducer,
  company: companySlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  category: categoriesSlice.reducer,
  discounts: discountsSlice.reducer,
  taxes: taxesSlice.reducer,
  stores: storesSlice.reducer,
  pos: posSlice.reducer,
  roles: rolesSlice.reducer,
  users: usersSlice.reducer,
  subscription: subscriptionSlice.reducer,
  paymentCard: paymentCardSlice.reducer,
  analytics: analyticsSlice.reducer,
  subscriptionInvoices: subscriptionInvoicesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
