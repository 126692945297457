// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, openDeleteCustomerDialog, openViewCustomerDialog, openCustomerActionsDialog }
) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {/* Render custom icon here */}
      <i className="fas fa-ellipsis-v" style={{ marginRight: "36px" }}></i>
      {children}
    </a>
  ));
  return (
    <>
      <Dropdown
        onClick={() => {
          openCustomerActionsDialog(row?._id);
        }}>
        <Dropdown.Toggle as={CustomToggle} variant="success" id="dropdown-basic"></Dropdown.Toggle>
        {/* <Dropdown.Menu>
          <Dropdown.Item onClick={() => openViewCustomerDialog(row._id)}>
            View{" "}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openEditCustomerDialog(row._id)}>
            Edit{" "}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => openDeleteCustomerDialog(row._id)}>
            Delete{" "}
          </Dropdown.Item>
        </Dropdown.Menu> */}
      </Dropdown>
    </>
  );
}
