import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextArea } from "../../../../_metronic/_partials/controls";
import "react-phone-input-2/lib/style.css";
import { i18n } from "../../../pages/private/languageSelector";
import IconClose from "../../../../assets/img/icon-close.svg";

// Validation schema
const CompanyEditSchema = Yup.object().shape({});

function CompanyTermsAndConditionsDialog({
  saveMessage,
  companyForEdit,
  secondaryAction,
  openTermsAndConditionsDialog,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={companyForEdit}
        validationSchema={CompanyEditSchema}
        onSubmit={(values) => {
          saveMessage({
            _id: values._id,
            termsAndConditions: values.termsAndConditions,
            returnPolicy: values.returnPolicy,
          });
        }}>
        {({ handleSubmit, values, setValues }) => (
          <>
            <Modal
              className="modal-drawer"
              size="lg"
              show={openTermsAndConditionsDialog}
              onHide={() => {}}
              aria-labelledby="example-modal-sizes-title-lg">
              <Modal.Header>
                <button type="button" className="btn btn-close" onClick={() => secondaryAction()}>
                  <img src={IconClose} alt="loading" />
                </button>
                <Modal.Title>{i18n("Company.TermsAndConditions")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default ">
                <Form className="form form-label-right mt-10">
                  <div>
                    <Field
                      name="termsAndConditions"
                      label={i18n("Company.TermsAndConditions")}
                      component={TextArea}
                      rows={10}
                      withFeedbackLabel={false}
                      companyLabel={true}
                    />
                  </div>
                  <div className="mt-10">
                    <Field
                      name="returnPolicy"
                      label={i18n("Company.EnterReturn&ReplacementPolicy")}
                      component={TextArea}
                      rows={5}
                      withFeedbackLabel={false}
                      companyLabel={true}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-primary btn-elevate">
                  {i18n("Company.Save")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
export default CompanyTermsAndConditionsDialog;
