import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../../../private/languageSelector";
import { Card } from "../../../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import PinInput from "react-pin-input";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconPosAuth from "../../../../../assets/img/pin-auth.png";
import * as usersActions from "../../../../../_redux/users/usersActions";

export const SetupOwnerPosPin = ({
  userPosPin,
  userPosPinModal,
  setUserPosPinModal,
  setOpenUsersAddDialog,
  setOpenUsersManageDialog,
}) => {
  const dispatch = useDispatch();

  const [posPin, setPosPin] = useState("");
  const { user } = useSelector((state) => state.auth);

  const handlePin = (value) => {
    setPosPin(value);
  };

  useEffect(() => {
    if (userPosPin) {
      setPosPin(userPosPin);
    }
  }, [userPosPinModal, userPosPin]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={() => {
          if (posPin) {
            if (posPin && posPin.toString().length === 4) {
              dispatch(
                usersActions.updateUserPosPin(user._id, {
                  posPin: posPin,
                })
              ).then(() => {
                dispatch(usersActions.fetchUsers());
                toast.success("Pos Pin Updated");
                setUserPosPinModal(false);
                setOpenUsersManageDialog(false);
                setOpenUsersAddDialog(true);
              });
            } else {
              toast.error("Enter a 4 Digits Pos Pin");
            }
          }
        }}
      >
        {({ handleSubmit }) => {
          return (
            <>
              <Modal
                className="modal-drawer my-profile invoice-drawer"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={userPosPinModal}
                onHide={() => {
                  setUserPosPinModal(false);
                }}
              >
                <Modal.Header className="d-flex justify-content-around">
                  <span
                    onClick={() => setUserPosPinModal(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={IconClose} alt="loading" />
                  </span>
                  <span
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#FFFFFF",
                    }}
                  >
                    <span>{user?.name?.first}'s</span>{" "}
                    {i18n("UserProfile.Profile")}
                  </span>
                  <span
                    onClick={() => handleSubmit()}
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: posPin.length === 4 && "#FFFFFF",
                      opacity: posPin.length === 4 ? 1 : 0.5,
                      cursor: "pointer",
                    }}
                  >
                    {i18n("UserProfile.Save")}
                  </span>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Form>
                    <Card>
                      <div className="row">
                        <div className="col-lg-12">
                          <h5 className="m-0">
                            <b>Set Pin</b>
                          </h5>
                        </div>
                        <h6 className="col-lg-6 mt-7 ">
                          {i18n("UserProfile.POSPIN")}
                        </h6>
                        <h6 className="col-lg-6 mt-3">
                          <div className="form-group">
                            <PinInput
                              length={4}
                              initialValue={posPin}
                              secret={false}
                              focus={false}
                              type="numeric"
                              style={{ width: "100%" }}
                              inputStyle={{
                                width: "30px",
                                height: "30px",
                                border: "0",
                                borderBottom: "1px solid",
                              }}
                              onChange={handlePin}
                            />
                          </div>
                        </h6>
                      </div>
                    </Card>
                    <div className="pin-pad text-center justify-content-center mt-5">
                      <img src={IconPosAuth} alt="loading" />
                      <p>
                        When adding users, the authentication by PIN code mode
                        is enabled. Set your own PIN to enter Fatoraty POS
                        application.
                      </p>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            </>
          );
        }}
      </Formik>
    </>
  );
};
