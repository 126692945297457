import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/customers/customersActions";
import { CustomerEditDialogHeader } from "./CustomerEditDialogHeader";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";

const initCustomer = {
  id: undefined,
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  city: "",
  country: "",
  address: "",
  state: "",
  zip: "",
  taxNumber: "",
  businessName: "",
};

export function CustomerEditDialog({
  id,
  view,
  onHide,
  forInvoice,
  customerName,
  showCustomerAddDialoag,
  setShowCustomerAddDialoag,
}) {
  const [invoiceCustomerName, setInvoiceCustomerName] = useState({
    customerFirstName: "",
    customerSecondName: "",
    customerThirdName: "",
  });

  useEffect(() => {
    if (customerName) {
      let firstName = customerName.split(" ").slice(0, 1);
      let secondName = customerName.split(" ").slice(1, 2);
      let thirdName = customerName.split(" ").slice(2, 3);
      setInvoiceCustomerName({
        customerFirstName: firstName[0],
        customerSecondName: secondName[0],
        customerThirdName: thirdName[0],
      });
    }
  }, [customerName]);

  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext?.queryParams,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit, navigate } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
      navigate: state.customers.navigate,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (navigate) {
      onHide();
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer, closeModal, showEmailError, showMobileError) => {
    if (!id) {
      // server request for creating customer
      dispatch(
        actions.createCustomer(
          customer,
          closeModal,
          showEmailError,
          showMobileError,
          forInvoice ? {} : customersUIProps.queryParams
        )
      );
    } else {
      // server request for updating customer
      dispatch(
        actions.updateCustomer(customer, closeModal, showEmailError, showMobileError, customersUIProps.queryParams)
      );
    }
  };

  return (
    <Modal
      size="lg"
      show={showCustomerAddDialoag}
      onHide={() => {}}
      aria-labelledby="example-modal-sizes-title-lg"
      className="modal-drawer">
      <CustomerEditDialogHeader
        id={id}
        view={view}
        onHide={onHide}
        showCustomerAddDialoag={showCustomerAddDialoag}
        setShowCustomerAddDialoag={setShowCustomerAddDialoag}
      />
      <CustomerEditForm
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        customer={
          customerForEdit && customerForEdit._id
            ? customerForEdit
            : {
                ...initCustomer,
                firstName: invoiceCustomerName.customerFirstName ? invoiceCustomerName.customerFirstName : "",
                lastName:
                  invoiceCustomerName.customerSecondName && invoiceCustomerName.customerThirdName
                    ? invoiceCustomerName.customerSecondName + " " + invoiceCustomerName.customerThirdName
                    : invoiceCustomerName.customerSecondName
                    ? invoiceCustomerName.customerSecondName
                    : "",
              }
        }
        view={view}
        onHide={onHide}
        showCustomerAddDialoag={showCustomerAddDialoag}
        setShowCustomerAddDialoag={setShowCustomerAddDialoag}
      />
    </Modal>
  );
}
