// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditInvoiceDialog,
    openDeleteInvoiceDialog,
    openViewInvoicesDialog,
    openPrintInvoicePage,
    openViewInvoiceActionDialog,
  }
) {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        openViewInvoiceActionDialog(row?._id);
      }}>
      {/* Render custom icon here */}
      <i
        className="fas fa-ellipsis-v"
        style={{ marginRight: "36px" }}
        onClick={() => openViewInvoiceActionDialog(row?._id)}></i>
      {/* {children} */}
    </a>
  ));
  return (
    <>
      <div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} variant="success"></Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => openViewInvoicesDialog(row._id)}>View</Dropdown.Item>
            <Dropdown.Item
              onClick={() => openEditInvoiceDialog(row._id)}
              style={!row?.status ? { display: "none" } : {}}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                openPrintInvoicePage(row._id);
              }}>
              Print
            </Dropdown.Item>
            {row.status === true && (
              <Dropdown.Item onClick={() => openDeleteInvoiceDialog(row._id, row.status)}>Delete</Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
}
