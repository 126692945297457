import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Mobile, Select } from "../../../../_metronic/_partials/controls";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import { AsYouType } from "libphonenumber-js/max";
import countryList from "react-select-country-list";
import { shallowEqual, useSelector } from "react-redux";
import ConfirmationDialog from "../../confirmationDialoge";
import { i18n } from "../../../pages/private/languageSelector";

let asYouType = new AsYouType();
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});
// Validation schema
const CustomerEditSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, i18n("Validation.Minimum3Symbols"))
    .max(50, i18n("Validation.Maximum50Symbols"))
    .required(i18n("Validation.FirstNameIsRequired")),
  lastName: Yup.string()
    .min(3, i18n("Validation.Minimum3Symbols"))
    .max(50, i18n("Validation.Maximum50Symbols"))
    .required(i18n("Validation.LastNameIsRequired")),
  email: Yup.string()
    .email(i18n("Validation.WrongEmailFormat"))
    .matches(
      // eslint-disable-next-line
      /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
      "Must contain e.g (example._-@example.com)"
    ),
  zip: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  address: Yup.string(),
  taxNumber: Yup.string(),
  mobile: Yup.string().validatePhoneNumber(i18n("Validation.INVALID_PHONE")),
});

export function CustomerEditForm({ view, onHide, customer, saveCustomer, setShowCustomerAddDialoag }) {
  const options = countryList().getData();

  const [showUniqueEmailError, setShowUniqueEmailError] = useState(false);
  const [showUniqueMobileError, setShowUniqueMobileError] = useState(false);
  const [showConfrmationDialog, setShowConfrmationDialog] = useState(false);

  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user,
    }),
    shallowEqual
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...customer,
          country: customer && customer?.country ? customer?.country : user?.companyId?.country,
        }}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values, setShowCustomerAddDialoag, setShowUniqueEmailError, setShowUniqueMobileError);
        }}>
        {({ handleSubmit, values, setValues }) => {
          return (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                <div className="mb-5">
                  <strong>{i18n("Customer.CustomerInformation")}</strong>
                </div>
                <Form className="form form-label-right">
                  <div className="form-group">
                    <Field
                      name="firstName"
                      component={Input}
                      placeholder={i18n("global.FirstName")}
                      label={i18n("global.FirstName")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="lastName"
                      component={Input}
                      placeholder={i18n("global.LastName")}
                      label={i18n("global.LastName")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      onClick={() => setShowUniqueEmailError(false)}
                      type="email"
                      name="email"
                      readOnly={view}
                      component={Input}
                      label={i18n("global.Email")}
                      placeholder={i18n("global.Email")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      uniqueEmailError={showUniqueEmailError ? "This Email is already in use" : false}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      onClick={() => setShowUniqueMobileError(false)}
                      placeholder={i18n("global.Mobile")}
                      label={i18n("global.Mobile")}
                      component={Mobile}
                      name="mobile"
                      type="mobile"
                      international
                      uniqueMobileError={showUniqueMobileError ? "This Mobile is already in use" : false}
                      defaultCountry="SA"
                      style={{ display: "flex" }}
                      value={values.mobile}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          mobile: value,
                        });
                      }}
                      switchIt={true}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="businessName"
                      component={Input}
                      placeholder={i18n("global.BusinessName")}
                      label={i18n("global.BusinessName")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="taxNumber"
                      component={Input}
                      placeholder={i18n("global.TaxNumber")}
                      label={i18n("global.TaxNumber")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="address"
                      component={Input}
                      placeholder={i18n("global.Address")}
                      label={i18n("global.Address")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="state"
                      component={Input}
                      placeholder={i18n("global.State/Province/Region")}
                      label={i18n("global.State/Province/Region")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className={`form-group form-select ${view ? "view-select" : ""}`}>
                    <Field
                      component={Select}
                      placeholder={i18n("global.Country")}
                      label={i18n("global.Country")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                      name="country">
                      {options.map((countries, index) => (
                        <option value={countries.label} key={index}>
                          {countries.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group">
                    <Field
                      name="city"
                      component={Input}
                      placeholder={i18n("global.City")}
                      label={i18n("global.City")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="zip"
                      component={Input}
                      placeholder={i18n("global.Postal/ZipCode")}
                      label={i18n("global.Postal/ZipCode")}
                      {...(view ? { disabled: true } : {})}
                      withFeedbackLabel={view ? false : true}
                      readOnly={view}
                    />
                  </div>
                </Form>
                <div className="btn-bar">
                  <button
                    type="button"
                    onClick={() => {
                      view ? onHide() : setShowConfrmationDialog(true);
                      setShowCustomerAddDialoag(false);
                    }}
                    className={view ? "btn btn-primary btn-elevate" : "btn btn-light btn-elevate"}>
                    {view ? i18n("Customer.Close") : i18n("Customer.Cancel")}
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate f-button"
                    style={view ? { display: "none" } : { display: "block" }}>
                    {i18n("Customer.Save")}
                  </button>
                </div>
              </Modal.Body>
            </>
          );
        }}
      </Formik>
      <ConfirmationDialog
        show={showConfrmationDialog}
        title="Confirmation ?"
        subTitle="Are you sure you want to cancel without saving?"
        primaryAction={() => {
          setShowConfrmationDialog(false);
          onHide();
        }}
        secondaryAction={() => {
          setShowConfrmationDialog(false);
        }}
        primaryActionTitle="Yes"
        secondaryActionTitle="No"
      />
    </>
  );
}
