import React from 'react'
import { Switch } from "react-router-dom";
import AnalyticsView from './analyticsView/AnalyticsView'

const AnalyticsPage = () => {
  return (
    <Switch>
    <AnalyticsView />
  </Switch>
  )
}

export default AnalyticsPage