import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { SubscriptionBillingAddressDialoge } from "./SubscriptionBillingAddressDialoge";

export const NewSubscription = ({ openNewSubDialog, setOpenNewSubDialog }) => {
  const [showBillingDialoge, setShowBillingDialoge] = useState(false);
  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openNewSubDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenNewSubDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body className="overlay overlay-block cursor-default">
          <>
            <div className="new-subscription text-left mt-20">
              <p>New Subscription</p>
              <p>Your Order</p>
              <p>Premium</p>
              <p>Yearly</p>
            </div>
            <div>
              <span className="subscription-users h3">6</span> <span className="h3">Users</span>
            </div>
            <div className="new-subscription text-left mt-5">
              <div className="d-flex justify-content-between mt-5">
                <span>Sub Total</span> <b>96.00 $</b> <span>(6 * 16 $)</span>
              </div>
              <div className="d-flex mt-2">
                <span className="span-summary">Coupon</span> <b>0.00 $</b>
              </div>
              <div className="d-flex mt-2">
                <span className="span-summary">Vat. (15%)</span> <b>14.40 $</b>
              </div>
              <div className="d-flex mt-2">
                <span className="span-summary">Total</span> <b>110.40 $</b>
              </div>
            </div>
            <div className="form-group input-group mt-10 mb-0">
              <input type="text" className="form-control float-left" name="searchText" placeholder="Coupon Code" />
              <div className="input-group-append">
                <button className="btn btn-primary btn-elevate pt-0 pb-0">Apply</button>
              </div>
            </div>
            <p className="text-muted">Enter Coupon Code</p>
          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="row justify-content-center">
            <button className="btn btn-outline-primary" style={{ display: "block" }}>
              Cancel
            </button>
            <button
              type="submit"
              onClick={() => setShowBillingDialoge(true)}
              className="btn btn-primary btn-elevate f-button ml-5"
              style={{ display: "block" }}>
              Continue
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <SubscriptionBillingAddressDialoge
        showBillingDialoge={showBillingDialoge}
        setShowBillingDialoge={setShowBillingDialoge}
      />
    </>
  );
};
