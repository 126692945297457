import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import CreatableSelect from "react-select/creatable";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["react-select"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  // if (touched && !errors) {
  //   classes.push("is-valid");
  // }
  classes.push("f-select");
  return classes.join(" ");
};

export function ReactCreateableSelector({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  touched2,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <CreatableSelect
        inputId={field.name}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        styles={{ padding: "0px", paddingRight: "0px", marginTop: "-1px" }}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
