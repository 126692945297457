import { ContactSupportOutlined } from "@material-ui/icons";
import moment from "moment";
import toast from "react-hot-toast";

// Total Paid
export const totalPaidHandler = (payments) => {
  let totalPaidd = 0;
  if (payments?.length) {
    totalPaidd = payments?.reduce((a, b) => {
      return (totalPaidd = b?.type === "DEBIT" ? a - +b.amount : a + +b.amount);
    }, 0);
  }
  return totalPaidd;
};

// Subtotal Price
export const subtotalPriceHandler = (selectedProducts) => {
  let subtotalP = 0;

  if (selectedProducts?.length) {
    subtotalP = selectedProducts?.reduce((a, b) => {
      return (subtotalP = a + +b.price * +b?.quantity);
    }, 0);
  }
  return subtotalP;
};
export const taxableAmountHandler = (selectedProducts) => {
  let totaltaxableAmount = 0;
  let taxableProducts = [];
  if (selectedProducts?.length) {
    selectedProducts.map((item) => {
      if (item?.taxes?.length > 0) {
        taxableProducts.push(item);
      } else if (item?.taxes?.length === 0 && item?.newTax?.length > 0) {
        taxableProducts.push(item);
      }
    });
    if (taxableProducts?.length) {
      totaltaxableAmount = taxableProducts?.reduce((a, b) => {
        return (totaltaxableAmount = a + +b.price * +b?.quantity);
      }, 0);
    }
    return totaltaxableAmount;
  }
};

// Total Price
export const totalPriceHandler = (selectedProducts) => {
  let totalP = 0;
  if (selectedProducts?.length) {
    totalP = selectedProducts?.reduce((a, b) => {
      return (totalP = a + +b.totalPrice);
    }, 0);
  }
  return totalP;
};

// Total Discount
export const totalDiscountHandler = (selectedProducts) => {
  let totalD = 0;
  if (selectedProducts?.length) {
    totalD = selectedProducts?.reduce((a, b) => {
      return (totalD = b?.discount ? a + Number(b?.discount) : totalD);
    }, 0);
  }
  return totalD;
};
export const totalCuponDiscountHandler = (selectedProducts) => {
  let totalD = 0;
  if (selectedProducts?.length) {
    totalD = selectedProducts?.reduce((a, b) => {
      return (totalD = b?.couponValueOnProduct
        ? a + Number(b?.couponValueOnProduct)
        : totalD);
    }, 0);
  }
  return totalD;
};

// Total Tax
export const totalTaxHandler = (selectedProducts, companyTax) => {
  let totalT = 0;
  if (selectedProducts?.length) {
    totalT = selectedProducts?.reduce((a, b) => {
      if (b?.tax) {
        return (totalT = a + +b?.tax);
      } else {
        return totalT;
      }
    }, 0);
  }
  return totalT;
};

// Total Added to the price Tax

export const totalAddedTaxHandler = (selectedProducts) => {
  let totalT = 0;
  if (selectedProducts?.length) {
    totalT = selectedProducts?.reduce((a, b) => {
      if (b?.addedTax) {
        return (totalT = a + b?.addedTax);
      } else {
        return totalT;
      }
    }, 0);
  }
  return totalT;
};

// Handling Payments
export const paymentHandler = (
  payments,
  setPayments,
  selectedPayment,
  setSelectedPayment
) => {
  if (!selectedPayment.paymentAmount) {
    toast.error("Enter Amount");
  } else {
    setPayments([
      ...payments,
      {
        amount: +selectedPayment.paymentAmount,
        createdAt: moment().format(),
        date: moment().format(),
        type: selectedPayment.paymentMethod,
        method: selectedPayment.paymentChannel,
        note: selectedPayment.paymentNote,
      },
    ]);
    setSelectedPayment({
      paymentAmount: 0,
      paymentMethod: "CREDIT",
      paymentChannel: "cash",
      paymentNote: "",
    });
    toast.success("Payment added to invoice");
  }
};

// Handling Product Total Price
export const handleTotalPrice = (
  index,
  selectedProducts,
  setSelectedProducts
) => {
  let productDiscount = 0;
  let discountedTaxPrice = 0;

  if (selectedProducts[index]?.discount) {
    productDiscount = +selectedProducts[index]?.discount;
  }

  if (selectedProducts) {
    let productPrice = +selectedProducts[index]?.totalPrice;
    let addedPrice = selectedProducts[index]?.addedTax;
    let productDiscount = +selectedProducts[index]?.discount;
    let couponValueOnProduct = +selectedProducts[index]?.couponValueOnProduct;
    if (productPrice + addedPrice < 0) {
      discountedTaxPrice = 0.0;
    } else {
      discountedTaxPrice = productPrice + addedPrice;
      if (couponValueOnProduct > 0) {
        discountedTaxPrice = discountedTaxPrice - couponValueOnProduct;
      } else {
        discountedTaxPrice = discountedTaxPrice - productDiscount;
      }
    }
    let newProduct;
    newProduct = {
      ...selectedProducts[index],
      totalPriceProduct:
        discountedTaxPrice > 0 ? discountedTaxPrice?.toFixed(2) : 0,
    };
    selectedProducts[index] = newProduct;
    setSelectedProducts([...selectedProducts]);
  }
};

export const addZeroes = (num) => {
  const dec = num.split(".")[1];
  const len = dec && dec.length > 3 ? dec.length : 3;
  return Number(num).toFixed(len);
};

export const addCustomerToInvoice = (
  option,
  selectedCustomer,
  setSelectedCustomer
) => {
  setSelectedCustomer({
    ...selectedCustomer,
    name: option?.item?.name,
    email: option?.item?.email ? option?.item?.email : "",
    mobile: option?.item?.mobile,
    taxNumber: option?.item?.taxNumber ? option?.item?.taxNumber : "",
    _id: option?.item?._id,
  });
};

export const addProductToInvoice = (
  setProduct,
  selectedProducts,
  setSelectedProducts
) => {
  setSelectedProducts([
    ...selectedProducts,
    {
      _id: "",
      name: "",
      description: "",
      price: "",
      isPrice: false,
      quantity: "",
      discount: "",
      barcode: "",
      taxable: false,
      vat: false,
      rate: "basic",
      totalPrice: "",
    },
  ]);
  toast.success("Product Card Added To List");
  setProduct({
    name: "",
    description: "",
    price: "",
    barcode: "",
    quantity: "",
    discount: "",
    totalPrice: "",
  });
};

export const addedtaxHandler = (
  index,
  selectedProducts,
  setSelectedProducts
) => {
  let totalT = 0;
  let totalTaxRate = 0;

  if (selectedProducts[index].taxes?.length) {
    // eslint-disable-next-line
    let data = selectedProducts[index].taxes?.map((item) => {
      return item?.active && item?.type === "Added to the price"
        ? (totalTaxRate = +totalTaxRate + +item?.taxRate)
        : totalTaxRate;
    });
  }
  selectedProducts[index].addedTaxRate = Number(totalTaxRate);
  let productPrice = +selectedProducts[index].totalPrice;
  let productDiscount = +selectedProducts[index].discount;
  if (selectedProducts[index].taxes?.length) {
    totalT = selectedProducts[index].taxes?.reduce((a, b) => {
      if (b?.active && b?.type === "Added to the price") {
        return (totalT =
          a + ((+productPrice - +productDiscount) * +b.taxRate) / 100);
      } else {
        return totalT;
      }
    }, 0);
  }
  if (totalT >= 0) {
    handleTotalPrice(index, selectedProducts, setSelectedProducts);
    return totalT;
  } else {
    handleTotalPrice(index, selectedProducts, setSelectedProducts);
    return 0;
  }
};

export const selectedDiscount = (
  productIndex,
  e,
  name,
  selectedProducts,
  refectorDiscounts,
  setRefectorDiscounts
) => {
  let productDiscounts = [];
  // for only one product
  if (selectedProducts && selectedProducts.length === 1) {
    let selectedDiscount = [];
    refectorDiscounts.map((item) => {
      if (item.name === name) {
        selectedDiscount.push({ ...item, selected: e.target.checked });
      } else {
        selectedDiscount.push(item);
      }
    });
    setRefectorDiscounts([...selectedDiscount]);
  }
  // for more than one product

  if (selectedProducts?.length > 1) {
    selectedProducts.map((item) => {
      let value = item.discounts.find(
        (discount) => discount.name === name && discount.selected
      )
        ? productDiscounts.push({ ...item, discountName: name })
        : null;
    });
    //   for equlation of product length

    if (productDiscounts?.length === selectedProducts?.length) {
      let selectedDiscount = [];
      refectorDiscounts.map((item) => {
        if (item.name === name) {
          selectedDiscount.push({ ...item, selected: true });
        } else {
          selectedDiscount.push(item);
        }
      });
      setRefectorDiscounts([...selectedDiscount]);
    }
    // for less product length

    if (productDiscounts?.length < selectedProducts?.length) {
      let selectedDiscount = [];
      refectorDiscounts.map((item) => {
        if (item.name === name) {
          selectedDiscount.push({ ...item, selected: false });
        } else {
          selectedDiscount.push(item);
        }
      });
      setRefectorDiscounts([...selectedDiscount]);
    }
  }
  productDiscounts = [];
};
