import { createSlice } from "@reduxjs/toolkit";
import {
  getSubscriptionInvoice,
  getSubscriptionInvoices,
} from "./SubscriptionInvoicesService";

const initialRolesState = {
  invoices: null,
  getInvoicesLoading: false,
  getInvoicesError: null,
  invoice: null,
  getInvoiceLoading: false,
  getInvoiceError: null,
};

export const subscriptionInvoicesSlice = createSlice({
  name: "subscriptionInvoices",
  initialState: initialRolesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionInvoices.pending, (state) => {
        state.getInvoicesLoading = true;
        state.getInvoicesError = null;
      })
      .addCase(getSubscriptionInvoices.fulfilled, (state, action) => {
        state.getInvoicesLoading = false;
        state.invoices = action.payload;
        state.getInvoicesError = null;
      })
      .addCase(getSubscriptionInvoices.rejected, (state, action) => {
        state.getInvoicesLoading = false;
        state.getInvoicesError = action.error.message;
      })
      .addCase(getSubscriptionInvoice.pending, (state) => {
        state.getInvoiceLoading = true;
        state.getInvoiceError = null;
      })
      .addCase(getSubscriptionInvoice.fulfilled, (state, action) => {
        state.getInvoiceLoading = false;
        state.invoice = action.payload;
        state.getInvoiceError = null;
      })
      .addCase(getSubscriptionInvoice.rejected, (state, action) => {
        state.getInvoiceLoading = false;
        state.getInvoiceError = action.error.message;
      });
  },
});
