import React from "react";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import { Formik, Form, Field } from "formik";
import { AsYouType } from "libphonenumber-js/max";
import countryList from "react-select-country-list";
import { CURRENCIES } from "../../../../helpers/constants";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../../pages/private/languageSelector";
import {
  Input,
  Mobile,
  Select,
} from "../../../../_metronic/_partials/controls";

let asYouType = new AsYouType();

Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});
// Validation schema
const CompanyEditSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .lowercase()
    .matches(
      // eslint-disable-next-line
      /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
      "Must contain e.g (example._-@example.com)"
    ),
  mobile: Yup.string().validatePhoneNumber("Invalid Phone Number"),
});

function CompanyEditDialog({
  saveCompany,
  companyForEdit,
  actionsLoading,
  secondaryAction,
  showCompanyEditDialog,
}) {
  const options = countryList().getData();

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={companyForEdit}
        validationSchema={CompanyEditSchema}
        onSubmit={(values) => {
          let data = { ...values };
          delete data.image;
          saveCompany({
            ...data,
            name: data.companyName,
            email: data.email.toLowerCase(),
            mobile: data?.mobile ? data?.mobile : "",
          });
        }}
      >
        {({ handleSubmit, values, setValues }) => (
          <>
            <Modal
              className="modal-drawer"
              size="lg"
              show={showCompanyEditDialog}
              onHide={() => {}}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <button
                  type="button"
                  className="btn btn-close"
                  onClick={secondaryAction}
                >
                  <img src={IconClose} alt="loading" />
                </button>
                <Modal.Title>{i18n("Company.EditCompanyProfile")}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="mb-7">
                  <strong>Customer Information</strong>
                </div>
                <Form className="form">
                  <div className="form-group">
                    <Field
                      name="companyName"
                      component={Input}
                      readOnly={true}
                      disabled={true}
                      withFeedbackLabel={false}
                      placeholder={i18n("Company.CompanyName")}
                      label={i18n("Company.CompanyName")}
                    />
                  </div>

                  <div className="form-group">
                    <div className="input-with-drop-down-v2">
                      <Field
                        placeholder={i18n("Company.PhoneNumber")}
                        label={i18n("Company.PhoneNumber")}
                        component={Mobile}
                        name="mobile"
                        international
                        defaultCountry="SA"
                        style={{ display: "flex" }}
                        value={values.mobile}
                        onChange={(value) => {
                          setValues({ ...values, mobile: value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <Field
                      name="email"
                      component={Input}
                      placeholder={i18n("Company.EmailAddress")}
                      label={i18n("Company.EmailAddress")}
                    />
                  </div>
                  <div className="form-group form-select">
                    <Field
                      name="currency"
                      component={Select}
                      placeholder={i18n("Company.Currency")}
                      label={i18n("Company.Currency")}
                    >
                      {CURRENCIES.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group">
                    <Field
                      name="taxNumber"
                      component={Input}
                      placeholder={i18n("Company.TaxNumber")}
                      label={i18n("Company.TaxNumber")}
                    />
                  </div>
                  {/* <div className="form-group">
                    <Field
                      name="taxRate"
                      component={Input}
                      placeholder={i18n("Company.TaxRate")}
                      label={i18n("Company.TaxRate")}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="taxName"
                      component={Input}
                      placeholder={i18n("Company.TaxName")}
                      label={i18n("Company.TaxName")}
                    />
                  </div> */}
                  <div className="form-group">
                    <Field
                      name="crNumber"
                      component={Input}
                      placeholder={i18n("Company.CR#")}
                      label={i18n("Company.CR#")}
                    />
                  </div>
                  <div className="form-group form-select">
                    <Field
                      component={Select}
                      placeholder={i18n("Company.Country")}
                      label={i18n("Company.Country")}
                      name="country"
                    >
                      {options.map((countries, index) => (
                        <option value={countries.label} key={index}>
                          {countries.label}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="form-group">
                    <Field
                      name="city"
                      component={Input}
                      placeholder={i18n("Company.City")}
                      label={i18n("Company.City")}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="state"
                      component={Input}
                      placeholder={i18n("Company.State/Province")}
                      label={i18n("Company.State/Province")}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="zipcode"
                      component={Input}
                      placeholder={i18n("Company.POBOX/ZipCode")}
                      label={i18n("Company.POBOX/ZipCode")}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="street"
                      component={Input}
                      placeholder={i18n("Company.Street")}
                      label={i18n("Company.Street")}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-light btn-elevate"
                  onClick={secondaryAction}
                >
                  {i18n("Company.Cancel")}
                </button>
                <button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="btn btn-primary btn-elevate"
                >
                  {i18n("Company.Save")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
export default CompanyEditDialog;
