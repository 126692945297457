import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../../assets/img/icon-close.svg";
import UserAvatar from "../../../../../assets/img/user-avatar-default.jpg";
import { ManageUsers } from "./ManageUsers";
import { UserEditForm } from "./UsersEditForm";
import * as usersActions from "../../../../../_redux/users/usersActions";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

const UserProfilePage = ({ userAccess, openUserProfilePage, setOpenUserProfilePage }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [openUsersAddDialog, setOpenUsersAddDialog] = useState(false);
  const [openUsersManageDialog, setOpenUsersManageDialog] = useState(false);
  const [userForEdit, setUserForEdit] = useState();

  const { user, users } = useSelector(
    (state) => ({
      user: state.auth.user,
      users: state.users.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(usersActions.fetchUsers());
  }, [dispatch]);

  let defaultPosPin = Math.floor(1000 + Math.random() * 9000).toString();

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openUserProfilePage}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenUserProfilePage(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Users</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {users?.length ? (
            <>
              <div className="input-group">
                <input
                  className="form-control mr-2"
                  placeholder="Search by users, email, mobile"
                  type="text"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <table className="table table-user">
                <tbody>
                  {users?.length
                    ? users
                        .filter((item) => {
                          return (
                            item.name?.first?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                            item.name?.last?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                            item.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                            item.mobile?.toLowerCase()?.includes(searchText?.toLowerCase())
                          );
                        })
                        .map((it, i) => {
                          return (
                            <tr className="company-users-list" key={i}>
                              <td>
                                <div className="col-avatar mb-5">
                                  <div className="avatar-wrap">
                                    <img src={it?.profileImage ? it.profileImage : UserAvatar} alt="loading" />
                                  </div>
                                </div>
                              </td>
                              <td className="col-user-detail">
                                <b className="h6 heading-color truncate-160 mb-0">
                                  {it?.name?.first + " " + it?.name?.last}
                                </b>
                                <small className="default-payment truncate-160">{it?.email}</small>
                                <small className="default-payment">{it?.rolePayload?.name}</small>
                                <small className="default-payment">{it?.mobile}</small>
                              </td>
                              <td
                                className={`col-status ${
                                  it?.isActive || it?.rolePayload?.name === "owner" ? "user-enabled" : "user-disabled"
                                }`}>
                                {it?.isActive || it?.rolePayload?.name === "owner" ? "Enabled" : "Disabled"}
                              </td>
                            </tr>
                          );
                        })
                    : ""}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="text-center">
                  <h4>You have no users added!</h4>
                  {userAccess?.createData ? (
                    <>
                      <p className="mt-5">To add a new user, click here to</p>
                      <p style={{ color: "#2D9CDB", cursor: "pointer" }} onClick={() => setOpenUsersAddDialog(true)}>
                        Add New Users
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        {users?.length ? (
          <>
            <Modal.Footer>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenUsersManageDialog(true);
                  }}>
                  Manage
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
      <ManageUsers
        user={user}
        users={users}
        userAccess={userAccess}
        userForEdit={userForEdit}
        setUserForEdit={setUserForEdit}
        defaultPosPin={defaultPosPin}
        openUsersManageDialog={openUsersManageDialog}
        setOpenUsersManageDialog={setOpenUsersManageDialog}
      />
      <UserEditForm
        edit={false}
        userForEdit={userForEdit}
        defaultPosPin={defaultPosPin}
        openUsersAddDialog={openUsersAddDialog}
        setOpenUsersAddDialog={setOpenUsersAddDialog}
      />
    </>
  );
};
export default UserProfilePage;
