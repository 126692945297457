import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { i18n } from "../../../private/languageSelector";
import { ManagePaymentTypes } from "./ManagePaymentTypes";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { PAYMENT_TYPES } from "../../../../../helpers/constants";

function PaymentTypeDialog({
  userAccess,
  savePayments,
  companyForEdit,
  secondaryAction,
  openPaymentTypeDialog,
}) {
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);
  const [showManagePayment, setShowManagePayments] = useState(false);

  useEffect(() => {
    if (companyForEdit?.paymentTypes?.length) {
      setPaymentTypesToShow([...companyForEdit?.paymentTypes]);
    } else {
      setPaymentTypesToShow([...PAYMENT_TYPES]);
    }
  }, [companyForEdit]);

  return (
    <>
      <Modal
        size="md"
        className="modal-drawer"
        show={openPaymentTypeDialog}
        onHide={() => {}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => secondaryAction()}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("global.PaymentChannels")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div>
            <div className="payment-types f-font">
              <div>{i18n("INVOICES.PaymentChannel")}</div>
              <div>{i18n("global.Status")}</div>
            </div>

            {paymentTypesToShow?.length
              ? paymentTypesToShow
                  .filter((item) => !item.isDeleted)
                  .map((item, i) => {
                    return (
                      <div key={i}>
                        <div className="payment-types mt-8">
                          <div>
                            <h5 className="heading-color m-0">{item.name}</h5>
                            {item.default ? (
                              <small className="default-payment">
                                Default payment type
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className={
                              item.active
                                ? "payment-type-active"
                                : "payment-type-inActive"
                            }
                          >
                            {item.active ? "Active" : "In Active"}
                          </div>
                        </div>
                        <hr className={`${item.default ? "mt-0" : ""}`} />
                      </div>
                    );
                  })
              : ""}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setShowManagePayments(true);
            }}
            className="btn btn-primary btn-elevate"
          >
            Manage
          </button>
        </Modal.Footer>
      </Modal>
      <ManagePaymentTypes
        userAccess={userAccess}
        savePayments={savePayments}
        companyForEdit={companyForEdit}
        showManagePayment={showManagePayment}
        setShowManagePayments={setShowManagePayments}
      />
    </>
  );
}
export default PaymentTypeDialog;
