import { createSlice } from "@reduxjs/toolkit";

const initialTaxesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  taxForEdit: undefined,
  error: null,
  navigate: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const taxesSlice = createSlice({
  name: "taxes",
  initialState: initialTaxesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    taxFetched: (state, action) => {
      state.actionsLoading = false;
      state.taxForEdit = {
        ...action.payload.taxForEdit,
      };
      state.error = null;
    },
    taxesFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    taxesCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.navigate = true;
    },
    taxesUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
    },

    taxesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
