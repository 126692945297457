import axios from "axios";
import moment from "moment";

export const INVOICE_URL = "api/invoice";
export const INVOICE_NOTIFICATION_URL = "api/notification";
export const FILTER_INVOICE_URL = "api/invoices/filter";

// CREATE =>  POST: add a new invoice to the server
export function createInvoice(invoice) {
  const invoiceData = {
    customerId: invoice?.receiverInformation?._id
      ? invoice?.receiverInformation?._id
      : undefined,
    store: invoice?.store,

    invoiceData: {
      ...invoice,
      channel: "web",
      date: moment(invoice.date).format(),
      createdAt: moment(invoice.createdAt).format(),
      invoiceDate: moment(invoice.invoiceDate).format(),
      paymentDate: moment(invoice.paymentDate).format(),
      dueDate: moment(invoice.dueDate).format(),
    },
  };
  return axios.post(`${INVOICE_URL}`, invoiceData);
}

// UPDATE => PUT: update the invoice on the server
export function updateInvoice(invoice) {
  const invoiceData = {
    customerId: invoice?.receiverInformation?._id,
    store: invoice?.store,

    invoiceData: {
      ...invoice,
      channel: "web",
      date: moment(invoice.date).format(),
      createdAt: moment(invoice.createdAt).format(),
      invoiceDate: moment(invoice.invoiceDate).format(),
      paymentDate: moment(invoice.paymentDate).format(),
      dueDate: moment(invoice.dueDate).format("YYYY-MM-DD"),
    },
  };
  return axios.patch(`${INVOICE_URL}/${invoice.id}`, invoiceData);
}
// READ
export function getAllInvoices() {
  return axios.get(`${INVOICE_URL}`);
}

export function getFilteredInvoices(data) {
  let type = data.type ? data.type : "";
  let product = data.product ? data.product : "";
  let user = data.user ? data.user : "";
  let status = data.status ? data.status : "";
  let channel = data.channel ? data.channel : "";
  let store = data.store ? data.store : "";
  let parentInvoice = data.parentInvoice ? data.parentInvoice : "";

  let startDate = data.startDate
    ? moment(data.startDate).format("MM/DD/YYYY")
    : "";

  let endDate = data.endDate ? moment(data.endDate).format("MM/DD/YYYY") : "";

  return axios.get(
    `${FILTER_INVOICE_URL}?${type ? `type=${type}&` : ""}${
      channel ? `channel=${channel}&` : ""
    }${product ? `product=${product}&` : ""}${user ? `user=${user}&` : ""}${
      status ? `status=${status}&` : ""
    }${startDate ? `startDate=${startDate}&` : ""}${
      endDate ? `endDate=${endDate}&` : ""
    }${store ? `store=${store}&` : ""}${
      parentInvoice ? `parentInvoice=${parentInvoice}` : ""
    }`
  );
}

export function getInvoiceById(invoiceId) {
  return axios.get(`${INVOICE_URL}/${invoiceId}`);
}

export function getInvoicesforLineChart(startDate, endDate) {
  return axios.get(
    `${FILTER_INVOICE_URL}?${startDate ? `startDate=${startDate}&` : ""}${
      endDate ? `endDate=${endDate}&` : ""
    }`
  );
}

export function updateInvoiceFromDialog(invoice) {
  const invoiceData = {
    customerId: invoice?.invoiceData?.receiverInformation?._id,
    invoiceData: { ...invoice.invoiceData },
  };
  return axios.patch(`${INVOICE_URL}/${invoice.id}`, invoiceData);
}

export function invoiceRefundUpdate(invoice) {
  return axios.patch(`${INVOICE_URL}/${invoice._id}`, invoice);
}

export function createRefundInvoice(invoice) {
  // console.log(invoice);
  invoice.invoiceData.products.forEach((item) => {
    if (item.refundQuantity > 0) {
      return (item.quantity = item.refundQuantity);
    }
  });

  invoice.invoiceData.products.forEach((item) => {
    delete item.refundAmount;
    delete item.refundQuantity;
  });
  let refundInvoice = {
    status: "paid",
    customerId: invoice.customerId,
    invoiceData: invoice.invoiceData,
    parentInvoice: invoice.parentInvoice,
    parentInvoiceNumber: invoice.parentInvoiceNumber,
  };
  return axios.post(`${INVOICE_URL}`, refundInvoice);
}

// UPDATE Status
export function updateStatusForInvoices(ids, status) {
  return axios.post(`${INVOICE_URL}/updateStatusForInvoices`, {
    ids,
    status,
  });
}

// DELETE => delete the invoice from the server
export function deleteInvoice(invoiceId, status) {
  return axios.patch(`${INVOICE_URL}/status/${invoiceId}`, { status: status });
}

// DELETE Invoices by ids
export function deleteInvoices(ids) {
  let toDelete = "?ids=";
  ids.forEach((item) => {
    toDelete = toDelete + item + ",";
  });
  var tmp = toDelete.split(""); // convert to an array
  tmp.splice(toDelete.length - 1, 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
  tmp = tmp.join("");
  return axios.patch(`${INVOICE_URL}/status${tmp}`);
}

export function getInvoiceByDate(date) {
  return axios.get(
    `/api/invoices/search?startDate=${date.startDate}&endDate=${date.endDate}`
  );
}

// NOTIFICATIONS

export function getNotifications() {
  return axios.get(`${INVOICE_NOTIFICATION_URL}`);
}
export function readSingleNotification(id) {
  return axios.patch(`${INVOICE_NOTIFICATION_URL}/${id}`, { isRead: true });
}

// read all notifications by ids
export function readAllNotifications(ids) {
  let toAssign = "?ids=";
  ids.forEach((item) => {
    toAssign = toAssign + item + ",";
  });
  var tmp = toAssign.split("");
  tmp.splice(toAssign.length - 1, 1);
  tmp = tmp.join("");
  console.log(tmp);
  return axios.patch(`${INVOICE_NOTIFICATION_URL}${tmp}`);
}
