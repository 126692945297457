import { createSlice } from "@reduxjs/toolkit";

const initialDiscountsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  discountForEdit: undefined,
  lastError: null,
  navigate: false,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const discountsSlice = createSlice({
  name: "discounts",
  initialState: initialDiscountsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      state.navigate = false;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    discountFetched: (state, action) => {
      state.actionsLoading = false;
      state.discountForEdit = {
        ...action.payload.discountForEdit,
      };
      state.error = null;
    },
    discountsFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
    },
    discountCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.navigate = true;
    },
    discountUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.navigate = true;
    },

    discountDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
  },
});
