import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FadeLoader } from "react-spinners";
import { PaymentCompletedDialoge } from "./PaymentCompletedDialoge";
import IconClose from "../../../../../assets/img/icon-close.svg";

export const PaymentCompletionDialoge = ({ showPaymentCompletionDialoge, setShowPaymentCompletionDialoge }) => {
  const [showPaymentCompletedDialoge, setShowPaymentCompletedDialoge] = useState(false);
  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={showPaymentCompletionDialoge}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setShowPaymentCompletionDialoge(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <>
            <div className="d-flex justify-content-center align-items-center h-50">
              <div className="text-center">Contacting your bank to complete the payment...</div>
            </div>
            <div className="text-center mr-8">
              <FadeLoader height={10} width={10} radius={"100%"} margin={2} color={"#2D9CDB"} />
            </div>
          </>
        </Modal.Body>
      </Modal>
      <PaymentCompletedDialoge
        showPaymentCompletedDialoge={showPaymentCompletedDialoge}
        setShowPaymentCompletedDialoge={setShowPaymentCompletedDialoge}
      />
    </>
  );
};
