import React from 'react'
import {Switch} from "react-router-dom"
import SalesByUser from './analyticsView/SalesByUser'

const SalesByUserPage = () => {
  return (
    <Switch>
    <SalesByUser />
  </Switch>
  )
}

export default SalesByUserPage