import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { Input, Card } from "../../../../_metronic/_partials/controls";
import { CATEGORY_COLORS } from "../../../../helpers/constants";
import "react-phone-input-2/lib/style.css";
import IconClose from "../../../../assets/img/icon-close.svg";
import * as actions from "../../../../_redux/category/categoryActions";
import { i18n } from "../../private/languageSelector";
import cloneDeep from "lodash/cloneDeep";
import toast from "react-hot-toast";

// Validation schema
export const AddCategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 Alphabets")
    .max(50, "Maximum 50 Alphabets")
    .required("English name is required"),

  description: Yup.string(),
  color: Yup.string(),
});

const initAddState = {
  name: "",
  color: "",
  isActive: true,
  description: "",
};

export function AddCategoryForm({ edit, category, openCategoryAddDialog, setOpenCategoryAddDialog }) {
  const dispatch = useDispatch();
  const [categoryColors, setCategoryColors] = useState([]);
  const [selectColor, setSelectColor] = useState("");
  const [prevIndex, setPrevIndex] = useState("");

  useEffect(() => {
    let data = cloneDeep([...CATEGORY_COLORS]);
    setCategoryColors(data);
    if (edit) {
      setSelectColor(category?.singleCategory?.color);
    } else {
      setSelectColor("");
    }
  }, [category, edit]);

  const checkedHandler = (index) => {
    setPrevIndex(index);
    categoryColors[index].isChecked = true;
    if (prevIndex !== "") {
      categoryColors[prevIndex].isChecked = false;
    }
  };

  return (
    <>
      <Modal
        className="modal-drawer invoice-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openCategoryAddDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              setOpenCategoryAddDialog(false);
              let categoryArray = CATEGORY_COLORS?.map((item) => {
                return { ...item, isChecked: false };
              });
              setCategoryColors([...categoryArray]);
            }}>
            <img src={IconClose} alt="loading" />
          </button>
          {(!category?.singleCategory?._id && edit === false) || (category?.singleCategory?._id && edit === false) ? (
            <>{<Modal.Title>{i18n("Category.AddANewCategory")}</Modal.Title>}</>
          ) : (
            <> {<Modal.Title>{i18n("Category.EditCategory")}</Modal.Title>}</>
          )}
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={edit ? category?.singleCategory : initAddState}
          validationSchema={AddCategorySchema}
          onSubmit={(values, { resetForm }) => {
            if (
              (category?.singleCategory?._id && edit === false) ||
              (!category?.singleCategory?._id && edit === false)
            ) {
              let newCategory = {
                isActive: values.isActive,
                name: values.name,
                description: values.description,
                color: selectColor ? selectColor : "#2d9cdb",
              };
              dispatch(actions.createCategory(newCategory)).then((res) => {
                if (res) {
                  toast.success("Category Created");
                  dispatch(actions.fetchCategories());
                  setOpenCategoryAddDialog(false);
                  resetForm();
                }
              });
            } else {
              dispatch(
                actions.updateCategory({
                  ...values,
                  color: selectColor ? selectColor : "#2d9cdb",
                })
              ).then(() => {
                setOpenCategoryAddDialog(false);
                resetForm();
              });
            }
            let categoryArray = CATEGORY_COLORS?.map((item) => {
              return { ...item, isChecked: false };
            });
            setCategoryColors([...categoryArray]);
          }}>
          {({ handleSubmit, values, setFieldValue }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <Card>
                    <div className="invoice-title border-bottom-0">
                      <p className="m-0">{i18n("Category.CategoryInformation")}</p>
                    </div>
                    <div className="invoice-items row">
                      <div className="col-12">
                        <div className="form-group">
                          <Field
                            name="name"
                            component={Input}
                            placeholder={i18n("global.Name")}
                            label={i18n("global.Name")}
                            withFeedbackLabel={false}
                          />
                        </div>
                        <div className="form-group">
                          <p>{i18n("Category.CategoryDescription")}</p>
                          <textarea
                            rows="7"
                            style={{ width: "100%" }}
                            name="description"
                            placeholder={i18n("Category.CategoryDescription")}
                            label={i18n("Category.Name")}
                            value={values?.description}
                            onChange={(e) => setFieldValue("description", e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="card-listitem select-color">
                    <div className="form-select">
                      <div className="invoice-title border-bottom-0">
                        <p className="m-0">{i18n("Category.RepresentationOnPOS")}</p>
                      </div>
                    </div>
                    <div className="mb-5">
                      <label>{i18n("Category.SelectCategoryColor")}</label>
                    </div>
                    <ul className="color-selection">
                      {categoryColors?.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className={`card-icon category ${item.isChecked ? "selected" : ""}`}
                            onClick={() => {
                              setSelectColor(item.color);
                              checkedHandler(index);
                            }}
                            style={{
                              backgroundColor: item.color,
                            }}
                          />
                        );
                      })}
                    </ul>
                  </Card>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <div className="row justify-content-center">
                  <button
                    type="button"
                    onClick={() => {
                      setSelectColor("");
                      setOpenCategoryAddDialog(false);
                      setCategoryColors([...CATEGORY_COLORS]);
                    }}
                    className="btn btn-outline-primary btn-elevate mr-5">
                    {i18n("Category.Back")}
                  </button>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate f-button"
                    style={{ display: "block" }}>
                    {i18n("Category.Save")}
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
}
