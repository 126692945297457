import React, { useState, useEffect } from "react";
import moment from "moment";
import IconQr from "../../../../assets/img/icon-qr.svg";

export const PosInvoicePreview = ({ invoice, invoiceData, companyData }) => {
  const [products, setProducts] = useState([]);
  const [filterTaxess, setFilterTaxess] = useState([]);
  let totalValueWithDiscount =
    Number(invoice?.invoiceData?.productsTotal) -
    Number(invoice?.invoiceData?.discountTotal);
  useEffect(() => {
    if (invoice) {
      setProducts([...invoice.invoiceData.products]);
    }
  }, [invoice]);

  function calculateDiscounts(products) {
    const discountsMap = {};

    products.forEach((product) => {
      product.discounts.forEach((discount) => {
        if (discount.isSelected) {
          const key = discount.name;
          if (!discountsMap[key]) {
            discountsMap[key] = 0;
          }
          if (discount.type === "%") {
            discountsMap[key] += parseFloat(
              (discount.value * product.price) / 100
            );
          } else {
            discountsMap[key] += parseFloat(discount.value);
          }
        }
      });
    });

    return discountsMap;
  }
  const filterTaxes = (products) => {
    if (products) {
      const allProductTaxes = [];
      for (let i = 0; i < products?.length; i++) {
        products[i].taxes.forEach((tax) => {
          allProductTaxes.push(tax);
        });
      }
      let taxesUnique = [];
      const uniqueTaxesByProduct = [];
      const uniqueArray = allProductTaxes.map((tax) => {
        const arr = allProductTaxes.filter((item) => item._id === tax._id);
        if (arr.length) {
          let includedValue = arr.reduce((a, b) => {
            return a + b.includedValue;
          }, 0);
          let excludedValue = arr.reduce((a, b) => {
            return a + b.exclusiveVaue;
          }, 0);
          taxesUnique = [...taxesUnique, arr];
          uniqueTaxesByProduct.push({ ...tax, includedValue, excludedValue });
        }
      });
      const newuniqueArray = uniqueTaxesByProduct.filter(function(
        value,
        index,
        self
      ) {
        return index === self.findIndex((t) => t._id === value._id);
      });

      return newuniqueArray?.length ? newuniqueArray : allProductTaxes;
    }
  };
  useEffect(() => {
    if (products) {
      let includedTaxes = filterTaxes(products);
      setFilterTaxess([...includedTaxes]);
    }
  }, [products]);

  console.log(invoice,"invoice")

  const aggregatedDiscounts = calculateDiscounts(
    invoice?.invoiceData?.products || []
  );
  return (
    <div className="pos-container">
      <div className="container">
        <div className="pos-invoice">
          <div className="ml-2">
            {invoice?.invoiceData?.senderInformation?.companyId?.image ? (
              <>
                <img
                  style={{ maxWidth: "328px" }}
                  src={
                    invoice?.invoiceData?.senderInformation?.companyId?.image
                  }
                  alt="loading"
                />
              </>
            ) : (
              ""
            )}
          </div>
          <div className="">
            <h2 className="mb-0">
              <b>
                {invoice?.invoiceData?.senderInformation?.companyId?.companyName
                  ? invoice?.invoiceData?.senderInformation?.companyId
                      ?.companyName
                  : ""}
                .
              </b>
            </h2>
            <p className="mt-0 mb-0 light-color">
              {invoice?.invoiceData?.senderInformation?.companyId?.street
                ? invoice?.invoiceData?.senderInformation?.companyId?.street
                : ""}
            </p>
            <p className="mt-0 mb-0 light-color">
              {invoice?.invoiceData?.senderInformation?.companyId?.city ||
              invoice?.invoiceData?.senderInformation?.companyId?.state
                ? `${
                    invoice?.invoiceData?.senderInformation?.companyId?.city
                      ? invoice?.invoiceData?.senderInformation?.companyId?.city
                      : ""
                  }, ${
                    invoice?.invoiceData?.senderInformation?.companyId?.state
                      ? invoice?.invoiceData?.senderInformation?.companyId
                          ?.state
                      : ""
                  }`
                : ""}
            </p>
            {invoice?.invoiceData?.senderInformation?.companyId?.taxNumber ? (
              <>
                <div className="mt-0 mb-0  light-color">
                  <div>{`TAX Number ${" "}${
                    invoice?.invoiceData?.senderInformation?.companyId
                      ?.taxNumber
                  }`}</div>
                  <div className="doted-line mw-100 mb-2 mt-2"></div>
                  <b>Simplified TAX Invoice</b>
                </div>
              </>
            ) : (
              ""
            )}
            {invoice?.invoiceData?.invoiceType === "refund" ? (
              !invoice?.invoiceData?.senderInformation?.companyId?.taxNumber ? (
                <>
                  <div>
                    <div className="doted-line mw-100 mb-2 mt-2"></div>
                    <b>Credit note</b>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <b>Credit note</b>
                  </div>
                </>
              )
            ) : (
              ""
            )}
            <div className="doted-line mb-2 mt-2"></div>

            <h2>
              {invoice?.invoiceData?.senderInformation?.companyId?.currency}{" "}
              {invoice?.invoiceData?.invoiceType !== "refund" ? (
                <>
                  {invoice?.invoiceData?.totalSales
                    ? Number(invoice?.invoiceData?.totalSales)?.toFixed(2)
                    : 0.0}
                </>
              ) : (
                <>
                  {invoice?.invoiceData?.paidTotal
                    ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2)
                    : 0.0}
                </>
              )}
            </h2>
            <h5>Total</h5>
            <div className="doted-line mb-2 mt-2"></div>
            <div className="row">
              <div className="col-7 text-left">
                <div className="bold-color">
                  Cashier:{" "}
                  {invoice?.invoiceData?.activeuser
                    ? invoice?.invoiceData?.activeuser
                    : ""}
                </div>
              </div>
              <div className="col-5 text-right">
                <div className="light-color">
                  {invoice?.invoiceData?.invoiceNumber
                    ? invoice?.invoiceData?.invoiceNumber
                    : ""}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-7 text-left">
                <div className="bold-color">
                  POS:{" "}
                  {invoice?.invoiceData?.pos ? invoice?.invoiceData?.pos : ""}{" "}
                </div>
                <div className="col-5 text-right"></div>
                <div className="light-color">
                  <span className="mr-2">
                    {" "}
                    {moment(invoiceData?.invoiceData?.invoiceDate).format(
                      "MM/DD/YYYY"
                    )}
                  </span>
                  {moment(invoice?.invoiceData?.invoiceDate).format("HH:mm")}
                </div>
                {invoice?.invoiceData?.invoiceType === "refund" ? (
                  <>
                    <div className="light-color">
                      <span>
                        {" "}
                        {`Partent Invoice: ${" "}${
                          invoice?.invoiceData?.parentinvoiceNumber
                            ? invoice?.invoiceData?.parentinvoiceNumber
                            : ""
                        }`}
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {invoiceData?.invoiceData?.receiverInformation?.name ? (
              <>
                <div className="doted-line mb-2 mt-2"></div>
                <div className="row">
                  <div className="col-7 text-left">
                    <div className="bold-color">
                      Customer:{" "}
                      {invoiceData?.invoiceData?.receiverInformation?.name
                        ? invoiceData?.invoiceData?.receiverInformation?.name
                        : ""}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="doted-line mb-2 mt-2"></div>

            {invoiceData?.invoiceData?.products?.map((item) => (
              <>
                <div className="row">
                  <div className="col-7 text-left">
                    <div>{item?.name ? item?.name : ""}</div>
                  </div>
                  <div className="col-5 text-right ">
                    <div>
                      {item?.totalPrice
                        ? Number(item?.totalPrice)?.toFixed(2)
                        : 0.0}
                    </div>
                  </div>
                </div>

                <div className="pos-invoice-cashier mt-1 mb-1">
                  <div className="light-color">{`${item?.quantity} x ${item?.price}`}</div>
                </div>
              </>
            ))}
            <div className="doted-line mb-2 mt-2"></div>
            {Number(invoice?.invoiceData?.discountTotal) ? (
              <>
                <div className="row">
                  <div className="col-7 text-left">
                    <b> Sub Total </b>
                  </div>
                  <div className="col-5 text-right">
                    <b>
                      {invoice?.invoiceData?.productsTotal
                        ? Number(invoice?.invoiceData?.productsTotal)?.toFixed(
                            2
                          )
                        : 0.0}
                    </b>
                  </div>
                </div>

                <div className="row">
                  <div className="col-7 text-left">
                    <b> Discount </b>
                  </div>
                  <div className="col-5 text-right">
                    <b>
                      {invoice?.invoiceData?.discountTotal
                        ? `-${Number(
                            invoice?.invoiceData?.discountTotal
                          )?.toFixed(2)}`
                        : 0.0}
                    </b>
                  </div>
                </div>

                {Object.keys(aggregatedDiscounts).map((discountName, index) => {
                  const totalDiscount = aggregatedDiscounts[discountName];

                  return (
                    <div key={index} className="row">
                      <div className="col-7 text-left">
                        <b>{discountName}</b>
                      </div>
                      <div className="col-5 text-right">
                        -{parseFloat(totalDiscount).toFixed(2)}
                      </div>
                    </div>
                  );
                })}

                <div className="doted-line mb-2 mt-2"></div>
                {invoice?.invoiceData?.taxTotal === "0.00" ? (
                  <>
                    <div className="row">
                      <div className="col-7 text-left">
                        <b> Total </b>
                      </div>
                      <div className="col-5 text-right">
                        <b>
                          {invoice?.invoiceData?.productsTotal
                            ? totalValueWithDiscount?.toFixed(2)
                            : 0.0}
                        </b>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {invoice?.invoiceData?.taxTotal === "0.00" ? (
                  <>
                    <div className="row">
                      <div className="col-7 text-left">
                        <b> Total </b>
                      </div>
                      <div className="col-5 text-right">
                        <b>
                          {invoice?.invoiceData?.productsTotal
                            ? Number(
                                invoice?.invoiceData?.productsTotal
                              )?.toFixed(2)
                            : 0.0}
                        </b>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            )}
            {invoice?.invoiceData?.taxTotal ? (
              <>
                <div className="row mt-3 mb-2">
                  <div className="col-7 text-left">
                    <b>Total Before Tax</b>
                  </div>
                  <div className="col-5 text-right">
                    <b>
                      {invoice?.invoiceData?.totalbeforetax
                        ? Number(invoice?.invoiceData?.totalbeforetax)?.toFixed(
                            2
                          )
                        : 0.0}
                    </b>
                  </div>
                </div>
                {filterTaxess?.map((tax) => (
                  <>
                    <div className="row mt-1">
                      <div className="col-7 text-left">
                        {`${tax?.name}  ${tax?.taxRate}%`}
                        <span className="light-color ml-1">
                          {tax?.type === "Included in the price" ? (
                            <span>(Inclusive)</span>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div className="col-5 text-right">
                        {tax?.includedValue
                          ? Number(tax?.includedValue)?.toFixed(2)
                          : Number(tax?.excludedValue)?.toFixed(2)}
                      </div>
                    </div>
                  </>
                ))}

                <div className="doted-line mb-2 mt-2"></div>
                <div className="row">
                  <div className="col-7 text-left">
                    <b> Total With Tax </b>
                  </div>
                  <div className="col-5 text-right">
                    <b>
                      {invoice?.invoiceData?.totalaftertax
                        ? Number(invoice?.invoiceData?.totalaftertax)?.toFixed(
                            2
                          )
                        : 0.0}
                    </b>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {invoiceData?.invoiceData?.payments?.length <= 1 ? (
              <>
                <div className="row mt-1">
                  <div className="col-7 text-left">
                    <b>{invoice?.invoiceData?.paidTotal ? "Cash" : ""}</b>
                  </div>
                  <div className="col-5 text-right">
                    <b>
                      {invoice?.invoiceData?.paidTotal
                        ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2)
                        : 0.0}
                    </b>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-7 text-left">Change</div>
                  <div className="col-5 text-right">
                    {invoice?.invoiceData?.change
                      ? Number(invoice?.invoiceData?.change)?.toFixed(2)
                      : 0.0}
                  </div>
                </div>
              </>
            ) : (
              invoice?.invoiceData?.payments?.map((item) => (
                <> 
                  {invoice?.invoiceData?.payments?.length > 1 ?(
                         <div className="row mt-2">
                         <div className="col-7 text-left">
                           <b>Amount Due</b>
                         </div>
                         <div className="col-5 text-right">
                           <b>
                             {item?.amount ? Number(item?.amount)?.toFixed(2) : 0.0}
                           </b>
                         </div>
                       </div>
                  ): ""}
         
                  <div className="row mt-1">
                    <div className="col-7 text-left">
                      <b>{item?.method ? item?.method : ""}</b>
                    </div>
                    <div className="col-5 text-right">
                      <b>
                        {item?.cashRecieved ? Number(item?.cashRecieved)?.toFixed(2) : 0.0}
                      </b>
                    </div>
                  </div>
                  {Number(item?.change).toFixed(2) !== "0.00" ? (
                  <div className="row mt-1">
                  <div className="col-7 text-left">Change</div>
                  <div className="col-5 text-right">
                    {item?.change ? item?.change : 0.0}
                  </div>
                </div>
                  ): ""}
                  
                </>
              ))
            )}
            {invoice?.invoiceData?.senderInformation?.companyId?.taxNumber ? (
              <>
                <div className="pos-qrcode">
                  <img src={IconQr} alt="loading" />
                </div>
              </>
            ) : (
              ""
            )}

            <div className="doted-line mb-2 mt-2"></div>
            <div className="mt-2">
              <h3>Thank You, Come Again !</h3>
            </div>
            <div className="mt-3">
              <small>Powerd by Fatoraty@ 2021</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
