import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ withFeedbackLabel, startDate, setStartDate, styleMargin, ...props }) {
  const { errors, touched } = useFormikContext();
  const [field] = useField(props);
  return (
    <div className="form-group datepicker" style={{ marginLeft: `${styleMargin ? "-10rem" : ""}` }}>
      {props.label && (
        <label className="mr-3">
          {props.label}

          {/* <div className="field-info ml-2">
            <i className="fa fa-question"></i>
            <span>
              Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
            </span>
          </div> */}
        </label>
      )}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
      />
      {errors[field.name] && touched[field.name] ? (
        <div className="feedback invalid-datepicker-feedback">{errors[field.name].toString()}</div>
      ) : (
        ""
      )}

      {withFeedbackLabel && (
        <>
          <div className="feedback">
            Please enter <b>{props.label}</b> in 'mm/dd/yyyy' format
          </div>
        </>
      )}
    </div>
  );
}
