import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import toast from "react-hot-toast";
import axios from "axios";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { AsYouType } from "libphonenumber-js/max";
import { i18n } from "../../../pages/private/languageSelector";

let asYouType = new AsYouType();
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  acceptTerms: false,
};

Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      asYouType.reset();
      asYouType.input(value);
      return asYouType.getNumber().isValid();
    },
  });
});

function Registration(props) {
  const { areYou } = props;
  const [loading, setLoading] = useState(false);
  const [stepOneData, setStepOneData] = useState(initialValues);
  const [platFormInfo, setPlatFormInfo] = useState({
    platform: "",
    ipAddress: "",
    timeZone: "",
  });
  const history = useHistory();

  const passwordValidation = i18n("Validation.INVALID_PASSWORD");
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^[a-zA-Z ]*$/, "Alphabets Required")
      .required(i18n("Validation.RequiredField")),
    lastName: Yup.string()
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^[a-zA-Z ]*$/, "Alphabets Required")
      .required(i18n("Validation.RequiredField")),
    email: Yup.string()
      .email(i18n("Validation.WrongEmailFormat"))
      .min(3, i18n("Validation.Minimum3Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(
        // eslint-disable-next-line
        /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
        "Must contain e.g (example._-@example.com)"
      )
      .required(i18n("Validation.RequiredField")),
    phone: Yup.string()

      .validatePhoneNumber(i18n("Validation.INVALID_PHONE"))
      .required(i18n("Validation.RequiredField")),
    password: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(50, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,50}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
    acceptTerms: Yup.bool().required("You must accept the terms and conditions"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid exclamationSign";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const getPlatformInfo = async () => {
    const response = await axios.get("https://api.ipify.org?format=json");
    const countryInfo = await axios.get(`https://ipapi.co/${response?.data?.ip}/json/`);
    const browserInfo = await axios.get("https://api.ipregistry.co/?key=td089a1mybgzdxyb");
    setPlatFormInfo({
      platform: browserInfo?.data?.user_agent?.type === "browser" ? "Web" : "App",
      ipAddress: countryInfo?.data?.ip,
      timeZone: countryInfo?.data?.timezone,
    });
  };

  useEffect(() => {
    getPlatformInfo();
  }, []);
  const formik = useFormik({
    initialValues: stepOneData,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      axios
        .get(`/api/users/search?email=${values.email}`)
        .then((response) => {
          setStatus("Email already in use");
          setSubmitting(false);
          disableLoading();
        })
        .catch((error) => {
          setStatus("");
          setStepOneData({ ...values });
          areYou();
          setSubmitting(false);
          disableLoading();
          const toSubmit = {
            email: values.email,
            password: values.password,
            mobile: values.phone,
            name: {
              first: values.firstName,
              last: values.lastName,
            },
            country: "",
            platformInfo: platFormInfo,
          };
          register(toSubmit)
            .then(({ data }) => {
              disableLoading();
              toast.success(data.message);
              history.push({
                pathname: "/register-next",
                state: values?.email,
              });
            })
            .catch((error) => {
              setSubmitting(false);
              setStatus(error?.message === "Request failed with status code 502" ? "Network Error" : error?.message);
              disableLoading();
            });
        });
    },
  });

  const [passwordShow, setPasswordShow] = useState(false);
  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  return (
    <div className="login-form login-signin">
      <>
        <div className="form-title">
          <h1>{i18n("SignUp.SignUpToFatoraty")}</h1>
          <p>{i18n("SignUp.EnterYourDetailsToCreateYourAccount")}</p>
        </div>

        <form
          id="kt_login_signin_form"
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          onSubmit={formik.handleSubmit}
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <Row>
            <Col xs={6}>
              <div className="form-group with-icon">
                <span style={{ width: "57px" }}>
                  <i className="flaticon2-user"></i>
                </span>
                <field>
                  <input
                    placeholder={i18n("global.FirstName")}
                    type="text"
                    className="form-control"
                    name="firstName"
                    {...formik.getFieldProps("firstName")}
                  />
                  <label>{i18n("global.FirstName")}</label>
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.firstName}</div>
                    </div>
                  ) : null}
                </field>
              </div>
            </Col>
            <Col xs={6}>
              <div className="form-group">
                <input
                  placeholder={i18n("global.LastName")}
                  type="text"
                  className="form-control"
                  name="lastName"
                  {...formik.getFieldProps("lastName")}
                />
                <label>{i18n("global.LastName")}</label>
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.lastName}</div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="form-group with-icon">
            <span>
              <i className="flaticon2-black-back-closed-envelope-shape"></i>
            </span>
            <field>
              <input placeholder={i18n("global.Email")} className="form-control" name="email" {...formik.getFieldProps("email")} />
              <label>{i18n("global.Email")}</label>
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </field>
          </div>
          <div className="form-group with-icon">
            <span>
              <i className="fa fa-mobile"></i>
            </span>
            <field>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="SA"
                style={{ display: "flex" }}
                className={`form-control ${formik.touched.phone && formik.errors.phone ? "is-invalid exclamationSign" : ""} `}
                name="phone"
                {...formik.getFieldProps("phone")}
                value={formik.values.phone}
                onChange={(value) => {
                  formik.setValues({ ...formik.values, phone: value });
                }}
              />
              <label>{i18n("global.Mobile")}</label>
              {formik.touched.phone && formik.errors.phone ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.phone}</div>
                </div>
              ) : null}
            </field>
          </div>
          <div className="form-group with-icon">
            <span>
              <i className="fa fa-lock"></i>
            </span>
            <field>
              <input
                placeholder={i18n("global.Password")}
                type={passwordShow ? "text" : "password"}
                className={`form-control ${getInputClasses("password")}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              <label>{i18n("global.Password")}</label>
              <span
                onClick={passwordShowHandler}
                style={{
                  float: " right",
                  marginTop: "-30px",
                  position: "relative",
                  zIndex: "1",
                  cursor: "pointer",
                  width: "auto",
                }}
              >
                <i className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"}></i>
              </span>

              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </field>
          </div>
          <div className="d-flex justify-content-between align-items-center" style={{ paddingLeft: "45px" }}>
            <div className="mr-auto">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  name="acceptTerms"
                  id="acceptTerms"
                  className="custom-control-input"
                  {...formik.getFieldProps("acceptTerms")}
                  {...(formik.values.acceptTerms ? { checked: true } : {})}
                />
                <label for="acceptTerms" className="custom-control-label">
                  {i18n("SignUp.IAgreeThe")}{" "}
                  <Link to="/terms" target="_blank" rel="noopener noreferrer">
                    {i18n("SignUp.Terms&Conditions")}
                  </Link>
                </label>
              </div>
              <div className="d-block pt-3">
                <span className="font-weight-bold text-dark-50">{i18n("SignUp.AlreadyHaveAnAccount?")}</span>
                <Link to="/auth/login" className="font-weight-bold ml-2" id="kt_login_signup">
                  {i18n("SignUp.Login")}
                </Link>
              </div>
            </div>
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.acceptTerms}</div>
              </div>
            ) : null}
            <button
              type="submit"
              disabled={!formik.values.acceptTerms}
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 ml-auto f-button"
              style={{ width: "auto" }}
            >
              <span> {i18n("SignUp.Submit")}</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
      </>
    </div>
  );
}

export default connect(null, auth.actions)(Registration);
