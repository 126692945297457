import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { NewSubscription } from "./NewSubscription";

const Subscription = ({ openSubsDialog, setOpenSubsDialog }) => {
  const [active, setActive] = useState("");
  const [openNewSubDialog, setOpenNewSubDialog] = useState(false);

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openSubsDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenSubsDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body className="overlay overlay-block cursor-default">
          <>
            <div className="d-flex justify-content-center mt-20">
              <span>
                Your current package: <b className="ml-1">FREE</b>
              </span>
              <span className="ml-5" style={{ color: "#2D9CDB" }}>
                (See Details)
              </span>
            </div>
            <div className="d-flex justify-content-center mt-10">
              <h4>Upgrade NOW</h4>
            </div>
            <div className="d-flex justify-content-center mt-15">
              <h2
                className={`subscription-label ml-1 ${active === "monthly" ? "label-border-color" : ""} `}
                onClick={() => setActive("monthly")}>
                Monthly
              </h2>
              <h2
                className={`subscription-label ml-1 ${active === "yearly" ? "label-border-color" : ""} `}
                onClick={() => setActive("yearly")}>
                Yearly
              </h2>
            </div>
            <div className="d-flex justify-content-center mt-10">
              <div className="subscription-buttons">
                <h2>$ 10</h2>
                <button style={{ borderColor: "#F2994A" }} className="btn btn-outline-primary btn-rounded">
                  Starter
                </button>
                <br />
                <button className="btn btn-primary button-margin-top">Start Now</button>
              </div>
              <div className="subscription-buttons ml-5">
                <h2>$ 16</h2>
                <button style={{ borderColor: "#3CAEA3" }} className="btn btn-outline-primary btn-rounded">
                  Premium
                </button>
                <br />
                <button className="btn btn-primary button-margin-top" onClick={() => setOpenNewSubDialog(true)}>
                  Start Now
                </button>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
      <NewSubscription openNewSubDialog={openNewSubDialog} setOpenNewSubDialog={setOpenNewSubDialog} />
    </>
  );
};
export default Subscription;
