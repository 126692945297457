import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const exportTableToPDF = (tableId, fileName) => {
  const input = document.getElementById(tableId);
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    const blobURL = pdf.output("bloburl");
    window.open(blobURL, "_blank");
  });
};
