import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field } from "formik";
import { Form } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import IconClose from "../../../../assets/img/icon-close.svg";
import { Input, SwitchToggler } from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/discount/discountActions";
import toast from "react-hot-toast";
import { i18n } from "../../private/languageSelector";
import { ToggleSwitch } from "../../../../_metronic/_partials/controls/forms/ToggleSwitch";

export const discountSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, i18n("Validation.Minimum3Symbols"))
    .max(50, i18n("Validation.Maximum50Symbols"))
    .required(i18n("Validation.RequiredField")),

  value: Yup.string(),
  restricted: Yup.boolean(),
});

const initAddState = {
  name: "",
  value: "",
  type: "%",
  restricted: false,
};

export const DiscountEditForm = ({
  edit,
  discountForEdit,
  openDiscountDialog,
  setOpenDiscountDialog,
}) => {
  const dispatch = useDispatch();

  const [type, setType] = useState(false);
  const [amount, setAmount] = useState("");

  const { currency } = useSelector(
    (state) => ({
      currency: state?.auth?.user?.companyId?.currency,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (discountForEdit) {
      setAmount(discountForEdit?.value);
      setType(discountForEdit?.type === "%" ? false : true);
    }
  }, [discountForEdit]);

  const handlingPercentage = (e) => {
    if (!type) {
      if (e.target.value > 99) {
        setAmount(99);
        toast.error("Max % value should not exceed more than 99%");
      }
    }
  };

  useEffect(() => {
    if (!type) {
      if (amount > 99) {
        setAmount(99);
      }
    }
  }, [amount, type]);

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openDiscountDialog}
        onHide={() => {}}
      >
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              setAmount();
              setType(false);
              setOpenDiscountDialog(false);
            }}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>
            {edit === false
              ? i18n("Discount.AddNewDiscounts")
              : i18n("Discount.EditDiscount")}
          </Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={edit ? discountForEdit : initAddState}
          validationSchema={discountSchema}
          onSubmit={(values, { resetForm }) => {
            let discount;
            if (values.type === "%") {
              discount = { ...values, value: amount };
            } else {
              discount = { ...values, value: amount, type: currency };
            }
            if (edit) {
              dispatch(actions.updateDiscount(discount));
            } else {
              dispatch(actions.createDiscount(discount));
            }

            resetForm();
            setAmount();
            setType(false);
            setOpenDiscountDialog(false);
          }}
        >
          {({ handleSubmit, values, setValues, setFieldValue }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="invoice-items row">
                    <div className="col-12">
                      <div className="form-group">
                        <Field
                          name="name"
                          component={Input}
                          placeholder={i18n("global.Name")}
                          label={i18n("global.Name")}
                          withFeedbackLabel={true}
                        />
                      </div>
                      <div className="d-flex">
                        <div className="form-group w-100 mb-0 remove-arrows">
                          <Field
                            name="value"
                            component={Input}
                            type="number"
                            placeholder={i18n("global.Value")}
                            label={i18n("global.Value")}
                            value={amount}
                            onChange={(e) => {
                              setFieldValue("value", e.target.value);
                              setAmount(e.target.value);
                              handlingPercentage(e);
                            }}
                            withFeedbackLabel={true}
                          />
                        </div>
                        <ToggleSwitch
                          selected={type}
                          currency={currency}
                          toggleSelected={() => {
                            setType(!type);
                            setFieldValue("type", type ? "%" : "currency");
                          }}
                        />
                      </div>
                      <span className="text-muted" style={{ fontSize: "12px" }}>
                        {i18n("Discount.LeaveBlankToSpecifyPrice")}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-9 my-6">
                      <div>
                        <strong>{i18n("Discount.RestrictedAccess")}</strong>
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="mx-5">
                        <SwitchToggler
                          className="default-switch"
                          id="restricted"
                          name="restricted"
                          checked={values?.restricted || false}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              restricted: e.target.checked,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <p>
                    {i18n(
                      "Discount.OnlyEmployeesWithAppropriateAccessRightAreAbleToApplyThisAccount"
                    )}
                  </p>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <div className="row justify-content-center">
                  <button
                    type="button"
                    onClick={() => {
                      setAmount();
                      setType(false);
                      setOpenDiscountDialog(false);
                    }}
                    className="btn btn-outline-primary btn-elevate mr-5"
                  >
                    {i18n("Discount.Back")}
                  </button>
                  <button
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                    }}
                    className="btn btn-primary btn-elevate f-button"
                    style={{ display: "block" }}
                  >
                    {i18n("Discount.Save")}
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};
