import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import IconClose from "../../../assets/img/icon-close.svg";
import UserAvatar from "../../../assets/img/user-avatar-default.jpg";
import { useSelector } from "react-redux";
import { getUserByToken } from "../../../app/modules/Auth/_redux/authCrud";
import { Card } from "../../../_metronic/_partials/controls";
import { ManageProfile } from "./manageProfile";
import PinInput from "react-pin-input";
import { i18n } from "../../pages/private/languageSelector";

export const MyProfile = ({ openProfileDrawer, setOpenProfileDrawer }) => {
  const [posPin, setPosPin] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [openManageUser, setOpenManageUser] = useState(false);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getUserByToken().then((res) => {
      if (res?.data?.posPin) {
        setPosPin(res?.data?.posPin);
        if (res?.data?.profileImage) {
          setProfilePicture(res?.data?.profileImage);
        }
      }
    });
  }, [openProfileDrawer, openManageUser]);

  return (
    <>
      <Formik enableReinitialize={true} onSubmit={() => {}}>
        {({ handleSubmit }) => {
          return (
            <>
              <Modal
                className="modal-drawer my-profile invoice-drawer"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={openProfileDrawer}
                onHide={() => {
                  setOpenProfileDrawer(false);
                }}
              >
                <Modal.Header>
                  <button type="button" className="btn btn-close" onClick={() => setOpenProfileDrawer(false)}>
                    <img src={IconClose} alt="loading" />
                  </button>
                  <Modal.Title>
                    <span>{user?.name?.first}'s </span>
                    {i18n("UserProfile.Profile")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <Card>
                    <div className="invoice-title" style={{ borderBottom: "none" }}>
                      <h5 className="m-0">
                        <b>{i18n("UserProfile.ProfilePicture")}</b>
                      </h5>
                    </div>
                    <div className="user-avatar">
                      <div className="img-wrap">
                        <img src={profilePicture ? profilePicture : user?.profileImage ? user?.profileImage : UserAvatar} alt="loading" />
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="invoice-title" style={{ borderBottom: "none" }}>
                      <h5 className="m-0">
                        <b>{i18n("UserProfile.MyInformation")}</b>
                      </h5>
                    </div>
                    <div className="row">
                      <h6 className="col-lg-3">{i18n("UserProfile.Name")}: </h6>
                      <h6 className="col-lg-9">
                        {user?.name?.first} {user?.name?.last}
                      </h6>
                      <h6 className="col-lg-3 mt-2">{i18n("UserProfile.Email")}: </h6>
                      <h6 className="col-lg-9 mt-2">{user?.email}</h6>
                      <h6 className="col-lg-3 mt-2">{i18n("UserProfile.Mobile")}: </h6>
                      <h6 className="col-lg-9 mt-2">{user?.mobile}</h6>
                    </div>
                  </Card>
                  <Card>
                    <div className="invoice-title" style={{ borderBottom: "none" }}>
                      <h5 className="m-0">
                        <b>{i18n("UserProfile.MyRole")}</b>
                      </h5>
                    </div>
                    <div className="row">
                      <h6 className="col-lg-12">
                        {user?.rolePayload?.name ? user?.rolePayload?.name.charAt(0).toUpperCase() + user?.rolePayload?.name.slice(1) : "Owner"}
                      </h6>
                      <h6 className="col-lg-6 mt-3">{i18n("UserProfile.POSPIN")}</h6>
                      <div className="form-group">
                        <PinInput
                          disabled
                          length={4}
                          initialValue={posPin}
                          secret={false}
                          focus={false}
                          type="numeric"
                          style={{ width: "100%" }}
                          inputStyle={{
                            width: "30px",
                            height: "30px",
                            border: "0",
                            borderBottom: "1px solid",
                          }}
                        />
                      </div>
                    </div>
                  </Card>
                  <Card>
                    <div className="invoice-title" style={{ borderBottom: "none" }}>
                      <h5 className="m-0">
                        <b>{i18n("UserProfile.StoresAccess")}</b>
                      </h5>
                    </div>
                    <div>
                      <h6>Store 1</h6>
                    </div>
                  </Card>
                  <Card>
                    <div className="invoice-title" style={{ borderBottom: "none" }}>
                      <h5 className="m-0">
                        <b>{i18n("UserProfile.MyPassword")}</b>
                      </h5>
                    </div>
                    <div>
                      <h6>********</h6>
                    </div>
                  </Card>

                  <div className="btn-bar">
                    <button
                      type="button"
                      className="btn btn-pri"
                      onClick={() => {
                        setOpenManageUser(true);
                      }}
                    >
                      {i18n("UserProfile.ManageUser")}
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          );
        }}
      </Formik>
      <ManageProfile
        userPosPin={posPin}
        openManageUser={openManageUser}
        setOpenManageUser={setOpenManageUser}
        setOpenProfileDrawer={setOpenProfileDrawer}
      />
    </>
  );
};
