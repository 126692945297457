import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { usersSlice } from "../_redux/users/usersSlice";
import NewChart from "../_metronic/_partials/charts/NewChart";
import PrivateRoute from "./PrivateRoute";
import { DashboardPage } from "./pages/DashboardPage";
import CompanyPage from "./pages/company/companyPage";
import ProductsPage from "./pages/products/ProductsPage";
import InvoicesPage from "./pages/invoices/InvoicesPage";
import CustomersPage from "./pages/customers/CustomersPage";
import Information from "./pages/company/information/Information";
import Users from "./pages/company/users/Users";
import { CustomersUIProvider } from "./pages/customers/CustomersUIContext";
import Subscription from "./pages/subscription/Subscription";
import AnalyticsPage from "./pages/analytics/analyticsPage";
import SalesByCategoryPage from "./pages/analytics/salesByCategory";
import SalesByProductPage from "./pages/analytics/salesBySummary";
import SalesByUserPage from "./pages/analytics/salesByUser";
import SalesByItemPage from "./pages/analytics/salesByItem";
import TaxReportPage from "./pages/analytics/taxReportPage";
import DiscountReportPage from "./pages/analytics/discountReport";
import SalesByTimePage from "./pages/analytics/salesByTime";
import Taxes from "./pages/company/taxes/Taxes";
import Pos from "./pages/company/pos/Pos";
import Role from "./pages/company/role/Role";
import { UserEditForm } from "./pages/company/company-edit-Dialog/users/UsersEditForm";

const { actions } = usersSlice;

export default function BasePage() {
  const history = useHistory();

  const dispatch = useDispatch();

  const [userAccess, setUserAccess] = useState({});

  const { user } = useSelector((state) => state.auth);

  let backOfficeAccess = user?.rolePayload?.backOffice;
  let access = Object.values(user?.rolePayload?.backOfficePayload);

  let dashboard = access[0]?.selected;
  let customers = access[1]?.selected;
  let invoices = access[2];
  let products = access[3];
  let company = access[4];
  let createData = access[5]?.selected;
  let editData = access[6]?.selected;
  let deleteData = access[7]?.selected;
  let liveChat = access[8]?.selected;

  useEffect(() => {
    setUserAccess({
      company: company,
      invoices: invoices,
      editData: editData,
      products: products,
      liveChat: liveChat,
      customers: customers,
      dashboard: dashboard,
      deleteData: deleteData,
      createData: createData,
    });
  }, [
    user,
    company,
    editData,
    invoices,
    liveChat,
    products,
    customers,
    dashboard,
    deleteData,
    createData,
  ]);

  useEffect(() => {
    dispatch(
      actions.userRoleAccess({
        entities: userAccess,
      })
    );
  }, [dispatch, userAccess]);

  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push("/e-commerce/products/new");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/e-commerce/products/${id}/edit`);
    },
    openViewCustomerDialog: (id) => {
      history.push(`/e-commerce/products/${id}/delete`);
    },
    openDeleteCustomerDialog: () => {
      history.push(`/e-commerce/products/deleteProducts`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/e-commerce/products/fetch`);
    },
    openFetchCustomersDialog: () => {
      history.push("/e-commerce/products/updateStatus");
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/e-commerce/products/updateStatus");
    },
    openCustomerActionsDialog: () => {
      history.push("/e-commerce/products/updateStatus");
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <Switch>
        {user && !user?.accountVerified ? (
          <Redirect exact from="/" to="/verify/account" />
        ) : user?.accountVerified &&
          !(user?.companyId?.profession || user?.companyId?.industry) ? (
          <Redirect exact from="/" to="/user-or-company" />
        ) : (
          <Redirect
            exact
            from="/"
            to={
              backOfficeAccess && access[0]?.selected
                ? "/dashboard"
                : backOfficeAccess && access[1]?.selected
                ? "/customers"
                : backOfficeAccess && access[2]?.selected
                ? "/invoices"
                : backOfficeAccess && access[3]?.selected
                ? "/products"
                : backOfficeAccess && access[4]?.selected
                ? "/company"
                : backOfficeAccess && access[5]?.selected
                ? "/information"
                : backOfficeAccess && access[6]?.selected
                ? "/subscription"
                : backOfficeAccess && access[7]?.selected
                ? "/subscription/new"
                : backOfficeAccess && access[8]?.selected
                ? "/users"
                : backOfficeAccess && access[9]?.selected
                ? "/users/add"
                : backOfficeAccess && access[5]?.selected
                ? "/analytics"
                : backOfficeAccess && access[6]?.selected
                ? "/sales-by-product"
                : backOfficeAccess && access[7]?.selected
                ? "/sales-by-category"
                : backOfficeAccess && access[8]?.selected
                ? "/sales-by-user"
                : backOfficeAccess && access[8]?.selected
                ? "/sales-by-time"
                : backOfficeAccess && access[8]?.selected
                ? "/sales-by-payment-channel"
                : backOfficeAccess && access[8]?.selected
                ? "/tax-report"
                : backOfficeAccess && access[8]?.selected
                ? "/discount-report"
                : "/error/access-denied"
            }
          />
        )}
        <PrivateRoute
          path="/dashboard"
          routeAccess={backOfficeAccess && access[0]?.selected}
          component={DashboardPage}
        />
        <PrivateRoute
          path="/customers"
          routeAccess={backOfficeAccess && access[1]?.selected}
          component={CustomersPage}
        />
        <PrivateRoute
          path="/invoices"
          routeAccess={backOfficeAccess && access[2]?.selected}
          component={InvoicesPage}
        />
        <PrivateRoute
          path="/products"
          routeAccess={backOfficeAccess && access[3]?.selected}
          component={ProductsPage}
        />
        <PrivateRoute
          path="/company"
          routeAccess={backOfficeAccess && access[4]?.selected}
          component={CompanyPage}
        />
        <PrivateRoute
          path="/information"
          routeAccess={backOfficeAccess && access[5]?.selected}
          component={Information}
        />
        <PrivateRoute
          path="/subscription"
          routeAccess={backOfficeAccess && access[6]?.selected}
          component={Subscription}
        />
        <PrivateRoute
          path="/subscription/new"
          routeAccess={backOfficeAccess && access[7]?.selected}
          component={Subscription}
        />
        <PrivateRoute
          path="/subscription/add-payment-card"
          routeAccess={backOfficeAccess && access[7]?.selected}
          component={Subscription}
        />
        <PrivateRoute
          path="/users"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={Users}
        />
        <PrivateRoute
          path="/users/add"
          routeAccess={backOfficeAccess && access[9]?.selected}
          component={Users}
        />
        <Route path="/new-chart" component={NewChart} />
        <PrivateRoute
          path="/analytics"
          routeAccess={backOfficeAccess && access[5]?.selected}
          component={AnalyticsPage}
        />
        <PrivateRoute
          path="/sales-by-product"
          routeAccess={backOfficeAccess && access[6]?.selected}
          component={SalesByProductPage}
        />
        <PrivateRoute
          path="/sales-by-category"
          routeAccess={backOfficeAccess && access[7]?.selected}
          component={SalesByCategoryPage}
        />
        <PrivateRoute
          path="/sales-by-user"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={SalesByUserPage}
        />
        <PrivateRoute
          path="/sales-by-payment-channel"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={SalesByItemPage}
        />
        <PrivateRoute
          path="/sales-by-time"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={SalesByTimePage}
        />
        <PrivateRoute
          path="/tax-report"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={TaxReportPage}
        />
        <PrivateRoute
          path="/discount-report"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={DiscountReportPage}
        />
        <PrivateRoute
          path="/user/setup-owner-pos-pin"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={Users}
        />
        <PrivateRoute
          path="/users/edit/delete"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={UserEditForm}
        />
        {/* <PrivateRoute
          path="/taxes"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={Taxes}
        /> */}
        {/* <PrivateRoute
          path="/pos-devices"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={Pos}
        /> */}
        {/* <PrivateRoute
          path="/roles"
          routeAccess={backOfficeAccess && access[8]?.selected}
          component={Role}
        /> */}
        <Redirect to="/error/error-v3" />
      </Switch>
    </CustomersUIProvider>
  );
}
