import React from 'react'
import { Switch } from "react-router-dom";
import SalesByCategory from './analyticsView/SalesByCategory';


const SalesByCategoryPage = () => {
  return (
    <Switch>
    <SalesByCategory />
  </Switch>
  )
}

export default SalesByCategoryPage