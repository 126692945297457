import React from 'react'
import {Switch} from "react-router-dom"
import SalesByPaymentChannel from './analyticsView/SalesByPaymentChannel'

const SalesByItemPage = () => {
  return (
    <Switch>
    <SalesByPaymentChannel />
  </Switch>
  )
}

export default SalesByItemPage