import React, { useEffect, useState } from "react";

import { BSSINESS_CARDS, HOMEPAGE_CARDS } from "../../../helpers/constants";
import HomeHeader from "./HomeHeader";
import HomeContainer from "./HomeContainer";
import HomeFooter from "./HomeFooter";

const HomeWrapper = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="homePage-wrapper pt-5">
      <HomeHeader />
      <HomeContainer />
      <div className="started-header">
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <p className="tool-text" style={{ fontWeight: "600", fontSize: "35px", color: "#FFFFFF", textAlign: "center" }}>
            Easy tools that enable you to grow and expand your business
          </p>
        </div>
      </div>
      <div className="cards-details">
        <div className="container">
          <div style={{ maxWidth: "1300px", justifyContent: "center" }} className="d-flex flex-wrap">
            {HOMEPAGE_CARDS.map((card, index) => (
              <div key={index} className="home-card" style={{ width: "100%", marginBottom: "38px", borderRadius: "10px" }}>
                <div>
                  <div style={{ alignContent: "center", alignItems: "center" }} className="p-5">
                    <div style={{ textAlign: "center", alignContent: "center", alignItems: "center" }}>
                      <img className="homeCard-img" src={card?.item?.img} alt="" />
                    </div>
                    <p
                      style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#4F4F4F",
                        lineHeight: "24px",
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {card?.item?.tag}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="started-header">
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <p className="tool-text" style={{ fontWeight: "600", fontSize: "35px", color: "#FFFFFF", textAlign: "center" }}>
            What is your bussiness?
          </p>
        </div>
      </div>

      <div className="bussiness-card-wrapper" style={{ maxWidth: "1100px", margin: "0 auto", display: "flex", justifyContent: "center" }}>
        <div className="row">
          {BSSINESS_CARDS.map((card, index) => (
            <div key={index} className="col-4 p-0">
              <div className="bussiness-card">
                <div className="p-5">
                  <p
                    className="m-0 p-0"
                    style={{
                      fontWeight: "400",

                      color: "#4F4F4F",
                      textAlign: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {screenSize < 700 && card?.item?.tag === "Clothes & Retail"? "Retail":card?.item?.tag}
                  </p>
                  <div className="text-center">
                    <img width={"100%"} src={card?.item?.img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <HomeFooter />
    </div>
  );
};

export default HomeWrapper;
