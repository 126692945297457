import { Storage } from "react-jhipster";
import { FATORATAY_AUTH } from "../../src/helpers/constants";

export default function setupAxios(axios, store, logout) {
  const TIMEOUT = 1 * 60 * 1000;
  axios.defaults.timeout = TIMEOUT;
  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

  const onResponseSuccess = (response) => response;
  const onResponseError = (err) => {
    if (!err.status) {
      // logout();
    }
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 401 || status === 502) {
      logout();
    }
    return Promise.reject(err);
  };

  axios.interceptors.request.use(
    (config) => {
      const token =
        Storage.session.get(FATORATAY_AUTH) ||
        Storage.local.get(FATORATAY_AUTH);

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
}
