import React from "react";
import { Dropdown } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import flag from "../../../../_assets/img/saudi-arabia.svg";
import IconUnitedStates from "../../../../../assets/img/united-states.svg";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../../../app/modules/Auth/_redux/authCrud";
import toast from "react-hot-toast";

export function LanguageSelectorDropdown() {
  const { user } = useSelector(
    (state) => ({
      user: state?.auth?.user,
    }),
    shallowEqual
  );
  const { language } = useSelector((state) => state.auth);

  const handleLanguage = async (language) => {
    try {
      localStorage.setItem("language", language);
      await actions.updateUserProfile(user?._id, {
        ...user,
        language: language,
      });
      toast.success("User Language Updated");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to update user language");
      console.error("Error updating user profile:", error);
    }
  };
  return (
    <Dropdown align="end">
      <Dropdown.Toggle as="div" id="dropdown-toggle-my-cart">
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip
              id="language-panel-tooltip"
              style={{ visibility: "hidden" }}
            >
              Select Language
            </Tooltip>
          }
        >
          <img
            className="h-25px rounded"
            src={language === "arabic" ? flag : IconUnitedStates}
            alt="language selector"
            role="button"
            id="dropdownMenuLink"
          />
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu className="py-2 mt-1 dropdown-menu rounded">
        <Dropdown.Item
          onClick={() => handleLanguage("arabic")}
          className="d-flex align-items-center dropdown-item"
          href="#"
        >
          <img src={flag} alt="flag" className="me-2 w-25 h-25" />
          <span className="ml-2 mr-2">Arabic</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => handleLanguage("english")}
          className="d-flex align-items-center dropdown-item"
          href="#"
        >
          <img
            src={IconUnitedStates}
            alt="IconUnitedStates"
            className="me-2 w-25 h-25"
          />
          <span className="ml-2 mr-2">English</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
