import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ProductStatusCssClasses } from "../ProductsUIHelpers";
import * as actions from "../../../../_redux/products/productsActions";
import { useProductsUIContext } from "../ProductsUIContext";

const selectedProducts = (entities, ids) => {
  const _products = [];
  ids.forEach((id) => {
    const product = entities.find((el) => el.id === id);
    if (product) {
      _products.push(product);
    }
  });
  return _products;
};

export function ProductsUpdateStateDialog({ show, onHide }) {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsUIContext.ids,
      setIds: productsUIContext.setIds,
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  // Products Redux state
  const { products, isLoading, productFilterState } = useSelector(
    (state) => ({
      products: selectedProducts(state.products.entities, productsUIProps.ids),
      isLoading: state.products.actionsLoading,
      productFilterState: state?.products?.productFilterState,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!productsUIProps.ids || productsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for update products status by selected ids
    dispatch(actions.updateProductsStatus(productsUIProps.ids, status)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchProducts(productFilterState, productsUIProps.queryParams)).then(() => {
        // clear selections list
        productsUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal className="modal-drawer" show={show} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Status has been updated for selected products</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {/*begin::Loading*/}
        {isLoading && (
          <div className="overlay-layer">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        {/*end::Loading*/}

        <div className="timeline timeline-5 mt-3">
          {products.map((product) => (
            <div className="timeline-item align-items-start" key={`productsUpdate${product.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i className={`fa fa-genderless text-${ProductStatusCssClasses[product.status]} icon-xxl`} />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span className={`label label-lg label-light-${ProductStatusCssClasses[product.status]} label-inline`}>
                  ID: {product.id}
                </span>
                <span className="ml-3">
                  {product.lastName}, {product.firstName}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select className="form-control" value={status} onChange={(e) => setStatus(+e.target.value)}>
            <option value="0">Suspended</option>
            <option value="1">Active</option>
            <option value="2">Pending</option>
          </select>
        </div>
        <div className="form-group">
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate mr-3">
            Cancel
          </button>
          <button type="button" onClick={updateStatus} className="btn btn-primary btn-elevate">
            Update Status
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
