import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { Input, Select } from "../../../../../_metronic/_partials/controls";
import countryList from "react-select-country-list";
import { SubscriptionPaymentDialoge } from "./SubscriptionPaymentDialoge";

const SubscriptiontSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("First name is required"),
  lastName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Last name is required"),
  addressLine1: Yup.string().required("Address is required"),
  addressLine2: Yup.string(),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  postalCode: Yup.string().required("Postal Code is required"),
});

export function SubscriptionBillingAddressDialoge({ showBillingDialoge, setShowBillingDialoge }) {
  const options = countryList().getData();
  const [showPaymentDialoge, setShowPaymentDialoge] = useState(false);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        validationSchema={SubscriptiontSchema}
        onSubmit={(values) => {
          setShowPaymentDialoge(true);
        }}>
        {({ handleSubmit, values, setValues }) => {
          return (
            <>
              <Modal
                className="modal-drawer"
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                show={showBillingDialoge}
                onHide={() => {}}>
                <Modal.Header>
                  <button type="button" className="btn btn-close" onClick={() => setShowBillingDialoge(false)}>
                    <img src={IconClose} alt="loading" />
                  </button>
                  <Modal.Title>Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <div className="mb-5">
                    <strong>Add your billing address</strong>
                  </div>
                  <Form className="form form-label-right">
                    <div className="form-group">
                      <Field
                        name="firstName"
                        component={Input}
                        placeholder="First Name"
                        label="First Name"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="lastName"
                        component={Input}
                        placeholder="Last Name"
                        label="Last Name"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="addressLine1"
                        component={Input}
                        placeholder="Address Line 1"
                        label="Address Line 1"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="addressLine2"
                        component={Input}
                        placeholder="Address Line 2 ( Optional )"
                        label="Address Line 2 ( Optional )"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                        <Field
                          name="city"
                          component={Input}
                          placeholder="City"
                          label="City"
                          withFeedbackLabel={false}
                        />
                      </div>
                      <div className="form-group col-6">
                        <Field
                          name="state"
                          component={Input}
                          placeholder="State"
                          label="State"
                          withFeedbackLabel={false}
                        />
                      </div>

                      <div className="form-group col-6">
                        <Field
                          name="postalCode"
                          component={Input}
                          placeholder="Postal Code"
                          label="Postal Code"
                          withFeedbackLabel={false}
                        />
                      </div>
                      <div className="form-group form-select col-6">
                        <Field
                          name="country"
                          label="Country"
                          placeholder="Country"
                          component={Select}
                          withFeedbackLabel={false}>
                          {options.map((countries, index) => (
                            <option value={countries.label} key={index}>
                              {countries.label}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row justify-content-center">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setShowBillingDialoge(false)}
                      style={{ display: "block" }}>
                      Back
                    </button>
                    <button
                      type="submit"
                      // onClick={() => handleSubmit()}
                      onClick={() => setShowPaymentDialoge(true)}
                      className="btn btn-primary btn-elevate f-button ml-5">
                      Continue
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
            </>
          );
        }}
      </Formik>
      <SubscriptionPaymentDialoge
        showPaymentDialoge={showPaymentDialoge}
        setShowPaymentDialoge={setShowPaymentDialoge}
      />
    </>
  );
}
