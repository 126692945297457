import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Card, Mobile } from "../../../../../_metronic/_partials/controls";
import IconFatoraty from "../../../../../assets/img/fat-logo.png";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AsYouType } from "libphonenumber-js/max";
import * as userActions from "../../../../../_redux/users/usersActions";
import toast from "react-hot-toast";

let asYouType = new AsYouType();
Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});

// Validation schema
export const AddTaxSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong Email Format")
    .min(3, "Minimum 3 Alphabets")
    .max(50, "Maximum 50 Alphabets")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Mobile is required")
    .validatePhoneNumber("Invalid Phone Number"),
  password: Yup.string()
    .min(8, "Minimum 8 Symbols")
    .max(50, "Maximum 50 Symbols")
    .matches(
      /^(?=.*[0-9])(?=.*[a-z]).{8,50}$/,
      "Password must be at least eight characters long, Contain letters and numbers"
    )
    .required("Password is required"),
});

const initAddState = {
  email: "",
  mobile: "",
  password: "",
};

export function UserRegistration() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = useParams();

  const [userForSetup, setUserForSetup] = useState({});
  const [passwordShow, setPasswordShow] = useState(false);

  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };

  useEffect(() => {
    if (token) {
      dispatch(userActions.fetchSetupUserDetail(token)).then((res) => setUserForSetup(res?.data?.user));
    }
  }, [dispatch, token]);

  return (
    <Card className="h-100 mb-0">
      <Formik
        enableReinitialize={true}
        initialValues={userForSetup ? { ...initAddState, email: userForSetup?.email } : initAddState}
        validationSchema={AddTaxSchema}
        onSubmit={(values) => {
          const toastId = toast.loading("Loading...");
          dispatch(userActions.setupUserAccount(token, values)).then((res) => {
            if (res) {
              history.push("/");
            }
          });
          toast.dismiss(toastId);
        }}>
        {({ handleSubmit, values, setValues }) => (
          <div className="container">
            <div className="text-center mt-5">
              <img src={IconFatoraty} alt="loading" />
            </div>
            <Form className="form form-label-right mt-10">
              <div className="invoice-items row justify-content-center align-items-center">
                <div className="col-6">
                  <div className="form-group">
                    <Field name="email" label="Email" readOnly={true} component={Input} placeholder="Email" />
                  </div>
                  <div className="form-group">
                    <Field
                      component={Mobile}
                      name="mobile"
                      international
                      defaultCountry="SA"
                      style={{ display: "flex" }}
                      value={values?.mobile}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          mobile: value,
                        });
                      }}
                      switchIt={true}
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      name="password"
                      component={Input}
                      placeholder="Password"
                      label="Password"
                      type={passwordShow ? "text" : "password"}
                      withFeedbackLabel={true}
                    />
                    <span
                      onClick={passwordShowHandler}
                      style={{
                        float: " right",
                        marginTop: "-30px",
                        position: "relative",
                        zIndex: "1",
                        cursor: "pointer",
                        width: "auto",
                      }}>
                      <i className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"} />
                    </span>
                  </div>
                </div>
              </div>
            </Form>
            <div className="row justify-content-center">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate f-button"
                style={{ display: "block" }}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Formik>
    </Card>
  );
}
