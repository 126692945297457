import React, { useState } from "react";
import logo from "./../../../../assets/img/password-reset.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import { resetPassword } from "../_redux/authCrud";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { i18n } from "../../../pages/private/languageSelector";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const PasswordReset = () => {
  const history = useHistory();
  const { token } = useParams();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);

  const passwordValidation = i18n("Validation.INVALID_PASSWORD");
  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(20, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
    confirmPassword: Yup.string()
      .min(8, i18n("Validation.Minimum8Symbols"))
      .max(20, i18n("Validation.Maximum50Symbols"))
      .matches(/^(?=.*[0-9])(?=.*[a-z]).{8,20}$/, passwordValidation)
      .required(i18n("Validation.RequiredField")),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();

      values.password !== values.confirmPassword
        ? setStatus(i18n("Validation.PasswordDoesntMatch"))
        : setTimeout(() => {
            resetPassword(token, values.password)
              .then(({ data: { token } }) => {
                disableLoading();
                history.push("/password-reset-next");
                toast.success("Password Reset Successfully");
              })
              .catch((err) => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  err.message === "Request failed with status code 404"
                    ? i18n("Validation.InvalidToken")
                    : i18n("Validation.ValidationError")
                );
              });
          }, 1000);
      disableLoading();
    },
  });

  const passwordShowHandler = () => {
    setPasswordShow(passwordShow ? false : true);
  };
  const passwordShowHandlerConfirm = () => {
    setPasswordShowConfirm(passwordShowConfirm ? false : true);
  };
  return (
    <div className="login-form login-signin">
      <div className="container">
        <div class="row">
          <div class="col-lg-6">
            <div className="illustration">
              <h1 style={{ marginBottom: "5rem" }}>{i18n("ResetPassword.PasswordReset")}</h1>
              <div className="img-wrap">
                <img src={logo} alt="loading" />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div className="form-wrap">
              <div className="form-title text-left">
                <p className="mx-0"> {i18n("ResetPassword.PleaseEnterANewPassword")}</p>
              </div>
              <form onSubmit={formik.handleSubmit}>
                {formik.status ? (
                  <div className="alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                  </div>
                ) : null}
                <div className="form-group with-icon">
                  <field>
                    <input
                      placeholder={i18n("global.Password")}
                      type={passwordShow ? "text" : "password"}
                      className="form-control"
                      name="password"
                      {...formik.getFieldProps("password")}
                    />
                    <label>{i18n("global.Password")}</label>
                    <span
                      onClick={passwordShowHandler}
                      style={{
                        float: " right",
                        marginTop: "-30px",
                        position: "relative",
                        zIndex: "1",
                        cursor: "pointer",
                        width: "auto",
                      }}>
                      <i className={passwordShow ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                    </span>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.password}</div>
                      </div>
                    ) : null}
                  </field>
                </div>
                <div className="form-group with-icon">
                  <field>
                    <input
                      placeholder={i18n("global.ConfirmNewPassword")}
                      type={passwordShowConfirm ? "text" : "password"}
                      className="form-control"
                      name="confirmPassword"
                      {...formik.getFieldProps("confirmPassword")}
                    />
                    <label>{i18n("global.ConfirmNewPassword")}</label>
                    <span
                      onClick={passwordShowHandlerConfirm}
                      style={{
                        float: " right",
                        marginTop: "-30px",
                        position: "relative",
                        zIndex: "1",
                        cursor: "pointer",
                        width: "auto",
                      }}>
                      <i className={passwordShowConfirm ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                    </span>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                      </div>
                    ) : null}
                  </field>
                </div>

                <div className="d-flex flex-wrap justify-content-center">
                  <button
                    type="submit"
                    className={`btn btn-primary font-weight-bold px-9 py-4 my-3 f-button`}
                    style={{ width: "auto" }}>
                    <span> {i18n("ResetPassword.Send")}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null, auth.actions)(PasswordReset);
