import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { i18n } from "../../private/languageSelector";
import IconClose from "../../../../assets/img/icon-close.svg";
import {
  Card,
  Input,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import { Field, Formik } from "formik";
import { InvoiceSummaryDialog } from "./InvoiceSummaryDialog";

export function InvoicePaymentsModal({
  show,
  onHide,
  payments,
  invNumber,
  setPayments,
  saveInvoice,
  paymentTypes,
  invoiceValues,
  invoiceDueDate,
  companyCurrency,
  formResetHandler,
  setInvoiceDueDate,
  PaymentDeleteHandler,
  setOpenInvoiceDialog,
}) {
  const [showInvoiceSummary, setShowInvoiceSummary] = useState(false);
  const [defaultPayment, setDefaultPayment] = useState("Cash");

  useEffect(() => {
    if (paymentTypes?.length) {
      let defaultChannel = paymentTypes.filter((item) => {
        return item.default === true;
      });
      setDefaultPayment(defaultChannel[0]?.name);
    }
    // eslint-disable-next-line
  }, [paymentTypes]);

  return (
    <>
      <Modal
        show={show}
        className="modal-drawer invoice-drawer"
        onHide={() => {}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              onHide();
            }}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">
            Invoice #{invNumber}
          </Modal.Title>
        </Modal.Header>

        <Formik
          enableReinitialize={true}
          initialValues={{ paymentDate: new Date() }}
        >
          {() => (
            <>
              <Modal.Body>
                <Card className="invoice-total">
                  <div className="invoice-title h5">
                    <b>{i18n("INVOICES.Total")}</b>
                    <b>
                      {companyCurrency}{" "}
                      {invoiceValues?.totalSales
                        ? invoiceValues?.totalSales.toFixed(2)
                        : 0.0}
                    </b>
                  </div>
                  <ul>
                    <li>
                      <span>{i18n("INVOICES.Subtotal")}</span>
                      <span>
                        {companyCurrency}{" "}
                        {invoiceValues?.subTotalPrice
                          ? invoiceValues?.subTotalPrice.toFixed(2)
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span>{i18n("INVOICES.Discounts")}</span>
                      <span>
                        {companyCurrency}{" "}
                        {invoiceValues?.totalDiscount
                          ? invoiceValues?.totalDiscount.toFixed(2)
                          : 0}
                      </span>
                    </li>
                    <li>
                      <span>Taxable Amount</span>
                      <strong>
                        {companyCurrency}{" "}
                        {invoiceValues?.taxableAmount
                          ? invoiceValues?.taxableAmount.toFixed(2)
                          : 0.0}
                      </strong>
                    </li>
                    <li>
                      <span>{i18n("INVOICES.Tax")}</span>
                      <span>
                        {companyCurrency}{" "}
                        {invoiceValues?.tax
                          ? invoiceValues?.tax.toFixed(2)
                          : 0.0}
                      </span>
                    </li>
                  </ul>
                </Card>
                {payments?.length
                  ? payments.map((item, index) => {
                      return (
                        <Card key={index}>
                          <div
                            className="invoice-title"
                            style={{ borderBottom: "none" }}
                          >
                            <h5 className="m-0">
                              <b>Payment {index + 1}</b>
                            </h5>
                            <p
                              className="pt-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => PaymentDeleteHandler(index)}
                            >
                              X
                            </p>
                          </div>
                          <div className="invoice-items row">
                            <div className="col-12">
                              <div className="form-group form-select">
                                <select
                                  style={{ width: "100%" }}
                                  value={item?.type}
                                  onChange={(e) => {
                                    let newPayment = {
                                      ...payments[index],
                                      type: e.target.value,
                                    };

                                    payments[index] = newPayment;
                                    setPayments([...payments]);
                                  }}
                                >
                                  <option value="CREDIT">
                                    {i18n("global.Payment")}
                                  </option>
                                  <option value="DEBIT">
                                    {i18n("global.Refund")}
                                  </option>
                                </select>
                              </div>

                              <div className="form-group">
                                <Field
                                  type="input"
                                  component={Input}
                                  placeholder={i18n("global.Amount")}
                                  label={i18n("global.Amount")}
                                  value={item?.amount}
                                  onChange={(e) => {
                                    let newPayment = {
                                      ...payments[index],
                                      amount: +e.target.value,
                                    };
                                    payments[index] = newPayment;
                                    setPayments([...payments]);
                                  }}
                                />
                              </div>
                              <div className=" form-select ">
                                <DatePickerField
                                  name="paymentDate"
                                  label="Payment Date"
                                  selected={new Date(item?.date)}
                                  onChange={(val) => {
                                    let newPayment = {
                                      ...payments[index],
                                      date: val,
                                    };

                                    payments[index] = newPayment;
                                    setPayments([...payments]);
                                  }}
                                />
                              </div>
                              <div className="form-group form-select">
                                <select
                                  style={{ width: "100%" }}
                                  value={item?.method}
                                  onChange={(e) => {
                                    let newPayment = {
                                      ...payments[index],
                                      method: e.target.value,
                                    };

                                    payments[index] = newPayment;
                                    setPayments([...payments]);
                                  }}
                                >
                                  {paymentTypes?.length
                                    ? paymentTypes
                                        .filter((item) => item.active)
                                        .map((type) => {
                                          return (
                                            <option
                                              key={type.name}
                                              value={type.name}
                                            >
                                              {type.name}
                                            </option>
                                          );
                                        })
                                    : ""}
                                </select>
                              </div>

                              <div className="form-group">
                                <Field
                                  type="input"
                                  component={Input}
                                  placeholder={i18n("global.Notes")}
                                  label={i18n("global.Notes")}
                                  value={item?.note}
                                  onChange={(e) => {
                                    let newPayment = {
                                      ...payments[index],
                                      note: e.target.value,
                                    };

                                    payments[index] = newPayment;
                                    setPayments([...payments]);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Card>
                      );
                    })
                  : ""}
                <Card>
                  <p
                    className="text-center pt-3"
                    style={{ cursor: "pointer", color: "#309cdc" }}
                    onClick={() => {
                      if (invoiceValues?.balance > 0) {
                        setPayments([
                          ...payments,
                          {
                            note: "",
                            amount: invoiceValues?.balance.toFixed(2),
                            type: "CREDIT",
                            method: defaultPayment,
                            date: new Date(),
                          },
                        ]);
                        toast.success("Payment Added To List");
                      } else {
                        toast.error("No Balance Due");
                      }
                    }}
                  >
                    Add a new Payment
                  </p>
                </Card>
                <Card className="invoice-total">
                  <div className="invoice-title h5">
                    <b>{i18n("INVOICES.Paid")}</b>
                    <b>
                      {companyCurrency}{" "}
                      {invoiceValues.totalPaid
                        ? invoiceValues?.totalPaid.toFixed(2)
                        : 0.0}
                    </b>
                  </div>
                  <ul>
                    <li>
                      <span>{i18n("INVOICES.BalanceDue")}</span>
                      <strong>
                        {companyCurrency}{" "}
                        {invoiceValues.balance
                          ? invoiceValues?.balance.toFixed(2)
                          : 0.0}
                      </strong>
                    </li>
                    {invoiceValues.balance > 0 ? (
                      <li className="text-muted">
                        <span className="pt-2">{i18n("global.DueDate")}</span>
                        <div className="form-select">
                          <DatePickerField
                            name="dueDate"
                            label={i18n("global.DueDate")}
                            placeholderText={i18n("global.DueDate")}
                            selected={invoiceDueDate}
                            onChange={(val) => {
                              setInvoiceDueDate(val);
                            }}
                          />
                        </div>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </Card>
              </Modal.Body>

              <Modal.Footer>
                <div>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-outline-primary"
                  >
                    {i18n("INVOICES.Back")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-elevate ml-5"
                    onClick={() => {
                      let payment = payments.filter(
                        (payment) =>
                          Number(payment?.amount)?.toFixed(2) >
                          Number(invoiceValues?.totalSales)?.toFixed(2)
                      );
                      if (payment?.length) {
                        toast.error("Payment can not exceed due amount ");
                      } else {
                        saveInvoice();
                        setShowInvoiceSummary(true);
                      }
                    }}
                  >
                    {i18n("INVOICES.Save")}
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal>
      <InvoiceSummaryDialog
        payments={payments}
        invNumber={invNumber}
        show={showInvoiceSummary}
        setPayments={setPayments}
        invoiceValues={invoiceValues}
        companyCurrency={companyCurrency}
        closePaymentModal={() => onHide()}
        formResetHandler={formResetHandler}
        onHide={() => setShowInvoiceSummary(false)}
        setOpenInvoiceDialog={setOpenInvoiceDialog}
      />
    </>
  );
}
