import React, { useEffect, useState } from "react";
import chromeImg from "../../../assets/img/ChromeIcon.svg";
import appStrore from "../../../assets/img/AppStore.svg";
import googlePlay from "../../../assets/img/GooglePlay.svg";
import tiktokIcon from "../../../assets/img/tiktokIcon.svg";
import twitterIcon from "../../../assets/img/twitterIcon.svg";
import snapchatIcon from "../../../assets/img/snapchatIcon.svg";
import fbIcon from "../../../assets/img/fbIcon.svg";
import instaIcon from "../../../assets/img/instaIcon.svg";
import YtIcon from "../../../assets/img/ytIcon.svg";
import logo from "../../../assets/img/posLogo.svg";
const HomeFooter = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div className="newsLetter">
        <div className="started-header  pt-3" style={{ alignItems: "center", marginTop: "4.5rem", padding: screenSize < 1074 ? "12px 25px" : "" }}>
          <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
            <div style={{ display: screenSize < 672 ? "" : "flex", justifyContent: screenSize < 1074 ? "" : "space-between" }}>
              <div className="pr-0 mt-4">
                <div className="media_links " style={{ margin: "21px 0" }}>
                  <img width={"138px"} src={googlePlay} alt="" />
                  <img style={{ margin: "0 10px" }} width={"138px"} src={appStrore} alt="" />
                  <img width={"138px"} src={chromeImg} alt="" />
                </div>
              </div>
              {screenSize < 672 ? null : (
                <div className=" mt-4 pl-0 terms-info">
                  <div>
                    <p style={{ color: "white", fontWeight: "400", fontSize: "16px", lineHeight: "21px" }}>©Fatoraty 2024 by Numu Technologies</p>
                    <p style={{ color: "white", fontWeight: "400", fontSize: "16px", lineHeight: "21px" }}>Saudi Arabia</p>
                    <p style={{ color: "white", fontWeight: "400", fontSize: "16px", lineHeight: "21px" }}>info@fatoraty.com</p>
                  </div>
                </div>
              )}

              <div className="footer-input mt-4" style={{ width: screenSize < 672 ? "100%" : "280px" }}>
                <div>
                  <p style={{ color: "white", fontWeight: "400", fontSize: "16px", lineHeight: "21px", textAlign: screenSize < 672 ? "center" : "" }}>
                    Subscribe to our newsletter
                  </p>
                  <p>
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="Email Address" />
                      <div class="input-group-btn">
                        <button class="btn btn-default" type="submit">
                          <i class="glyphicon glyphicon-search"></i>
                        </button>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: "1300px", margin: "0 auto", marginBottom: "3.5rem", padding: "0 18px" }}>
        <div className="footer-wrapper1" style={{ justifyContent: "space-between" }}>
          <div style={{ marginTop: "2.8rem", paddingLeft: "0" }}>
            <div className="d-flex" style={{ gap: "20px" }}>
              <p style={{ color: "#4F4F4F", fontWeight: "500", fontSize: "16px", lineHeight: "21px" }}>Terms & Conditions</p>
              <p style={{ color: "#4F4F4F", fontWeight: "500", fontSize: "16px", lineHeight: "21px" }}>Privacy</p>
              <p style={{ color: "#4F4F4F", fontWeight: "500", fontSize: "16px", lineHeight: "21px" }}>Support</p>
              <p style={{ color: "#4F4F4F", fontWeight: "500", fontSize: "16px", lineHeight: "21px" }}>Help</p>
            </div>
          </div>
          <div style={{ marginTop: "2.2rem", marginLeft: "100px" }}>
            <img width={"160px"} src={logo} alt="" />
          </div>
          <div style={{ marginTop: "2.8rem", marginLeft: "200px" }}>
            <div className="d-flex justify-content-center" style={{ gap: "30px" }}>
              <img src={snapchatIcon} alt="" />
              <img src={twitterIcon} alt="" />
              <img src={fbIcon} alt="" />
              <img src={instaIcon} alt="" />
              <img src={YtIcon} alt="" />
              <img src={tiktokIcon} alt="" />
            </div>
          </div>
        </div>
        {screenSize < 1074 ? (
          <div className="footer-wrapper2 mt-5 mb-5">
            <div className="term-condition">
              <div className="row">
                <div className="col-6">
                  <p>Terms & Conditions</p>
                  <p>Privacy</p>
                  <p>Support</p>
                  <p>Help</p>
                  <p>Contant</p>
                </div>
                <div className="col-6">
                  {" "}
                  <p style={{ color: "#4F4F4F" }} className="text-right">
                    Features
                  </p>
                  <p style={{ color: "#4F4F4F" }} className="text-right">
                    Industries
                  </p>
                  <p style={{ color: "#4F4F4F" }} className="text-right">
                    Prices
                  </p>
                  <p style={{ color: "#4F4F4F" }} className="text-right">
                    About Us
                  </p>
                  <p style={{ color: "#4F4F4F" }} className="text-right">
                    info@fatoraty.com
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-5" style={{ gap: "30px" }}>
              <img src={snapchatIcon} alt="" />
              <img src={twitterIcon} alt="" />
              <img src={fbIcon} alt="" />
              <img src={instaIcon} alt="" />
              <img src={YtIcon} alt="" />
              <img src={tiktokIcon} alt="" />
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: "30px" }}>
              <img className="home-logo" width={"160px"} src={logo} alt="" />
            </div>
            <p style={{ fontSize: "14px", color: "#828282" }} className="text-center mt-5">
              ©Fatoraty 2023 by Numu Technologies
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default HomeFooter;
