import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../_metronic/_partials/controls";
import { Radio } from "../../../../_metronic/_partials/controls/forms/Radio";
import { Formik, Form, Field } from "formik";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/category/categoryActions";
import * as productActions from "../../../../_redux/products/productsActions";
import { useProductsUIContext } from "../../../pages/products/ProductsUIContext";

import { useParams } from "react-router-dom";
import { i18n } from "../../private/languageSelector";
import toast from "react-hot-toast";

const initAddState = {
  name: "",
  description: "",
  color: "",
  isActive: true,
};
export const AssignToCategory = ({ openAssignCategoryDialog, setOpenAssignCategoryDialog, productId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const productsUiContext = useProductsUIContext();

  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUiContext?.queryParams,
    };
  }, [productsUiContext]);

  const [searchCategory, setSearchCategory] = useState("");
  const [category, setCategory] = useState("");
  const [initAddCategoryState] = useState(initAddState);
  const { categories, productForEdit, products, productFilterState } = useSelector(
    (state) => ({
      categories: state?.category?.categoryForEdit,
      productForEdit: state?.products?.productForEdit,
      products: state?.products?.entities,
      productFilterState: state?.products?.productFilterState,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchCategories());
    dispatch(productActions.fetchProduct(id));
    // eslint-disable-next-line
  }, []);
  const assignHandler = () => {
    if (category?.name) {
      dispatch(
        productActions.productsAssignToCategory(
          productFilterState,
          [productForEdit?._id],
          {
            category: category?._id,
          },
          productsUIProps.queryParams
        )
      );
      setCategory({});
      setOpenAssignCategoryDialog(false);
    } else {
      toast.error(i18n("Validation.NoCategorySelected"));
    }
  };
  let itemsAssignedToCategory = categories?.map((item) => {
    return products?.filter((product) => product?.categoryData?._id === item?._id).length;
  });

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openAssignCategoryDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              setOpenAssignCategoryDialog(false);
              setCategory({});
            }}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("MENU.AssignItemsToACategory")}</Modal.Title>
        </Modal.Header>
        <Formik enableReinitialize={true} initialValues={initAddCategoryState} onSubmit={(values, { resetForm }) => {}}>
          {({ handleSubmit, values, setValues, setFieldValue, touched }) => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="row mt-5">
                    <div className="col-lg-12 ">
                      <Field
                        name="category"
                        component={Input}
                        placeholder={i18n("Category.SearchCategory")}
                        withFeedbackLabel={false}
                        onChange={(e) => {
                          setSearchCategory(e.target.value);
                        }}
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <ul className="card-listitem list-unstyled">
                    {categories
                      ?.filter((category) => category?.name?.toLowerCase().includes(searchCategory?.toLowerCase()))
                      ?.map((category, index) => {
                        return (
                          <li>
                            <div className="card-info w-50">
                              <h5>
                                <span
                                  className="card-icon category"
                                  style={{
                                    backgroundColor: category.color,
                                  }}
                                />
                                {category.name}
                              </h5>
                              <small className="d-block w-100">
                                {itemsAssignedToCategory?.length ? itemsAssignedToCategory[index] : 0}{" "}
                                {i18n("MENU.Items")}
                              </small>
                            </div>
                            <div className="card-actions ml-auto">
                              <Radio
                                id={category?._id}
                                name="category"
                                label
                                value={category?._id}
                                onChange={(e) => {
                                  setCategory(category);
                                }}
                              />
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>

        <Modal.Footer>
          <div className="row justify-content-center">
            <button
              className="btn btn-primary btn-elevate f-button"
              onClick={() => {
                assignHandler();
              }}>
              {i18n("MENU.Assign")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
