import React from 'react'
import {Switch} from "react-router-dom"
import SalesByTime from './analyticsView/SalesByTime'

const SalesByTimePage = () => {
  return (
    <Switch>
    <SalesByTime/>
  </Switch>
  )
}

export default SalesByTimePage