export const initFormValues = {
  dueDate: "",
  generalNotes: "",
  privateNotes: "",
  invoiceStatus: "Due",
  paymentDate: new Date(),
  invoiceDate: new Date(),
};

export const initialPayment = {
  paymentNote: "",
  paymentAmount: 12,
  paymentMethod: "CREDIT",
  paymentChannel: "cash",
};

export const initialSelectedCustomer = {
  _id: "",
  name: "",
  email: "",
  mobile: "",
  taxNumber: "",
};

export const initialProducts = {
  _id: "",
  name: "",
  price: "",
  tax: 0,
  addedTax: 0,
  vat: false,
  barcode: "",
  discount: "",
  priceAfterDiscount: "",
  couponValueOnProduct: "",
  priceAfterCoupon: "",
  discounts: [],
  quantity: "",
  rate: "basic",
  soldBy: "each",
  isPrice: false,
  taxable: false,
  totalPrice: "",
  description: "",
};

export const initialSelectedProduct = {
  _id: "",
  name: "",
  price: "",
  tax: 0,
  addedTax: 0,
  vat: false,
  barcode: "",
  quantity: "",
  discount: "",
  rate: "basic",
  soldBy: "each",
  taxable: false,
  isPrice: false,
  totalPrice: "",
  description: "",
};

export const initialInvoiceValues = {
  balance: 0,
  totalPaid: 0,
  totalPrice: 0,
  totalSales: 0,
  subTotalPrice: 0,
  totalDiscount: 0,
  productsTotal: 0,
  taxableAmount: 0,
  addedTax: 0,
};
