import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { i18n } from "../../private/languageSelector";
import FormControl from "@material-ui/core/FormControl";
import { useProductsUIContext } from "../ProductsUIContext";
import FilterIcon from "../../../../assets/img/Icon-filter.png";
import * as storesActions from "../../../../_redux/stores/storeActions";
import * as categoryActions from "../../../../_redux/category/categoryActions";
import * as productsActions from "../../../../_redux/products/productsActions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FilterDialogeProducts = ({
  title,
  classes,
  size = "sm",
  show = false,
  setFilterActive,
  primaryActionTitle = "yes",
  secondaryAction = () => {},
}) => {
  const dispatch = useDispatch();
  const classess = useStyles();

  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  const [state, setState] = useState({
    store: "",
    status: "",
    category: "",
  });

  const statusType = [
    { label: "Active ", value: true },
    { label: "In-Active", value: false },
  ];

  const { category, filter, stores, productFilterState } = useSelector(
    (state) => ({
      stores: state?.stores?.entities,
      filter: state?.products?.filterOn,
      productFilterState: state?.products?.productFilterState,
      category: state?.category?.categoryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(storesActions.fetchStores());
    dispatch(categoryActions.fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    setFilterActive(filter);
    // eslint-disable-next-line
  }, [filter]);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleFilter = () => {
    dispatch(productsActions.filterProducts(state, productsUIProps.queryParams));
    dispatch(productsActions.setProductFilterState(state));
    dispatch(productsActions.setProductFilterOnState(true));
    secondaryAction(false);
  };
  const clerFilterHandler = () => {
    dispatch(productsActions.clearProductFilterState());
    dispatch(productsActions.setProductFilterOnState(false));
    dispatch(
      productsActions.fetchProducts(
        {
          category: "",
          status: "",
          store: "",
        },
        productsUIProps.queryParams
      )
    );
    setState({
      category: "",
      status: "",
      store: "",
    });
    secondaryAction(false);
  };
  return (
    <>
      <Modal
        size={size}
        show={show}
        backdrop={true}
        onHide={() => {}}
        className={`f-confirmation-modal ${classes}`}
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <button type="button" className="btn btn-close" onClick={() => secondaryAction(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <br />
          <br />
          <form>
            <div
              type="submit"
              className="mb-5"
              onClick={() => {
                clerFilterHandler();
              }}>
              <img src={FilterIcon} alt="loading" style={{ cursor: "pointer", height: "25px" }} />
              <span
                style={{
                  color: "#2D9CDB",
                  fontSize: "16px",
                  cursor: "pointer",
                }}>
                {i18n("MENU.ClearAllFilters")}
              </span>
            </div>
            <FormControl className={classess.formControl}>
              <InputLabel htmlFor="status-native-simple">{i18n("MENU.Status")}</InputLabel>
              <Select
                native
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: "status",
                  id: "status-native-simple",
                }}>
                <option aria-label="None" value="" />
                {statusType.map((type, i) => (
                  <option key={i} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classess.formControl}>
              <InputLabel htmlFor="category-native-simple">{i18n("global.Category")}</InputLabel>
              <Select
                native
                value={state.category}
                onChange={handleChange}
                inputProps={{
                  name: "category",
                  id: "category-native-simple",
                }}>
                <option aria-label="None" value="" />
                {category?.map((item, i) => (
                  <option key={i} value={item?._id}>
                    {item?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classess.formControl}>
              <InputLabel htmlFor="store-native-simple">{i18n("MENU.Store")}</InputLabel>
              <Select
                native
                value={state.store}
                onChange={handleChange}
                inputProps={{
                  name: "store",
                  id: "store-native-simple",
                }}>
                <option aria-label="None" value="" />
                {stores?.map((item, i) => (
                  <option key={i} value={item?._id}>
                    {item?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-center">
            <button type="submit" onClick={() => handleFilter()} className="btn btn-primary btn-elevate mt-5">
              {primaryActionTitle}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
