import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import IconClose from "../../../../assets/img/icon-close.svg";
import IconNotification from "../../../../assets/img/notification.svg";
import moment from "moment";
import { i18n } from "../../../pages/private/languageSelector";
import toast from "react-hot-toast";

export const NotificationDialog = ({ openNotificationDialog, setOpenNotificationDialog }) => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => ({ notifications: state?.invoices?.notifications }), shallowEqual);
  useEffect(() => {
    dispatch(actions.getNotifications());
  }, [dispatch, openNotificationDialog]);

  let notificationsToShow = notifications?.filter((notification) => {
    return notification.isRead === false;
  });

  const readSingleNotification = (id, isRead) => {
    if (!isRead) {
      dispatch(actions.readSingleNotification(id));
    }
  };

  const readAllNotificationsHandler = () => {
    let allIds = [];
    // eslint-disable-next-line
    let data = notificationsToShow?.filter((item) => {
      return item.isRead === false ? allIds.push(item._id) : "";
    });
    if (allIds?.length) {
      dispatch(actions.readAllNotification(allIds));
    } else {
      toast.error("All notifications read");
    }
  };
  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openNotificationDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenNotificationDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("LandingPage.NotificationsCenter")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="notifications">
            {notificationsToShow && notificationsToShow.length ? (
              <div className="unread-no">
                <b>{notificationsToShow.length}</b> {i18n("LandingPage.NewNotifications")}
              </div>
            ) : (
              <div className="unread-no">
                <b>0 </b>
                {i18n("LandingPage.NewNotifications")}
              </div>
            )}
            {notifications && notifications.length
              ? [...notifications].reverse().map((it, i) => {
                  return (
                    <div
                      key={i}
                      className="notifications-list"
                      onClick={() => readSingleNotification(it?._id, it?.isRead)}>
                      <div className="d-flex">
                        <span className="img-wrap mr-3">
                          <img src={IconNotification} alt="loading" />
                        </span>
                        <div className="notification-detail">
                          <span className="d-flex justify-content-between">
                            <span className={it?.isRead === false ? "invoice-unread" : "invoice-read"}>
                              Invoice{" "}
                              {it?.invoice?.invoiceNumber >= 10 && it?.invoice?.invoiceNumber <= 99
                                ? "000" + it?.invoice?.invoiceNumber
                                : it?.invoice?.invoiceNumber >= 100 && it?.invoice?.invoiceNumber <= 999
                                ? "00" + it?.invoice?.invoiceNumber
                                : it?.invoice?.invoiceNumber >= 1000 && it?.invoice?.invoiceNumber <= 9999
                                ? "0" + it?.invoice?.invoiceNumber
                                : "0000" + it?.invoice?.invoiceNumber}
                            </span>
                            <span className="detail-text">{moment(it?.created_at).format("DD/MM/YYYY")}</span>
                          </span>
                          <p className="detail-text">{i18n("LandingPage.TheDueDateOfTheDeferredBillPaymentIsDue")}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={() => setOpenNotificationDialog(false)}>
            {i18n("LandingPage.Back")}
          </button>
          {notificationsToShow && notificationsToShow.length ? (
            <button
              className="btn btn-primary ml-5"
              onClick={() => {
                readAllNotificationsHandler();
              }}>
              {i18n("LandingPage.MarkAllAsRead")}
            </button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};
