import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import IconClose from "../../../../assets/img/icon-close.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as storesActions from "../../../../_redux/stores/storeActions";
import {
  Card,
  Input,
  Mobile,
  ReactSelector,
} from "../../../../_metronic/_partials/controls";
import InvoiceCreateDialog from "./InvoiceCreateDialog";
import toast from "react-hot-toast";

export const InvoiceStore = ({
  id,
  edit,
  onHide,
  invoice,
  openStoreDialog,
  setOpenStoreDialog,
  customerFromAction,
}) => {
  const dispatch = useDispatch();

  const [selectedStore, setSelectedStore] = useState({});
  const [storesToShow, setStoresToShow] = useState([]);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);

  const { user, stores } = useSelector(
    (state) => ({
      user: state?.auth?.user,
      stores: state.stores?.entities,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(storesActions.fetchStores());
  }, [dispatch]);

  useEffect(() => {
    if (user?.rolePayload?.name !== "owner" && user?.storesAccess) {
      setStoresToShow([...user?.storesAccess]);
    } else {
      setStoresToShow(stores);
    }
  }, [user, stores]);

  useEffect(() => {
    if (invoice) {
      setSelectedStore(invoice?.invoiceData?.store);
    } else if (storesToShow?.length) {
      setSelectedStore({ ...storesToShow[0] });
      if (openStoreDialog && storesToShow?.length === 1) {
        setOpenStoreDialog(false);
        setOpenInvoiceDialog(true);
      }
    }
    // eslint-disable-next-line
  }, [openStoreDialog, invoice, storesToShow]);

  return (
    <>
      <Modal
        className="modal-drawer invoice-drawer-info"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openStoreDialog}
        onHide={() => {}}
      >
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => setOpenStoreDialog(false)}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Stores</Modal.Title>
        </Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={{
            address: selectedStore?.address ? selectedStore?.address : "",
            mobile: selectedStore?.mobile ? selectedStore?.mobile : "",
            description: selectedStore?.description
              ? selectedStore?.description
              : "",
          }}
        >
          {({ touched, values }) => {
            return (
              <>
                <Modal.Body className="overlay overlay-block cursor-default">
                  <>
                    <Form className="form form-label-right">
                      <Card>
                        <div
                          className="invoice-title"
                          style={{ borderBottom: "none" }}
                        >
                          <h5 className="m-0">
                            <b>Store Information</b>
                          </h5>
                        </div>
                        <div className="invoice-items row">
                          <div className="col-12">
                            <div className="form-group input-select">
                              <Field
                                name="storeName"
                                touched2={touched}
                                component={ReactSelector}
                                label="Store Name"
                                placeholder="Store Name"
                                style={{ maxWidth: "auto" }}
                                isDisabled={edit}
                                options={
                                  storesToShow?.length
                                    ? [].concat(
                                        ...storesToShow?.map((item) => {
                                          return {
                                            item: item,
                                            value: item?._id,
                                            label: `${
                                              item?.name ? item?.name : ""
                                            }`,
                                          };
                                        })
                                      )
                                    : undefined
                                }
                                defaultValue={{ label: 2002, value: 2002 }}
                                value={{
                                  name: selectedStore?.name
                                    ? selectedStore?.name
                                    : "Store Name",
                                  label: selectedStore?.name
                                    ? selectedStore?.name
                                    : "Store Name",
                                }}
                                onChange={(option) => {
                                  setSelectedStore(option.item);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Card>
                      <Card>
                        <div className="form-group">
                          <Field
                            name="address"
                            readOnly={true}
                            component={Input}
                            label="Store Address"
                            placeholder="Store Address"
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            component={Mobile}
                            name="mobile"
                            international
                            defaultCountry="SA"
                            style={{ display: "flex" }}
                            value={values?.mobile}
                            readOnly={true}
                            switchIt={true}
                          />
                        </div>
                        <div className="form-group">
                          <Field
                            readOnly={true}
                            component={Input}
                            name="description"
                            label="Description"
                            placeholder="Description"
                          />
                        </div>
                      </Card>
                    </Form>
                  </>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row justify-content-center">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => setOpenStoreDialog(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-elevate f-button ml-5"
                      onClick={() =>
                        selectedStore?.name
                          ? setOpenInvoiceDialog(true)
                          : toast.error("Please Select A Store")
                      }
                    >
                      Proceed to Invoice
                    </button>
                  </div>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal>
      <InvoiceCreateDialog
        id={id}
        edit={edit}
        onHide={onHide}
        invoice={invoice}
        selectedStore={selectedStore}
        openInvoiceDialog={openInvoiceDialog}
        setOpenStoreDialog={setOpenStoreDialog}
        setOpenInvoiceDialog={setOpenInvoiceDialog}
        customerFromAction={customerFromAction}
      />
    </>
  );
};
