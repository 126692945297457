import React, { useState, useEffect, useMemo, useRef } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { PAYMENT_TYPES } from "../../../../helpers/constants";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../../pages/private/languageSelector";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as storesActions from "../../../../_redux/stores/storeActions";
import * as productActions from "../../../../_redux/products/productsActions";
import * as taxesActions from "../../../../_redux/taxes/taxesActions";
import * as invoiceActions from "../../../../_redux/invoices/invoicesActions";
import * as discountActions from "../../../../_redux/discount/discountActions";
import * as customerActions from "../../../../_redux/customers/customersActions";
import { ProductEditDialog } from "../../products/product-edit-dialog/ProductEditDialog";
import { CustomerEditDialog } from "../../customers/customer-edit-dialog/CustomerEditDialog";
import { InvoicePaymentsModal } from "./InvoicePaymentsModal";
import {
  Card,
  Input,
  TextArea,
  SwitchToggler,
  DatePickerField,
  ReactCreateableSelector,
} from "../../../../_metronic/_partials/controls";
import {
  addZeroes,
  totalTaxHandler,
  totalAddedTaxHandler,
  addedtaxHandler,
  taxableAmountHandler,
  totalPaidHandler,
  handleTotalPrice,
  totalPriceHandler,
  addProductToInvoice,
  subtotalPriceHandler,
  addCustomerToInvoice,
  totalDiscountHandler,
  totalCuponDiscountHandler,
  selectedDiscount,
} from "./InvoiceHelpers";
import {
  initialPayment,
  initFormValues,
  initialProducts,
  initialInvoiceValues,
  initialSelectedProduct,
  initialSelectedCustomer,
} from "./InvoiceConstants";
import { getNumber } from "../../../../helpers/helpers";

const InvoiceCreateDialog = ({
  edit,
  invoice,
  selectedStore,
  openInvoiceDialog,
  customerFromAction,
  setOpenStoreDialog,
  setOpenInvoiceDialog,
}) => {
  const dispatch = useDispatch();
  const selectRef = useRef(null);

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext?.queryParams,
    };
  }, [invoicesUIContext]);

  const [totalD, setTotalD] = useState(0);
  const [totalT, setTotalT] = useState(0);
  const [payments, setPayments] = useState([]);
  const [companyTax, setCompanyTax] = useState(0);
  const [invNumber, setInvNumber] = useState("00001");
  const [productIndex, setProductIndex] = useState("");
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [product, setProduct] = useState(initialProducts);
  const [productsToShow, setProductsToShow] = useState([]);
  const [newProductName, setNewProductName] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("Due");
  const [companyCurrency, setCompanyCurrency] = useState("");
  const [newCustomerName, setNewCustomerName] = useState("");
  const [showNewProduct, setShowNewProduct] = useState(false);
  const [showNewCustomer, setShowNewCustomer] = useState(false);
  const [newProductDialog, setNewProductDialog] = useState(false);
  const [invoiceDueDate, setInvoiceDueDate] = useState(new Date());
  const [showPaymentsModal, setShowPaymentsModal] = useState(false);
  const [showProductAddDialoag, setShowProductAddDialoag] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([initialProducts]);
  const [showCustomerAddDialoag, setShowCustomerAddDialoag] = useState(false);
  const [refectorDiscounts, setRefectorDiscounts] = useState([]);
  const [productsIndex, setProductsIndex] = useState();
  const [productQuantity, setProductQuantity] = useState();
  const [discountValue, setDiscountValue] = useState();
  const [discountName, setDiscountName] = useState();
  const [allTaxes, setAllTaxes] = useState([]);

  // Fetching Customers and Products
  useEffect(() => {
    dispatch(storesActions.fetchStores());
    dispatch(
      productActions.fetchProducts(
        productFilterState,
        invoicesUIProps.queryParams
      )
    );
    dispatch(customerActions.fetchCustomers());
    dispatch(discountActions.fetchDiscounts());
    dispatch(taxesActions.fetchTaxes());
    // eslint-disable-next-line
  }, [dispatch]);

  const {
    user,
    products,
    customers,
    taxes,
    discounts,
    userAccess,
    invoiceFilterState,
    productFilterState,
  } = useSelector(
    (state) => ({
      user: state?.auth?.user,
      products: state.products,
      taxes: state.taxes.entities,
      customers: state.customers,
      userAccess: state?.users?.userAccess,
      discounts: state?.discounts?.entities,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
      productFilterState: state?.products?.productFilterState,
    }),
    shallowEqual
  );
  useEffect(() => {
    let newProducts = [];
    if (selectedStore?._id && products?.entities?.length) {
      products.entities.map((item) => {
        return item?.store?.length
          ? item?.store?.find((subItem) => {
              return subItem === selectedStore?._id
                ? newProducts.push(item)
                : null;
            })
          : null;
      });
    }
    setProductsToShow([...newProducts]);
  }, [products, selectedStore]);

  // Setting Payment Types and Stores
  useEffect(() => {
    if (user?.companyId?.paymentTypes?.length) {
      setPaymentTypes([...user?.companyId?.paymentTypes]);
    } else {
      setPaymentTypes([...PAYMENT_TYPES]);
    }
  }, [user]);

  // Setting Company Tax and Currency
  useEffect(() => {
    setCompanyTax(user?.companyId?.taxRate ? user?.companyId?.taxRate : 0);
    setCompanyCurrency(
      user?.companyId?.currency ? user?.companyId?.currency : ""
    );
  }, [user]);

  // Filling customer data after creating new customer
  useEffect(() => {
    if (customers?.newCustomer && showNewCustomer) {
      selectRef.current.focus();
      let customer = customers?.newCustomer;
      setSelectedCustomer({
        name: customer?.name?.first + " " + customer?.name?.last,
        email: customer?.email,
        mobile: customer?.mobile,
        taxNumber: customer?.taxNumber ? customer?.taxNumber : "",
        _id: customer?._id,
      });
    }
  }, [customers, showNewCustomer]);
  useEffect(() => {
    if (discounts && discounts.length) {
      let newDiscount = discounts.map((discount) => {
        return { ...discount, isPrice: discount?.value ? true : false };
      });
      setRefectorDiscounts(newDiscount);
    }
  }, [discounts]);

  const handlingPercentage = (e, type) => {
    if (e.target.value > 99 && type === "%") {
      toast.error("Max % value should not exceed more than 99%");
    }
  };
  useEffect(() => {
    if (products?.newProduct && showNewProduct) {
      selectRef.current.focus();
      let product = products?.newProduct;
      let newProduct = {
        ...selectedProducts[productIndex],
        name: product?.englishName,
        price: product?.price ? product?.price : "",
        isPrice: product?.price ? true : false,
        description: product?.englishDetail ? product?.englishDetail : "",
        barcode: product?.barcode ? product?.barcode : "",
        discount: 0,
        priceAfterDiscount: 0,
        couponValueOnProduct: 0,
        priceAfterCoupon: 0,
        quantity: 1,
        tax: 0,
        addedTax: 0,
        taxRate: 0,
        addedTaxRate: 0,
        taxable: product?.taxable ? true : false,
        totalPrice: product?.price ? product?.price : "",
        _id: product?._id,
        vat: false,
        soldBy: product?.soldBy ? product?.soldBy : "each",
        discounts: refectorDiscounts,
        newTax: product?.taxes.map((item) => {
          return {
            ...item,
            selected: true,
          };
        }),
        taxes: allTaxes,
        showDiscounts: false,
        showTaxes: true,
      };
      selectedProducts[productIndex] = newProduct;
      setSelectedProducts([...selectedProducts]);
      taxesCalculation(productIndex, selectedProducts);
      handleTotalPrice(productIndex, selectedProducts, setSelectedProducts);
      taxValueHandler(productIndex);
      addedtaxHandler(productIndex, selectedProducts, setSelectedProducts);
    }
    // eslint-disable-next-line
  }, [products, showNewProduct]);

  const taxesCalculation = (index, selectedProducts) => {
    let selectedTax;
    let unselectedTax;
    let newModifiedTaxes;
    if (selectedProducts && selectedProducts?.length) {
      if (selectedProducts[index]?.newTax) {
        selectedTax = taxes?.filter(({ _id: id1 }) =>
          selectedProducts[index]?.newTax?.some(({ _id: id2 }) => id2 === id1)
        );
        unselectedTax = taxes?.filter(
          ({ _id: id1 }) =>
            !selectedProducts[index]?.newTax?.some(
              ({ _id: id2 }) => id2 === id1
            )
        );
      }

      let refectorSelectedTax;
      let refectorUnSelectedTax;
      if (selectedTax && selectedTax.length) {
        refectorSelectedTax = selectedTax?.map((item) => {
          return { ...item, active: true };
        });
      }
      if (unselectedTax && unselectedTax.length) {
        refectorUnSelectedTax = unselectedTax?.map((item) => {
          return { ...item, active: false };
        });
      }
      if (
        refectorSelectedTax &&
        refectorSelectedTax.length &&
        refectorUnSelectedTax &&
        refectorUnSelectedTax.length
      ) {
        newModifiedTaxes = refectorSelectedTax?.concat(refectorUnSelectedTax);
      }
      if (
        refectorSelectedTax &&
        refectorSelectedTax.length &&
        !refectorUnSelectedTax?.length
      ) {
        newModifiedTaxes = refectorSelectedTax;
      }
      if (
        refectorUnSelectedTax &&
        refectorUnSelectedTax?.length &&
        !refectorSelectedTax?.length
      ) {
        newModifiedTaxes = refectorUnSelectedTax;
      }
      if (newModifiedTaxes && newModifiedTaxes.length) {
        selectedProducts[index].taxes = newModifiedTaxes;
        return newModifiedTaxes;
      } else {
        return [];
      }
    }
  };
  const [editInitialInvoice, setEditInitialInvoice] = useState({});
  const [selectedPayment, setSelectedPayment] = useState(initialPayment);
  const [invoiceValues, setInvoiceValues] = useState(initialInvoiceValues);
  const [initialFormValues, setInitialFormValues] = useState(initFormValues);

  // Handling Create Invoice With Customer
  let withCustomer = {
    name: customerFromAction?.firstName
      ? customerFromAction?.firstName + " " + customerFromAction?.lastName
      : "",
    email: customerFromAction?.email,
    mobile: customerFromAction?.mobile,
    taxNumber: customerFromAction?.taxNumber,
    _id: customerFromAction?._id,
  };
  const [selectedCustomer, setSelectedCustomer] = useState(
    initialSelectedCustomer
  );
  useEffect(() => {
    if (withCustomer) {
      setSelectedCustomer(withCustomer);
    }
    // eslint-disable-next-line
  }, [customerFromAction]);

  // Setting Default Payment Channel
  useEffect(() => {
    if (paymentTypes?.length) {
      let defaultChannel = paymentTypes.filter((item) => {
        return item.default === true;
      });
      setSelectedPayment({
        ...selectedPayment,
        paymentChannel: defaultChannel[0]?.name,
      });
    }
    // eslint-disable-next-line
  }, [paymentTypes]);

  useEffect(() => {
    if (discountValue !== null) {
      let dublicateArray = [...selectedProducts];
      dublicateArray.forEach((item, index) => {
        let findProduct = dublicateArray[index].discounts?.some(
          (item) => !item.isPrice
        );
        let discountArray = [...item.discounts];
        if (findProduct) {
          let discountedIndex = discountArray?.findIndex(
            (item) => !item.isPrice
          );
          if (discountedIndex !== null) {
            discountArray[discountedIndex].value = discountValue;
          }
          dublicateArray[index].discounts = [...discountArray];
        }
        let valueDiscount = refectorDiscounts.filter(
          (item) => item.type !== "%"
        );
        if (valueDiscount && discountArray) {
          let newDiscountArray = discountArray.concat(valueDiscount);
          setRefectorDiscounts([...newDiscountArray]);
        } else if (discountArray && valueDiscount.length === 0) {
          setRefectorDiscounts([...discountArray]);
        } else {
          setRefectorDiscounts([...valueDiscount]);
        }
      });
      setSelectedProducts([...dublicateArray]);
    }
  }, [discountValue]);

  // Calculating Values
  let totalP = 0;
  let subtotalP = 0;
  let totalPaidd = 0;
  let totalTaxableAmount = 0;
  let addedTotalT = 0;

  totalPaidd = totalPaidHandler(payments);
  totalP = totalPriceHandler(selectedProducts);
  subtotalP = subtotalPriceHandler(selectedProducts);
  totalTaxableAmount = taxableAmountHandler(selectedProducts);
  addedTotalT = totalAddedTaxHandler(selectedProducts);
  // Setting Invoice Summary Values
  useEffect(() => {
    let balance =
      addedTotalT && totalPaidd && totalD
        ? totalP + addedTotalT - totalD - totalPaidd > 0
          ? totalP + addedTotalT - totalD - totalPaidd
          : 0.0
        : addedTotalT && totalPaidd
        ? totalP + addedTotalT - totalPaidd > 0
          ? totalP + addedTotalT - totalPaidd
          : 0
        : addedTotalT && totalD
        ? totalP + addedTotalT - totalD > 0
          ? totalP + addedTotalT - totalD
          : 0
        : totalPaidd && totalD
        ? totalP - totalD - totalPaidd > 0
          ? totalP - totalD - totalPaidd
          : 0
        : addedTotalT
        ? totalP + addedTotalT > 0
          ? totalP + addedTotalT
          : 0
        : totalD
        ? totalP - totalD > 0
          ? totalP - totalD
          : 0
        : totalPaidd
        ? totalP - totalPaidd > 0
          ? totalP - totalPaidd
          : 0
        : totalP;
    setInvoiceValues({
      ...invoiceValues,
      subTotalPrice: subtotalP,
      totalDiscount: totalD,
      totalPaid: totalPaidd,
      taxableAmount: totalTaxableAmount,
      totalSales:
        addedTotalT && totalD
          ? totalP + addedTotalT - totalD > 0
            ? totalP + addedTotalT - totalD
            : 0
          : addedTotalT
          ? totalP + addedTotalT > 0
            ? totalP + addedTotalT
            : 0
          : totalD
          ? totalP - totalD > 0
            ? totalP - totalD
            : 0
          : totalP,
      productsTotal: totalP,
      totalPrice:
        totalT && totalD
          ? totalP + totalT - totalD > 0
            ? totalP + totalT - totalD
            : 0
          : totalT
          ? totalP + totalT > 0
            ? totalP + totalT
            : 0
          : totalD
          ? totalP - totalD > 0
            ? totalP - totalD
            : 0
          : totalP,
      balance: balance,
      tax: totalT,
      addedTax: addedTotalT,
    });
    setSelectedPayment({
      ...selectedPayment,
      paymentAmount: balance ? balance : 0,
    });
    // eslint-disable-next-line
  }, [subtotalP, totalD, totalP, totalT, totalPaidd]);

  // Deleting Products
  const ProductDeleteHandler = (index) => {
    let selectedProduct = [...selectedProducts];
    selectedProduct.splice(index, 1);
    setSelectedProducts([...selectedProduct]);
    selectedDiscount(
      index,
      discountName,
      selectedProduct,
      refectorDiscounts,
      setRefectorDiscounts
    );
  };

  // Deleting Payments
  const PaymentDeleteHandler = (index) => {
    let allPayments = [...payments];
    allPayments.splice(index, 1);
    setPayments([...allPayments]);
  };

  // Fetching and setting Invoice Number
  useEffect(() => {
    if (!edit) {
      if (selectedStore && selectedStore._id) {
        axios
          .get(`api/invoices/latest?store=${selectedStore?._id}`)
          .then((res) => {
            if (res) {
              if (res?.data?.initialInvoiceNumber) {
                let invoiceNumber = res?.data?.initialInvoiceNumber;
                setInvNumber(invoiceNumber);
              } else {
                let invoiceNumber = res?.data?.invoice?.invoiceNumber + 1;
                let number = `${selectedStore?.identityPrefix}0-${getNumber(
                  invoiceNumber
                )}`;
                setInvNumber(number);
              }
            }
          });
      }
    }
  }, [openInvoiceDialog, edit]);
  if (product.quantity < 0) {
    setProduct({ ...product, quantity: 0, totalPrice: 0 });
  }

  // Displaying Values when editing and viewing invoice
  useEffect(() => {
    if (invoice !== null && edit) {
      setInvNumber(
        `${invoice?.invoiceData?.store?.identityPrefix}0-${invoice?.invoiceNumber}`
      );
      setSelectedCustomer({
        name: invoice?.invoiceData?.receiverInformation?.name?.first
          ? invoice?.invoiceData?.receiverInformation?.name?.first +
            " " +
            invoice?.invoiceData?.receiverInformation?.name?.last
          : invoice?.invoiceData?.receiverInformation?.name,
        email: invoice?.invoiceData?.receiverInformation?.email,
        mobile: invoice?.invoiceData?.receiverInformation?.mobile,
        taxNumber: invoice?.invoiceData?.receiverInformation?.taxNumber,
        _id: invoice?.invoiceData?.receiverInformation?._id,
      });
      setSelectedProducts(
        invoice?.invoiceData?.products.length
          ? [...invoice?.invoiceData?.products]
          : []
      );
      setInvoiceValues({
        subTotalPrice: invoice?.invoiceData?.productsTotal,
        totalDiscount: invoice?.invoiceData?.discountTotal,
        taxableAmount: invoice?.invoiceData?.productsTotal,
        totalPrice: invoice?.invoiceData?.discountTotal
          ? +invoice?.invoiceData?.productsTotal -
              +invoice?.invoiceData?.discountTotal >
            0
            ? +invoice?.invoiceData?.productsTotal -
              +invoice?.invoiceData?.discountTotal
            : 0
          : invoice?.invoiceData?.totalSales,
        totalPaid: invoice?.invoiceData?.paidTotal,
        totalSales: invoice?.invoiceData?.totalSales,
        addedTax: invoice?.invoiceData?.addedTaxTotal,
        productsTotal: invoice?.invoiceData?.productsTotal,
        balance: invoice?.invoiceData?.balance,
        tax: invoice?.invoiceData?.taxTotal,
      });
      setTotalD(
        invoice?.invoiceData?.discount
          ? invoice?.invoiceData?.discount
          : invoice?.invoiceData?.couponValueOnProduct
      );
      setTotalT(invoice?.invoiceData?.taxTotal);
      setPayments(
        invoice?.invoiceData?.payments.length
          ? [...invoice?.invoiceData?.payments]
          : []
      );
      setEditInitialInvoice({
        invoiceStatus:
          invoice?.status?.charAt(0).toUpperCase() + invoice?.status?.slice(1),
        invoiceDate: invoice?.created_at ? new Date(invoice?.created_at) : "",
        paymentDate: new Date(),
        dueDate:
          invoice?.invoiceData?.dueDate &&
          invoice?.invoiceData?.dueDate !== "Invalid date"
            ? new Date(invoice?.invoiceData?.dueDate)
            : "",
        generalNotes: invoice?.invoiceData?.generalNotes,
        privateNotes: invoice?.invoiceData?.privateNotes,
      });
      setInvoiceDueDate(
        invoice?.invoiceData?.dueDate &&
          invoice?.invoiceData?.dueDate !== "Invalid date"
          ? new Date(invoice?.invoiceData?.dueDate)
          : ""
      );
      setInvoiceStatus(
        invoice?.status?.charAt(0).toUpperCase() + invoice?.status?.slice(1)
      );
    } else {
      setInvoiceValues(initialInvoiceValues);
    }
  }, [invoice, edit, openInvoiceDialog]);

  useEffect(() => {
    if (invoiceValues.totalPaid > 0 && invoiceValues.balance === 0) {
      setInitialFormValues({ ...initialFormValues, invoiceStatus: "Paid" });
      setInvoiceStatus("Paid");
    } else {
      setInitialFormValues({ ...initialFormValues, invoiceStatus: "Due" });
      setInvoiceStatus("Due");
    }
    // eslint-disable-next-line
  }, [invoiceValues]);

  // Adding Price in Existing Products
  const addProductPriceHandler = (e, index) => {
    let newProduct;
    newProduct = {
      ...selectedProducts[index],
      price: e.target.value,
      totalPrice: e.target.value * selectedProducts[index].quantity,
    };
    selectedProducts[index] = newProduct;
    setSelectedProducts([...selectedProducts]);

    discountValueHandler(index);
    //cuponDiscountHandler(index);
    taxValueHandler(index).toFixed(2);
    addedtaxHandler(index, selectedProducts, setSelectedProducts).toFixed(2);
    handleTotalPrice(index, selectedProducts, setSelectedProducts);
  };

  const createProductHandler = (inputValue, index) => {
    setNewProductName(inputValue);
    setShowProductAddDialoag(true);
    setShowNewProduct(true);
    setProductIndex(index);
  };

  const formResetHandler = () => {
    setPayments([]);
    setAllTaxes([]);
    setInvoiceValues(initialInvoiceValues);
    let newDiscounts = refectorDiscounts.map((item) => {
      return !item?.isPrice
        ? { ...item, selected: false, value: 0 }
        : { ...item, selected: false };
    });
    setRefectorDiscounts([...newDiscounts]);
    setProduct(initialProducts);
    setSelectedPayment(initialPayment);
    setInvoiceValues(initialInvoiceValues);
    setSelectedCustomer(initialSelectedCustomer);
    setSelectedProducts([initialSelectedProduct]);
  };

  let discountValueHandler = (index) => {
    let totalD = 0;
    let discountValue = 0;
    let priceAfterDiscount = 0;

    let productPrice = selectedProducts[index].totalPrice;
    if (selectedProducts[index].discounts?.length) {
      selectedProducts[index].priceAfterDiscount = 0;
      selectedProducts[index].discount = 0;
      totalD = selectedProducts[index].discounts?.reduce((a, b) => {
        if (b?.selected && b?.type === "%") {
          if (priceAfterDiscount === 0) {
            priceAfterDiscount = Number(productPrice);
          }
          priceAfterDiscount = priceAfterDiscount * (1 - b?.value / 100.0);
          discountValue = Number(productPrice) - priceAfterDiscount;
          totalD = discountValue;
          return totalD;
        } else {
          return totalD;
        }
      }, 0);
    }
    selectedProducts[index].priceAfterDiscount = Number(
      priceAfterDiscount
    )?.toFixed(2);
    selectedProducts[index].discount = Number(totalD)?.toFixed(2);
    setTotalD(totalDiscountHandler(selectedProducts));
    handleTotalPrice(index, selectedProducts, setSelectedProducts);
    return totalD;
  };

  let cuponDiscountHandler = (index) => {
    if (selectedProducts && selectedProducts.length) {
      let totalD = 0;
      let allCupons = 0;
      let allDiscount = 0;
      let cuponToUpdate = 0;
      let totalProductPrice = 0;
      let totalProductDiscount = 0;

      selectedProducts[index].priceAfterCoupon = 0;
      selectedProducts[index].couponValueOnProduct = 0;
      totalProductPrice = selectedProducts.reduce((a, b) => {
        if (+b?.priceAfterDiscount !== 0) {
          return (totalProductPrice = a + +b?.priceAfterDiscount);
        } else {
          return (totalProductPrice = a + +b?.totalPrice);
        }
      }, 0);
      let data = selectedProducts.map((b) => {
        if (+b?.discount) {
          return (totalProductDiscount = +totalProductDiscount + +b?.discount);
        } else {
          return totalProductDiscount;
        }
      }, 0);

      let productPrice = selectedProducts[index].totalPrice;
      let priceAfterCupon = +selectedProducts[index].priceAfterCoupon;
      let allPriceAfterDiscount = +selectedProducts[index].priceAfterDiscount;

      if (selectedProducts[index].discounts?.length) {
        if (allPriceAfterDiscount === 0) {
          allPriceAfterDiscount = Number(productPrice);
        }
        let data = selectedProducts[index].discounts.map((b) => {
          if (b?.selected && b?.type !== "%") {
            return (allCupons = +allCupons + +b.value);
          } else {
            return allCupons;
          }
        });
        totalD = selectedProducts[index].discounts.reduce((a, b) => {
          if (b?.selected && b?.type !== "%") {
            allDiscount = allCupons + totalProductDiscount;
            cuponToUpdate =
              allDiscount * (allPriceAfterDiscount / totalProductPrice);
            if (allPriceAfterDiscount <= cuponToUpdate) {
              return (totalD = 0);
            }
            priceAfterCupon = allPriceAfterDiscount - cuponToUpdate;
            totalD = cuponToUpdate;
            return totalD;
          } else {
            return totalD;
          }
        }, 0);
      }
      if (totalD > 0) {
        selectedProducts[index].couponValueOnProduct = Number(totalD).toFixed(
          2
        );
        selectedProducts[index].priceAfterCoupon = Number(
          priceAfterCupon
        )?.toFixed(2);

        setTotalD(totalCuponDiscountHandler(selectedProducts));
        handleTotalPrice(index, selectedProducts, setSelectedProducts);
        return totalD;
      }
    }
  };

  let taxValueHandler = async (index) => {
    let totalT = 0;
    let totalTaxRate = 0;
    let includedTaxRate = 0;
    let excludedTaxRate = 0;
    if (selectedProducts[index].taxes?.length) {
      // eslint-disable-next-line
      let data = selectedProducts[index].taxes?.map((item) => {
        if (item?.active && item?.type === "Added to the price") {
          return (excludedTaxRate = +excludedTaxRate + +Number(item?.taxRate));
        } else if (item?.active && item?.type !== "Added to the price") {
          return (includedTaxRate = +includedTaxRate + +Number(item?.taxRate));
        }
      });
    }
    totalTaxRate = includedTaxRate + excludedTaxRate;
    selectedProducts[index].taxRate = totalTaxRate;
    let productPrice = +selectedProducts[index].totalPrice;
    let priceAfterDiscount = +selectedProducts[index].priceAfterDiscount;
    let priceAfterCupon = +selectedProducts[index].priceAfterCoupon;
    if (priceAfterDiscount === 0) {
      priceAfterDiscount = productPrice;
    }
    if (priceAfterCupon === 0) {
      priceAfterCupon = priceAfterDiscount;
    }
    let productIncludedTax =
      (priceAfterCupon / (1 + includedTaxRate / 100.0)) *
      (includedTaxRate / 100.0);

    let productExcludedTax =
      (priceAfterCupon - productIncludedTax) * (excludedTaxRate / 100.0);
    selectedProducts[index].addedTax = productExcludedTax;
    if (selectedProducts[index].taxes?.length) {
      let data = selectedProducts[index].taxes?.map((item) => {
        if (item?.active && item?.type === "Added to the price") {
          return (totalT =
            (priceAfterCupon - productIncludedTax) * (totalTaxRate / 100.0));
        } else if (item?.active && item?.type !== "Added to the price") {
          return (totalT =
            (productIncludedTax / includedTaxRate) * totalTaxRate);
        }
      }, 0);
    }
    if (totalT >= 0) {
      selectedProducts[index].tax = Number(totalT)?.toFixed(2);
      setTotalT(totalTaxHandler(selectedProducts));
      handleTotalPrice(index, selectedProducts, setSelectedProducts);
      return totalT;
    } else {
      selectedProducts[index].tax = 0?.toFixed(2);
      setTotalT(totalTaxHandler(selectedProducts));
      handleTotalPrice(index, selectedProducts, setSelectedProducts);
      return 0;
    }
  };

  useEffect(() => {
    allFunctions();
  }, [productsIndex, productQuantity]);

  const allFunctions = () => {
    selectedProducts.map((item, index) => {
      discountValueHandler(index);
      cuponDiscountHandler(index);
      taxValueHandler(index);
      addedtaxHandler(index, selectedProducts, setSelectedProducts);
    });
  };

  let oneTimeFunction = (products, productId, productDiscount) => {
    let selectedProduct = [...products];

    let productIndex = selectedProduct.findIndex(
      (item) => item._id === productId
    );

    if (productIndex !== null) {
      let refectorDiscount = productDiscount.map((item) => {
        return { ...item, selected: false };
      });
      selectedProduct[productIndex].discounts = refectorDiscount;

      setSelectedProducts([...selectedProduct]);
      setRefectorDiscounts([...refectorDiscount]);
    }

    setProductsIndex(productIndex);
    discountValueHandler(productIndex);
    cuponDiscountHandler(productIndex);
    taxesCalculation(productIndex, selectedProducts);
    handleTotalPrice(productIndex, selectedProducts, setSelectedProducts);

    taxValueHandler(productIndex);
    addedtaxHandler(productIndex, selectedProducts, setSelectedProducts);
  };

  const discountSettingHandler = (
    e,
    productDiscounts,
    discount,
    products,
    selectedProductID
  ) => {
    // for specific discount
    let percentageProductDiscounts = [...productDiscounts];
    let newDiscount = { ...discount, selected: e.target.checked };
    let index = percentageProductDiscounts.findIndex(
      (item) => item._id === discount._id
    );
    if (index !== null) {
      percentageProductDiscounts[index] = { ...newDiscount };
    }

    // for specific product
    let dublicateProducts = [...products];
    let productIndex = dublicateProducts.findIndex(
      (item) => item._id === selectedProductID
    );
    if (productIndex !== null) {
      dublicateProducts[productIndex].discounts = [
        ...percentageProductDiscounts,
      ];
    }

    setSelectedProducts([...dublicateProducts]);
    let name = selectedProducts[productIndex].discounts[index].name;
    setDiscountName(name);
    discountValueHandler(productIndex);
    cuponDiscountHandler(productIndex);
    taxValueHandler(productIndex);
    addedtaxHandler(productIndex, selectedProducts, setSelectedProducts);
    selectedDiscount(
      productIndex,
      e,
      name,
      selectedProducts,
      refectorDiscounts,
      setRefectorDiscounts
    );
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={edit ? editInitialInvoice : initialFormValues}
        onSubmit={(values) => {
          let newInvoice = {
            ...values,
            invoiceType: "sale",
            invoiceStatus: invoiceStatus,
            createdAt: new Date(),
            date: new Date(),
            dueDate: invoiceDueDate,
            payments,
            // removing empty product cards
            products: selectedProducts.filter((p) => {
              return p.name !== "";
            }),
            newInvoiceNumber: invNumber,
            senderInformation: user,
            receiverInformation: selectedCustomer ? selectedCustomer : "",
            productsTotal: invoiceValues.productsTotal,
            tax: companyTax,
            taxTotal: totalT,
            addedTaxTotal: addedTotalT,
            totalSales: invoiceValues.totalSales?.toFixed(2),
            paidTotal: invoiceValues.totalPaid,
            discount: invoiceValues.totalDiscount,
            discountTotal: invoiceValues.totalDiscount,
            discountType: true,
            balance: invoiceValues.balance?.toFixed(2),
            store: selectedStore ? selectedStore : {},
          };
          if (selectedProducts.length) {
            if (edit) {
              // updating
              dispatch(
                invoiceActions.updateInvoice(
                  invoiceFilterState,
                  { ...newInvoice, id: invoice?._id },
                  invoicesUIProps.queryParams
                )
              );
              setOpenStoreDialog(false);
            } else {
              // creating
              dispatch(
                invoiceActions.createInvoice(
                  invoiceFilterState,
                  newInvoice,
                  invoicesUIProps.queryParams
                )
              );
              setOpenStoreDialog(false);
            }
            dispatch(
              invoiceActions.fetchInvoices(
                invoiceFilterState,
                invoicesUIProps.queryParams
              )
            );
          } else {
            toast.error("Please Enter Invoice Data");
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, touched, handleBlur }) => {
          return (
            <>
              <Modal
                size="lg"
                className="modal-drawer invoice-drawer-info"
                show={openInvoiceDialog}
                onHide={() => {}}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenInvoiceDialog(false);
                      // Reseting Forms
                      formResetHandler();
                    }}
                    className="btn btn-close"
                  >
                    <img src={IconClose} alt="loading" />
                  </button>
                  <Modal.Title>
                    {edit
                      ? i18n("INVOICES.EditInvoice")
                      : i18n("INVOICES.NewTaxInvoice")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form className="form form-label-right">
                    <Card>
                      <div
                        className="invoice-title"
                        style={{ borderBottom: "none", marginTop: "-1rem" }}
                      >
                        <h5 className="m-0">
                          <b>{i18n("INVOICES.InvoiceInformation")}</b>
                        </h5>
                      </div>
                      {edit ? (
                        <label className="invoice-status-ribbon">
                          {invoiceStatus}
                        </label>
                      ) : null}
                      <div className="invoice-items row">
                        <div className={"col-12 mt-5"}>
                          <div className="form-group input-select">
                            <Field
                              name="storeName"
                              touched2={touched}
                              component={Input}
                              label={i18n("global.StoreName")}
                              placeholder={i18n("global.StoreName")}
                              style={{ maxWidth: "auto" }}
                              readOnly={true}
                              value={
                                selectedStore?.name
                                  ? selectedStore?.name
                                  : i18n("global.StoreName")
                              }
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="invoiceNumber"
                              type="input"
                              component={Input}
                              disabled={true}
                              value={invNumber}
                              placeholder={i18n("global.InvoiceNumber")}
                              label={i18n("global.InvoiceNumber")}
                            />
                          </div>
                          <div className=" form-select ">
                            <DatePickerField
                              name="invoiceDate"
                              label="Invoice Date"
                              selected={values.invoiceDate}
                              onChange={(val) => {
                                setFieldValue("invoiceDate", val);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <div
                        className="invoice-title"
                        style={{ borderBottom: "none" }}
                      >
                        <h5 className="m-0">
                          <b>{i18n("INVOICES.CustomerInformation")}</b>
                        </h5>
                      </div>
                      <div className="invoice-items row">
                        <div className="col-12">
                          <div className="form-group input-select">
                            <Field
                              name="customerName"
                              label={i18n("global.CustomerName")}
                              disabled={true}
                              component={ReactCreateableSelector}
                              placeholder={i18n("global.CustomerName")}
                              options={
                                customers?.entities
                                  ? [].concat(
                                      ...customers?.entities.map((item) => {
                                        return {
                                          item: item,
                                          value: item?._id,
                                          label: `${
                                            item?.name ? item?.name : ""
                                          }`,
                                        };
                                      })
                                    )
                                  : undefined
                              }
                              value={{
                                name: selectedCustomer?.name
                                  ? selectedCustomer?.name
                                  : i18n("global.CustomerName"),
                                label: selectedCustomer?.name
                                  ? selectedCustomer?.name
                                  : i18n("global.CustomerName"),
                              }}
                              onCreateOption={(inputValue) => {
                                setNewCustomerName(inputValue);
                                setShowCustomerAddDialoag(true);
                                setShowNewCustomer(true);
                              }}
                              onChange={(option) => {
                                addCustomerToInvoice(
                                  option,
                                  selectedCustomer,
                                  setSelectedCustomer
                                );
                              }}
                              onBlur={handleBlur}
                              touched2={touched}
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="customerEmail"
                              label={i18n("global.Email")}
                              placeholder={i18n("global.Email")}
                              component={Input}
                              disabled={true}
                              value={selectedCustomer?.email}
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="customerMobile"
                              type="input"
                              disabled={true}
                              value={selectedCustomer?.mobile}
                              component={Input}
                              placeholder={i18n("global.Mobile")}
                              label={i18n("global.Mobile")}
                            />
                          </div>
                          <div className="form-group">
                            <Field
                              name="customerTaxNumber"
                              component={Input}
                              disabled={true}
                              value={selectedCustomer?.taxNumber}
                              placeholder={i18n("global.TaxNumber")}
                              label={i18n("global.TaxNumber")}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                    <input
                      style={{ opacity: "0" }}
                      ref={selectRef}
                      autoFocus={true}
                    />
                    {selectedProducts?.length
                      ? selectedProducts.map((product, index) => {
                          return (
                            <Card
                              key={index}
                              className="position-relative overflow-hidden"
                            >
                              <div
                                className="invoice-title"
                                style={{ borderBottom: "none" }}
                              >
                                <h5 className="m-0 row">
                                  <b>
                                    {i18n("global.ProductNumber")} {index + 1}
                                  </b>
                                </h5>
                                {index > 0 ? (
                                  <>
                                    <p
                                      className="pt-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        ProductDeleteHandler(index)
                                      }
                                    >
                                      X
                                    </p>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {product && product.refundedQuantity && (
                                <label className="ribbon-label">
                                  {product.refundedQuantity} x Refund
                                </label>
                              )}
                              <div className="invoice-items row">
                                <div
                                  className={`col-12 ${
                                    product?.refundedQuantity ? "mt-5" : ""
                                  }`}
                                >
                                  <div className="form-group input-select">
                                    <Field
                                      name="productName"
                                      label={i18n("global.Name")}
                                      disabled={true}
                                      component={ReactCreateableSelector}
                                      options={
                                        productsToShow
                                          ? [].concat(
                                              ...productsToShow
                                                .filter((it) => {
                                                  return it.active !== false;
                                                })
                                                .map((item) => {
                                                  return {
                                                    item: item,
                                                    value: item?._id,
                                                    label: `${
                                                      item?.name
                                                        ? item?.name
                                                        : ""
                                                    }`,
                                                  };
                                                })
                                            )
                                          : undefined
                                      }
                                      placeholder={i18n("global.Name")}
                                      value={{
                                        name: selectedProducts[index]?.name
                                          ? selectedProducts[index]?.name
                                          : i18n("global.ProductName"),
                                        label: selectedProducts[index]?.name
                                          ? selectedProducts[index]?.name
                                          : i18n("global.ProductName"),
                                      }}
                                      onCreateOption={(inputValue) =>
                                        userAccess?.invoices?.subItem[0]
                                          ?.selected
                                          ? createProductHandler(
                                              inputValue,
                                              index
                                            )
                                          : toast.error("Access Denied")
                                      }
                                      onChange={(option) => {
                                        let newProduct;
                                        newProduct = {
                                          ...selectedProducts[index],
                                          name: option?.item?.name,
                                          price: option?.item?.price,
                                          isPrice:
                                            option?.item?.price &&
                                            option?.item?.price > 0,
                                          description:
                                            option?.item?.description,
                                          barcode: option?.item?.barcode,
                                          discount: 0,
                                          priceAfterDiscount: 0,
                                          couponValueOnProduct: 0,
                                          priceAfterCoupon: 0,
                                          tax: 0,
                                          addedTax: 0,
                                          taxRate: 0,
                                          addedTaxRate: 0,
                                          quantity:
                                            option?.item?.soldBy === "each"
                                              ? "1"
                                              : addZeroes("1"),
                                          taxable: option?.item?.taxable,
                                          totalPrice: option?.item?.price,
                                          _id: option?.item?._id,
                                          vat: option?.item?.taxable,
                                          soldBy: option?.item?.soldBy,
                                          discounts: refectorDiscounts,
                                          newTax: option?.item?.taxes.map(
                                            (item) => {
                                              return {
                                                ...item,
                                                selected: true,
                                              };
                                            }
                                          ),
                                          taxes: allTaxes,

                                          showDiscounts: false,
                                          showTaxes: false,
                                        };

                                        selectedProducts[index] = newProduct;
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                        oneTimeFunction(
                                          selectedProducts,
                                          newProduct._id,
                                          newProduct.discounts
                                        );
                                      }}
                                      onBlur={handleBlur}
                                      touched2={touched}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <Field
                                      label={i18n("global.Description")}
                                      name="productDescription"
                                      type="input"
                                      value={product?.description}
                                      component={Input}
                                      disabled={true}
                                      placeholder={i18n("global.Description")}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <Field
                                      label={i18n("global.UnitPrice")}
                                      placeholder={i18n("global.UnitPrice")}
                                      name="productPrice"
                                      type="number"
                                      component={Input}
                                      value={product?.price}
                                      disabled={product?.isPrice}
                                      onChange={(e) =>
                                        addProductPriceHandler(e, index)
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`form-group ${
                                      selectedProducts[index].soldBy ===
                                      "amount"
                                        ? "remove-arrows"
                                        : ""
                                    }`}
                                  >
                                    <Field
                                      label={
                                        selectedProducts[index].soldBy ===
                                        "each"
                                          ? i18n("global.Quantity")
                                          : i18n("MENU.Amount")
                                      }
                                      placeholder={
                                        selectedProducts[index].soldBy ===
                                        "each"
                                          ? i18n("global.Quantity")
                                          : i18n("MENU.Amount")
                                      }
                                      component={Input}
                                      type={"number"}
                                      name="productQuantity"
                                      value={product?.quantity}
                                      min="0"
                                      onClick={(e) => e.target.select()}
                                      onKeyDown={(e) =>
                                        product?.soldBy === "each"
                                          ? ["e", "E", "+", "-", "."].includes(
                                              e.key
                                            ) && e.preventDefault()
                                          : ["e", "E", "+", "-"].includes(
                                              e.key
                                            ) && e.preventDefault()
                                      }
                                      onChange={(e) => {
                                        let newProduct = {
                                          ...selectedProducts[index],
                                          quantity: Number(e.target.value),
                                          totalPrice:
                                            product.price * e.target.value,
                                        };

                                        selectedProducts[index] = newProduct;

                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                        setProductQuantity(
                                          selectedProducts[index].quantity
                                        );

                                        allFunctions();
                                        handleTotalPrice(
                                          index,
                                          selectedProducts,
                                          setSelectedProducts
                                        );
                                      }}
                                      onBlur={(e) => {
                                        let value = addZeroes(e.target.value);
                                        let newProduct = {
                                          ...selectedProducts[index],
                                          quantity:
                                            selectedProducts[index].soldBy ===
                                            "each"
                                              ? e.target.value
                                              : value,
                                          totalPrice:
                                            product.price * e.target.value,
                                        };

                                        selectedProducts[index] = newProduct;
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                        setProductQuantity(
                                          selectedProducts[index].quantity
                                        );

                                        allFunctions();
                                        handleTotalPrice(
                                          index,
                                          selectedProducts,
                                          setSelectedProducts
                                        );
                                      }}
                                    />
                                  </div>
                                  {/* // Products Discounts */}
                                  <div className="form-group mb-1">
                                    <Field
                                      label={i18n("global.Discount")}
                                      placeholder={i18n("global.Discount")}
                                      name="productDiscount"
                                      type="text"
                                      component={Input}
                                      readOnly={true}
                                      onClick={() => {
                                        let newProduct = {
                                          ...selectedProducts[index],
                                          showDiscounts: !selectedProducts[
                                            index
                                          ].showDiscounts,
                                        };

                                        selectedProducts[index] = newProduct;
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                      }}
                                    />
                                    {selectedProducts[index]
                                      .couponValueOnProduct ? (
                                      <>
                                        <span className="data-in-field">
                                          {
                                            selectedProducts[index]
                                              .couponValueOnProduct
                                          }
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span className="data-in-field">
                                          {selectedProducts[index].discount}
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  {selectedProducts[index].showDiscounts ? (
                                    <div className="d-inline-block w-100">
                                      {product?.discounts?.length
                                        ? product?.discounts
                                            ?.filter(
                                              (discount) =>
                                                discount.type === "%"
                                            )
                                            ?.map((item, ind) => {
                                              return (
                                                <div
                                                  key={ind}
                                                  className="d-flex align-items-center justify-content-between"
                                                >
                                                  <div className="d-flex align-items-center">
                                                    <SwitchToggler
                                                      className="default-switch"
                                                      id={
                                                        Math.random() *
                                                        (100 - 1)
                                                      }
                                                      name={"ind"}
                                                      checked={item?.selected}
                                                      onChange={(e) => {
                                                        discountSettingHandler(
                                                          e,
                                                          product?.discounts,
                                                          item,
                                                          selectedProducts,
                                                          product._id
                                                        );
                                                      }}
                                                    />
                                                    <strong className="pt-7">
                                                      {item?.name}
                                                    </strong>
                                                  </div>
                                                  {item?.selected ? (
                                                    <div className="pt-3">
                                                      <strong className="d-flex align-items-baseline">
                                                        <span
                                                          style={{
                                                            position:
                                                              "relative",
                                                            zIndex: "1",
                                                            left: "25px",
                                                          }}
                                                        >
                                                          {item?.type}
                                                        </span>
                                                        <div
                                                          className="form-group mb-0"
                                                          style={{
                                                            width: "55px",
                                                          }}
                                                        >
                                                          <Field
                                                            name="amount"
                                                            type="input"
                                                            component={Input}
                                                            classes="text-right"
                                                            value={item?.value}
                                                            onClick={(e) =>
                                                              e.target.select()
                                                            }
                                                            disabled={
                                                              item?.isPrice
                                                            }
                                                            onChange={(e) => {
                                                              handlingPercentage(
                                                                e,
                                                                item?.type
                                                              );
                                                              setDiscountValue(
                                                                e.target.value
                                                              );
                                                              let productDiscounts = [
                                                                ...selectedProducts[
                                                                  index
                                                                ]?.discounts,
                                                              ];
                                                              let newDiscount = {
                                                                ...selectedProducts[
                                                                  index
                                                                ]?.discounts[
                                                                  ind
                                                                ],
                                                                value:
                                                                  e.target
                                                                    .value,
                                                              };
                                                              productDiscounts[
                                                                ind
                                                              ] = newDiscount;

                                                              let newProduct = {
                                                                ...selectedProducts[
                                                                  index
                                                                ],
                                                                discounts: productDiscounts,
                                                              };
                                                              selectedProducts[
                                                                index
                                                              ] = newProduct;
                                                              let name =
                                                                selectedProducts[
                                                                  index
                                                                ].discounts[ind]
                                                                  .name;
                                                              setDiscountName(
                                                                name
                                                              );

                                                              setSelectedProducts(
                                                                [
                                                                  ...selectedProducts,
                                                                ]
                                                              );
                                                              discountValueHandler(
                                                                index
                                                              );
                                                              cuponDiscountHandler(
                                                                index
                                                              );
                                                              taxValueHandler(
                                                                index
                                                              );
                                                              addedtaxHandler(
                                                                index,
                                                                selectedProducts,
                                                                setSelectedProducts
                                                              );
                                                            }}
                                                          />
                                                        </div>
                                                      </strong>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              );
                                            })
                                        : ""}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {/* // Products Taxes */}
                                  <div className="form-group mb-1 mt-5">
                                    <Field
                                      type="text"
                                      readOnly={true}
                                      name="productTax"
                                      component={Input}
                                      label={i18n("INVOICES.Tax")}
                                      placeholder={i18n("INVOICES.Tax")}
                                      onClick={() => {
                                        let newProduct = {
                                          ...selectedProducts[index],
                                          showTaxes: !selectedProducts[index]
                                            .showTaxes,
                                        };
                                        selectedProducts[index] = newProduct;
                                        setSelectedProducts([
                                          ...selectedProducts,
                                        ]);
                                      }}
                                    />
                                    <span className="data-in-field">
                                      {selectedProducts[index].tax}
                                    </span>
                                  </div>
                                  {selectedProducts[index].showTaxes ? (
                                    <div className="d-inline-block w-100">
                                      {product?.taxes?.length
                                        ? product?.taxes?.map((item, ind) => {
                                            return (
                                              <div
                                                key={ind}
                                                className="d-flex align-items-center justify-content-between"
                                              >
                                                <div className="d-flex align-items-center">
                                                  <SwitchToggler
                                                    className="default-switch"
                                                    id={
                                                      Math.random() * (100 - 1)
                                                    }
                                                    name={"ind"}
                                                    checked={
                                                      item?.active || false
                                                    }
                                                    onChange={(e) => {
                                                      let productTaxes = [
                                                        ...selectedProducts[
                                                          index
                                                        ]?.taxes,
                                                      ];
                                                      let newTax = {
                                                        ...selectedProducts[
                                                          index
                                                        ]?.taxes[ind],
                                                        active:
                                                          e.target.checked,
                                                      };

                                                      productTaxes[
                                                        ind
                                                      ] = newTax;
                                                      let newProduct = {
                                                        ...selectedProducts[
                                                          index
                                                        ],
                                                        taxes: productTaxes,
                                                      };

                                                      selectedProducts[
                                                        index
                                                      ] = newProduct;

                                                      setSelectedProducts([
                                                        ...selectedProducts,
                                                      ]);
                                                      discountValueHandler(
                                                        index
                                                      );
                                                      cuponDiscountHandler(
                                                        index
                                                      );
                                                      taxValueHandler(index);
                                                      addedtaxHandler(
                                                        index,
                                                        selectedProducts,
                                                        setSelectedProducts
                                                      );
                                                    }}
                                                  />
                                                  <strong className="pt-7">
                                                    {item?.name}
                                                  </strong>
                                                </div>
                                                {item?.active ? (
                                                  <div className="pt-3">
                                                    <strong className="d-flex align-items-baseline">
                                                      <span
                                                        style={{
                                                          position: "relative",
                                                          zIndex: "1",
                                                          left: "15px",
                                                        }}
                                                      >
                                                        %
                                                      </span>
                                                      <div
                                                        className="form-group mb-0"
                                                        style={{
                                                          width: "45px",
                                                        }}
                                                      >
                                                        <Field
                                                          type="input"
                                                          name="amount"
                                                          readOnly={true}
                                                          component={Input}
                                                          classes="text-right"
                                                          value={item?.taxRate}
                                                        />
                                                      </div>
                                                    </strong>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            );
                                          })
                                        : ""}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="form-group mt-7">
                                    <Field
                                      disabled={true}
                                      component={Input}
                                      name="productBarcode"
                                      value={product?.barcode}
                                      label={i18n("global.Barcode")}
                                      placeholder={i18n("global.Barcode")}
                                    />
                                  </div>
                                  <div className="form-group">
                                    <Field
                                      label={i18n("global.ItemTotalPrice")}
                                      name="productTotalPrice"
                                      placeholder={i18n(
                                        "global.ItemTotalPrice"
                                      )}
                                      component={Input}
                                      value={
                                        product?.totalPriceProduct >= 0
                                          ? product?.totalPriceProduct
                                          : Number(product?.totalPrice)
                                      }
                                      disabled={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Card>
                          );
                        })
                      : ""}
                    <Card>
                      <p
                        className="text-center pt-3"
                        style={{ cursor: "pointer", color: "#309cdc" }}
                        onClick={() => {
                          setAllTaxes([]);
                          addProductToInvoice(
                            setProduct,
                            selectedProducts,
                            setSelectedProducts
                          );
                        }}
                      >
                        {i18n("INVOICES.AddANewItemCard")}
                      </p>
                    </Card>
                    <Card className="invoice-total">
                      <div>
                        {selectedProducts?.length
                          ? refectorDiscounts.map((items, ind) => {
                              return (
                                <div className="invoice-items row">
                                  <div>
                                    <div
                                      key={ind}
                                      className="d-flex align-items-center justify-content-between"
                                    >
                                      <div className="d-flex align-items-center">
                                        <SwitchToggler
                                          className="default-switch"
                                          id={ind + Math.random() * (100 - 1)}
                                          name={"ind"}
                                          checked={items?.selected}
                                          onChange={(e) => {
                                            let newDiscount = {
                                              ...items,
                                              selected: e.target.checked,
                                            };
                                            refectorDiscounts[
                                              ind
                                            ] = newDiscount;
                                            setRefectorDiscounts([
                                              ...refectorDiscounts,
                                            ]);

                                            selectedProducts.map(
                                              (item, index) => {
                                                selectedProducts[
                                                  index
                                                ].discounts[ind].selected =
                                                  refectorDiscounts[
                                                    ind
                                                  ].selected;
                                              }
                                            );
                                            allFunctions();
                                          }}
                                        />
                                        <strong className="pt-7">
                                          {items?.name}
                                        </strong>
                                      </div>
                                      {items?.selected ? (
                                        <div className="pt-3">
                                          <strong className="d-flex align-items-baseline">
                                            <span
                                              style={{
                                                position: "relative",
                                                zIndex: "1",
                                                left: "25px",
                                              }}
                                            >
                                              {items?.type}
                                            </span>
                                            <div
                                              className="form-group mb-0"
                                              style={{
                                                width: "55px",
                                              }}
                                            >
                                              <Field
                                                name="amount"
                                                type="input"
                                                component={Input}
                                                classes="text-right"
                                                value={items?.value}
                                                onClick={(e) =>
                                                  e.target.select()
                                                }
                                                disabled={items?.isPrice}
                                                onChange={(e) => {
                                                  handlingPercentage(
                                                    e,
                                                    items?.type
                                                  );

                                                  let newDiscount = {
                                                    ...items,
                                                    value: e.target.value,
                                                  };
                                                  refectorDiscounts[
                                                    ind
                                                  ] = newDiscount;
                                                  setRefectorDiscounts([
                                                    ...refectorDiscounts,
                                                  ]);
                                                  selectedProducts.map(
                                                    (item, index) => {
                                                      selectedProducts[
                                                        index
                                                      ].discounts = refectorDiscounts;
                                                    }
                                                  );
                                                  allFunctions();
                                                }}
                                              />
                                            </div>
                                          </strong>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </Card>
                    <Card className="invoice-total">
                      <div
                        className="invoice-title"
                        style={{ borderBottom: "none" }}
                      >
                        <h5 className="m-0">
                          <b>{i18n("INVOICES.Summary")}</b>
                        </h5>
                      </div>
                      <ul>
                        <li>
                          <span>{i18n("INVOICES.Subtotal")}</span>
                          <span>
                            {companyCurrency}{" "}
                            {invoiceValues?.subTotalPrice
                              ? invoiceValues?.subTotalPrice?.toFixed(2)
                              : 0.0}
                          </span>
                        </li>
                        <li>
                          <span>{i18n("INVOICES.Discounts")}</span>
                          <span>
                            {companyCurrency}{" "}
                            {invoiceValues?.totalDiscount
                              ? invoiceValues?.totalDiscount?.toFixed(2)
                              : 0.0}
                          </span>
                        </li>
                        <li>
                          <span>Taxable Amount</span>
                          <strong>
                            {companyCurrency}{" "}
                            {invoiceValues?.taxableAmount
                              ? invoiceValues?.taxableAmount?.toFixed(2)
                              : 0.0}
                          </strong>
                        </li>
                        <li>
                          <span>{i18n("INVOICES.Tax")}</span>
                          <span>
                            {companyCurrency}{" "}
                            {invoiceValues?.tax
                              ? invoiceValues?.tax?.toFixed(2)
                              : 0.0}
                          </span>
                        </li>
                        {/* {invoiceValues.balance > 0 ? (
                          <>
                            <div className=" form-select ">
                              <DatePickerField
                                name="dueDate"
                                label={i18n("global.DueDate")}
                                placeholderText={i18n("global.DueDate")}
                                selected={values.dueDate}
                                onChange={(val) => {
                                  setFieldValue("dueDate", val);
                                  setInvoiceDueDate(val);
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )} */}
                      </ul>
                    </Card>
                    <Card>
                      <div
                        className="invoice-title"
                        style={{ borderBottom: "none" }}
                      >
                        <h5 className="m-0">
                          <b>{i18n("INVOICES.Notes")}</b>
                        </h5>
                      </div>
                      <div className="invoice-items row">
                        <div className="col-12">
                          <div className="form-group">
                            <div>{i18n("INVOICES.GeneralNotes")}</div>
                            <Field
                              name="generalNotes"
                              component={TextArea}
                              placeholder={i18n("global.GeneralNotes")}
                            />
                          </div>
                          <div className="form-group">
                            <div>{i18n("INVOICES.PrivateNotes")}</div>
                            <Field
                              name="privateNotes"
                              component={TextArea}
                              placeholder={i18n("global.PrivateNotes")}
                            />
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="btn-bar">
                    <button
                      type="button"
                      className="btn btn-seconday btn-elevate  mr-5"
                      style={{ backgroundColor: "#00000024" }}
                      onClick={() => {
                        setOpenInvoiceDialog(false);
                        // Reseting Forms
                        formResetHandler();
                      }}
                    >
                      {i18n("INVOICES.Cancel")}
                    </button>
                    <button
                      type="submit"
                      style={{ display: "block" }}
                      className="btn btn-primary btn-elevate f-button"
                      onClick={() => {
                        if (selectedProducts[0].name) {
                          setShowPaymentsModal(true);
                        } else {
                          toast.error("Please add invoice data first");
                        }
                      }}
                    >
                      {i18n("INVOICES.ProceedToPayments")}
                    </button>
                  </div>
                </Modal.Footer>
              </Modal>
              <InvoicePaymentsModal
                payments={payments}
                invNumber={invNumber}
                show={showPaymentsModal}
                setPayments={setPayments}
                saveInvoice={handleSubmit}
                paymentTypes={paymentTypes}
                invoiceValues={invoiceValues}
                invoiceDueDate={invoiceDueDate}
                companyCurrency={companyCurrency}
                formResetHandler={formResetHandler}
                setInvoiceDueDate={setInvoiceDueDate}
                onHide={() => setShowPaymentsModal(false)}
                PaymentDeleteHandler={PaymentDeleteHandler}
                setOpenInvoiceDialog={() => setOpenInvoiceDialog(false)}
              />
            </>
          );
        }}
      </Formik>
      <ProductEditDialog
        show={newProductDialog}
        onHide={() => setNewProductDialog(false)}
      />
      <CustomerEditDialog
        customerName={newCustomerName}
        showCustomerAddDialoag={showCustomerAddDialoag}
        onHide={() => setShowCustomerAddDialoag(false)}
        setShowCustomerAddDialoag={() => setShowCustomerAddDialoag(false)}
      />
      <ProductEditDialog
        productName={newProductName}
        show={showProductAddDialoag}
        onHide={() => setShowProductAddDialoag(false)}
      />
    </>
  );
};
export default InvoiceCreateDialog;
