import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";

let multiAxisOptions = {
  stacked: false,
  maintainAspectRatio: false,
  aspectRatio: 0.6,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "#495057",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
    y: {
      type: "linear",
      display: true,
      position: "left",
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      ticks: {
        color: "#495057",
      },
      grid: {
        drawOnChartArea: false,
        color: "#ebedef",
      },
    },
  },
};

export const LineChart = ({ data }) => {
  const [chartOptions, setChartoptions] = useState(multiAxisOptions);

  useEffect(() => {
    if (data) {
      if (data?.datasets[0]?.hidden && data?.datasets[1]?.hidden) {
        setChartoptions({
          ...chartOptions,
          scales: {
            ...chartOptions.scales,
            y: { ...chartOptions.scales.y, display: false },
          },
        });
      } else {
        setChartoptions({
          ...chartOptions,
          scales: {
            ...chartOptions.scales,
            y: { ...chartOptions.scales.y, display: true },
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [data]);
  return (
    <div>
      <Chart type="line" data={data} options={chartOptions} />
    </div>
  );
};
