import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { useProductsUIContext } from "../ProductsUIContext";
import { Categories } from "../categories/Categories";
import { Discounts } from "../product-discount/Discounts";
import { FilterDialogeProducts } from "./FilterDialogeProducts";
import { i18n } from "../../private/languageSelector";
import { ProductGroupActionModal } from "../../../modules/ECommerce/pages/products/product-edit/ProductGroupActionModal";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = { ...queryParams.filter };
  filter.name = searchText;
  filter.barcode = searchText;
  filter.description = searchText;
  newQueryParams.filter = filter;
  return newQueryParams;
};

function ProductsFilter({ selectedProducts, setIds }) {
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUIContext.queryParams,
      setQueryParams: productsUIContext.setQueryParams,
      newProductButtonClick: productsUIContext.newProductButtonClick,
    };
  }, [productsUIContext]);

  const [openManageCategoryDialog, setOpenManageCategoryDialog] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [openGroupDialog, setOpenGroupDialog] = useState(false);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      productsUIProps.setQueryParams(newQueryParams);
    }
  };

  const { userAccess } = useSelector((state) => ({
    userAccess: state?.users?.userAccess,
  }));

  let productsAccess = userAccess?.products?.subItem;

  return (
    <>
      <Formik
        initialValues={{
          status: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}>
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="action-bar">
              <div className="d-flex align-items-center">
                <button
                  disabled={!selectedProducts?.length}
                  type="button"
                  className={` mr-5 btn ${selectedProducts?.length ? "btn-outline-primary" : "btn-outline-dark"}`}
                  onClick={() => setOpenGroupDialog(true)}>
                  {i18n("MENU.Actions")}
                </button>
                <button
                  onClick={() => {
                    setFilterDialog(true);
                  }}
                  type="button"
                  className={`btn btn-outline-primary ${filterActive ? "active" : ""} `}>
                  {i18n("MENU.Filter")}
                </button>
              </div>
              <input
                type="text"
                className="form-control"
                name="searchText"
                placeholder={i18n("MENU.SearchByNameDescriptionOrBarcode")}
                onBlur={handleBlur}
                value={values.searchText}
                onChange={(e) => {
                  setFieldValue("searchText", e.target.value);
                  handleSubmit();
                }}
              />
              <div className="d-flex align-items-center justify-content-end">
                {userAccess?.createData ? (
                  <button
                    type="button"
                    className="btn btn-pri btn-sm btn-rounded"
                    onClick={productsUIProps.newProductButtonClick}>
                    <span className="fas fa-plus mr-2" />
                    {i18n("MENU.PRODUCT")}
                  </button>
                ) : (
                  ""
                )}
                {productsAccess && productsAccess[1]?.selected ? (
                  <button
                    type="button"
                    className="btn btn-pri btn-sm btn-rounded"
                    onClick={() => {
                      setOpenManageCategoryDialog(true);
                    }}>
                    {i18n("MENU.CATEGORIES")}
                  </button>
                ) : (
                  ""
                )}
                {productsAccess && productsAccess[2]?.selected ? (
                  <button
                    type="button"
                    className="btn btn-pri btn-sm btn-rounded"
                    onClick={() => {
                      setOpenDiscountDialog(true);
                    }}>
                    {i18n("MENU.DISCOUNTS")}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Categories
        userAccess={userAccess}
        openManageCategoryDialog={openManageCategoryDialog}
        setOpenManageCategoryDialog={setOpenManageCategoryDialog}
      />
      <Discounts
        userAccess={userAccess}
        openDiscountDialog={openDiscountDialog}
        setOpenDiscountDialog={setOpenDiscountDialog}
      />
      <ProductGroupActionModal
        setIds={setIds}
        userAccess={userAccess}
        openGroupDialog={openGroupDialog}
        setOpenGroupDialog={setOpenGroupDialog}
        selectedProducts={selectedProducts}
      />
      <FilterDialogeProducts
        setFilterActive={setFilterActive}
        show={filterDialog}
        title={i18n("MENU.FilterProductsList")}
        subTitle="Clear All Filters"
        primaryAction={() => setFilterDialog(false)}
        secondaryAction={() => setFilterDialog(false)}
        classes="modal-drawer"
        primaryActionTitle={i18n("MENU.Apply")}
        secondaryActionTitle="No"
      />
    </>
  );
}
export default ProductsFilter;
