/* eslint-disable no-useless-concat */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Card, Input, Mobile, Checkbox } from "../../../../_metronic/_partials/controls";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import IconEye from "../../../../assets/img/eye.svg";
import { i18n } from "../../private/languageSelector";
import IconView from "../../../../assets/img/view.svg";
import IconClose from "../../../../assets/img/icon-close.svg";
import IconDownload from "../../../../assets/img/download.svg";

import { AsYouType } from "libphonenumber-js/max";

let asYouType = new AsYouType();

Yup.addMethod(Yup.mixed, "validatePhoneNumber", function(msg) {
  return this.test({
    name: "validatePhoneNumber",
    message: msg,
    test: (value) => {
      if (value) {
        asYouType.reset();
        asYouType.input(value);
        return asYouType.getNumber().isValid();
      } else {
        return true;
      }
    },
  });
});
// Validation schema
const pdfInvoiceDialog = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .matches(
      // eslint-disable-next-line
      /^\w+[\w-\.]*\@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/,
      "Must contain e.g (example._-@example.com)"
    ),
  mobile: Yup.string().validatePhoneNumber("Invalid Phone Number"),
});
const initPDFInvoiceDialog = {
  email: "",
  mobile: "",
  sendViaSMS: "",
  smsMessage: "",
  emailMessage: "",
  sendViaEmail: "",
  language: "englishLanguage",
  invoiceUrl: `${process.env.REACT_APP_URL}view/invoiceHtml/`,
  posInvoiceUrl: `${process.env.REACT_APP_URL}posInvoice/`,
};

function SendPdfInvoiceDialog({ id, secondaryAction, sendPdfInvoiceDialog, setSendPdfInvoiceDialog }) {
  const dispatch = useDispatch();

  const { invoice } = useSelector((state) => ({
    invoice: state?.invoices.invoiceForEdit,
  }));

  const [showSms, setShowSms] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  let resetFromState;

  let copyToClipboard = (textToCopy) => {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand("copy") ? res() : rej();
        textArea.remove();
      });
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ initPDFInvoiceDialog }}
        validationSchema={pdfInvoiceDialog}
        onSubmit={(values) => {
          if (values?.sendViaSMS === true && values?.sendViaEmail === true) {
            const smsMessage = {
              recipient: "+923364184194",
              sender: "+19042951659",
              content:
                invoice?.invoiceData?.channel === "web"
                  ? values?.smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.invoiceUrl + id
                  : values?.smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.posInvoiceUrl + id,
            };
            const emailMessage = {
              recipient: values.email,
              subject: "suppot@fatoraty.com",
              content:
                // values?.message +
                invoice?.invoiceData?.channel === "web"
                  ? "Your Invoice URL is here " + initPDFInvoiceDialog.invoiceUrl + id
                  : "Your Invoice URL is here " + initPDFInvoiceDialog.posInvoiceUrl + id,
              invoiceId: id,
            };
            dispatch(
              axios
                .post("api/message", smsMessage)
                .then(
                  dispatch(
                    axios
                      .post("api/email", emailMessage)
                      .then(toast.success("Url send Via Email and SMS"), resetFromState.resetForm(initPDFInvoiceDialog))
                  )
                )
            );
          } else if (values?.sendViaEmail === true) {
            const emailMessage = {
              recipient: values.email,
              subject: "suppot@fatoraty.com",
              content:
                invoice?.invoiceData?.channel === "web"
                  ? `${initPDFInvoiceDialog.invoiceUrl + id}`
                  : `${initPDFInvoiceDialog.posInvoiceUrl + id}`,
              invoiceId: id,
            };
            dispatch(
              axios
                .post("api/email", emailMessage)
                .then(toast.success("Url send Via Email"), resetFromState.resetForm(initPDFInvoiceDialog))
            );
          } else if (values?.sendViaSMS === true) {
            const smsMessage = {
              recipient: "+923364184194",
              sender: "+19042951659",
              content:
                invoice?.invoiceData?.channel === "web"
                  ? values?.smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.invoiceUrl + id
                  : values?.smsMessage + " " + "Your Invoice URL is here " + initPDFInvoiceDialog.posInvoiceUrl + id,
            };
            dispatch(
              axios
                .post("api/message", smsMessage)
                .then(toast.success("Url send Via SMS"), resetFromState.resetForm(initPDFInvoiceDialog))
            );
          }
        }}>
        {({ handleSubmit, values, setValues, setFieldValue, ...rest }) => {
          resetFromState = rest;
          return (
            <>
              <Form className="form form-label-right">
                <Modal
                  className="modal-drawer invoice-drawer"
                  size="lg"
                  show={sendPdfInvoiceDialog}
                  onHide={() => {}}
                  aria-labelledby="example-modal-sizes-title-lg">
                  <Modal.Header>
                    <button
                      type="button"
                      className="btn btn-close"
                      onClick={() => {
                        setSendPdfInvoiceDialog(false);
                        setShowEmail(false);
                        setShowSms(false);
                      }}>
                      <img src={IconClose} alt="loading" />
                    </button>

                    {<Modal.Title>{i18n("INVOICES.ShareInvoice")}</Modal.Title>}
                  </Modal.Header>
                  <Modal.Body className="overlay overlay-block cursor-default">
                    <Card>
                      <div className="row">
                        <div className="col-lg-12">
                          <p>{i18n("INVOICES.InvoiceLanguage")}</p>
                        </div>
                        <div className="col-lg-12">
                          <Field type="radio" name="language" value="englishLanguage" />
                          <label className="ml-4">English</label>
                        </div>
                        <div className="col-lg-12">
                          <Field type="radio" name="language" value="arabicLanguage" />
                          <label className="ml-4">Arabic</label>
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <div
                        className="row"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(`${process.env.REACT_APP_URL}view/invoice/${id}`, "_blank");
                        }}>
                        <div className="col-lg-10 mt-2">
                          <p>{i18n("INVOICES.DownloadInvoiceAsPDF")}</p>
                        </div>
                        <div className="col-lg-2 cursor-pointer">
                          <img src={IconDownload} alt="loading" />
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <div className="row">
                        <div className="col-lg-12">
                          <p>{i18n("INVOICES.CopyOrPreviewInvoiceLink")}</p>
                        </div>
                        <div className="col-lg-9">
                          <small>{`${process.env.REACT_APP_URL}view/invoice/${id}`}</small>
                        </div>
                        <div className="col-lg-1 cursor-pointer">
                          <img
                            onClick={() => {
                              // eslint-disable-next-line no-lone-blocks
                              {
                                invoice?.invoiceData?.channel === "web"
                                  ? copyToClipboard(`${process.env.REACT_APP_URL}view/invoiceHtml/${id}`)
                                  : copyToClipboard(`${process.env.REACT_APP_URL}posInvoice/${id}`);
                              }
                              toast.success("Copied to clipboard");
                            }}
                            src={IconView}
                            alt="loading"
                          />
                        </div>
                        <div
                          className="col-lg-1 cursor-pointer"
                          onClick={() => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              invoice?.invoiceData?.channel === "web"
                                ? window.open(`/view/invoiceHtml/${id}`, "_blank")
                                : window.open(`/posInvoice/${id}`, "_blank");
                            }
                          }}>
                          <img src={IconEye} alt="loading" />
                        </div>
                      </div>
                    </Card>
                    <Card>
                      <div className="row">
                        <div className="col-lg-2">
                          <Field
                            name="sendViaEmail"
                            component={Checkbox}
                            onChange={(e) => {
                              setFieldValue("sendViaEmail", e.target.checked);
                              setShowEmail((p) => !p);
                            }}
                          />
                        </div>

                        <div className="col-lg-10">
                          <label>{i18n("INVOICES.SendViaEmail")}</label>
                        </div>
                        {showEmail && (
                          <>
                            <div className="col-lg-12 mt-4 form-group">
                              <Field
                                type="email"
                                name="email"
                                label={i18n("global.Email")}
                                placeholder={i18n("global.Email")}
                                component={Input}
                              />
                            </div>
                            <div className="col-lg-12 mt-4 form-group">
                              <Field
                                type="text"
                                name="emailMessage"
                                label={i18n("global.Message")}
                                placeholder={i18n("global.Message")}
                                component={Input}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </Card>
                    <Card>
                      <div className="row">
                        <div className="col-lg-2">
                          <Field
                            name="sendViaSMS"
                            component={Checkbox}
                            onChange={(e) => {
                              setFieldValue("sendViaSMS", e.target.checked);
                              setShowSms((p) => !p);
                            }}
                          />
                        </div>
                        <div className="col-lg-10">
                          <label>{i18n("INVOICES.SendViaSMS")}</label>
                        </div>
                        {showSms && (
                          <>
                            <div className="col-lg-10 mt-4 form-group">
                              <Field
                                placeholder="Enter mobile"
                                label="Mobile"
                                component={Mobile}
                                name="mobile"
                                international
                                defaultCountry="SA"
                                style={{ display: "flex" }}
                                value={values.mobile}
                                onChange={(value) => {
                                  setValues({ ...values, mobile: value });
                                }}
                              />
                            </div>
                            <div className="col-lg-12 mt-4 form-group">
                              <Field
                                type="text"
                                name="smsMessage"
                                label={i18n("global.Message")}
                                placeholder={i18n("global.Message")}
                                component={Input}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </Card>
                  </Modal.Body>
                  <Modal.Footer>
                    <div className="row">
                      <button
                        type="button"
                        className="btn btn-secondary btn-elevate"
                        onClick={() => {
                          secondaryAction();
                          setSendPdfInvoiceDialog(false);
                          setShowEmail(false);
                          setShowSms(false);
                        }}>
                        {i18n("INVOICES.Cancel")}
                      </button>
                      <button
                        type="submit"
                        onClick={() => {
                          handleSubmit();
                          secondaryAction();
                          setSendPdfInvoiceDialog(false);
                          setShowEmail(false);
                          setShowSms(false);
                        }}
                        className="btn btn-primary btn-elevate f-button ml-3">
                        {i18n("INVOICES.Send")}
                      </button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}
export default SendPdfInvoiceDialog;
