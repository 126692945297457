import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import toast from "react-hot-toast";
import { i18n } from "../../app/pages/private/languageSelector";

export const SUBSCRIPTION_URL = "api/subscription";

export const startFreeTrial = createAsyncThunk(
  "subscription/freeTrial",
  async (_, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/startTrial`);
      toast.success(i18n("SubscriptionToast.TrialActivatedSuccessfully"));
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async ({ updatedData }, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/updateSubscription`, updatedData);
      toast.success("Successfully subscribed");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const getSubscription = createAsyncThunk(
  "subscription/getSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${SUBSCRIPTION_URL}/getSubscription`);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const unSubscribeTrial = createAsyncThunk(
  "subscription/unsubscribeTrial",
  async (_, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/unsubscribeTrial`);
      toast.success("Successfully unsubscribed");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const changePlan = createAsyncThunk(
  "subscription/changePlan",
  async ({ updatedData }, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/changePlan`, updatedData);
      toast.success("Plan Updated Successfully");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const unSubscribeSubscription = createAsyncThunk(
  "subscription/unSubscribeSubscription",
  async (_, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/unsubscribeSubscription`);
      toast.success("Successfully Unsubscribed");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const subscriptionAddUser = createAsyncThunk(
  "subscription/subscriptionAddUser",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${SUBSCRIPTION_URL}/addNewUser`);
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const pausedSubscription = createAsyncThunk(
  "subscription/pausedSubscription",
  async (_, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/pauseSubscription`);
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const getSubscriptionUsers = createAsyncThunk(
  "subscription/getSubscriptionUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${SUBSCRIPTION_URL}/getAllSubscriptionUsers`
      );
      return response?.data?.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const expiredSubscriptionWithTwoMinutes = createAsyncThunk(
  "subscription/expiredSubscriptionWithTwoMinutes",
  async (_, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/expireSubscriptionInTwoMinutes`);
      toast.success("Plan Updated Successfully");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const failEveryPayment = createAsyncThunk(
  "subscription/failEveryPayment",
  async (_, { rejectWithValue }) => {
    try {
      await axios.patch(`${SUBSCRIPTION_URL}/failEveryPayment`);
      toast.success("Updated Successfully");
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const expiredTrialModalShowToggler = createAsyncThunk(
  "subscription/expiredTrialModalShowToggler",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${SUBSCRIPTION_URL}/ExpiredTrialModalShowToggler`
      );
      return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
