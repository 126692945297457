import * as requestFromServer from "./discountCrud";
import { discountsSlice, callTypes } from "./discountSlice";
import toast from "react-hot-toast";

const { actions } = discountsSlice;

export const fetchDiscounts = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllDiscounts()
    .then((response) => {
      const { discounts } = response.data;
      dispatch(
        actions.discountsFetched({
          entities: discounts,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find discounts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createDiscount = (discountForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDiscount(discountForCreation)
    .then((response) => {
      const { discount } = response.data;
      dispatch(fetchDiscounts());
      dispatch(actions.discountCreated({ discount }));
      toast.success("Discount Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create discount";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDiscount = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.discountFetched({ discountForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDiscountById(id)
    .then((response) => {
      const { discount } = response.data;
      dispatch(actions.discountFetched({ discountForEdit: discount }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find discount";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteDiscount = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deleteDiscount(id).then((response) => {
    toast.success("discount deleted");
    dispatch(actions.discountDeleted({ id }));
    dispatch(fetchDiscounts());
  });
  // .catch((error) => {
  //   // toast.error(error.response.data.message);
  //   error.clientMessage = "Can't delete discount";
  //   dispatch(actions.catchError({ error, callType: callTypes.action }));
  // });
};

export const updateDiscount = (discount, queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDiscount(discount)
    .then(() => {
      dispatch(actions.discountUpdated({ discount }));
      dispatch(fetchDiscounts(queryParams));
      toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update discount";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
