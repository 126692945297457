import React, { createContext, useContext, useState, useCallback } from "react";
import isEqual from "lodash/isEqual";
import isFunction from "lodash/isFunction";
import { initialFilter } from "./InvoicesUIHelpers";

const InvoicesUIContext = createContext();

export function useInvoicesUIContext() {
  return useContext(InvoicesUIContext);
}

export const InvoicesUIConsumer = InvoicesUIContext.Consumer;

export function InvoicesUIProvider({ invoicesUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initInvoice = {
    id: undefined,
    englishName: "",
    arabicName: "",
    price: 0.0,
    catagory: "",
    serviceIsActive: "",
    serviceIsTaxable: "",
    englishDetails: "",
    arabicDetails: "",
    detailedInvoice: "",
    paymentDate: "",
    barcode: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initInvoice,
    newInvoiceButtonClick: invoicesUIEvents.newInvoiceButtonClick,
    openEditInvoiceDialog: invoicesUIEvents.openEditInvoiceDialog,
    openViewInvoicesDialog: invoicesUIEvents.openViewInvoicesDialog,
    openDeleteInvoiceDialog: invoicesUIEvents.openDeleteInvoiceDialog,
    openDeleteInvoicesDialog: invoicesUIEvents.openDeleteInvoicesDialog,
    openFetchInvoicesDialog: invoicesUIEvents.openFetchInvoicesDialog,
    openUpdateInvoicesStatusDialog: invoicesUIEvents.openUpdateInvoicesStatusDialog,
    openCustomerEditDialog: invoicesUIEvents.openCustomerEditDialog,
    openPrintInvoicePage: invoicesUIEvents.openPrintInvoicePage,
    openViewInvoiceActionDialog: invoicesUIEvents.openViewInvoiceActionDialog,
  };

  return <InvoicesUIContext.Provider value={value}>{children}</InvoicesUIContext.Provider>;
}
