// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import {
  sortCaret,
  getSelectRow,
  PleaseWaitMessage,
  headerSortingClasses,
  getHandlerTableChange,
  NoRecordsFoundMessage,
} from "../../../../_metronic/_helpers";
import * as uiHelpers from "../InvoicesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { Pagination } from "../../../../_metronic/_partials/controls";

export function InvoicesTable({ tableFields }) {
  const dispatch = useDispatch();

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
      setQueryParams: invoicesUIContext.setQueryParams,
      openEditInvoiceDialog: invoicesUIContext.openEditInvoiceDialog,
      openDeleteInvoiceDialog: invoicesUIContext.openDeleteInvoiceDialog,
      openViewInvoicesDialog: invoicesUIContext.openViewInvoicesDialog,
      openPrintInvoicePage: invoicesUIContext.openPrintInvoicePage,
      openViewInvoiceActionDialog:
        invoicesUIContext.openViewInvoiceActionDialog,
    };
  }, [invoicesUIContext]);

  // Getting curret state of invoices list from store (Redux)
  const { currentState, invoiceFilterState } = useSelector(
    (state) => ({
      currentState: state.invoices,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );
  
  const { totalCount, entities, listLoading } = currentState;

  useEffect(() => {
    invoicesUIProps.setIds([]);
    dispatch(
      actions.fetchInvoices(invoiceFilterState, invoicesUIProps.queryParams)
    );
    // eslint-disable-next-line
  }, [invoicesUIProps.queryParams, dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "invoice",
      text: "INVOICE#",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "date",
      text: "DATE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "totalSales",
      text: "TOTAL SALES",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => <>{value ? value.toFixed(2) : "0.00"}</>,
    },
    {
      dataField: "customerName",
      text: "CUSTOMER NAME",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => (
        <>
          <div>
            {cell && cell !== "" && cell.length > 20
              ? cell.substring(0, 15) + "..."
              : cell}
          </div>
        </>
      ),
    },
    {
      dataField: "issuedBy",
      text: "ISSUED BY",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "pos",
      text: "POS",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "store",
      text: "STORE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "STATUS",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => (
        <>
          {cell === "paid" ? (
            <div style={{ color: "green" }}>Paid</div>
          ) : cell === "due" ? (
            <div style={{ color: "yellowgreen" }}>Due</div>
          ) : (
            <div style={{ color: "red" }}>Void</div>
          )}
        </>
      ),
    },
    {
      dataField: "invoiceType",
      text: "TYPE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => (
        <>{cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : cell === ""}</>
      ),
    },
    {
      dataField: "channel",
      text: "CHANNEL",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => (
        <>{cell === "web" ? "WEB" : cell === "pos" ? "POS" : ""}</>
      ),
    },
    {
      dataField: "paid",
      text: "PAYMENT",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => (
        <>
          {parseFloat(value)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
        </>
      ),
    },
    {
      dataField: "balance",
      text: "BALANCE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value, row) => (
        <>
          {parseFloat(value)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}{" "}
        </>
      ),
    },

    {
      dataField: "action",
      text: "ACTIONS",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditInvoiceDialog: invoicesUIProps.openEditInvoiceDialog,
        openDeleteInvoiceDialog: invoicesUIProps.openDeleteInvoiceDialog,
        openViewInvoicesDialog: invoicesUIProps.openViewInvoicesDialog,
        openPrintInvoicePage: invoicesUIProps.openPrintInvoicePage,
        openViewInvoiceActionDialog:
          invoicesUIProps.openViewInvoiceActionDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3 text-width",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: invoicesUIProps.queryParams.pageSize,
    page: invoicesUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns.filter((item) => {
                  if (item.text !== "INVOICE#" && item.text !== "ACTIONS") {
                    let el = tableFields?.find(
                      (subItem) =>
                        subItem.name.toLowerCase() === item.text.toLowerCase()
                    );
                    if (el?.active) {
                      return item;
                    } else {
                      return null;
                    }
                  } else {
                    return item;
                  }
                })}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  invoicesUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: invoicesUIProps.ids,
                  setIds: invoicesUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
