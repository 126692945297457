import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../private/languageSelector";

export function CustomerEditDialogHeader({ id, view, onHide, setShowCustomerAddDialoag }) {
  // Customers Redux state
  const { customerForEdit, actionsLoading } = useSelector(
    (state) => ({
      customerForEdit: state.customers.customerForEdit,
      actionsLoading: state.customers.actionsLoading,
    }),
    shallowEqual
  );
  const [title, setTitle] = useState("");

  useEffect(() => {
    let _title = id ? "" : i18n("Customer.NewCustomer");
    if (customerForEdit && id) {
      _title = `${view ? i18n("Customer.ViewCustomer") : i18n("Customer.EditCustomer")}`;
    }

    setTitle(_title);
  }, [customerForEdit, actionsLoading, id, view]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <button
          type="button"
          onClick={() => {
            setShowCustomerAddDialoag(false);
            onHide();
          }}
          className="btn btn-close">
          <img src={IconClose} alt="loading" />
        </button>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
