import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../../assets/img/icon-close.svg";
import registerSuccessIcon from "../../../../../assets/img/register-success.svg";

export const PaymentCompletedDialoge = ({ showPaymentCompletedDialoge, setShowPaymentCompletedDialoge }) => {
  return (
    <Modal
      className="modal-drawer"
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={showPaymentCompletedDialoge}
      onHide={() => {}}>
      <Modal.Header>
        <button type="button" className="btn btn-close" onClick={() => setShowPaymentCompletedDialoge(false)}>
          <img src={IconClose} alt="loading" />
        </button>
        <Modal.Title>Subscription</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        <>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <p>Payment completed successfully</p>
              <div className="mt-10">
                <img src={registerSuccessIcon} alt="loading" />
              </div>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div className="row justify-content-center">
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              setShowPaymentCompletedDialoge(false);
            }}>
            Close
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
