import React, { useState } from "react";
import IconClose from "../../../../assets/img/icon-close.svg";
import IconDelete from "../../../../assets/img/invoice-actions/delete.svg";
import toast from "react-hot-toast";
import * as actions from "../../../../_redux/customers/customersActions";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { i18n } from "../../private/languageSelector";
import { CustomersDeleteConfirmation } from "./CustomerDeleteConfirmation";

export const CustomersDeleteModal = ({
  openCustomersDeleteDialog,
  setOpenCustomersDeleteDialog,
  selectedCustomers,
  setSelectedCustomers,
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const dispatch = useDispatch();
  const deleteCustomers = () => {
    if (selectedCustomers?.length) {
      dispatch(actions.deleteCustomers(selectedCustomers)).then(() => {
        toast.success("Customers Deleted Successfully");
        setOpenCustomersDeleteDialog(false);
        setSelectedCustomers([]);
      });
    } else {
      toast.error("No Customer Selected");
    }
  };

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openCustomersDeleteDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              setOpenCustomersDeleteDialog(false);
            }}>
            <img src={IconClose} alt="loading" />
          </button>

          <Modal.Title>{i18n("MENU.GroupActions")}</Modal.Title>
        </Modal.Header>

        <>
          <Modal.Body className="overlay overlay-block cursor-default">
            <div className="mt-5 cursor-pointer" onClick={() => setOpenDeleteDialog(true)}>
              <img src={IconDelete} alt="loading" />
              <span className="ml-5">{i18n("Customer.Delete")}</span>
            </div>
          </Modal.Body>
        </>
      </Modal>
      <CustomersDeleteConfirmation
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        deleteCustomers={deleteCustomers}
      />
    </>
  );
};
