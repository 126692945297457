import React, { useState, useEffect, useLayoutEffect } from "react";
import "./PdfInvoicePageHtml.css";
import moment from "moment";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IconFatoraty from "../../../../assets/img/icon-fatoraty.svg";
import IconFatoratyFooter from "../../../../assets/img/fatoraty-footer.svg";
import IconPdf from "../../../../assets/img/icon-pdf.svg";
import IconPrint from "../../../../assets/img/icon-print.svg";
import IconPremier from "../../../../assets/img/Premier-Logo.svg";
import IconQr from "../../../../assets/img/icon-qr.svg";
import { PosInvoicePreview } from "./PosInvoicePreview";

const PdfInvoicePageHtml = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [invoiceData, setInvoiceData] = useState();
  const [companyData, setCompanyData] = useState();
  const [invoiceSummary, setInvoiceSummary] = useState({
    total: 0,
    subtotal: 0,
    discount: 0,
    taxAmount: 0,
    allTaxAmount: 0,
    taxableAmount: 0,
  });
  const { invoice } = useSelector(
    (state) => ({
      invoice: state?.invoices?.invoiceForEdit,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (id) {
      dispatch(actions.fetchInvoice(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (invoice) {
      let customerName =
        invoice?.invoiceData?.channel === "web"
          ? invoice?.invoiceData?.customerName
          : invoice?.invoiceData?.customerName?.first
          ? invoice?.invoiceData?.customerName?.first +
            " " +
            invoice?.invoiceData?.customerName?.last
          : "";

      let receieverName =
        invoice?.invoiceData?.channel === "web"
          ? invoice?.invoiceData?.receiverInformation?.name
          : invoice?.invoiceData?.receiverInformation?.name?.first
          ? invoice?.invoiceData?.receiverInformation?.name?.first +
            " " +
            invoice?.invoiceData?.receiverInformation?.name?.last
          : "";
      setInvoiceData({
        ...invoice,
        invoiceData: {
          ...invoice?.invoiceData,
          customerName: customerName,
          receiverInformation: {
            ...invoice?.invoiceData.receiverInformation,
            name: receieverName,
          },
        },
      });

      setCompanyData({ ...invoice?.company });
      setProducts([...invoice.invoiceData.products]);
    }
    //eslint-disable-next-line
  }, [dispatch, invoice]);

  // Invoice Summary
  useEffect(() => {
    let subtotalP = 0;
    let totalD = 0;
    let taxAmount = 0;
    let allTaxAmount = 0;

    if (products && products.length) {
      ///// Subtotal ////
      subtotalP = products?.reduce((a, b) => {
        return (subtotalP = a + +b.price * +b?.quantity);
      }, 0);

      ///// Discount ////
      totalD = products.reduce((a, b) => {
        if (b.discount) {
          return (totalD = a + +b.discount);
        } else {
          return totalD;
        }
      }, 0);

      // Tax
      taxAmount = products?.reduce((a, b) => {
        if (b?.addedTax) {
          return (taxAmount = a + b?.addedTax);
        } else {
          return taxAmount;
        }
      }, 0);
    }
    allTaxAmount = products?.reduce((a, b) => {
      if (b?.addedTax) {
        return (allTaxAmount = a + +b?.tax);
      } else {
        return allTaxAmount;
      }
    }, 0);
    setInvoiceSummary({
      subtotal: subtotalP,
      discount: totalD,
      taxableAmount: subtotalP - totalD,
      taxAmount: taxAmount,
      allTaxAmount: allTaxAmount,
      total: subtotalP - totalD + taxAmount,
    });
  }, [invoiceData, products, companyData]);

  return invoiceData?.invoiceData ? (
    invoice?.invoiceData?.channel === "web" ? (
      <div className="container">
        <div className="html-invoice">
          {/* //////header section////// */}
          <div className="d-flex justify-content-between header">
            <div className="header-img">
              <img src={IconFatoraty} alt="loading" />
            </div>
            <div className="d-flex">
              <div className="header-img mt-5">
                <div>
                  <img
                    src={IconPdf}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        `${process.env.REACT_APP_URL}view/invoice/${invoiceData?._id}`,
                        "_blank"
                      );
                    }}
                    alt="loading"
                  />
                </div>
              </div>
              <div className="header-img mr-3 mt-5">
                <img
                  src={IconPrint}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `${process.env.REACT_APP_URL}view/invoice/${invoiceData?._id}`,
                      "_blank"
                    );
                  }}
                  alt="loading"
                />
              </div>
            </div>
          </div>
          {/* //////company section////// */}
          <div className=" header-row">
            <div className="row">
              <div className="col-lg-5 header-img mt-2">
                {invoice?.invoiceData?.senderInformation?.companyId?.image ? (
                  <>
                    <img
                      style={{ maxWidth: "400px" }}
                      src={
                        invoice?.invoiceData?.senderInformation?.companyId
                          ?.image
                      }
                      alt="loading"
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              {companyData?.taxNumber ? (
                <>
                  <div
                    style={{ position: "realtive", left: "23px" }}
                    className="col-lg-2 icon-logo"
                  >
                    <img src={IconQr} alt="loading" />
                  </div>
                </>
              ) : (
                ""
              )}

              <div className="col-lg-3 company-detail">
                <b>
                  {companyData?.companyName ? companyData?.companyName : ""}
                </b>
                <p className="mb-0">
                  {companyData?.taxNumber
                    ? `TAX Number${" "}${companyData?.taxNumber}`
                    : ""}
                </p>
                <p className="mb-0">
                  {companyData?.crNumber ? "C.R. " + companyData?.crNumber : ""}
                </p>
                <p className="mb-0">
                  {companyData?.mobile ? companyData?.mobile : ""}
                </p>
                <p className="mb-0">
                  {companyData?.email ? companyData?.email : ""}
                </p>
                <p className="mb-0">
                  {companyData?.city ? companyData?.city : ""}
                </p>
              </div>
            </div>
            {/* //////customer and invoice section////// */}
            <div className="row info-row">
              <div className="col-lg-4">
                <div className="customer-information">
                  <div className="customer-name">
                    <b>Customer Information</b>
                  </div>
                  <div className="customer-detail">
                    <div>
                      <div className="title">Name:</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.customerName
                          ? invoiceData?.invoiceData?.customerName
                          : invoiceData?.invoiceData?.receiverInformation?.name
                          ? invoiceData?.invoiceData?.receiverInformation?.name
                          : ""}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Email:</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.customerEmail
                          ? invoiceData?.invoiceData?.customerEmail
                          : invoiceData?.invoiceData?.receiverInformation?.email
                          ? invoiceData?.invoiceData?.receiverInformation?.email
                          : ""}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Mobile:</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.receiverInformation?.mobile
                          ? invoiceData?.invoiceData?.receiverInformation
                              ?.mobile
                          : ""}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Tax No.:</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.receiverInformation
                          ?.taxNumber
                          ? invoiceData?.invoiceData?.receiverInformation
                              ?.taxNumber
                          : ""}
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>

              <div
                className={`col-lg-3 align-items-center ${
                  invoiceData?.invoiceData?.invoiceType === "refund"
                    ? "refund-invoice"
                    : "tax-invoice"
                } `}
              >
                <b>
                  {invoiceData?.invoiceData?.invoiceType === "refund" &&
                  companyData?.taxNumber
                    ? "Refund Tax Invoice"
                    : companyData?.taxNumber
                    ? "Tax Invoice"
                    : invoiceData?.invoiceData?.invoiceType === "refund"
                    ? "Refund Invoice"
                    : "Invoice"}
                </b>
              </div>
              <div className="col-5 invoice-info">
                <div className="invoice-information">
                  <div className="invoice-name">
                    <b>Invoice Information</b>
                  </div>
                  <div className="invoice-detail">
                    <div>
                      <div className="title">Number:</div>
                      <div className="detail">
                        {invoiceData?.invoiceNumber
                          ? invoiceData?.invoiceNumber
                          : ""}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Date:</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.invoiceDate
                          ? moment(
                              invoiceData?.invoiceData?.invoiceDate
                            ).format("MM/DD/YYYY")
                          : ""}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Status:</div>
                      <div
                        className="detail"
                        style={{
                          color:
                            invoiceData?.status === "paid" ? "#3CAEA3" : "",
                        }}
                      >
                        {invoiceData?.status
                          ? invoiceData?.status.charAt(0).toUpperCase() +
                            invoiceData?.status.slice(1)
                          : ""}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Cashier:</div>
                      <div className="detail">
                        {invoiceData?.invoiceData?.senderInformation?.name
                          ?.first
                          ? invoiceData?.invoiceData?.senderInformation?.name
                              ?.first +
                            " " +
                            invoiceData?.invoiceData?.senderInformation?.name
                              ?.last
                          : ""}
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            {/* //////invoice products section////// */}

            <table className="table">
              <thead>
                <tr>
                  <th>Item No.</th>
                  <th>Item Name</th>
                  <th style={{ width: "32rem" }}>Item Description</th>
                  <th>Unit Price</th>
                  <th>Qty</th>
                  <th>Discount Amount</th>
                  <th>Taxable Amount</th>
                  <th>Tax Rates</th>
                  <th>Tax Amount</th>
                  <th>Item Subtotal Including VAT</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.invoiceData?.products?.map(
                  (item, index) => (
                    console.log(item, "item"),
                    (
                      <>
                        <tr key={index}>
                          <td>{index + 1}.</td>
                          <td>{item.name ? item.name : ""}</td>
                          <td>{item.description ? item.description : ""}</td>
                          <td>
                            {item?.price
                              ? Number(item?.price).toFixed(2)
                              : item?.price}
                          </td>
                          <td>{item?.quantity}</td>
                          <td>
                            {item?.discount
                              ? Number(item?.discount).toFixed(2)
                              : 0.0}
                          </td>
                          <td>
                            {item?.price && item?.quantity
                              ? (
                                  +item?.price * +item?.quantity -
                                  Number(item?.discount)
                                ).toFixed(2)
                              : item?.price
                              ? Number(item?.price).toFixed(2)
                              : item?.price}
                          </td>
                          <td>
                            {item?.taxRate ? Number(item?.taxRate) : 0.0} %
                          </td>
                          <td>
                            {item?.tax ? Number(item?.tax).toFixed(2) : 0.0}
                          </td>
                          <td>
                            {item?.addedTax && item?.discount
                              ? (
                                  item?.addedTax +
                                  +item?.price?.toLocaleString() *
                                    item?.quantity -
                                  Number(item?.discount)
                                )?.toFixed(2)
                              : item?.discount
                              ? (
                                  item?.price?.toLocaleString() *
                                    item?.quantity -
                                  Number(item?.discount)
                                )?.toFixed(2)
                              : item?.addedTax
                              ? (
                                  item?.addedTax +
                                  +item?.price?.toLocaleString() *
                                    item?.quantity
                                )?.toFixed(2)
                              : 0.0}
                          </td>
                        </tr>
                        {/* showing refunded items number */}
                        {item && item.refundedQuantity ? (
                          <>
                            <tr>
                              <td colSpan="1"></td>
                              <td colSpan="9" style={{ color: "red" }}>
                                {item?.refundedQuantity} X Refunded On{" "}
                                {invoiceData?.invoiceData.invoiceDate
                                  ? moment(
                                      invoiceData?.invoiceData.invoiceDate
                                    ).format("dddd MMM DD, YYYY")
                                  : ""}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )
                  )
                )}
              </tbody>
            </table>
            {/* //////payments section////// */}

            <div className="row info-row">
              <div className="col-lg-4">
                <div className="payment-information">
                  <div className="payment-name">
                    <b>Payment Details</b>
                  </div>
                  <div className="payment-detail">
                    <div className="payment-border">
                      <div>
                        <div className="title due">Paid</div>
                        <div className="detail due">
                          {companyData?.currency}{" "}
                          {invoiceData?.invoiceData?.paidTotal
                            ? Number(
                                invoiceData?.invoiceData?.paidTotal
                              ).toFixed(2)
                            : 0}
                        </div>
                        <div className="payments">
                          {invoiceData?.invoiceData?.payments?.map(
                            (payment, index) => (
                              <React.Fragment key={index}>
                                <span className="date">
                                  {moment(payment?.date).format("MM/DD/YYYY")}
                                </span>
                                <span className="method">
                                  {payment?.method === "visa/master_card"
                                    ? "Master/Visa Card"
                                    : payment.method === "mada_card"
                                    ? "Mada Card"
                                    : payment.method === "bank_transfer"
                                    ? "Bank "
                                    : payment.method === "cash"
                                    ? "Cash"
                                    : ""}
                                </span>
                                <span className="amount">
                                  {" "}
                                  {companyData?.currency}{" "}
                                  {payment?.amount
                                    ? Number(payment?.amount).toFixed(2)
                                    : payment?.amount}
                                </span>
                                <br />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <br />

                    <div className="payment-border">
                      <div>
                        <div>
                          <div className="title due">Balance Due</div>
                          <div className="detail due">
                            {companyData?.currency}{" "}
                            {invoiceData?.invoiceData?.balance
                              ? Number(
                                  invoiceData?.invoiceData?.balance
                                )?.toFixed(2)
                              : 0}
                          </div>
                        </div>
                        <br />
                        <div>
                          <div className="title">Due Date</div>
                          <div className="detail">
                            {invoiceData?.invoiceData?.dueDate &&
                            invoiceData?.invoiceData?.dueDate !== "Invalid date"
                              ? moment(
                                  invoiceData?.invoiceData?.dueDate
                                ).format("MM/DD/YYYY")
                              : ""}
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              {/* // Total Section */}
              <div
                className="col-lg-5 offset-lg-3"
                style={{ paddingLeft: "5.5rem", paddingRight: "0rem" }}
              >
                <div className="col-11 total-information">
                  <div className="total-name">
                    <b>Total</b>
                  </div>
                  <div className="total-detail">
                    <div>
                      <div className="title">Subtotal (Excluding Tax)</div>
                      <div className="detail">
                        {companyData?.currency}{" "}
                        {invoiceSummary?.subtotal
                          ? Number(invoiceSummary?.subtotal).toFixed(2)
                          : invoiceSummary?.subtotal}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Discount</div>
                      <div className="detail">
                        {companyData?.currency}{" "}
                        {invoiceSummary?.discount
                          ? Number(invoiceSummary?.discount).toFixed(2)
                          : invoiceSummary?.discount}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Taxable Amount</div>
                      <div className="detail">
                        {companyData?.currency}{" "}
                        {invoiceSummary?.taxableAmount
                          ? Number(invoiceSummary?.taxableAmount).toFixed(2)
                          : invoiceSummary?.taxableAmount}
                      </div>
                    </div>
                    <br />
                    <div>
                      <div className="title">Tax Amount</div>
                      <div className="detail">
                        {companyData?.currency}{" "}
                        {invoiceSummary?.allTaxAmount
                          ? Number(invoiceSummary?.allTaxAmount).toFixed(2)
                          : invoiceSummary?.allTaxAmount}
                      </div>
                    </div>
                    <div>
                      <div className="title">
                        <b>Total (Including Tax)</b>
                      </div>
                      <div className="detail">
                        <b>
                          {companyData?.currency}{" "}
                          {invoiceSummary?.total
                            ? Number(invoiceSummary?.total).toFixed(2)
                            : invoiceSummary?.total}
                        </b>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            {/* //////terms and policy section////// */}
            <hr />
            <div className="row terms-info-row">
              <div className="col-lg-4">
                <div className="terms-information">
                  <div className="header-name">
                    <b>Replacement Policy:</b>
                  </div>
                  <div className="terms-border">
                    <div className="terms-detail">
                      <div>
                        <p>
                          To view the replacement policy, please visit the
                          following link <br />
                          <a
                            href="https://www.premier.com/ReplacementPolicy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            www.premier.com/ReplacementPolicy
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="terms-information">
                  <div className="header-name">
                    <b>Note</b>
                  </div>
                  <div className="terms-border">
                    <div className="terms-detail">
                      <div>
                        <p>
                          {invoiceData?.invoiceData?.generalNotes
                            ? invoiceData?.invoiceData?.generalNotes
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="terms-information">
                  <div className="terms-name">
                    <b>Terms And Conditions:</b>
                  </div>
                  <div className="terms-border2">
                    <div className="terms-detail">
                      <p>
                        To view the Terms and Conditions, please visit the
                        following link
                        <br />
                        <a
                          href="https://www.premier.com/TermsAndConditions"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.premier.com/TermsAndConditions
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* //////footer section////// */}

            <div className="row footer">
              <div className="col-lg-4 invoice-number">
                {invoiceData?.invoiceData?.invoiceType === "refund" ? (
                  <>
                    Refund Invoice No. {invoiceData?.invoiceNumber}, Page 1/1
                    <br />
                    Reference Invoice No. {invoiceData?.parentInvoiceNumber},
                  </>
                ) : (
                  <> Invoice No. {invoiceData?.invoiceNumber}, Page 1/1</>
                )}
              </div>
              <div className="col-lg-4 company-icon">
                <img width={"270px"} src={IconFatoratyFooter} alt="loading" />
              </div>
              <div className="col-lg-3 date-time">
                <span>Printed On {moment().format("ddd, DD MMM YYYY")}</span>
                <br></br>
                <p className="time">{moment().format("HH:mm:ss")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <PosInvoicePreview
        invoice={invoice}
        invoiceData={invoiceData}
        companyData={companyData}
      />
    )
  ) : null;
};
export default PdfInvoicePageHtml;
