import * as requestFromServer from "./taxesCrud";
import { taxesSlice, callTypes } from "./taxesSlice";
import toast from "react-hot-toast";

const { actions } = taxesSlice;

export const fetchTaxes = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllTaxes()
    .then((response) => {
      const { taxes } = response.data;
      dispatch(
        actions.taxesFetched({
          entities: taxes,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find taxes";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createTax = (taxForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTax(taxForCreation)
    .then((response) => {
      dispatch(fetchTaxes());
      toast.success("Tax Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create Tax";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchTax = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.taxFetched({ taxForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTaxById(id)
    .then((response) => {
      const { tax } = response.data;
      dispatch(actions.taxFetched({ taxForEdit: tax }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find Tax";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTax = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deleteTax(id).then((response) => {
    toast.success("Tax deleted");
    dispatch(fetchTaxes());
  });
};

export const updateTax = (id, tax) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTax(id, tax)
    .then(() => {
      dispatch(fetchTaxes());
      toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update Tax";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
