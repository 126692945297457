import React, { useState, useEffect } from "react";
import IconClose from "../../../assets/img/icon-close.svg";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import IconDelete from "../../../assets/img/invoice-actions/delete.svg";
import IconEye from "../../../assets/img/invoice-actions/eye.svg";
import IconEdit from "../../../assets/img/carbon_edit.svg";
import { i18n } from "../private/languageSelector";
import { InvoiceStore } from "../invoices/invoice-create-dialog/InvoiceStore";

const CustomerActionsModal = ({
  open,
  openEditCustomerDialog,
  openViewCustomerDialog,
  openDeleteCustomerDialog,
  setShowCustomerActionsDialoag,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openStoreDialog, setOpenStoreDialog] = useState(false);

  const { customerForEdit, userAccess } = useSelector(
    (state) => ({
      customerForEdit: state?.customers?.customerForEdit,
      userAccess: state?.users?.userAccess,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(actions.fetchCustomer(id));
  }, [dispatch, id]);

  const editCustomerHandler = () => {
    openEditCustomerDialog(id);
    setShowCustomerActionsDialoag(false);
  };
  const deleteCustomerHandler = () => {
    openDeleteCustomerDialog(id);
    setShowCustomerActionsDialoag(false);
  };

  return (
    <>
      <Modal
        size="lg"
        show={open}
        onHide={() => {}}
        className="modal-drawer"
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setShowCustomerActionsDialoag(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("Customer.CustomerActions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="row mt-3">
            <div className="col-lg-2 customer-table-actions-icon">
              <img
                src={IconEye}
                alt="loading"
                onClick={() => {
                  openViewCustomerDialog(id);
                  setShowCustomerActionsDialoag(false);
                }}
              />
            </div>
            <div
              className="col-lg-4  customer-table-actions"
              onClick={() => {
                openViewCustomerDialog(id);
                setShowCustomerActionsDialoag(false);
              }}>
              <b>{i18n("Customer.View")}</b>
            </div>
          </div>
          <div className="row mt-3">
            {userAccess?.editData ? (
              <>
                <div className="col-lg-2 customer-table-actions-icon">
                  <img src={IconEdit} alt="loading" onClick={() => editCustomerHandler()} />
                </div>
                <div className="col-lg-4  customer-table-actions" onClick={() => editCustomerHandler()}>
                  <b>{i18n("Customer.Edit")}</b>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="row mt-3">
            {userAccess?.deleteData ? (
              <>
                <div className="col-lg-2 customer-table-actions-icon pr-4">
                  <img src={IconDelete} alt="loading" onClick={() => deleteCustomerHandler()} />
                </div>
                <div className="col-lg-4  customer-table-actions" onClick={() => deleteCustomerHandler()}>
                  <b>{i18n("Customer.Delete")}</b>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {userAccess?.invoices?.selected && userAccess?.createData ? (
            <div className="row mt-3" onClick={() => setOpenStoreDialog(true)}>
              <div className="col-lg-2 customer-table-actions-icon pr-5">
                <span className="fa fa-plus" />
              </div>
              <div className="col-lg-8 customer-table-actions">
                <b>{i18n("Customer.CreateInvoice")}</b>
              </div>
            </div>
          ) : (
            ""
          )}
        </Modal.Body>
      </Modal>
      <InvoiceStore
        openStoreDialog={openStoreDialog}
        setOpenStoreDialog={setOpenStoreDialog}
        customerFromAction={customerForEdit}
      />
    </>
  );
};
export default CustomerActionsModal;
