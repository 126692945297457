import * as requestFromServer from "./rolesCrud";
import { rolesSlice, callTypes } from "./rolesSlice";
import toast from "react-hot-toast";

const { actions } = rolesSlice;

export const fetchRoles = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllRoles()
    .then((response) => {
      const { roles } = response.data;
      dispatch(
        actions.rolesFetched({
          entities: roles,
        })
      );
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find role";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createRole = (roleForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRole(roleForCreation)
    .then((response) => {
      dispatch(fetchRoles());
      toast.success("Role Created");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't create Role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRole = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.roleFetched({ roleForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRoleById(id)
    .then((response) => {
      const { Role } = response.data;
      dispatch(actions.roleFetched({ roleForEdit: Role }));
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't find Role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRole = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.deleteRole(id).then((response) => {
    toast.success("Role deleted");
    dispatch(fetchRoles());
  });
};

export const updateRole = (id, Role) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRole(id, Role)
    .then(() => {
      dispatch(fetchRoles());
      toast.success("Updated");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      error.clientMessage = "Can't update Role";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
