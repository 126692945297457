import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CardIcon from "../../../../assets/img/company/card.svg";
import { i18n } from "../../private/languageSelector";

const SubscriptionDialog = ({
  show,
  onHide,
  modalWidth,
  modalHeight,
  title,
  titleWidth,
  titleHeight,
  titleColor,
  titleFontWeight,
  titleFontSize,
  titleLineHeight,
  message,
  messageWidth,
  messageHeight,
  messageColor,
  messageFontWeight,
  messageFontSize,
  messageLineHeight,
  primaryAction,
  primaryLabel,
  primaryLabelWidth,
  primaryLabelHeight,
  primaryLabelFontWeight,
  primaryLabelFontSize,
  primaryLabelLineHeight,
  primaryLabelColor,
  secondaryAction,
  secondaryLabel,
  secondaryLabelWidth,
  secondaryLabelHeight,
  secondaryLabelFontWeight,
  secondaryLabelFontSize,
  secondaryLabelLineHeight,
  secondaryLabelColor,
  payementCardBrand,
  paymentCardLastFourDigit,
  isTrialExpired,
}) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Modal
      isOpen={show}
      toggle={onHide}
      className="plan-dialog modal-main"
      centered
      style={{ width: modalWidth, height: modalHeight }}
      backdrop={isTrialExpired && "static"}
    >
      <ModalHeader
        className="border-0 container-fluid d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "white",
          borderRadius: "14px",
        }}
      >
        <span
          style={{
            width: titleWidth,
            height: titleHeight,
            color: titleColor,
            fontWeight: titleFontWeight,
            fontSize: titleFontSize,
            lineHeight: titleLineHeight,
          }}
        >
          {title}
        </span>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center align-items-center flex-column p-6">
        <span
          className="text-center"
          style={{
            width: messageWidth,
            height: messageHeight,
            color: messageColor,
            fontWeight: messageFontWeight,
            fontSize: messageFontSize,
            lineHeight: messageLineHeight,
          }}
        >
          {message}
        </span>

        {payementCardBrand && paymentCardLastFourDigit && (
          <p className="mt-6 d-flex">
            <img src={CardIcon} alt="card" />
            <span className="ml-4">
              {i18n("SubscriptionModals.YourPaymentCardIs")} {payementCardBrand}{" "}
              **** {paymentCardLastFourDigit}
            </span>
          </p>
        )}
      </ModalBody>
      <ModalFooter
        style={{
          direction: user && user?.language === "arabic" ? "rtl" : "ltr",
        }}
        className={`cursor-pointer border-0 d-flex mt-2 ${
          primaryLabel && secondaryLabel
            ? "justify-content-between"
            : "justify-content-center"
        }  align-items-center p-1 mx-14 mb-2`}
      >
        <p
          style={{
            width: primaryLabelWidth,
            height: primaryLabelHeight,
            color: primaryLabelColor,
            fontWeight: primaryLabelFontWeight,
            fontSize: primaryLabelFontSize,
            lineHeight: primaryLabelLineHeight,
          }}
          onClick={primaryAction}
        >
          {primaryLabel}
        </p>
        <p
          style={{
            width: secondaryLabelWidth,
            height: secondaryLabelHeight,
            color: secondaryLabelColor,
            fontWeight: secondaryLabelFontWeight,
            fontSize: secondaryLabelFontSize,
            lineHeight: secondaryLabelLineHeight,
          }}
          onClick={secondaryAction}
        >
          {secondaryLabel}
        </p>
      </ModalFooter>
    </Modal>
  );
};

export default SubscriptionDialog;
