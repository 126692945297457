import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconMyProfile from "../../../../../assets/img/profile.svg";
import IconLogout from "../../../../../assets/img/sign-out.svg";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { MyProfile } from "../../../../../app/components/profile/myProfile";
import { i18n } from "../../../../../app/pages/private/languageSelector";
import { shallowEqual, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { updateUserProfile } from "../../../../../app/modules/Auth/_redux/authCrud";

export function QuickUser() {
  const { language } = useSelector((state) => state.auth);
  const { user } = useSelector(
    (state) => ({
      user: state?.auth?.user,
    }),
    shallowEqual
  );

  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);

  const closeModal = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
  };

  const handleLanguage = async (language) => {
    try {
      localStorage.setItem("language", language);
      await updateUserProfile(user?._id, {
        ...user,
        language: language,
      });
      toast.success("User Language Updated");
      window.location.reload();
    } catch (error) {
      toast.error("Failed to update user language");
      console.error("Error updating user profile:", error);
    }
  };

  return (
    <div
      id="kt_quick_user"
      className="user-drawer offcanvas offcanvas-right offcanvas"
    >
      <div className="user-header">
        <button
          type="button"
          className="btn btn-close"
          onClick={(toggle) => closeModal(toggle)}
        >
          <img src={IconClose} alt="loading" />
        </button>
        <h4>{i18n("UserProfile.UserProfile")}</h4>
      </div>
      <div className="user-option">
        <Link
          to="#"
          id="kt_quick_user_close"
          onClick={() => {
            setOpenProfileDrawer(true);
          }}
        >
          <span>
            <img src={IconMyProfile} alt="loading" />
            {i18n("UserProfile.MyProfile")}
          </span>
        </Link>
        <Link to="/logout">
          <span>
            <img src={IconLogout} alt="loading" />
            {i18n("UserProfile.SignOut")}
          </span>
        </Link>

        <div className="ml-8">
          {language === "arabic" ? (
            <button
              onClick={() => handleLanguage("english")}
              className="btn btn-primary"
            >
              <span className="text-white">English</span>
            </button>
          ) : (
            <button
              onClick={() => handleLanguage("arabic")}
              className="btn btn-primary ml-4"
            >
              <span className="text-white">Arabic</span>
            </button>
          )}
        </div>
      </div>

      <MyProfile
        openProfileDrawer={openProfileDrawer}
        setOpenProfileDrawer={setOpenProfileDrawer}
      />
    </div>
  );
}
