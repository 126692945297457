import React, { useMemo, useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, useFormikContext } from "formik";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { Input } from "../../../../../_metronic/_partials/controls";
import PhoneInput from "react-phone-number-input";
import { CURRENCIES } from "../../../../../helpers/constants";
import countryToCurrency from "country-to-currency";
import countryList from "react-select-country-list";
import SubscriptionDialog from "../../company-edit-Dialog/SubscriptionDialog";
import { useDispatch, useSelector } from "react-redux";
import { updateCompany } from "../../../../../_redux/company/companyActions";
import { i18n } from "../../../private/languageSelector";
import { values } from "lodash";

const EditCompanyInformation = ({
  openEditCompanyInformationDialog,
  setOpenEditCompanyInformationDialog,
  companyInformationData,
}) => {
  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.auth);

  const companyInitialData = {
    companyName: companyInformationData?.companyName,
    email: companyInformationData?.email,
    mobile: companyInformationData?.mobile,
    taxNumber: companyInformationData?.taxNumber,
    currency: companyInformationData?.currency,
    country: companyInformationData?.country,
    address: companyInformationData?.address,
  };

  const options = useMemo(() => countryList().getData(), []);

  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const formRef = useRef(null);

  const handleClose = () => {
    if (formChanged) {
      setShowSubscriptionDialog(true);
    } else {
      setOpenEditCompanyInformationDialog(false);
    }
  };

  const PhoneInputField = () => {
    const { values, setFieldValue, touched, errors } = useFormikContext();

    return (
      <div className="form-group">
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="SA"
          style={{
            display: "flex",
            direction: language === "arabic" ? "rtl" : "ltr",
          }}
          className={`form-control ${
            touched.mobile && errors.mobile ? "is-invalid exclamationSign" : ""
          }`}
          name="mobile"
          value={values.mobile}
          onChange={(value) => {
            setFieldValue("mobile", value);
            setFormChanged(true);
          }}
        />
        {touched.mobile && errors.mobile ? (
          <div className="invalid-feedback">{errors.mobile}</div>
        ) : null}
      </div>
    );
  };

  const CurrencySelectField = () => {
    const { values, touched, errors } = useFormikContext();

    return (
      <div className="form-group">
        <label htmlFor="currency">Currency</label>
        <select
          id="currency"
          name="currency"
          className={`form-control ${
            touched.currency && errors.currency
              ? "is-invalid exclamationSign"
              : ""
          }`}
          value={values.currency}
          style={{ pointerEvents: "none", cursor: "not-allowed" }}
          disabled
        >
          <option value="" label="Select currency" />
          {CURRENCIES.map((currency, index) => (
            <option key={index} value={currency}>
              {currency}
            </option>
          ))}
        </select>
        {touched.currency && errors.currency ? (
          <div className="invalid-feedback">{errors.currency}</div>
        ) : null}
      </div>
    );
  };

  const CountrySelectField = () => {
    const { values } = useFormikContext();
  
    return (
      <div className="form-group">
        <label htmlFor="country">Country</label>
        <Field
          name="country"
          component={Input}
          placeholder={i18n("global.Country")}
          label={i18n("global.Country")}
          id="country"
          value={values.country}
          disabled
          style={{
            direction: language === "arabic" ? "rtl" : "ltr",
          }}
        />
      </div>
    );
  };

  const handleFormChange = () => {
    setFormChanged(true);
  };

  const onSubmitHandler = async (values, { resetForm }) => {
    await dispatch(
      updateCompany({ _id: companyInformationData._id, ...values })
    );
    setOpenEditCompanyInformationDialog(false);
    setFormChanged(false);
    resetForm();
  };

  return (
    <Modal
      className={`${language === "arabic" && "ar"}  modal-drawer`}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={openEditCompanyInformationDialog}
      onHide={handleClose}
    >
      <Formik
        initialValues={companyInitialData}
        enableReinitialize={true}
        onSubmit={(values, { resetForm }) => onSubmitHandler(values, resetForm)}
        innerRef={formRef}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Header
              className="d-flex justify-content-around"
              style={{
                direction: language === "arabic" ? "rtl" : "ltr",
              }}
            >
              <img
                src={IconClose}
                alt="close"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
              <Modal.Title>{i18n("Company.CompanyInformation")}</Modal.Title>
              <Modal.Title onClick={handleSubmit} style={{ cursor: "pointer" }}>
                {i18n("UserProfile.Save")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form
                className="form form-label-right"
                onChange={handleFormChange}
              >
                <div className="invoice-items row">
                  <div className="col-12">
                    <div className="form-group">
                      <Field
                        name="companyName"
                        component={Input}
                        placeholder={i18n("global.BusinessName")}
                        label={i18n("global.BusinessName")}
                        id="companyName"
                        style={{
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="email"
                        component={Input}
                        placeholder={i18n("UserProfile.Email")}
                        label={i18n("UserProfile.Email")}
                        id="email"
                        style={{
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      />
                    </div>
                    <PhoneInputField />
                    <div className="form-group">
                      <Field
                        name="taxNumber"
                        component={Input}
                        placeholder={i18n("Company.TaxNumber")}
                        label={i18n("Company.TaxNumber")}
                        id="taxNumber"
                        style={{
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      />
                    </div>
                    <CurrencySelectField />
                    <div className="form-group">
                      <Field
                        name="country"
                        component={Input}
                        placeholder={i18n("global.Country")}
                        label={i18n("global.Country")}
                        id="country"
                        style={{
                          pointerEvents: "none",
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        name="address"
                        component={Input}
                        placeholder={i18n("global.Address")}
                        label={i18n("global.Address")}
                        id="address"
                        style={{
                          direction: language === "arabic" ? "rtl" : "ltr",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
          </>
        )}
      </Formik>

      {/* unsaved changes modal */}
      <SubscriptionDialog
        modalWidth={"350px"}
        modalHeight={"185px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showSubscriptionDialog}
        onHide={() => setShowSubscriptionDialog(false)}
        title={i18n("SubscriptionModals.UnsavedChanges")}
        titleWidth={"140px"}
        titleHeight={"20px"}
        titleFontWeight={"700"}
        titleFontSize={"16px"}
        titleLineHeight={"20px"}
        titleColor={"#333333"}
        message={i18n(
          "SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges"
        )}
        messageWidth={"320px"}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"400"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        secondaryAction={() => {
          setOpenEditCompanyInformationDialog(false);
          setShowSubscriptionDialog(false);
        }}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"20px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"20px"}
        primaryLabelColor={"#828282"}
        primaryAction={() => setShowSubscriptionDialog(false)}
        secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
        secondaryLabelWidth={"75px"}
        secondaryLabelHeight={"20px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"20px"}
        secondaryLabelColor={"#2D9CDB"}
      />
    </Modal>
  );
};

export default EditCompanyInformation;
