import { createSlice } from "@reduxjs/toolkit";
import { getGrossSalesChartData, getTableData, getChartsSalesByTime, getAllStores,getAllUsers } from "./analyticsService";

const anayticsSlice = {
  salesChartData: {},
  analyticsTableData: [],
  ananlyticsTableDataLoading: false,
  analyticsTableDataError: null,
  grossSalesChartData: [],
  grossSalesChartDataLoading: false,
  grossSalesChartDataError: null,
  grossTimeData: [],
  grossTimeChartData: [],
  grossTimeChartDataLoading: false,
  grossTimeChartDataError: null,
  startDate: null, 
  endDate: null,   
  datesRangeLabel: "",
  stores: [], 
  storesLoading: false,
  storesError: null,
  users: [], 
  usersLoading: false,
  usersError: null,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState: anayticsSlice,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setDatesRangeLabel: (state, action) => {
      state.datesRangeLabel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTableData.pending, (state) => {
        state.ananlyticsTableDataLoading = true;
        state.analyticsTableDataError = null;
      })
      .addCase(getTableData.fulfilled, (state, action) => {
        state.ananlyticsTableDataLoading = false;
        state.analyticsTableDataError = null;
        state.analyticsTableData = action.payload;
      })
      .addCase(getTableData.rejected, (state, action) => {
        state.ananlyticsTableDataLoading = false;
        state.analyticsTableDataError = action.error.message;
      })
      .addCase(getGrossSalesChartData.pending, (state) => {
        state.grossSalesChartDataLoading = true;
        state.grossSalesChartDataError = null;
      })
      .addCase(getGrossSalesChartData.fulfilled, (state, action) => {
        state.grossSalesChartDataLoading = false;
        state.grossSalesChartDataError = null;
        state.salesChartData = action.payload;
        state.grossSalesChartData = action.payload.result;
      })
      .addCase(getGrossSalesChartData.rejected, (state, action) => {
        state.grossSalesChartDataLoading = false;
        state.grossSalesChartDataError = action.error.message;
      })
      .addCase(getChartsSalesByTime.pending, (state) => {
        state.grossTimeChartDataLoading = true;
        state.grossTimeChartDataError = null;
      })
      .addCase(getChartsSalesByTime.fulfilled, (state, action) => {
        state.grossTimeChartDataLoading = false;
        state.grossTimeChartDataError = null;
        state.grossTimeData = action.payload;
        state.grossTimeChartData = action.payload.result;
      })
      .addCase(getChartsSalesByTime.rejected, (state, action) => {
        state.grossTimeChartDataLoading = false;
        state.grossTimeChartDataError = action.error.message;
      })
      .addCase(getAllStores.pending, (state) => {
        state.storesLoading = true;
        state.storesError = null;
      })
      .addCase(getAllStores.fulfilled, (state, action) => {
        state.storesLoading = false;
        state.storesError = null;
        state.stores = action.payload; 
      })
      .addCase(getAllStores.rejected, (state, action) => {
        state.storesLoading = false;
        state.storesError = action.error.message;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.usersLoading = true;
        state.usersError = null;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.usersError = null;
        state.users = action.payload; 
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.usersLoading = false;
        state.usersError = action.error.message;
      });
  },
});

export const { setStartDate, setEndDate, setDatesRangeLabel } = analyticsSlice.actions;

export default analyticsSlice.reducer;
