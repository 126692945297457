import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/customers/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../private/languageSelector";

export function CustomerDeleteDialog({ id, show, onHide }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => ({ isLoading: state.customers.actionsLoading }), shallowEqual);

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteCustomer = () => {
    // server request for deleting customer by id
    dispatch(actions.deleteCustomer(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchCustomers(customersUIProps.queryParams));
      // clear selections list
      customersUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal show={show} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg" className="modal-drawer">
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <button
          type="button"
          onClick={() => {
            onHide();
          }}
          className="btn btn-close">
          <img src={IconClose} alt="loading" />
        </button>
        <Modal.Title id="example-modal-sizes-title-lg">{i18n("Customer.CustomerDelete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <>
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-center">
                <div
                  style={{
                    color: "#ED553B",
                    fontWeight: "700",
                    fontSize: "17px",
                  }}>
                  <div>{i18n("Customer.DeleteCustomer")}</div>
                </div>

                <div
                  className="mt-5"
                  style={{
                    fontSize: "16px",
                  }}>
                  {i18n("Customer.AreYouSureToPermanentlyDeleteThisCustomer")}
                </div>
              </div>
            </div>
          </>
        )}
        {isLoading && <span>{i18n("Customer.CustomerIsDeleting")}...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            {i18n("Customer.Cancel")}
          </button>
          <button
            type="button"
            onClick={deleteCustomer}
            className="btn btn-primary btn-elevate ml-5"
            style={{ backgroundColor: "#ED553B", borderColor: "#ED553B" }}>
            {i18n("Customer.Delete")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
