import React from 'react';

const InfoCard = (props) => {
    const { title, amount, className } = props;
    return (
        <div className={`col-md-4 small-card ${className}`}>
            <div className="card card-custom">
                <span className='info-title'>{title}</span>
                <div className="card-body">
                    <span className="amount-value text-center">{amount}</span>
                </div>
            </div>
        </div>
    );
}

export default InfoCard;
