import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors, applyClasses) => {
  const classes = ["form-control"];
  if (applyClasses) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    // if (touched && !errors) {
    //   classes.push("is-valid");
    // }
  }
  return classes.join(" ");
};

export function TextArea({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  rows = 2,
  cols = 50,
  applyClasses = true,
  companyLabel,
  ...props
}) {
  return (
    <>
      {label && companyLabel ? <b>{label}</b> : <label className="mb-0">{label}</label>}
      <textarea
        rows={rows}
        cols={cols}
        className={getFieldCSSClasses(touched[field.name], errors[field.name], applyClasses)}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={"text"}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
