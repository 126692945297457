import React from "react";
// import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }

  if (touched && !errors) {
    classes.push("is-valid-select");
  }

  return classes.join(" ");
};

export function Select({
  field,
  form: { touched, errors },
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  return (
    <>
      <select
        style={{ backgroundColor: "transparent", border: "#E4E6EF 1px solid" }}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}>
        {children}
      </select>
      {label && <label>{label}</label>}
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={errors[field.name]}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
