import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import { Storage } from "react-jhipster";
import { FATORATAY_AUTH } from "../../../../../src/helpers/constants";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};
const initialAuthState = {
  authToken: undefined,
  user: undefined,
  lanugage: null,
  rememberMe: false,
};

export const reducer = persistReducer(
  {
    storage,
    key: "fatoraty-auth",
    whitelist: ["user", "authToken", "rememberMe"],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, rememberMe } = action.payload;
        return { authToken, user: undefined, rememberMe: rememberMe };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        let prevLocation = localStorage.getItem("prevLocation");
        if (prevLocation) {
          localStorage.removeItem("prevLocation");
        }
        if (state.rememberMe) {
          Storage.local.remove(FATORATAY_AUTH);
        } else {
          Storage.session.remove(FATORATAY_AUTH);
        }
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        const { language } = user;
        return { ...state, user, language };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, rememberMe) => ({
    type: actionTypes.Login,
    payload: { authToken, rememberMe },
  }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user, lanugage) => ({
    type: actionTypes.UserRequested,
    payload: { user, lanugage },
  }),
  fulfillUser: (user, lanugage) => ({
    type: actionTypes.UserLoaded,
    payload: { user, lanugage },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });
}
