import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/products/productsActions";
import { ProductEditDialogHeader } from "./ProductEditDialogHeader";
import { ProductEditForm } from "./ProductEditForm";
import { useProductsUIContext } from "../ProductsUIContext";
import toast from "react-hot-toast";

const initProduct = {
  id: undefined,
  englishName: "",
  arabicName: "",
  price: "",
  categoryInProduct: "",
  serviceIsActive: true,
  serviceIsTaxable: false,
  englishDetail: "",
  arabicDetail: "",
  sku: 0,
  detail: "",
  barcode: "",
  soldBy: "each",
  pos: "colorAndShape",
};
export function ProductEditDialog({
  id,
  view,
  show,
  onHide,
  forInvoice,
  productName,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      queryParams: productsUIContext?.queryParams,
    };
  }, [productsUIContext]);

  // Products Redux state
  const {
    actionsLoading,
    productForEdit,
    navigate,
    productFilterState,
    skuCount,
    products,
  } = useSelector(
    (state) => ({
      navigate: state.products.navigate,
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
      productFilterState: state?.products?.productFilterState,
      skuCount: state?.products?.skuCount,
      products: state?.products?.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (navigate) {
      onHide();
    }
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    dispatch(actions.fetchProduct(id));
  }, [id, dispatch]);
  const saveProduct = (product) => {
    let skuProduct = products.filter(
      (distintProduct) => distintProduct?.sku === Number(product?.sku)
    );
    if (!id) {
      if (skuProduct && skuProduct.length) {
        toast.error("SKU already exist");
      } else {
        dispatch(
          actions.createProduct(
            productFilterState,
            product,
            forInvoice ? {} : productsUIProps.queryParams
          )
        ).then(() => setLoading(false));
      }
    } else {
      if (
        skuProduct &&
        skuProduct.length &&
        skuProduct[0]._id !== product?._id
      ) {
        toast.error("SKU already exist");
      } else {
        setLoading(true);
        dispatch(
          actions.updateProduct(
            productFilterState,
            product,
            productsUIProps.queryParams
          )
        ).then(() => setLoading(false));
      }
    }
  };
  return (
    <Modal
      className="modal-drawer invoice-drawer"
      size="lg"
      show={show}
      onHide={() => {}}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ProductEditDialogHeader onHide={onHide} id={id} view={view} />
      <ProductEditForm
        view={view}
        onHide={onHide}
        loading={loading}
        saveProduct={saveProduct}
        actionsLoading={actionsLoading}
        product={
          productForEdit || {
            ...initProduct,
            sku: skuCount,
            englishName: productName ? productName : "",
          }
        }
      />
    </Modal>
  );
}
