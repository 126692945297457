import axios from "axios";

export const Stores_URL = "api/store";

export function getStoreById(storeId) {
  return axios.get(`${Stores_URL}/${storeId}`);
}

export function getAllStores() {
  return axios.get(`${Stores_URL}`);
}

export function createStore(store) {
  return axios.post(`${Stores_URL}`, store);
}

export function updateStore(id, store) {
  return axios.patch(`${Stores_URL}/${id}`, store);
}

export function deleteStore(storeId) {
  return axios.delete(`${Stores_URL}/${storeId}`);
}
