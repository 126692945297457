import axios from "axios";

export const LOGIN_URL = "api/login";
export const REGISTER_URL = "api/register";
export const REQUEST_PASSWORD_URL = "api/forgot_password";
export const RESET_PASSWORD_URL = "api/reset/";
export const CHANGE_PASSWORD_URL = "api/change_password";
export const UPDATE_USER_URL = "api/users";
export const UPDATE_COMPANY_URL = "api/company";

export const ME_URL = "api/whoAmI";

export function login(email, password, platformInfo) {
  return axios.post(LOGIN_URL, { email, password, platformInfo });
}

export function register(register) {
  return axios.post(`${REGISTER_URL}`, {
    ...register,
    accountType: "individual",
    posPin: "",
  });
}
export function updateUser(userId, updatedUser, typeChange) {
  return axios.patch(`${UPDATE_USER_URL}/${userId}`, {
    ...updatedUser,
    accountType: typeChange ? "company" : "individual",
  });
}
export function updateUserCompany(companyId, updatedUser) {
  return axios.patch(`${UPDATE_COMPANY_URL}/${companyId}`, {
    ...updatedUser,
    accountType: updatedUser.companyName ? "company" : "individual",
  });
}

export function updateUserProfile(userId, updatedUser) {
  return axios.patch(`${UPDATE_USER_URL}/${userId}`, updatedUser);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function resetPassword(token, password) {
  return axios.post(`${RESET_PASSWORD_URL}${token}`, { password });
}

export function changePassword(password) {
  return axios.post(`${CHANGE_PASSWORD_URL}`, {
    oldPassword: password.currentPassword,
    newPassword: password.newPassword,
  });
}

export function getUserByToken() {
  return axios.get(ME_URL);
}
