import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { InvoiceStatusCssClasses } from "../InvoicesUIHelpers";
import { useInvoicesUIContext } from "../InvoicesUIContext";

const selectedInvoices = (entities, ids) => {
  const _invoices = [];
  ids.forEach((id) => {
    const invoice = entities.find((el) => el.id === id);
    if (invoice) {
      _invoices.push(invoice);
    }
  });
  return _invoices;
};

export function InvoicesFetchDialog({ show, onHide }) {
  // Invoices UI Context
  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
    };
  }, [invoicesUIContext]);

  // Invoices Redux state
  const { invoices } = useSelector(
    (state) => ({
      invoices: selectedInvoices(state.invoices.entities, invoicesUIProps.ids),
    }),
    shallowEqual
  );

  // if invoices weren't selected we should close modal
  useEffect(() => {
    if (!invoicesUIProps.ids || invoicesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesUIProps.ids]);

  return (
    <Modal show={show} onHide={() => {}} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Fetch selected elements</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {invoices.map((invoice) => (
            <div className="timeline-item align-items-start" key={`id${invoice.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i className={`fa fa-genderless text-${InvoiceStatusCssClasses[invoice.status]} icon-xxl`} />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span className={`label label-lg label-light-${InvoiceStatusCssClasses[invoice.status]} label-inline`}>
                  ID: {invoice.id}
                </span>
                <span className="ml-3">
                  {invoice.lastName}, {invoice.firstName}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={onHide} className="btn btn-primary btn-elevate">
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
