import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as taxActions from "./../../../../../_redux/taxes/taxesActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import IconClose from "../../../../../assets/img/icon-close.svg";
import { TaxesEditForm } from "./TaxesEditForm";
import { ManageTaxes } from "./ManageTaxes";

const Taxes = ({ userAccess, openTaxesDialog, setOpenTaxesDialog }) => {
  const dispatch = useDispatch();
  const [openTaxesAddDialog, setOpenTaxesAddDialog] = useState(false);
  const [openTaxesManageDialog, setOpenTaxesManageDialog] = useState(false);

  const { taxes } = useSelector(
    (state) => ({
      taxes: state.taxes?.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(taxActions.fetchTaxes());
  }, [dispatch]);
  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openTaxesDialog}
        onHide={() => {}}
      >
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => setOpenTaxesDialog(false)}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Taxes</Modal.Title>
        </Modal.Header>
        <Formik>
          {() => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                {taxes?.length ? (
                  <>
                    <Form className="form form-label-right">
                      <ul className="card-listitem list-unstyled">
                        <div className="h4 d-flex justify-content-between">
                          <b>Defined Taxes</b>
                          <b>Rate</b>
                        </div>
                        {taxes?.map((tax, i) => {
                          return (
                            <li key={i}>
                              <div className="card-info d-flex justify-content-between w-100">
                                <div>
                                  <b className="heading-color truncate h6">
                                    {tax.name}
                                  </b>
                                  <p className="sub-heading-color mb-0">
                                    Default Scheme: {tax.taxScheme}
                                  </p>
                                  <p className="sub-heading-color mb-0">
                                    {tax.type}
                                  </p>
                                </div>
                                <b className="heading-color h6">
                                  %{tax.taxRate}
                                </b>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="text-center">
                        <h4>You have no Taxes added!</h4>
                        {userAccess?.createData ? (
                          <>
                            <p className="mt-5">
                              To add a new Tax, click here to
                            </p>
                            <p
                              style={{ color: "#2D9CDB", cursor: "pointer" }}
                              onClick={() => setOpenTaxesAddDialog(true)}
                            >
                              Add New Taxes
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
            );
          }}
        </Formik>
        {taxes?.length ? (
          <>
            <Modal.Footer>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenTaxesManageDialog(true);
                  }}
                >
                  Manage
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
      <TaxesEditForm
        edit={false}
        openTaxesAddDialog={openTaxesAddDialog}
        setOpenTaxesAddDialog={setOpenTaxesAddDialog}
      />
      <ManageTaxes
        taxes={taxes}
        userAccess={userAccess}
        openTaxesManageDialog={openTaxesManageDialog}
        setOpenTaxesManageDialog={setOpenTaxesManageDialog}
      />
    </>
  );
};
export default Taxes;
