import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../private/languageSelector";

export function CustomersDeleteConfirmation({ deleteCustomers, openDeleteDialog, setOpenDeleteDialog }) {
  return (
    <Modal
      className="modal-drawer"
      show={openDeleteDialog}
      onHide={() => {}}
      aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <button type="button" className="btn btn-close" onClick={() => setOpenDeleteDialog()}>
          <img src={IconClose} alt="loading" />
        </button>

        <Modal.Title id="example-modal-sizes-title-lg">{i18n("Customer.CustomerDelete")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center">
              <h6 style={{ color: "red" }}>{i18n("Customer.DeleteCustomer")}</h6>
              <span>{i18n("Customer.AreYouSureToPermanentlyDeleteThisCustomer")}</span>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => setOpenDeleteDialog(false)}
            className="btn btn-outline-primary btn-elevate">
            {i18n("UserProfile.Back")}
          </button>
          <button
            type="button"
            onClick={() => {
              deleteCustomers();
              setOpenDeleteDialog(false);
            }}
            className="btn btn-danger btn-elevate ml-5">
            {i18n("Customer.Delete")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
