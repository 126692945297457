import React, { useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({
  path,
  routeAccess,
  component: Component,
  ...restOfProps
}) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { subscriptionData } = useSelector((state) => state.subscription);

  const isTrialExpired =
    subscriptionData?.subscription?.currentPlan?.plan === "Trial" &&
    subscriptionData?.subscription?.currentPlan?.status === "Expired" &&
    subscriptionData?.subscription?.userTrial?.isTrialExpired === true;

  const isSubscriptionPaused =
    subscriptionData?.subscription?.currentPlan?.status === "Paused";

  useEffect(() => {
    if (routeAccess) {
      localStorage.setItem("prevLocation", path);
    }
  }, [restOfProps, path, routeAccess]);

  let companyInfoRoute = "/user-or-company";
  let currLocation = localStorage.getItem("prevLocation");
  let companyInfo = user?.companyId?.profession || user?.companyId?.industry;

  useEffect(() => {
    if (isTrialExpired || isSubscriptionPaused) {
      history.push("/subscription");
    }
  }, [isTrialExpired, history, isSubscriptionPaused]);

  return (
    <Route
      render={(props) =>
        companyInfo && routeAccess ? (
          <Component {...props} />
        ) : routeAccess && !companyInfo ? (
          <Redirect to={companyInfoRoute} />
        ) : companyInfo && !routeAccess ? (
          <Redirect to={currLocation} />
        ) : (
          <Redirect to={"/error"} />
        )
      }
    />
  );
};
export default PrivateRoute;
