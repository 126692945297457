import React, { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { RefundQuantity } from "./RefundQuantity";
import { i18n } from "../../private/languageSelector";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import IconClose from "../../../../assets/img/icon-close.svg";
import { PAYMENT_TYPES } from "../../../../helpers/constants";
import { Card } from "../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import toast from "react-hot-toast";

function InvoiceRefundDialog({
  onHide,
  invoice,
  showRefundDialog,
  setShowRefundDialog,
}) {
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [defaultPayment, setDefaultPayment] = useState("Cash");
  const [paymentTypesToShow, setPaymentTypesToShow] = useState([]);

  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext.queryParams,
    };
  }, [invoicesUIContext]);

  const { user, invoiceFilterState } = useSelector(
    (state) => ({
      user: state?.auth?.user,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (invoice?.invoiceData?.products?.length) {
      let data = invoice?.invoiceData?.products?.map((item) => {
        return { ...item, refundQuantity: 0, refundAmount: 0 };
      });
      setProducts(data);
      setOriginalProducts([...invoice?.invoiceData?.products]);
    }
  }, [invoice, showRefundDialog]);

  useEffect(() => {
    if (user?.companyId?.paymentTypes?.length) {
      setPaymentTypesToShow([...user?.companyId?.paymentTypes]);
    } else {
      setPaymentTypesToShow([...PAYMENT_TYPES]);
    }
  }, [user]);

  // Setting Default Payment Channel
  useEffect(() => {
    if (paymentTypesToShow?.length) {
      let defaultChannel = paymentTypesToShow.filter((item) => {
        return item.default === true;
      });
      setDefaultPayment(defaultChannel[0]?.name);
    }
    // eslint-disable-next-line
  }, [paymentTypesToShow]);

  const selectPayment = (e) => {
    setDefaultPayment(e.target.value);
  };
  let amount = 0;
  if (products?.length) {
    amount = products?.reduce((a, b) => {
      return (amount = b.refundAmount ? a + b.refundAmount : amount);
    }, 0);
  }

  const refundInvoice = () => {
    if (+invoice.invoiceData.totalSales > +invoice?.invoiceData?.paidTotal) {
      toast.error(
        `You can only refund upto ${+invoice?.invoiceData?.paidTotal?.toFixed(
          2
        )}`
      );
    } else {
      let originalInvoice = {
        ...invoice.invoiceData,
        products: originalProducts,
        balance: +invoice.invoiceData.balance,
        totalSales: +invoice.invoiceData.totalSales,
      };
      let newInvoice = {
        ...invoice.invoiceData,
        products: products,
        invoiceType: "refund",
        invoiceStatus: "paid",
        channel: "web",

        payments: [
          {
            note: "",
            method: "cash",
            type: "CREDIT",
            amount: amount,
            date: new Date(),
            createdAt: new Date(),
          },
        ],
        balance: 0,
        paidTotal: amount,
        totalSales: amount,
        productsTotal: amount,
      };
      let refundedProducts = newInvoice?.products?.filter((item) => {
        return !item.refundQuantity ? (item = null) : item;
      });
      let refundedInvoiceData = { ...newInvoice, products: refundedProducts };
      dispatch(
        actions.createRefundInvoice(
          invoiceFilterState,
          {
            ...invoice,
            invoiceData: refundedInvoiceData,
            parentInvoice: invoice._id,
            parentInvoiceNumber: invoice.invoiceNumber,
          },
          invoicesUIProps.queryParams
        )
      );
      dispatch(
        actions.updateInvoiceRefund({
          ...invoice,
          invoiceData: originalInvoice,
        })
      );

      setShowRefundDialog(false);
      onHide();
    }
  };
  return (
    <>
      <Modal
        size="lg"
        className="modal-drawer invoice-drawer"
        show={showRefundDialog}
        onHide={() => {}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <button
            type="button"
            onClick={() => {
              setShowRefundDialog();
            }}
            className="btn btn-close"
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("INVOICES.Refund")}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="overlay overlay-block cursor-default"
          style={{ backgroundColor: "whitesmoke", width: "100%" }}
        >
          {products?.map((product, index) => {
            return (
              <Card key={index}>
                <div className="invoice-title">
                  <h5 className="m-0">
                    <b>
                      {i18n("global.ItemNumber")} {index + 1}
                    </b>
                  </h5>
                  <div className="pr-3">
                    <RefundQuantity
                      products={products}
                      productIndex={index}
                      price={product?.price}
                      taxRate={product?.addedTax}
                      setProducts={setProducts}
                      originalProducts={originalProducts}
                      setOriginalProducts={setOriginalProducts}
                      alreadyRefunded={
                        product?.refundedQuantity
                          ? product?.refundedQuantity
                          : 0
                      }
                      soldQuantity={
                        product?.refundedQuantity
                          ? +product?.quantity - +product?.refundedQuantity
                          : +product?.quantity
                      }
                    />
                  </div>
                </div>
                <div className="invoice-items row">
                  <div className="col-12">
                    <small className="text-muted">Item Name</small>
                    <p>{product?.name}</p>
                  </div>
                  <div className="col-3">
                    <small className="text-muted">Amount</small>
                    <p>
                      {product?.price
                        ? Number(product?.price)?.toFixed(2)
                        : 0.0}
                    </p>
                  </div>
                  <div className="col-4">
                    <small className="text-muted">Sold</small>
                    <p>
                      <small>X </small>
                      {product?.quantity}
                    </p>
                  </div>
                  {product?.refundedQuantity ? (
                    <div className="col-5">
                      <small className="text-muted">Refunded</small>
                      <p style={{ color: "red" }}>
                        <small>X </small>
                        {product?.refundedQuantity}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Card>
            );
          })}
          <Card>
            <div className="invoice-title">
              <h5 className="m-0">
                <b>{i18n("INVOICES.PaymentChannel")}</b>
              </h5>
            </div>
            <div className="form-group form-select">
              <select
                className="form-select form w-100"
                value={defaultPayment}
                onChange={(e) => selectPayment(e)}
              >
                {paymentTypesToShow?.length
                  ? paymentTypesToShow
                      ?.filter((it) => it.active)
                      .map((item, index) => {
                        return (
                          <option key={index} value={item?.name}>
                            {item?.name}
                          </option>
                        );
                      })
                  : ""}
              </select>
            </div>
          </Card>
          <Card className="invoice-total">
            <ul>
              <li>
                <span>{i18n("INVOICES.Subtotal")}</span>
                <strong>
                  {invoice?.invoiceData?.productsTotal
                    ? Number(invoice?.invoiceData?.productsTotal)?.toFixed(2)
                    : 0.0}
                </strong>
              </li>
              <li>
                <span>Total Discount</span>
                <strong>
                  {invoice?.invoiceData?.discountTotal
                    ? Number(invoice?.invoiceData?.discountTotal)?.toFixed(2)
                    : 0.0}
                </strong>
              </li>
              <li>
                <span>Total Tax</span>
                <strong>
                  {invoice?.invoiceData?.taxTotal
                    ? Number(invoice?.invoiceData?.taxTotal)?.toFixed(2)
                    : 0.0}
                </strong>
              </li>
              <li>
                <span>{i18n("INVOICES.Total")}</span>
                <strong>
                  {invoice?.invoiceData?.totalSales
                    ? Number(invoice?.invoiceData?.totalSales)?.toFixed(2)
                    : 0.0}
                </strong>
              </li>
              <li>
                <span>
                  {i18n("INVOICES.Total") + " " + i18n("INVOICES.Paid")}
                </span>
                <strong>
                  {invoice?.invoiceData?.paidTotal
                    ? Number(invoice?.invoiceData?.paidTotal)?.toFixed(2)
                    : 0.0}
                </strong>
              </li>
            </ul>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <small className={!amount ? "text-muted" : ""}>
            {i18n("INVOICES.RefundAmount")}
          </small>
          <h3 className={!amount ? "text-muted" : ""}>
            {amount ? amount?.toFixed(2) : 0.0}
          </h3>
          <button
            type="button"
            onClick={() => refundInvoice()}
            {...(!amount ? { disabled: true } : {})}
            className={`btn btn-elevate btn-primary`}
          >
            {i18n("INVOICES.Refund")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default InvoiceRefundDialog;
