import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";
import CustomersActionsModal from "./CustomersActionsModal";

function CustomersPage({ history }) {
  const [showCustomerAddDialoag, setShowCustomerAddDialoag] = useState(false);
  const [showCustomerActionsDialoag, setShowCustomerActionsDialoag] = useState(false);

  const customersUIEvents = {
    newCustomerButtonClick: () => {
      setShowCustomerAddDialoag(true);
      history.push("/customers/new");
    },
    openEditCustomerDialog: (id) => {
      history.push(`/customers/${id}/edit`);
      setShowCustomerAddDialoag(true);
    },
    openDeleteCustomerDialog: (id) => {
      history.push(`/customers/${id}/delete`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/customers/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/customers/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/customers/updateStatus");
    },
    openViewCustomerDialog: (id) => {
      setShowCustomerAddDialoag(true);
      history.push(`/customers/${id}/view`);
    },
    openCustomerActionsDialog: (id) => {
      history.push(`/customers/${id}/actions`);
      setShowCustomerActionsDialoag(true);
    },
    openCreateInvoiceDialog: (id) => {
      history.push(`/invoices/new/${id}`);
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Switch>
        {/* <Route path="/customers"> */}
        <Route exact={true} path="/customers/new">
          {({ history, match }) => (
            <CustomerEditDialog
              show={match != null}
              onHide={() => {
                history.push("/customers");
              }}
              view={false}
              showCustomerAddDialoag={showCustomerAddDialoag}
              setShowCustomerAddDialoag={setShowCustomerAddDialoag}
            />
          )}
        </Route>
        <Route exact={true} path="/customers/:id/edit">
          {({ history, match }) => (
            <CustomerEditDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/customers");
              }}
              view={false}
              showCustomerAddDialoag={showCustomerAddDialoag}
              setShowCustomerAddDialoag={setShowCustomerAddDialoag}
            />
          )}
        </Route>
        <Route exact={true} path="/customers/:id/view">
          {({ history, match }) => (
            <CustomerEditDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push("/customers");
              }}
              view={true}
              showCustomerAddDialoag={showCustomerAddDialoag}
              setShowCustomerAddDialoag={setShowCustomerAddDialoag}
            />
          )}
        </Route>
      </Switch>

      <Route path="/customers/deleteCustomers">
        {({ history, match }) => (
          <CustomersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/customers");
            }}
          />
        )}
      </Route>

      <Route path="/customers/:id/delete">
        {({ history, match }) => (
          <CustomerDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/customers");
            }}
          />
        )}
      </Route>
      <Route path="/customers/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/customers");
            }}
          />
        )}
      </Route>
      <Route path="/customers/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/customers");
            }}
          />
        )}
      </Route>

      <Route path="/customers/:id/actions">
        {({ history, match }) => (
          <CustomersActionsModal
            show={match != null}
            onHide={() => {
              history.push("/customers");
            }}
            open={showCustomerActionsDialoag}
            setShowCustomerActionsDialoag={setShowCustomerActionsDialoag}
            openEditCustomerDialog={customersUIEvents.openEditCustomerDialog}
            openDeleteCustomerDialog={customersUIEvents.openDeleteCustomerDialog}
            openViewCustomerDialog={customersUIEvents.openViewCustomerDialog}
            openCreateInvoiceDialog={customersUIEvents.openCreateInvoiceDialog}
          />
        )}
      </Route>
      <CustomersCard />
    </CustomersUIProvider>
  );
}
export default CustomersPage;
