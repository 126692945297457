/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import { useLocation } from "react-router-dom";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { useEffect } from "react";
import HomeWrapper from "../../../pages/HomePage/HomeWrapper";

const login = {
  title: "",
  subTitle: "",
  img: toAbsoluteUrl("/media/bg/bg_login.svg"),
};

const register = {
  title: "",
  subTitle: "",
  img: toAbsoluteUrl("/media/bg/bg_register.svg"),
};

const areYou = {
  title: "Freelancer or Company",
  subTitle: "Wether your are a Company or a Freelancer, Fatoraty will provide you with innovative tools to grow your business  ",
  img: toAbsoluteUrl("/media/bg/bg_are_you.svg"),
};

const individual = {
  title: "Invoice like a professional",
  subTitle: "Start creating and sharing invoices with your customers like a professional to increase your value",
  img: toAbsoluteUrl("/media/bg/bg_individual.svg"),
};

const company = {
  title: "Simplicity or scalability",
  subTitle: "Fatoraty provides you with sample tools to enable your team to create invoices online while you can manage it instantly from anywhere",
  img: toAbsoluteUrl("/media/bg/bg_company.svg"),
};
const passwordReset = {
  title: "Password Reset",
  subTitle: "",
  img: toAbsoluteUrl("/media/bg/password-reset.svg"),
};
export function AuthPage() {
  const location = useLocation();

  const [module, setModule] = useState(login);
  useEffect(() => {
    if (location?.pathname) {
      if (location.pathname === "/auth/login") {
        setModule(login);
      } else if (location.pathname === "/auth/registration") {
        setModule(register);
      } else if (location.pathname === "/auth/forgot-password") {
        setModule(passwordReset);
      }
    }
  }, [location]);
  return (
    <>
      {window.location.pathname === "/homePage" ? (
        <>
          <Switch>
            <ContentRoute path="/homePage" component={HomeWrapper} />
          </Switch>
        </>
      ) : (
        <div className="login-form login-signin">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="illustration">
                  <h1>{module.title}</h1>
                  <p>{module.subTitle}</p>
                  <div className="img-wrap">
                    <img alt="module" src={toAbsoluteUrl(module.img)} />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-wrap">
                <Switch>
                  <ContentRoute path="/auth/login" component={Login} />
                  <ContentRoute path="/auth/registration">
                    <Registration
                      areYou={() => {
                        setModule(areYou);
                      }}
                      individual={() => {
                        setModule(individual);
                      }}
                      company={() => {
                        setModule(company);
                      }}
                      passwordReset={() => setModule(passwordReset)}
                    />
                  </ContentRoute>
                  <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />

                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
