import React from "react";
import logo from "./../../../../assets/img/activate-user.svg";
import { NavLink, Link } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import { i18n } from "../../../pages/private/languageSelector";

const VerifyUserAccount = () => {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.user,
    }),
    shallowEqual
  );

  const resendEmailHandler = () => {
    if (user && user?.email) {
      let email = user.email;
      axios.post("/api/resend-confirmation", { email }).then((res) => {
        toast.success(res.data.message);
      });
    }
  };

  return (
    <div className="login-form login-signin user-activation">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6 col-md-6">
            <div className="illustration">
              <h1>{i18n("ActivateAccount.ActivateAccount")}</h1>
            </div>
            <div className="img-wrap" style={{ maxWidth: "400px", margin: "4rem auto 0" }}>
              <img src={logo} alt="loading" width="100%" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 text-center">
            <div className="text-block">
              <h6>{i18n("ActivateAccount.WehaveSentAVerificationCode")}</h6>
              <p>
                {i18n("ActivateAccount.DidntReceiveTheCodeYet")}{" "}
                <Link to="#" style={{ color: "#2D9CDB" }} onClick={resendEmailHandler}>
                  {i18n("ActivateAccount.Resend")}
                </Link>
              </p>
              <NavLink
                className="menu-link"
                to={
                  user?.companyId?.profession
                    ? "/dashboard"
                    : user?.companyId?.industry !== ""
                    ? "/dashboard"
                    : "/user-or-company"
                }>
                {i18n("ActivateAccount.NoThanksIWillActivateMyAccountLater")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyUserAccount;
