import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import IconClose from "../../../../assets/img/icon-close.svg";
import { i18n } from "../../../pages/private/languageSelector";
import InvoiceRefundDialog from "../invoice-refund-dialog copy/InvoiceRefundDialog";

export function InvoiceDeleteDialog({ id, show, onHide }) {
  const [showRefundDialog, setShowRefundDialog] = useState(false);
  const dispatch = useDispatch();

  // Invoices UI Context
  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
    };
  }, [invoicesUIContext]);

  useEffect(() => {
    // server call for getting Invoice by id
    dispatch(actions.fetchInvoice(id));
  }, [id, dispatch]);
  const { invoice } = useSelector(
    (state) => ({
      invoice: state?.invoices.invoiceForEdit,
    }),
    shallowEqual
  );
  // Invoices Redux state
  const { isLoading, invoiceFilterState } = useSelector(
    (state) => ({
      isLoading: state?.invoices?.actionsLoading,

      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteInvoice = () => {
    // server request for deleting invoice by id
    dispatch(actions.deleteInvoice(id, false)).then(() => {
      // refresh list after deletion
      dispatch(
        actions.fetchInvoices(invoiceFilterState, invoicesUIProps.queryParams)
      );
      // clear selections list
      invoicesUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <>
      <Modal
        show={show}
        className="modal-drawer"
        onHide={() => {}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {/*begin::Loading*/}
        {isLoading && <ModalProgressBar />}
        {/*end::Loading*/}
        <Modal.Header closeButton>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => onHide()}
          >
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title id="example-modal-sizes-title-lg">
            {i18n("INVOICES.InvoiceDelete")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && (
            <div className="d-flex justify-content-center align-items-center h-100">
              <div className="text-center">
                <h6 style={{ color: "red" }}>
                  {i18n("INVOICES.CantDeleteInvoice")}
                </h6>
                <span>
                  {i18n(
                    "INVOICES.ClickRefundButtonInsteadToFullyRefunTheInvoice"
                  )}
                </span>
              </div>
            </div>
          )}
          {isLoading && (
            <span>{i18n("INVOICES.InvoiceIsDeActivating")}...</span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              {i18n("INVOICES.Cancel")}
            </button>
            <button
              type="button"
              onClick={() => {
                setShowRefundDialog(true);
              }}
              className="btn btn-danger btn-elevate ml-5"
            >
              {i18n("INVOICES.Refund")}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <InvoiceRefundDialog
        onHide={onHide}
        invoice={invoice}
        showRefundDialog={showRefundDialog}
        setShowRefundDialog={setShowRefundDialog}
      />
    </>
  );
}
