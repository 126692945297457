import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { i18n } from "../../private/languageSelector";
import FilterDialogeNew from "./FilterDialogeNew";
import { useInvoicesUIContext } from "../InvoicesUIContext";
import { InvoiceStore } from "../invoice-create-dialog/InvoiceStore";
// import InvoiceCreateDialog from "../invoice-create-dialog/InvoiceCreateDialog";
import { InvoiceColumnsModal } from "../invoice-columns-dialog/InvoiceColumnsModal";
import { InvoiceGroupActionsModal } from "../invoice-actions-modals/InvoiceGroupActionsModal";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;

  const newQueryParams = { ...queryParams };

  const filter = {};
  if (searchText) {
    filter.mobile = searchText;
    filter.invoice = searchText;
    filter.customerName = searchText;
    filter.customerEmail = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function InvoicesFilter({ tableFields, setTableFields, selectedInvoices, setSelectedInvoices }) {
  // Invoices UI Context
  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      queryParams: invoicesUIContext.queryParams,
      setQueryParams: invoicesUIContext.setQueryParams,
      newInvoiceButtonClick: invoicesUIContext.newInvoiceButtonClick,
    };
  }, [invoicesUIContext]);

  const [edit, setEdit] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterActive, setFilterActive] = useState(false);
  const [openStoreDialog, setOpenStoreDialog] = useState(false);
  // const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [openInvoiceGroupDialog, setOpenInvoiceGroupDialog] = useState(false);
  const [openInvoiceColumnDialog, setOpenInvoiceColumnDialog] = useState(false);

  const { userAccess } = useSelector((state) => ({
    userAccess: state?.users?.userAccess,
  }));

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(invoicesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, invoicesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      invoicesUIProps.setQueryParams(newQueryParams);
    }
  };
  return (
    <>
      <Formik
        initialValues={{
          type: "",
          status: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}>
        {({ values, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="action-bar">
              <div className="d-flex align-items-center">
                <button
                  disabled={!selectedInvoices?.length}
                  type="button"
                  className="btn btn-outline-primary mr-3"
                  onClick={() => setOpenInvoiceGroupDialog(true)}>
                  {i18n("INVOICES.Actions")}
                </button>
                <button
                  type="button"
                  className={`btn btn-outline-primary ${filterActive ? "active" : ""}  mr-3`}
                  onClick={() => setFilterDialog(true)}>
                  {i18n("INVOICES.Filter")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-primary mr-3"
                  onClick={() => setOpenInvoiceColumnDialog(true)}>
                  {i18n("INVOICES.Columns")}
                </button>
              </div>
              <input
                type="text"
                className="form-control float-left"
                name="searchText"
                placeholder={i18n("global.Search")}
                onBlur={handleBlur}
                value={values.searchText}
                onChange={(e) => {
                  setFieldValue("searchText", e.target.value);
                  handleSubmit();
                }}
              />
              <div className="text-center">
                {userAccess?.createData ? (
                  <button
                    type="button"
                    className="btn btn-pri btn-sm btn-rounded"
                    onClick={() => {
                      setEdit(false);
                      // setOpenInvoiceDialog(true);
                      setOpenStoreDialog(true);
                    }}>
                    <span className="fas fa-plus mr-2" />
                    {i18n("INVOICES.TaxInvoice")}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <FilterDialogeNew
        classes="modal-drawer"
        show={filterDialog}
        setFilterActive={setFilterActive}
        secondaryActionTitle={i18n("Category.No")}
        title={i18n("INVOICES.FilterInvoicesList")}
        subTitle={i18n("INVOICES.ClearAllFilters")}
        primaryAction={() => setFilterDialog(false)}
        primaryActionTitle={i18n("DASHBOARD.Apply")}
        secondaryAction={() => setFilterDialog(false)}
      />
      <InvoiceGroupActionsModal
        userAccess={userAccess}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
        openInvoiceGroupDialog={openInvoiceGroupDialog}
        setOpenInvoiceGroupDialog={setOpenInvoiceGroupDialog}
      />
      <InvoiceColumnsModal
        tableFields={tableFields}
        setTableFields={setTableFields}
        openInvoiceColumnDialog={openInvoiceColumnDialog}
        setOpenInvoiceColumnDialog={setOpenInvoiceColumnDialog}
      />
      {/* <InvoiceCreateDialog
        edit={edit}
        openInvoiceDialog={openInvoiceDialog}
        setOpenInvoiceDialog={setOpenInvoiceDialog}
      /> */}
      <InvoiceStore edit={edit} openStoreDialog={openStoreDialog} setOpenStoreDialog={setOpenStoreDialog} />
    </>
  );
}
