import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Modal } from "react-bootstrap";
import { ManagePos } from "./ManagePos";
import { PosEditForm } from "./PosEditForm";
import IconClose from "../../../../../assets/img/icon-close.svg";
import * as posActions from "../../../../../_redux/pos/posActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as storesActions from "../../../../../_redux/stores/storeActions";

const Pos = ({ userAccess, openPosDialog, setOpenPosDialog }) => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [openPosAddDialog, setOpenPosAddDialog] = useState(false);
  const [openPosManageDialog, setOpenPosManageDialog] = useState(false);

  const { pos, stores } = useSelector(
    (state) => ({
      pos: state.pos?.entities,
      stores: state.stores.entities,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(posActions.fetchAllPos());
    dispatch(storesActions.fetchStores());
    setSearchText("");
  }, [dispatch, openPosDialog]);

  return (
    <>
      <Modal
        className="modal-drawer"
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openPosDialog}
        onHide={() => {}}>
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenPosDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>Pos Devices</Modal.Title>
        </Modal.Header>
        <Formik>
          {() => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                {pos?.length ? (
                  <>
                    <Form className="form form-label-right">
                      <div className="d-flex justify-content-end">
                        <div className="form-group input-select w-50 mb-0">
                          <p className="text-muted mb-0">Store</p>
                          <select className="form-control" onChange={(e) => setSearchText(e.target.value)}>
                            <option value="" selected>
                              All Stores
                            </option>
                            {stores?.length
                              ? stores.map((item) => {
                                  return (
                                    <option value={item?.name ? item?.name : item?.storeId}>
                                      {item?.name ? item?.name : item?.storeId}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                        </div>
                      </div>
                      <ul className="card-listitem list-unstyled">
                        <div className="h5 d-flex justify-content-between">
                          <b>POS Name</b>
                          <b>Status</b>
                        </div>
                        {pos?.length
                          ? pos
                              ?.filter((it) => {
                                return (
                                  it?.store?.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
                                  it?.store?.storeId?.toLowerCase()?.includes(searchText?.toLowerCase())
                                );
                              })
                              .map((pos, i) => {
                                return (
                                  <li key={i}>
                                    <div className="card-info d-flex justify-content-between w-100">
                                      <div>
                                        <b className="heading-color truncate h5">{pos.name ? pos.name : pos.posId}</b>
                                        <p className="sub-heading-color mb-0">
                                          {pos?.store?.name ? pos?.store?.name : pos?.store?.storeId}
                                        </p>
                                      </div>
                                      <b className="heading-color h5">{pos?.isActive ? "Active" : "In-Active"}</b>
                                    </div>
                                    <hr />
                                  </li>
                                );
                              })
                          : ""}
                      </ul>
                    </Form>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="text-center">
                        <h4>You have no pos added!</h4>
                        {userAccess?.createData ? (
                          <>
                            <p className="mt-5">To add a new Pos Device, click here to</p>
                            <p
                              style={{ color: "#2D9CDB", cursor: "pointer" }}
                              onClick={() => {
                                setOpenPosAddDialog(true);
                              }}>
                              Add New Pos Devices
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Modal.Body>
            );
          }}
        </Formik>
        {pos?.length ? (
          <>
            <Modal.Footer>
              <div className="row justify-content-center">
                <button
                  className="btn btn-primary btn-elevate f-button"
                  onClick={() => {
                    setOpenPosManageDialog(true);
                  }}>
                  Manage
                </button>
              </div>
            </Modal.Footer>
          </>
        ) : (
          ""
        )}
      </Modal>
      <PosEditForm openPosAddDialog={openPosAddDialog} setOpenPosAddDialog={setOpenPosAddDialog} edit={false} />
      <ManagePos
        pos={pos}
        stores={stores}
        userAccess={userAccess}
        openPosManageDialog={openPosManageDialog}
        setOpenPosManageDialog={setOpenPosManageDialog}
      />
    </>
  );
};
export default Pos;
