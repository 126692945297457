import React from 'react'
import { Switch } from "react-router-dom";
import SalesByProduct from './analyticsView/SalesByProduct';

const SalesByProductPage = () => {
  return (
    <Switch>
    <SalesByProduct />
  </Switch>
  )
}

export default SalesByProductPage