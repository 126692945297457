import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../private/languageSelector";
import { TextArea } from "../../../../_metronic/_partials/controls";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Card, CardBody } from "../../../../_metronic/_partials/controls";
import "react-phone-input-2/lib/style.css";
import * as auth from "../../../modules/Auth/_redux/authRedux";
import * as actions from "../../../../_redux/company/companyActions";
import CompanyLogo from "../../../../_metronic/_assets/img/default-picture.png";
import toast from "react-hot-toast";
import ImageUploading from "react-images-uploading";

import Pos from "./pos/Pos";
import Taxes from "./taxes/Taxes";
import Stores from "./stores/Stores";
import UserRole from "./roles/userRole";
import CompanyEditDialog from "./companyEditDialog";
import Subscription from "./subscription/Subscription";
import PaymentTypeDialog from "./payments/paymentTypes";
import CompanyMessageDialog from "./companyMessagesDialog";
import CompanyTermsAndConditionsDialog from "./termsAndConditionDialog";
import UserProfilePage from "../company-edit-Dialog/users/userProfilePage";

const initCompanyState = {
  companyName: "",
  email: "",
  mobile: "",
  currency: "",
  industry: "",
  taxNumber: "",
  taxRate: "",
  taxName: "",
  crNumber: "",
  country: "",
  city: "",
  state: "",
  zipcode: "",
  street: "",
};

const initMessageState = {
  defaultEmail: {
    messageEnglish: "",
    messageArabic: "",
  },
  defaultSms: {
    messageEnglish: "",
    messageArabic: "",
  },
};

const initTearmsAndConditionsState = {
  termsAndConditions: "",
  returnPolicy: "",
};

const CompanyView = ({ requestUser, actionsLoading }) => {
  const dispatch = useDispatch();

  const [openCompanyMessageDialog, setOpenCompanyMessageDialog] = useState(false);
  const [openTermsAndConditionsDialog, setOpenTermsAndConditionsDialog] = useState(false);
  const [image, setImage] = useState([]);
  const [openPosDialog, setOpenPosDialog] = useState(false);
  const [openSubsDialog, setOpenSubsDialog] = useState(false);
  const [openTaxesDialog, setOpenTaxesDialog] = useState(false);
  const [userCompanyAccess, setUserCompanyAccess] = useState({});
  const [openStoresDialog, setOpenStoresDialog] = useState(false);
  const [openUserRoleDialog, setOpenUserRoleDialog] = useState(false);
  const [openUserProfilePage, setOpenUserProfilePage] = useState(false);
  const [showCompanyEditDialog, setShowCompanyEditDialog] = useState(false);
  const [openPaymentTypeDialog, setOpenPaymentTypeDialog] = useState(false);

  const { userAccess, companyForEdit, navigate, user } = useSelector(
    (state) => ({
      user: state.auth.user,
      authToken: state.auth.authToken,
      navigate: state.company.navigate,
      userAccess: state?.users?.userAccess,
      companyForEdit: state.company.companyForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.CompanyFetch());
  }, [dispatch]);

  useEffect(() => {
    if (navigate) {
      dispatch(actions.CompanyFetch());
      setShowCompanyEditDialog(false);
      setOpenCompanyMessageDialog(false);
      setOpenTermsAndConditionsDialog(false);
    }
    // eslint-disable-next-line
  }, [navigate]);

  const saveCompany = (company) => {
    const toastId = toast.loading("Loading...");
    if (companyForEdit && companyForEdit._id) {
      dispatch(actions.updateCompany(company)).then(() => {
        requestUser(user);
        toast.dismiss(toastId);
      });
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    setImage(imageList);
  };

  const imageChangeHandler = (e, onImageUpload) => {
    e.preventDefault();
    onImageUpload();
  };

  const companyInfoHandler = (e) => {
    e.preventDefault();
    setShowCompanyEditDialog(true);
  };

  let companyAccess = userAccess?.company?.subItem;

  useEffect(() => {
    if (companyAccess) {
      setUserCompanyAccess({
        companyInfo: companyAccess[0]?.selected,
        roles: companyAccess[1]?.selected,
        users: companyAccess[2]?.selected,
        pos: companyAccess[3]?.selected,
        stores: companyAccess[4]?.selected,
        taxes: companyAccess[5]?.selected,
        paymentChannels: companyAccess[6]?.selected,
        subscriptions: companyAccess[7]?.selected,
        logBook: companyAccess[8]?.selected,
      });
    }
  }, [companyAccess]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={
          companyForEdit && companyForEdit._id
            ? companyForEdit
            : {
                ...initCompanyState,
                ...initMessageState,
                ...initTearmsAndConditionsState,
              }
        }
        onSubmit={(values) => {}}
      >
        {({ handleSubmit }) => (
          <>
            <div className="action-bar mb-2" style={{ justifyContent: "end" }}>
              <div className="d-flex align-items-right">
                {userCompanyAccess?.logBook ? <button className="btn btn-pri btn-sm btn-rounded">{i18n("Company.LogBook")}</button> : ""}
                {userCompanyAccess?.subscriptions ? (
                  <button className="btn btn-pri btn-sm btn-rounded" onClick={() => setOpenSubsDialog(true)}>
                    {i18n("global.SUBSCRIPTIONS")}
                  </button>
                ) : (
                  ""
                )}
                {userCompanyAccess?.paymentChannels ? (
                  <button className="btn btn-pri btn-sm btn-rounded" onClick={() => setOpenPaymentTypeDialog(true)}>
                    {i18n("global.PAYMENTCHANNELS")}
                  </button>
                ) : (
                  ""
                )}
                {userCompanyAccess?.taxes ? (
                  <button className="btn btn-pri btn-sm btn-rounded" onClick={() => setOpenTaxesDialog(true)}>
                    {i18n("global.TAXES")}
                  </button>
                ) : (
                  ""
                )}
                {userCompanyAccess?.stores ? (
                  <button className="btn btn-pri btn-sm btn-rounded" onClick={() => setOpenStoresDialog(true)}>
                    {i18n("global.STORES")}
                  </button>
                ) : (
                  ""
                )}
                {userCompanyAccess?.pos ? (
                  <button className="btn btn-pri btn-sm btn-rounded" onClick={() => setOpenPosDialog(true)}>
                    {i18n("global.POS")}
                  </button>
                ) : (
                  ""
                )}
                {userCompanyAccess?.users ? (
                  <button className="btn btn-pri btn-sm btn-rounded" onClick={() => setOpenUserProfilePage(true)}>
                    {i18n("Company.USERS")}
                  </button>
                ) : (
                  ""
                )}
                {userCompanyAccess?.roles ? (
                  <button className="btn btn-pri btn-sm btn-rounded " onClick={() => setOpenUserRoleDialog(true)}>
                    {i18n("Company.ROLES")}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
            {/* <Card>
              <CardBody>
                <Modal.Body className="overlay overlay-block cursor-default">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right">
                    <div className="row">
                      <div className="col-lg-4 text-center">
                        <div className="company-section-title">
                          <div className="title-text text-left">{i18n("Company.CompanyLogo")}</div>
                        </div>
                        <div className="img-company-logo">
                          {image?.length ? (
                            (console.log(image, "image"),
                            image.map((image, index) => <img key={index} src={image["data_url"]} alt="loading" width="100" />))
                          ) : (
                            <img src={companyForEdit?.image ? companyForEdit?.image : CompanyLogo} alt="loading" width="100" />
                          )}
                        </div>
                        <ImageUploading value={image} onChange={onChange} dataURLKey="data_url">
                          {({ imageList, onImageUpload }) => (
                            <>
                              {userCompanyAccess?.companyInfo && userAccess?.editData ? (
                                <button
                                  className="btn btn-pri btn-elevate f-button btn-upload float-left company-button"
                                  onClick={(e) => imageChangeHandler(e, onImageUpload)}
                                >
                                  <span className="fas fa-pen mr-2" />
                                  {i18n("Customer.Edit")}
                                </button>
                              ) : (
                                ""
                              )}
                              {image?.length ? (
                                <button
                                  type="submit"
                                  onClick={() => {
                                    saveCompany({
                                      _id: companyForEdit._id,
                                      image: image[0]?.data_url,
                                    });
                                    setImage([]);
                                  }}
                                  className="btn btn-pri btn-elevate f-button btn-upload float-left company-button ml-5"
                                >
                                  Save Image
                                </button>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </ImageUploading>
                      </div>
                      <div className="col-lg-4">
                        <div className="col-lg-12">
                          <div className="company-section-title">
                            <div className="title-text">{i18n("Company.CompanyPolicy")}</div>
                          </div>
                        </div>
                        <div className="row my-2 px-5">
                          <div className="col-lg-12">
                            <Field
                              name="termsAndConditions"
                              readOnly={true}
                              disabled={true}
                              applyClasses={false}
                              rows={3}
                              component={TextArea}
                              withFeedbackLabel={false}
                              label={i18n("Company.EnterTerms&Conditions")}
                            />
                          </div>
                        </div>
                        <div className="row my-2 px-5">
                          <div className="col-lg-12">
                            <Field
                              name="returnPolicy"
                              readOnly={true}
                              disabled={true}
                              applyClasses={false}
                              rows={3}
                              component={TextArea}
                              withFeedbackLabel={false}
                              label={i18n("Company.EnterReturn&ReplacementPolicy")}
                            />
                          </div>
                        </div>
                        <div className="row my-5 px-5">
                          <div className="col-lg-12">
                            {userCompanyAccess?.companyInfo && userAccess?.editData ? (
                              <button
                                type="submit"
                                className="btn btn-primary btn-elevate f-button company-button"
                                onClick={() => setOpenTermsAndConditionsDialog(true)}
                              >
                                <span className="fas fa-pen mr-2"></span>
                                {i18n("Customer.Edit")}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-9">
                      <div className="col-lg-4">
                        <div className="company-section-title">
                          <div className="title-text">{i18n("Company.CompanyDetails")}</div>
                        </div>
                        <div className="mt-10">
                          <div className="company-space-between">
                            <div>{i18n("Company.CompanyName")}</div>
                            <span>{companyForEdit?.companyName}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.EmailAddress")}</div>
                            <span>{companyForEdit?.email}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.PhoneNumber")}</div>
                            <span>{companyForEdit?.mobile}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.Currency")}</div>
                            <span>{companyForEdit?.currency}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.TaxNumber")}</div>
                            <span>{companyForEdit?.taxNumber}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.CR#")}</div>
                            <span>{companyForEdit?.crNumber}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.Country")}</div>
                            <span>{companyForEdit?.country}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.State/Province")}</div>
                            <span>{companyForEdit?.state}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.Street")}</div>
                            <span>{companyForEdit?.street}</span>
                          </div>
                          <div className="company-space-between mt-5">
                            <div>{i18n("Company.POBOX/ZipCode")}</div>
                            <span>{companyForEdit?.zipcode}</span>
                          </div>
                        </div>
                        <div>
                          {userCompanyAccess?.companyInfo && userAccess?.editData ? (
                            <button className="btn btn-primary btn-elevate f-button company-button mt-5" onClick={(e) => companyInfoHandler(e)}>
                              <span className="fas fa-pen mr-2"></span>
                              {i18n("Customer.Edit")}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="company-section-title">
                          <div className="title-text">{i18n("Company.DefaultEmail/SMSMessage")}</div>
                        </div>
                        <div className="mt-7">
                          <div className="mb-2">
                            <Field
                              rows={2}
                              readOnly={true}
                              disabled={true}
                              applyClasses={false}
                              name="defaultEmail.messageEnglish"
                              component={TextArea}
                              withFeedbackLabel={false}
                              label={i18n("Company.EnterDefaultEmailMessageEnglish")}
                            />
                          </div>
                          <div className="mb-2">
                            <Field
                              rows={2}
                              readOnly={true}
                              disabled={true}
                              applyClasses={false}
                              name="defaultEmail.messageArabic"
                              component={TextArea}
                              withFeedbackLabel={false}
                              label={i18n("Company.EnterDefaultEmailMessageArabic")}
                            />
                          </div>
                          <div className="mb-2">
                            <Field
                              rows={2}
                              readOnly={true}
                              disabled={true}
                              applyClasses={false}
                              name="defaultSms.messageEnglish"
                              component={TextArea}
                              withFeedbackLabel={false}
                              label={i18n("Company.EnterDefaultSMSMessageEnglish")}
                            />
                          </div>
                          <div className="mb-2">
                            <Field
                              rows={2}
                              readOnly={true}
                              disabled={true}
                              applyClasses={false}
                              name="defaultSms.messageArabic"
                              component={TextArea}
                              withFeedbackLabel={false}
                              label={i18n("Company.EnterDefaultSMSMessageArabic")}
                            />
                          </div>
                        </div>
                        <div>
                          {userCompanyAccess?.companyInfo && userAccess?.editData ? (
                            <button
                              type="submit"
                              className="btn btn-primary btn-elevate f-button company-button mt-5"
                              onClick={() => setOpenCompanyMessageDialog(true)}
                            >
                              <span className="fas fa-pen mr-2"></span>
                              {i18n("Customer.Edit")}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
              </CardBody>
            </Card> */}
          </>
        )}
      </Formik>
      <CompanyEditDialog
        showCompanyEditDialog={showCompanyEditDialog}
        secondaryAction={() => {
          setShowCompanyEditDialog(false);
        }}
        saveCompany={saveCompany}
        companyForEdit={companyForEdit || initCompanyState}
      />
      <CompanyMessageDialog
        openCompanyMessageDialog={openCompanyMessageDialog}
        companyForEdit={companyForEdit || initMessageState}
        secondaryAction={() => {
          setOpenCompanyMessageDialog(false);
        }}
        saveMessage={saveCompany}
      />
      <CompanyTermsAndConditionsDialog
        companyForEdit={companyForEdit || initTearmsAndConditionsState}
        openTermsAndConditionsDialog={openTermsAndConditionsDialog}
        secondaryAction={() => {
          setOpenTermsAndConditionsDialog(false);
        }}
        saveMessage={saveCompany}
      />
      <PaymentTypeDialog
        userAccess={userAccess}
        companyForEdit={companyForEdit}
        openPaymentTypeDialog={openPaymentTypeDialog}
        secondaryAction={() => {
          setOpenPaymentTypeDialog(false);
        }}
        savePayments={saveCompany}
      />
      <UserProfilePage userAccess={userAccess} openUserProfilePage={openUserProfilePage} setOpenUserProfilePage={setOpenUserProfilePage} />
      <UserRole userAccess={userAccess} openUserRoleDialog={openUserRoleDialog} setOpenUserRoleDialog={setOpenUserRoleDialog} />
      <Taxes userAccess={userAccess} openTaxesDialog={openTaxesDialog} setOpenTaxesDialog={setOpenTaxesDialog} />
      <Stores userAccess={userAccess} openStoresDialog={openStoresDialog} setOpenStoresDialog={setOpenStoresDialog} />
      <Pos userAccess={userAccess} openPosDialog={openPosDialog} setOpenPosDialog={setOpenPosDialog} />
      <Subscription openSubsDialog={openSubsDialog} setOpenSubsDialog={setOpenSubsDialog} />
    </>
  );
};
export default connect(null, auth.actions)(CompanyView);
