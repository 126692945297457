import { createSlice } from "@reduxjs/toolkit";
import { getPaymentCard, savePaymentCard, verifyCompanyPaymentCard } from "./paymentService";

const initialRolesState = {
  paymentCard: null,
  savePaymentCardLoading: false,
  savePaymentCardError: null,
  verifyCompanyPaymentCardLoading: false,
  verifyCompanyPaymentCardError: null,
  getPaymentCardLoading: false,
  getPaymentCardError: null,
};

export const paymentCardSlice = createSlice({
  name: "paymentCard",
  initialState: initialRolesState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(savePaymentCard.pending, (state) => {
        state.savePaymentCardLoading = true;
        state.savePaymentCardError = null;
      })
      .addCase(savePaymentCard.fulfilled, (state, action) => {
        state.savePaymentCardLoading = false;
        state.savePaymentCardError = null;
        state.paymentCard = action.payload;
      })
      .addCase(savePaymentCard.rejected, (state, action) => {
        state.savePaymentCardLoading = false;
        state.savePaymentCardError = action.error.message;
      })
      .addCase(verifyCompanyPaymentCard.pending, (state) => {
        state.verifyCompanyPaymentCardLoading = true;
        state.savePaymentCardError = null;
      })
      .addCase(verifyCompanyPaymentCard.fulfilled, (state, action) => {
        state.verifyCompanyPaymentCardLoading = false;
        state.savePaymentCardError = null;
        state.paymentCard = action.payload;
      })
      .addCase(verifyCompanyPaymentCard.rejected, (state, action) => {
        state.verifyCompanyPaymentCardLoading = false;
        state.savePaymentCardError = action.error.message;
      })
      .addCase(getPaymentCard.pending, (state) => {
        state.getPaymentCardLoading = true;
        state.getPaymentCardError = null;
      })
      .addCase(getPaymentCard.fulfilled, (state, action) => {
        state.getPaymentCardLoading = false;
        state.getPaymentCardError = null;
        state.paymentCard = action.payload;
      })
      .addCase(getPaymentCard.rejected, (state, action) => {
        state.getPaymentCardLoading = false;
        state.getPaymentCardError = action.error.message;
      });
  },
});
