import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import registerSuccessIcon from "../../../../assets/img/register-success.svg";
import { i18n } from "../../../pages/private/languageSelector";

const RegistrationNext = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div style={{ backgroundColor: "white", height: "100%" }}>
      <div class="row" style={{ marginTop: "14%" }}>
        <div className="col-12 text-center mb-5">
          <img src={registerSuccessIcon} alt="loading" />
        </div>
        <div className="col-12 mt-5 text-center">
          <h2>{i18n("SignUpNext.ThankYouForRegistering")}!</h2>
          <p>
            {i18n("SignUpNext.PleaseConfirmTheCorrectnessOfYourEmailAddressAConfirmationLetterHasBeenSentTo")}{" "}
            <b>{location?.state}</b>.
          </p>
        </div>
        <div className="col-11" style={{ marginTop: "14%" }}>
          <NavLink className="menu-link float-right" to="/logout">
            <p onClick={() => history.push("/auth/login")}>
              <b>{i18n("SignUpNext.GOTOLOGIN")}</b>
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default RegistrationNext;
