import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { InvoiceStatusCssClasses } from "../InvoicesUIHelpers";
import * as actions from "../../../../_redux/invoices/invoicesActions";
import { useInvoicesUIContext } from "../InvoicesUIContext";

const selectedInvoices = (entities, ids) => {
  const _invoices = [];
  ids.forEach((id) => {
    const invoice = entities.find((el) => el.id === id);
    if (invoice) {
      _invoices.push(invoice);
    }
  });
  return _invoices;
};

export function InvoicesUpdateStateDialog({ show, onHide }) {
  // Invoices UI Context
  const invoicesUIContext = useInvoicesUIContext();
  const invoicesUIProps = useMemo(() => {
    return {
      ids: invoicesUIContext.ids,
      setIds: invoicesUIContext.setIds,
      queryParams: invoicesUIContext.queryParams,
    };
  }, [invoicesUIContext]);

  // Invoices Redux state
  const { invoices, isLoading, invoiceFilterState } = useSelector(
    (state) => ({
      invoices: selectedInvoices(state.invoices.entities, invoicesUIProps.ids),
      isLoading: state.invoices.actionsLoading,
      invoiceFilterState: state?.invoices?.invoiceFilterState,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!invoicesUIProps.ids || invoicesUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for update invoices status by selected ids
    dispatch(actions.updateInvoicesStatus(invoicesUIProps.ids, status)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchInvoices(invoiceFilterState, invoicesUIProps.queryParams)).then(() => {
        // clear selections list
        invoicesUIProps.setIds([]);
        // closing delete modal
        onHide();
      });
    });
  };

  return (
    <Modal
      show={show}
      // onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">Status has been updated for selected invoices</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {/*begin::Loading*/}
        {isLoading && (
          <div className="overlay-layer">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        {/*end::Loading*/}

        <div className="timeline timeline-5 mt-3">
          {invoices.map((invoice) => (
            <div className="timeline-item align-items-start" key={`invoicesUpdate${invoice.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i className={`fa fa-genderless text-${InvoiceStatusCssClasses[invoice.status]} icon-xxl`} />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span className={`label label-lg label-light-${InvoiceStatusCssClasses[invoice.status]} label-inline`}>
                  ID: {invoice.id}
                </span>
                <span className="ml-3">
                  {invoice.lastName}, {invoice.firstName}
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select className="form-control" value={status} onChange={(e) => setStatus(+e.target.value)}>
            <option value="0">Suspended</option>
            <option value="1">Active</option>
            <option value="2">Pending</option>
          </select>
        </div>
        <div className="form-group">
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate mr-3">
            Cancel
          </button>
          <button type="button" onClick={updateStatus} className="btn btn-primary btn-elevate">
            Delete All
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
