import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { RolesEditForm } from "./RoleEditForm";
import IconBin from "../../../../../assets/img/bin.svg";
import { i18n } from "../../../private/languageSelector";
import IconPen from "../../../../../assets/img/icon-pen.svg";
import ConfirmationDialoge from "../../../confirmationDialoge";
import IconClose from "../../../../../assets/img/icon-close.svg";
import IconTrash from "../../../../../assets/img/icon-trash.svg";
import * as rolesActions from "../../../../../_redux/roles/rolesActions";
import { getUserByToken } from "../../../../../app/modules/Auth/_redux/authCrud";

export const ManageUserRole = ({ roles, userAccess, openManageRoleDialog, setOpenManageRoleDialog }) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [roleForEdit, setRoleForEdit] = useState();
  const [loggedInUser, setloggedInUser] = useState();
  const [searchText, setSearchText] = useState("");
  const [roleDeleteId, setRoleDeleteId] = useState("");
  const [openRolesAddDialog, setOpenRolesAddDialog] = useState(false);
  const [confirmDialogForClose, setConfirmDialogForClose] = useState(false);
  const [confirmDialogForDelete, setConfirmDialogForDelete] = useState(false);

  useEffect(() => {
    getUserByToken().then((res) => {
      setloggedInUser(res?.data);
    });
  }, [openManageRoleDialog, openRolesAddDialog]);

  const deleteRoleHandler = () => {
    if (roleDeleteId) {
      dispatch(rolesActions.deleteRole(roleDeleteId))
        .then(() => {
          setConfirmDialogForClose(false);
          dispatch(rolesActions.fetchRoles());
        })
        .catch((error) => {
          error.clientMessage = "Can't delete Role";
          setConfirmDialogForDelete(true);
        });
    }
  };
  return (
    <>
      <Modal
        size="lg"
        onHide={() => {}}
        show={openManageRoleDialog}
        className="modal-drawer roles-drawer"
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header>
          <button type="button" className="btn btn-close" onClick={() => setOpenManageRoleDialog(false)}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("Company.RolesManagement")}</Modal.Title>
        </Modal.Header>
        <Formik>
          {() => {
            return (
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <span>Search</span>
                  <div className="input-group">
                    <input
                      className="form-control mr-2"
                      placeholder="Enter Role Name"
                      type="text"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <ul className="card-listitem list-unstyled">
                    <div className="h4 d-flex ml-2 w-100">
                      <b style={{ width: "220px" }}>Role</b>
                      <b>Access</b>
                    </div>
                    {roles?.length
                      ? roles
                          ?.filter((it) => {
                            return it.name?.toLowerCase()?.includes(searchText?.toLowerCase());
                          })
                          .map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="card-info d-flex w-100">
                                  <div style={{ width: "165px" }}>
                                    <b className="heading-color truncate-160 d-inline-block h6">
                                      {item?.name.charAt(0).toUpperCase() + item?.name.slice(1)}
                                    </b>
                                    <p className="mb-0 sub-heading-color">{item?.users?.length} Employees</p>
                                    <p className="mb-0 sub-heading-color">
                                      {item.pos === true && item.backOffice === true
                                        ? "Back office & POS"
                                        : item.pos === true && item.backOffice === false
                                        ? "POS"
                                        : item.pos === false && item.backOffice === true
                                        ? "Back office"
                                        : ""}
                                    </p>
                                  </div>
                                  <div className="card-actions ml-auto">
                                    {userAccess?.editData ? (
                                      loggedInUser.rolePayload.originalName === "owner" ? (
                                        <span className="action action-edit">
                                          <img
                                            title="Edit"
                                            src={IconPen}
                                            alt="loading"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setEdit(true);
                                              setRoleForEdit(item);
                                              setOpenRolesAddDialog(true);
                                            }}
                                          />
                                        </span>
                                      ) : null
                                    ) : null}

                                    {userAccess?.deleteData ? (
                                      <span className="action action-delete">
                                        {item?.originalName === "owner" ||
                                        item?.originalName === "manager" ||
                                        item?.originalName === "cashier" ? (
                                          <img title="Delete" src={IconBin} alt="loading" />
                                        ) : (
                                          <img
                                            title="Delete"
                                            style={{ cursor: "pointer" }}
                                            src={IconTrash}
                                            alt="loading"
                                            onClick={() => {
                                              setRoleDeleteId(item._id);
                                              setConfirmDialogForClose(true);
                                            }}
                                          />
                                        )}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          })
                      : ""}
                  </ul>
                </Form>
              </Modal.Body>
            );
          }}
        </Formik>
        <Modal.Footer>
          <div className="row justify-content-center">
            <button
              className="btn btn-outline-primary btn-elevate mr-5"
              onClick={() => {
                setOpenManageRoleDialog(false);
              }}>
              Back
            </button>
            {userAccess?.createData ? (
              <button
                className="btn btn-primary btn-elevate f-button"
                onClick={() => {
                  setRoleForEdit();
                  setEdit(false);
                  setOpenRolesAddDialog(true);
                }}>
                Add New
              </button>
            ) : (
              ""
            )}
          </div>
        </Modal.Footer>
      </Modal>
      <RolesEditForm
        edit={edit}
        roleForEdit={roleForEdit}
        disabled={
          loggedInUser?.rolePayload?.name === roleForEdit?.name
            ? true
            : roleForEdit?.originalName === "owner"
            ? true
            : false
        }
        nameDisabled={roleForEdit?.originalName === "owner" && loggedInUser?.rolePayload?.originalName !== "owner"}
        openRolesAddDialog={openRolesAddDialog}
        setOpenRolesAddDialog={setOpenRolesAddDialog}
      />
      <ConfirmationDialoge
        show={confirmDialogForClose}
        title="Delete Role!"
        subTitle="Are you sure you want to delete this Role?"
        primaryAction={() => deleteRoleHandler()}
        secondaryAction={() => setConfirmDialogForClose(false)}
        primaryActionTitle="Delete"
        secondaryActionTitle="Back"
        classes="modal-drawer"
        heading="Delete Role"
        headingColor="#ED553B"
        viewButon={true}
      />
      <ConfirmationDialoge
        show={confirmDialogForDelete}
        secondaryAction={() => {
          setConfirmDialogForDelete(false);
          setConfirmDialogForClose(false);
        }}
        title="Delete Role!"
        subTitle="There are users linked to this role! Kindly serach for theose users and change thier role before you will be able to delete this role."
        classes="modal-drawer"
        heading="Can't Delete Role!"
        headingColor="#ED553B"
        viewButon={false}
        buttonText={"Back"}
      />
    </>
  );
};
