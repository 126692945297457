import React, { useState } from "react";
import IconClose from "../../../../../../assets/img/icon-close.svg";
import IconDelete from "../../../../../../assets/img/invoice-actions/delete.svg";
import IconAssign from "../../../../../../assets/img/assign.svg";
import IconExcel from "../../../../../../assets/img/group-invoice-actions/list-excel.svg";
import IconPdf from "../../../../../../assets/img/group-invoice-actions/list-pdf.svg";
import * as actions from "../../../../../../_redux/products/productsActions";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { ProductsAssignToCategory } from "./productsAssignToCategory";
import { ProductsDeleteDialog } from "./ProductsDeleteModal";
import { i18n } from "../../../../../pages/private/languageSelector";

export const ProductGroupActionModal = ({
  setIds,
  userAccess,
  openGroupDialog,
  selectedProducts,
  setOpenGroupDialog,
}) => {
  const dispatch = useDispatch();
  const [openAssignCategoryDialog, setOpenAssignCategoryDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const deleteProducts = () => {
    if (selectedProducts?.length) {
      dispatch(actions.deleteProducts(selectedProducts)).then(() => {
        toast.success("Products Deleted Successfully");
        setOpenGroupDialog(false);
        setIds([]);
      });
    } else {
      toast.error("No Product Selected");
    }
  };

  return (
    <>
      <Modal
        size="lg"
        onHide={() => {}}
        show={openGroupDialog}
        className="modal-drawer"
        aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header>
          <button
            type="button"
            className="btn btn-close"
            onClick={() => {
              setOpenGroupDialog(false);
            }}>
            <img src={IconClose} alt="loading" />
          </button>
          <Modal.Title>{i18n("MENU.GroupActions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {userAccess?.deleteData ? (
            <div className="product-action" onClick={() => setOpenDeleteDialog(true)}>
              <img src={IconDelete} alt="loading" />
              <b className={!userAccess?.deleteData ? "text-muted" : ""}>{i18n("MENU.Delete")}</b>
            </div>
          ) : (
            ""
          )}
          {userAccess?.editData ? (
            <div className="product-action" onClick={() => setOpenAssignCategoryDialog(true)}>
              <img src={IconAssign} alt="loading" />
              <b className={!userAccess?.editData ? "text-muted" : ""}>{i18n("MENU.AssignToCategory")}</b>
            </div>
          ) : (
            ""
          )}
          <div className="product-action">
            <img src={IconExcel} alt="loading" />
            <b>{i18n("MENU.ExportListToExcel")}</b>
          </div>
          <div className="product-action">
            <img src={IconPdf} alt="loading" />
            <b>{i18n("MENU.ExportListToPdf")}</b>
          </div>
        </Modal.Body>
      </Modal>
      <ProductsAssignToCategory
        openAssignCategoryDialog={openAssignCategoryDialog}
        setOpenAssignCategoryDialog={setOpenAssignCategoryDialog}
        setOpenGroupDialog={setOpenGroupDialog}
        productsIds={selectedProducts}
        setIds={setIds}
      />
      <ProductsDeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        deleteProducts={deleteProducts}
      />
    </>
  );
};
