import axios from "axios";

export const Taxes_URL = "api/tax";

export function getTaxById(taxId) {
  return axios.get(`${Taxes_URL}/${taxId}`);
}

export function getAllTaxes() {
  return axios.get(`${Taxes_URL}`);
}

export function createTax(tax) {
  return axios.post(`${Taxes_URL}`, tax);
}

export function updateTax(id, tax) {
  return axios.patch(`${Taxes_URL}/${id}`, tax);
}

export function deleteTax(taxId) {
  return axios.delete(`${Taxes_URL}/${taxId}`);
}
