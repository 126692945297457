import React, { useEffect, useState } from "react";
import chromeImg from "../../../assets/img/ChromeIcon.svg";
import appStrore from "../../../assets/img/AppStore.svg";
import googlePlay from "../../../assets/img/GooglePlay.svg";
import TabLaptopImg from "../../../assets/img/laptopTabImg.png";

const HomeContainer = () => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  console.log(screenSize);

  return (
    <div className="pos-wrapper">
      {" "}
      <div className="pos-view p-4 align-center">
        <div style={{ maxWidth: "1200px", margin: "0 auto" }}>
          <div className="row pos-view-wrapper">
            <div className="col-lg-7 pt-3">
              <h2 className=" pt-2" style={{ fontWeight: "700", color: "#333333", textAlign: screenSize < 1006 ? "center" : "" }}>
                Free POS App
              </h2>
              {screenSize > 1006 ? (
                <h3 className="mb-2" style={{ fontWeight: "400", color: "#333333", marginTop: "49px" }}>
                  Empower your tablet for sales, Manage invoices, track customers, boost revenue, and enhance your brand.
                </h3>
              ) : null}

              {screenSize > 1006 ? (
                <button
                  style={{
                    height: "47px",
                    marginTop: "63px",
                    borderRadius: "8px",
                    border: "0",
                    backgroundColor: "#9B51E0",
                    color: "#FFFFFF",
                  }}
                >
                  <p
                    style={{
                      margin: "0",
                      padding: "8px 28px",
                      fontWeight: "700",
                      fontSize: "16px",
                      lineHeight: "21.79px",
                    }}
                  >
                    TRY FATORATY NOW
                  </p>
                </button>
              ) : null}
            </div>
            <div className="laptop-img-wrapper col-lg-4" style={{ marginTop: "30px" }}>
              <div className="img-wrapper">
                <img className="laptopImg" src={TabLaptopImg} alt="" />
              </div>
              <div>
                {screenSize < 1006 ? (
                  <h3
                    className="mb-2"
                    style={{ fontWeight: "400", color: "#333333", marginTop: "22px", textAlign: screenSize < 1006 ? "center" : "" }}
                  >
                    Empower your tablet for sales, Manage invoices, track customers, boost revenue, and enhance your brand.
                  </h3>
                ) : null}
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div style={{ maxWidth: "1200px", margin: "0 auto", padding: screenSize < 700 ? "0 20px" : "0 18px" }}>
        <div className="media_links" style={{ margin: screenSize < 700 ? "30px 0" : "21px 0" }}>
          <div className="row">
            <div className="col-lg-2 col-4">
              <img style={{ width: "100%" }} src={chromeImg} alt="" />
            </div>
            <div className="col-lg-2 col-4">
              <img style={{ width: "100%" }} src={appStrore} alt="" />
            </div>
            <div className="col-lg-2 col-4">
              <img style={{ width: "100%" }} src={googlePlay} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
