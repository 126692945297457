import React, { useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import IconClose from "../../../../../assets/img/icon-close.svg";
import SubscriptionDialog from "../../company-edit-Dialog/SubscriptionDialog";
import { updateCompany } from "../../../../../_redux/company/companyActions";
import { useDispatch, useSelector } from "react-redux";
import { i18n } from "../../../private/languageSelector";

const EditInformation = ({
  openEditInformationDialog,
  setOpenEditInformationDialog,
  openEditInformationDialogValue,
  companyInformationData,
}) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.auth);

  const [formChanged, setFormChanged] = useState(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const formRef = useRef(null);

  const getInitialValues = () => {
    let initialValue = "";
    if (openEditInformationDialogValue === "SMS_MESSAGE") {
      initialValue = companyInformationData?.defaultSms?.messageEnglish || "";
    } else if (openEditInformationDialogValue === "TERM_CONDITIONS") {
      initialValue = companyInformationData?.termsAndConditions || "";
    } else if (openEditInformationDialogValue === "RETURN_POLICY") {
      initialValue = companyInformationData?.returnPolicy || "";
    }
    return { textareaValue: initialValue };
  };

  const saveForm = async (values) => {
    try {
      let updateData = {};
      if (openEditInformationDialogValue === "SMS_MESSAGE") {
        updateData = { defaultSms: { messageEnglish: values.textareaValue } };
      } else if (openEditInformationDialogValue === "TERM_CONDITIONS") {
        updateData = { termsAndConditions: values.textareaValue };
      } else if (openEditInformationDialogValue === "RETURN_POLICY") {
        updateData = { returnPolicy: values.textareaValue };
      }
      await dispatch(
        updateCompany({ _id: companyInformationData._id, ...updateData })
      );
      setFormChanged(false);
      setOpenEditInformationDialog(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    if (formChanged) {
      setShowSubscriptionDialog(true);
    } else {
      setOpenEditInformationDialog(false);
    }
  };

  const handleFormChange = () => {
    setFormChanged(true);
  };

  return (
    <>
      <Modal
        className={`${language === "arabic" && "ar"}  modal-drawer`}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
        show={openEditInformationDialog}
        onHide={handleClose}
      >
        <Formik
          initialValues={getInitialValues()}
          enableReinitialize={true}
          onSubmit={saveForm}
          innerRef={formRef}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Header
                className="d-flex justify-content-around"
                style={{
                  direction: language === "arabic" ? "rtl" : "ltr",
                }}
              >
                <img
                  src={IconClose}
                  alt="close"
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
                <Modal.Title>
                  {openEditInformationDialogValue === "SMS_MESSAGE"
                    ? i18n("Company.EmailSmsMessage")
                    : openEditInformationDialogValue === "TERM_CONDITIONS"
                    ? i18n("Company.Term&Conditions")
                    : openEditInformationDialogValue === "RETURN_POLICY"
                    ? i18n("Company.ReturnPolicy")
                    : null}
                </Modal.Title>
                <Modal.Title
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {i18n("UserProfile.Save")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form
                  className="form form-label-right"
                  onChange={handleFormChange}
                >
                  <div
                    style={{
                      direction: language === "arabic" ? "rtl" : "ltr",
                      textAlign: language === "arabic" ? "right" : "left",
                    }}
                  >
                    <label htmlFor="textarea">
                      {openEditInformationDialogValue === "SMS_MESSAGE"
                        ? i18n("Company.CompanyDefaultEmail/SMSMessageForEdit")
                        : openEditInformationDialogValue === "TERM_CONDITIONS"
                        ? i18n("Company.Term&Conditions")
                        : openEditInformationDialogValue === "RETURN_POLICY"
                        ? i18n("Company.EnterReturn&ReplacementPolicy")
                        : null}
                    </label>
                    <Field
                      style={{ border: "2px solid #E0E0E0" }}
                      as="textarea"
                      name="textareaValue"
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="6"
                      placeholder={
                        openEditInformationDialogValue === "SMS_MESSAGE"
                          ? i18n("Company.YourDefaultEmail/SMSMessageGoesHere")
                          : openEditInformationDialogValue === "TERM_CONDITIONS"
                          ? i18n("Company.TypeTerms&ConditionsHere")
                          : openEditInformationDialogValue === "RETURN_POLICY"
                          ? i18n("Company.YourReturn&ReplacementPolicyhere")
                          : ""
                      }
                    />
                  </div>
                </Form>
              </Modal.Body>
            </>
          )}
        </Formik>
      </Modal>

      {/* unsaved modal */}
      <SubscriptionDialog
        modalWidth={"350px"}
        modalHeight={"185px"}
        modalBackgroundColor={"#FFFFFF"}
        show={showSubscriptionDialog}
        onHide={() => setShowSubscriptionDialog(false)}
        title={i18n("SubscriptionModals.UnsavedChanges")}
        titleWidth={"140px"}
        titleHeight={"20px"}
        titleFontWeight={"700"}
        titleFontSize={"16px"}
        titleLineHeight={"20px"}
        titleColor={"#333333"}
        message={i18n(
          "SubscriptionModals.AreYouSureYouWantToContinueWithoutSavingChanges"
        )}
        messageWidth={"320px"}
        messageHeight={"44px"}
        messageFontSize={"16px"}
        messageFontWeight={"400"}
        messageLineHeight={"21.79px"}
        messageColor={"#333333"}
        primaryLabel={i18n("SubscriptionModals.CANCEL")}
        primaryAction={() => setShowSubscriptionDialog(false)}
        primaryLabelWidth={"55px"}
        primaryLabelHeight={"20px"}
        primaryLabelFontWeight={"700"}
        primaryLabelFontSize={"14px"}
        primaryLabelLineHeight={"20px"}
        primaryLabelColor={"#828282"}
        secondaryAction={() => {
          setOpenEditInformationDialog(false);
          setShowSubscriptionDialog(false);
        }}
        secondaryLabel={i18n("SubscriptionModals.CONTINUE")}
        secondaryLabelWidth={"75px"}
        secondaryLabelHeight={"20px"}
        secondaryLabelFontWeight={"700"}
        secondaryLabelFontSize={"14px"}
        secondaryLabelLineHeight={"20px"}
        secondaryLabelColor={"#2D9CDB"}
      />
    </>
  );
};

export default EditInformation;
