import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import isEqual from "lodash/isEqual";
import { useSelector } from "react-redux";
import { i18n } from "../../private/languageSelector";
import { CustomersDeleteModal } from "./CustomersDeleteModal";
import { useCustomersUIContext } from "../CustomersUIContext";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.name = searchText;
  if (searchText) {
    filter.name = searchText;
    filter.email = searchText;
    filter.mobile = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ groupActions, setGroupActions, selectedCustomers, setSelectedCustomers }) {
  const [openCustomersDeleteDialog, setOpenCustomersDeleteDialog] = useState(false);
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const { userAccess } = useSelector((state) => ({
    userAccess: state?.users?.userAccess,
  }));

  return (
    <>
      <Formik
        initialValues={{
          status: "",
          type: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}>
        {({ values, handleSubmit, handleBlur, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="action-bar">
              <div className="d-flex align-items-center">
                {userAccess?.deleteData ? (
                  <button
                    disabled={!selectedCustomers?.length}
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => setOpenCustomersDeleteDialog(true)}>
                    {i18n("Customer.Delete")}
                  </button>
                ) : (
                  ""
                )}
              </div>
              <input
                type="text"
                className="form-control"
                name="searchText"
                placeholder={i18n("global.Search")}
                onBlur={handleBlur}
                value={values.searchText}
                onChange={(e) => {
                  setFieldValue("searchText", e.target.value);
                  handleSubmit();
                }}
              />
              <div className="d-flex align-items-center justify-content-end">
                {userAccess?.createData ? (
                  <button
                    type="button"
                    className="btn btn-pri btn-sm btn-rounded"
                    onClick={() => customersUIProps.newCustomerButtonClick()}>
                    <span className="fas fa-plus mr-2" />
                    {i18n("Customer.Customer")}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
      <CustomersDeleteModal
        openCustomersDeleteDialog={openCustomersDeleteDialog}
        setOpenCustomersDeleteDialog={setOpenCustomersDeleteDialog}
        selectedCustomers={selectedCustomers}
        setSelectedCustomers={setSelectedCustomers}
        groupActions={groupActions}
        setGroupActions={setGroupActions}
      />
    </>
  );
}
